import {FaPencilAlt, FaTrash} from "react-icons/fa";
import React from "react";

const invoiceChargesTableColumns = (setSelectedRow, setEditModal, setDeleteModal) => {
    return [
        {
            Header: 'Date',
            accessor: 'date',
            width: 80
        },
        {
            Header: 'Job',
            accessor: 'job_number',
            width: 100
        },
        {
            Header: 'Rig',
            accessor: 'rig',
            width: 70
        },
        {
            Header: 'Client',
            accessor: 'client_name'
        },
        {
            Header: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Units',
            accessor: 'units',
            width: 60
        },
        {
            Header: 'Price',
            accessor: 'price',
            width: 100
        },
        {
            Header: 'Total',
            accessor: 'total',
            width: 100
        },
        {
            Header: 'Invoice #',
            accessor: 'invoice_number',
            width: 100
        },
        {
            Header: 'Created By',
            accessor: 'created_by'
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaPencilAlt
                            color='#A51E37'
                            title="Edit"
                            onClick={() => {
                                setSelectedRow(row);
                                setEditModal(true);
                            }}
                        />
                        {' '}
                        <FaTrash
                            color='#A51E37'
                            title="Remove"
                            onClick={() => {
                                const msg = <p>Are you sure that you want to remove the invoice charge?</p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                )
            }
        },
    ];
}

export default invoiceChargesTableColumns;

