import React, {useState} from 'react';
import Table from "../../../common/table/table";
import AssetsTable from "./assetsTable";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";

/**
 * AssetsBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const AssetsBody = (prop) => {
    const {filters, setFilters, tableData, setTableData, selectedRow, setSelectedRow} = prop;
    const [deactivateModal, setDeactivateModal] = useState({isOpen: false, message: <p></p>});
    const [reactivateModal, setReactivateModal] = useState({isOpen: false, message: <p></p>});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    const DeactivateHandler = () => {
        apiClient
            .post('deactivateAsset', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeactivateModal({...deactivateModal, isOpen: false})
            });
    }

    const ReactivateHandler = () => {
        apiClient
            .post('reactivateAsset', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setReactivateModal({...reactivateModal, isOpen: false})
            });
    }

    const DeleteHandler = () => {
        apiClient
            .post('deleteAsset', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    AssetsTable(setFilters, setSelectedRow, setReactivateModal, setDeactivateModal, setDeleteModal)
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'assets'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={deactivateModal}
                setModal={setDeactivateModal}
                title="Deactivate Asset"
                onConfirm={DeactivateHandler}
            />
            <ModalConfirmation
                modal={reactivateModal}
                setModal={setReactivateModal}
                title="Reactivate Asset"
                onConfirm={ReactivateHandler}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Delete Asset"
                onConfirm={DeleteHandler}
            />
        </div>
    );
}

export default AssetsBody;
