import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import {useFieldArray} from "react-hook-form";
import EditSelectInTable from "../../../../forms/form-control/daily/EditSelectInTable";

const Bit = (props) => {
    const {
        errors, register, control, collectedHoles, fieldDaily, action, disciplineId, fieldDrillBitsTemplate,
        fieldBitsTemplate
    } = props;
    const kindOfBit = [1, 2].includes(disciplineId) ? 'bit' : 'drill_bit';
    const template = [1, 2].includes(disciplineId) ? fieldBitsTemplate : fieldDrillBitsTemplate;
    const status = [1, 2].includes(disciplineId) ? 'bit_status' : 'status';
    const size = [1, 2].includes(disciplineId) ? 'bit_size' : 'size';
    const type = [1, 2].includes(disciplineId) ? 'bit_type' : 'type';
    const {fields, append, remove} = useFieldArray({control, name: kindOfBit});
    const [selectedHole, setSelectedHole] = useState('');

    useEffect(() => {
        const fieldArr = fields;
        if (action === 'edit') {
            append([1, 2].includes(disciplineId) ? fieldDaily.field_bits : fieldDaily.field_drill_bits);
        }

        if (action === 'create' && template.length > 0) {
            // delete all field values and populate field array.
            remove();
            if (fieldArr.length > 0) {
                append(fieldArr);
            } else {
                append(template);
            }
        }

        if (action === 'create' && template.length === 0) {
            remove();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [append, fieldDaily.field_bits, fieldDaily.field_drill_bits, disciplineId, action, template, remove]);

    return (
        <AccordionItem className="card-header bg-primary" title="Bit">
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>Size</th>
                                    <th>Serial</th>
                                    {[3, 4].includes(disciplineId) &&
                                        <>
                                            <th>Depth In</th>
                                            <th>Depth Out</th>
                                            <th>Hours</th>
                                            <th>Minutes</th>
                                        </>
                                    }
                                    <th>Hole</th>
                                    <th className="action-cell">
                                        <FaPlus
                                            color='#22af47'
                                            size={20}
                                            title="Add New"
                                            onClick={() => append({})}
                                        />
                                        <FaEraser
                                            color='#A51E37'
                                            className="marginLeft"
                                            size={20}
                                            title="Remove All"
                                            onClick={() => remove()}
                                        />
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <SelectInTable
                                                className="short-select-width"
                                                id={`${kindOfBit}[${index}].${status}`}
                                                value={
                                                    [1, 2].includes(disciplineId)
                                                        ?
                                                            item.bit_status
                                                        :
                                                            item.status
                                                }
                                                name={`${kindOfBit}[${index}].${status}`}
                                                options={[{value: 1, label: 'New'}, {value: 2, label: 'Used'}]}
                                                register={register({required: {value: true, message: "required"}})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="short-select-width"
                                                id={`${kindOfBit}[${index}].${type}`}
                                                value={
                                                    [1, 2].includes(disciplineId)
                                                        ?
                                                            item.bit_type
                                                        :
                                                            item.type
                                                }
                                                name={`${kindOfBit}[${index}].${type}`}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`${kindOfBit}[${index}].${size}`}
                                                name={`${kindOfBit}[${index}].${size}`}
                                                value={
                                                    [1, 2].includes(disciplineId)
                                                        ?
                                                            item.bit_size
                                                        :
                                                            item.size
                                                }
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                id={`${kindOfBit}[${index}].serial_number`}
                                                value={(item.serial_number) ? item.serial_number : ''}
                                                name={`${kindOfBit}[${index}].serial_number`}
                                                register={register({required: false})}
                                            />
                                            {[3, 4].includes(disciplineId) &&
                                                <>
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`${kindOfBit}[${index}].bit_depth_in`}
                                                        name={`${kindOfBit}[${index}].bit_depth_in`}
                                                        type="number"
                                                        value={(item.bit_depth_in) ? item.bit_depth_in : ''}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                        register={register({required: false})}
                                                    />
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`${kindOfBit}[${index}].bit_depth_out`}
                                                        name={`${kindOfBit}[${index}].bit_depth_out`}
                                                        type="number"
                                                        value={(item.bit_depth_out) ? item.bit_depth_out : ''}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                        register={register({required: false})}
                                                    />
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`${kindOfBit}[${index}].bit_hours`}
                                                        name={`${kindOfBit}[${index}].bit_hours`}
                                                        type="number"
                                                        value={(item.bit_hours) ? item.bit_hours : ''}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                        register={register({required: false})}
                                                    />
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`${kindOfBit}[${index}].bit_minutes`}
                                                        name={`${kindOfBit}[${index}].bit_minutes`}
                                                        type="number"
                                                        value={(item.bit_minutes) ? item.bit_minutes : ''}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                        register={register({required: false})}
                                                    />
                                                </>
                                            }
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`${kindOfBit}[${index}].hole_name`}
                                                    name={`${kindOfBit}[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                    register={register({required: false})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <EditSelectInTable
                                                    id={`${kindOfBit}[${index}].hole_name`}
                                                    name={`${kindOfBit}[${index}].hole_name`}
                                                    value={
                                                        selectedHole
                                                            ?
                                                                selectedHole
                                                            :
                                                                (collectedHoles.length === 1)
                                                                    ?
                                                                        collectedHoles[0].value
                                                                    :
                                                                        item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedHole)
                                                    }
                                                    options={collectedHoles}
                                                    register={register({required: {value: false, message: "required"}})}
                                                    errors={errors}
                                                />
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => remove(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default React.memo(Bit);
