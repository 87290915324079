import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import {useFieldArray} from "react-hook-form";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import * as Shared from "../../../../common/shared";

const GeneralCharges = (props) => {
    const {errors, register, control, collectedHoles, generalDescriptions, fieldDaily, setValue, action} = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "general_charges"
    });
    const [selectedGeneralChargesHole, setSelectedGeneralChargesHole] = useState('');

    useEffect(() => {
        if (action === 'edit') {
            append(fieldDaily.field_general_charges);

            setTimeout(() => {
                fieldDaily.field_general_charges.forEach((item, index) => {
                    // populate select
                    setValue(
                        `general_charges[${index}].cost_code`,
                        {value: item.cost_code, label: item.description}
                    );
                });
            }, 500);
        }
    }, [action, append, fieldDaily, setValue]);

    return (
        <AccordionItem className="card-header bg-primary" title="General Charges / Equipment">
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th>Hole</th>
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <SearchableSelectInTable
                                                id={`general_charges[${index}].cost_code`}
                                                options={generalDescriptions}
                                                rules={{ required: "required" }}
                                                control={control}
                                                errors={errors}
                                                value={(action === 'edit') ? item.cost_code : ''}
                                                customStyles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: 700,
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 700,
                                                        position: 'sticky',
                                                    })
                                                }}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`general_charges[${index}].qty`}
                                                name={`general_charges[${index}].qty`}
                                                value={(action === 'edit') ? item.qty : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`general_charges[${index}].hole_name`}
                                                    name={`general_charges[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`general_charges[${index}].hole_name`}
                                                    name={`general_charges[${index}].hole_name`}
                                                    value={
                                                        selectedGeneralChargesHole
                                                            ?
                                                            selectedGeneralChargesHole
                                                            :
                                                            (collectedHoles.length === 1)
                                                                ?
                                                                collectedHoles[0].value
                                                                :
                                                                item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedGeneralChargesHole)
                                                    }
                                                    options={collectedHoles}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => remove(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default GeneralCharges;
