import { createContext, useContext } from "react";

export const initialState = {
    state: {},
    dispatch: (action) => {},
};

export const AppStateContext = createContext(initialState);

export const useSelector = (selectorFn) => {
    const value = useContext(AppStateContext);
    return selectorFn(value.state);
};

export const useDispatch = () => {
    const value = useContext(AppStateContext);
    return value.dispatch;
};

export function reducer(state, action) {
    switch (action.type) {
        // usecase for setTitle
        case "SET_TITLE":
            return {
                ...state,
                title: action.payload,
            };
        default:
            return state;
    }
}
