import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import ReportFilters from "./reportFilters";
import ReportBody from "./reportBody";
import moment from 'moment';
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Rec Report Component
 * @returns {JSX.Element}
 * @constructor
 */
const Rec = () => {

    const [tableData, setTableData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        date_from: moment().startOf('month').format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        discipline_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Rec Report" parent="Reports"/>
            <ReportFilters reportFilters={reportFilters} setTableData={setTableData} setReportFilters={setReportFilters}/>
            <ReportBody reportFilters={reportFilters} tableData={tableData}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
};

export default Rec;
