import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../utils/stateHelpers";
import {isEmpty} from "lodash";

/**
 * PayPeriodFormFilter component
 * @returns {JSX.Element}
 * @constructor
 */
const PayPeriodFormFilter = (prop) => {
    const {setFilters, setTableData, OnSubmitUrl} = prop;
    const {register, handleSubmit, errors, watch, setValue} = useForm();
    const [payPeriods, setPayPeriods] = useState([]);
    const [endDate, setEndDate] = useState(0);

    const pay_period_id = watch('pay_period_id');

    // get list of pay periods
    useEffect(() => {
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                if (typeof response.data !== 'undefined') {
                    // it is ugly but safari on mobile device doesn't want to operate with this date format
                    // temporary solution for now until time entry mobile app will be build
                    // do not remove commented out code!
                    const endDateArray = response.data.end_date.split('-');

                    // let year = Number(moment(new Date(response.data.end_date)).format('YYYY'));
                    // let year = new Date(response.data.end_date).getFullYear();
                    let year = 2000 + Number(endDateArray[2]);
                    setEndDate(year);
                }
            });

        apiClient
            .post('getPayPeriodsByYear', {'year': endDate})
            .then(response => {
                setPayPeriods(
                    response.data?.map(
                        ({id, start_date, end_date, is_current}) => {

                            if (is_current) {
                                setValue('pay_period_id', id);
                            }

                            return {
                                id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')
                            }
                        }
                    )
                );
            });
    }, [endDate, setValue]);

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post(OnSubmitUrl, data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
                updateStateValue(setFilters,'isLoading', false);
            });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row">
                <FormSelect
                    id="pay_period_id"
                    label="Pay Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={pay_period_id}
                    options={payPeriods}
                    onChange={(e) => setValue('pay_period_id', e.target.value)}
                    register={register('pay_period_id', {required: "required"})}
                    isControlled
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default PayPeriodFormFilter;
