import React from 'react';
import {Chart as ChartJS, ArcElement, Title, Tooltip, Legend} from 'chart.js';
import autocolors from 'chartjs-plugin-autocolors';
import {Pie} from 'react-chartjs-2';

ChartJS.register(ArcElement, Title, Tooltip, Legend, autocolors);

/**
 * PieChart component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PieChart(props) {

    const {labels, values, title} = props;

    const data = {
        labels: labels,
        datasets: [
            {
                data: values,
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
            },
            autocolors: {
                mode: 'data'
            },
            title: {
                display: true,
                position: 'top',
                color: 'black',
                text: title,
                font: {
                    size: 20,
                    family: "work-Sans, sans-serif",
                }
            },
            tooltip: {
                callbacks: {
                    label: (Item) => Item.label + ': ' + (Item.formattedValue) + '%'
                }
            }
        }
    }

    return <Pie data={data} options={options}/>
}
