import React from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'

const Breadcrumb = props => {
    const { title, parent } = props;
    return (
        <>
            <div className="container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col">
                            <div className="page-header-left">
                                <h3>{title}</h3>
                                { (parent) &&
                                    <ol className="breadcrumb pull-right">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard/default">
                                                <Home />
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">{parent}</li>
                                        <li className="breadcrumb-item active">{title}</li>
                                    </ol>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Breadcrumb
