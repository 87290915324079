const revenueWeeklyTrackingColumns = (tableDataItem) => {
    let tableCols = [
        {
            Header: 'Description',
            accessor: 'description',
        },
    ];

    tableDataItem?.tableHeader?.forEach((item, index) => {
        if (index & 1) {
            if (item.Header !== 'Total') {
                tableCols.push({
                    Header: item.Header,
                    columns: [
                        {
                            Header: 'D',
                            accessor: item.accessor,
                        },
                        {
                            Header: 'N',
                            accessor: tableDataItem.tableHeader[index + 1].accessor,
                        },
                    ],
                });
            }
        }
    });

    tableCols.push({
        Header: 'Total',
        accessor: 'total',
    });

    return tableCols;
}

export default revenueWeeklyTrackingColumns;
