import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import RevenueByRigFilters from "./revenueByRigFilters";
import RevenueByRigBody from "./revenueByRigBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Revenue By Rig Report
 * @returns {JSX.Element}
 * @constructor
 */
const RevenueByRig = () => {
    const [tableDataDiscipline1, setTableDataDiscipline1] = useState([]);
    const [tableDataDiscipline2, setTableDataDiscipline2] = useState([]);
    const [tableDataDiscipline3, setTableDataDiscipline3] = useState([]);
    const [tableDataDiscipline4, setTableDataDiscipline4] = useState([]);
    const [combined, setCombined] = useState([]);
    const [totalTableData, setTotalTableData] = useState([]);
    const [reportStartDate, setStartDate] = useState([]);
    const [reportEndDate, setEndDate] = useState([]);

    const [periodId, setPeriodId] = useState({
        id: '',
        type: 'pay_period'
    });
    const [reportFilters, setReportFilters] = useState({
        financial_period_id: '',
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    const reportFilterProps = {
        setReportFilters,
        setTableDataDiscipline1,
        setTableDataDiscipline2,
        setTableDataDiscipline3,
        setTableDataDiscipline4,
        setTotalTableData,
        setStartDate,
        setEndDate,
        setPeriodId,
        setCombined
    }

    const reportBodyProps = {
        reportFilters,
        tableDataDiscipline1,
        tableDataDiscipline2,
        tableDataDiscipline3,
        tableDataDiscipline4,
        totalTableData,
        reportStartDate,
        reportEndDate,
        periodId,
        combined
    }

    return (
        <>
            <Breadcrumb title="Revenue By Rig" parent="Reports"/>
            <RevenueByRigFilters {...reportFilterProps} />
            <RevenueByRigBody {...reportBodyProps} />
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default RevenueByRig;
