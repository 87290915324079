import {FaPlus, FaTrash} from "react-icons/fa";
import React from "react";

const JobsToBypassTable = (setSelectedRow, setFormModal, setDeleteModal) => {
    return [
        {
            Header: 'Job',
            accessor: 'job_number',
        },
        {
            id: 'actions',
            Header: () => {
                return (
                    <FaPlus
                        color='#22af47'
                        size={18}
                        title="Create"
                        onClick={() => {
                            setSelectedRow({});
                            setFormModal({isOpen: true});
                        }}
                    />
                )
            },
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaTrash
                            className="actions"
                            title="Delete"
                            onClick={() => {
                                const msg = <p>Are you sure you want to delete this job: <b>{row.job_number}</b> from list?</p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                )
            }
        }
    ];
}

export default JobsToBypassTable;



