import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import ShopTimeEntryDetailBody from "./shopTimeEntryDetailBody";
import ShopTimeEntryDetailFilter from "./shopTimeEntryDetailFilter";

/**
 * ShopTimeEntrySummary Component
 * @returns {JSX.Element}
 * @constructor
 */
const ShopTimeEntryDetail = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        status: '',
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Shop Time Entry Detail" parent="Time Entry"/>
            <ShopTimeEntryDetailFilter {...{filters, setFilters, setTableData}}/>
            <ShopTimeEntryDetailBody {...{filters, tableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default ShopTimeEntryDetail;
