import React, {useEffect, useState, useCallback} from 'react';
import FormInputDate from "../../../forms/form-control/FormInputDate";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import {useForm} from "react-hook-form";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";

/**
 * Filter component for TransactionRegister Report
 * @returns {JSX.Element}
 * @constructor
 */
const TransactionRegisterPlusFilters = (prop) => {
    const {reportFilters, setReportFilters, setTableData} = prop;
    const { register, handleSubmit, errors, setValue } = useForm();
    const [clients, setClients] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [invStatuses, setInvStatuses] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState({});
    const [financialPeriods, setFinancialPeriods] = useState([]);

    const setDate = useCallback((value) => {
        if (value) {
            apiClient
                .post('getFinancialPeriodById', {'id': value})
                .then(response => {
                    let startDate = response.data.start_date;
                    let endDate = response.data.end_date;
                    document.getElementById('date_from').value = startDate;
                    document.getElementById('date_to').value = endDate;

                    updateStateValue(setReportFilters, 'date_from', startDate);
                    updateStateValue(setReportFilters, 'date_to', endDate);
                });
        }
    }, [setReportFilters]);

    // get list of active clients and invoice status
    useEffect(() => {
        apiClient
            .get('getActiveClientsList')
            .then(response => {
                const clientsList = response.data.activeClientsList.map(
                    ({id, client_name}) => ({id: id, name: client_name})
                );
                setClients(clientsList);
            });

        apiClient
            .get('getInvoicesStatuses')
            .then(response => {
                response.data.push({id: 4, name: 'Blank'})
                setInvStatuses(response.data);
            });

        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => {
                            if (is_current) {
                                setDate(id);
                                setCurrentPeriod({id: id, name: name + ' (Current)'})
                            }
                            return {
                                id: id, name: name + (is_current ? ' (Current)' : '')
                            }
                        }
                    )
                );
            });
    }, [setDate]);

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            setValue('financial_period_id', currentPeriod.id);
        }, 200);
    }, [currentPeriod, setValue]);

    // get list of jobs
    useEffect(() => {
        //check if client id was selected
        if (!reportFilters.client_id) {
            setJobs([]);
            updateStateValue(setReportFilters, 'job_id', '');
            return;
        }

        apiClient
            .post('getActiveJobsByClientId', {'client_id': reportFilters.client_id})
            .then(response => {
                const jobsList = response.data.jobs.map(
                    ({id, job_number}) => ({id: id, name: job_number})
                );
                setJobs(jobsList);
            });
    }, [reportFilters.client_id, setReportFilters]);

    const onSubmit = () => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient
            .post('getTransactionRegisterDetail', reportFilters)
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
                updateStateValue(setReportFilters, 'isLoading', false);
            });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    id="financial_period_id"
                    label="Financial Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={financialPeriods}
                    register={register({required: false})}
                    onChange={(e) => setDate(e.target.value)}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    // className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    className="col-md-q"
                    value={reportFilters.date_from}
                    register={register({required: "required"})}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    errors={errors}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    // className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    className="col-md-q"
                    register={register({required: "required"})}
                    value={reportFilters.date_to}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    errors={errors}
                />
                <FormSelect
                    id="client_id"
                    label="Client"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={reportFilters.client_id}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    options={clients}
                />
                <FormSelect
                    id="job_id"
                    label="Job"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={reportFilters.job_id}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    options={jobs}
                />
                <FormSelect
                    id="invoice_status_id"
                    label="Invoice Status"
                    // className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    className="col-md-q"
                    value={reportFilters.invoice_status_id}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    options={invStatuses}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default TransactionRegisterPlusFilters;
