import {FaPencilAlt, FaTrash} from "react-icons/fa";
import React from "react";

const directEntrieTableColumns = (setSelectedRow, setEditModal, setDeleteModal) => {
    return [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Pay Seq',
            accessor: 'pay_sequence',
            Cell: (cell) => {
                const row = cell.row.original;

                if (typeof row.pay_sequence !== 'undefined') {
                    switch (row.pay_sequence) {
                        case 1:
                            return 'Current';
                        case 2:
                            return 'Previous';
                        default:
                            return null;
                    }
                } else {
                    return null;
                }
            }
        },
        {
            Header: 'Payroll Date',
            accessor: 'payroll_date',
        },
        {
            Header: 'Work Date',
            accessor: 'work_date',
        },
        {
            Header: 'Job',
            accessor: 'job_number',
        },
        {
            Header: 'Hours',
            accessor: 'hours',
        },
        {
            Header: 'EC',
            accessor: 'ec_id',
        },
        {
            Header: 'Per Diem',
            accessor: 'per_diem',
        },
        {
            Header: 'Equip #',
            accessor: 'equipment_number',
        },
        {
            Header: 'State',
            accessor: 'state',
            width: 40
        },
        {
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaPencilAlt
                            color='#A51E37'
                            title="Edit"
                            onClick={() => {
                                setSelectedRow(row);
                                setEditModal(true);
                            }}
                        />
                        {' '}
                        <FaTrash
                            color='#A51E37'
                            title="Remove"
                            onClick={() => {
                                const msg = <p>Are you sure that you want to remove the direct entry?</p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                )
            }
        },
    ];
}

export default directEntrieTableColumns;

