import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import EditInvoiceForm from "./editInvoiceForm";
import LoadingIndicator from "../../common/loadingIndicator";
import apiClient from "../../../utils/apiClient";

/**
 * Edit Invoice Component
 * @returns {JSX.Element}
 * @constructor
 */
const EditInvoice = (props) => {
    const invoiceId  = props.match.params.invoice_id;
    const [invoiceStatuses, setInvoiceStatuses] = useState([]);
    const [jobId, setJobId] = useState(0);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        // invoices statuses
        apiClient
            .get('getInvoicesStatuses')
            .then(response => {
                setInvoiceStatuses(response.data);
            });
    }, []);

    // get invoice data
    useEffect(() => {
        //get invoice information
        apiClient
            .post(`getInvoiceById`, {id: invoiceId} )
            .then(response => {
                const invoice = response.data.invoice;
                setJobId(invoice.job_id);
                setFilters({
                    invoice_id: invoice.id,
                    discipline: invoice.discipline,
                    discipline_id: invoice.discipline_id,
                    invoice_number: invoice.invoice_number,
                    invoice_status: invoice.invoice_status,
                    date: invoice.date,
                    client: invoice.client,
                    client_id: invoice.client_id,
                    job: invoice.job,
                    job_id: invoice.job_id,
                    po_number: invoice.po_number,
                    isLoading: false,
                    isSubmitted: false,
                });
            });
    }, [invoiceId, invoiceStatuses]);

    return (
        <>
            <Breadcrumb title="Edit Invoice" parent="Invoicing"/>
            <EditInvoiceForm {...{invoiceId, filters, setFilters, invoiceStatuses, jobId}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default EditInvoice;
