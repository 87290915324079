import React, {useState, useEffect} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import apiClient from "../../../utils/apiClient";
import {toast} from "react-toastify";
import TableReport from "../../common/table/table";
import FinancialPeriodsTableColumns from "./FinancialPeriodsTableColumns";
import ModalConfirmation from "../../common/modal/ModalConfirmation";
import {removeObjFromArray} from "../../../utils/arrayHelpers";
import FinancialPeriodsFormModal from "./FinancialPeriodsFormModal";
import FinancialPeriodsFormFilters from "./FinancialPeriodsFormFilters";
import moment from "moment";
import {updateStateValue} from "../../../utils/stateHelpers";
import LoadingIndicator from "../../common/loadingIndicator";

const FinancialPeriods = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [formModal, setFormModal] = useState({isOpen: false, action: 'create'});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});
    const [filters, setFilters] = useState({
        year: moment().format('YYYY'),
        isLoading: false,
    });

    // delete
    const onDelete = () => {
        apiClient
            .delete('financialPeriods/' + selectedRow.id)
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch()
            .then(function () {
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    useEffect(() => {
        apiClient
            .get('financialPeriods', {params: {year: moment().format('YYYY')}})
            .then(response => {
                setTableData(response.data.periods);
            })
            .catch()
            .then(function () {
                updateStateValue(setFilters,'isLoading', false);
            });
    }, []);

    return (
        <>
            <Breadcrumb title="Financial Periods" parent="Admin"/>
            <div className="container-fluid">
                <FinancialPeriodsFormFilters {...{filters, setFilters, setTableData}} />
                <TableReport
                    columns={FinancialPeriodsTableColumns(setSelectedRow, setFormModal, setDeleteModal)}
                    data={tableData}
                    getExportFileName={() => 'financial_periods'}
                />
                <FinancialPeriodsFormModal
                    title="Financial Period"
                    modal={formModal}
                    filters={filters}
                    setModal={setFormModal}
                    tableData={tableData}
                    setTableData={setTableData}
                    selectedRow={selectedRow}
                />
                <ModalConfirmation
                    title="Delete"
                    modal={deleteModal}
                    setModal={setDeleteModal}
                    onConfirm={onDelete}
                />
                <LoadingIndicator isLoading={filters.isLoading}/>
            </div>
        </>
    );
}

export default FinancialPeriods;
