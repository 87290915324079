import React, {useEffect, useState} from "react";
import {useFieldArray} from "react-hook-form";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import {AccordionItem} from "react-light-accordion";
import * as Shared from "../../../../common/shared";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import FormCreatableSelect from "../../../../forms/form-control/FormCreatableSelect";

const BottomHole = (props) => {
    const { errors, register, control, collectedHoles, fieldDaily, action, fieldBottomHoleTemplate, mobileBottomHoleDescriptions } = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "bottom_hole"
    });
    const [totalLength, setTotalLength] = useState(0);
    const [selectedBottomHoleHole, setSelectedBottomHoleHole] = useState('');
    const fieldArr = fields;

    useEffect(() => {
        if (!fields.length) {
            setTotalLength(0);
        }
    }, [fields.length])

    useEffect(() => {
        if (action === 'edit') {
            remove();

            if (fieldArr.length > 0) {
                // populate react-hook-form data into the table for every operation.
                append(fieldArr);
            } else {
                // format initial data and populate them into the table.
                const formatData = fieldDaily.field_bottom_holes.map((item) => {
                    const label = (action === 'edit') ? item.description : item.description?.label;
                    return {
                        ...item,
                        description: {
                            value: mobileBottomHoleDescriptions?.find(desc => desc.label === label)?.value,
                            label: label
                        }
                    };
                })
                append(formatData);
            }

            setTimeout(() => {
                if (fieldDaily.field_bottom_holes.length > 0) {
                    // make visible
                    let totals = document.getElementById("bh_totals");

                    if (totals) {
                        totals.classList.remove('hidden');
                    }

                    Shared.calculateTotals('total_length', setTotalLength);
                }
            }, 200);
        }

        if (action === 'create' && fieldBottomHoleTemplate.length > 0) {
            remove();
            if (fieldArr.length > 0) {
                append(fieldArr);
            } else {
                // format initial data.
                const originData = fieldBottomHoleTemplate[0];
                append({...originData, description: {value: 4, label: 'AAA'}});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, fieldDaily.field_bottom_holes, fieldBottomHoleTemplate, append, remove, mobileBottomHoleDescriptions.length]);

    const calculateTotalLengthInRow = (e, length) => {
        e.preventDefault();
        let lengthValue = (document.getElementById(length).value) ? document.getElementById(length).value : 0;

        if (lengthValue > 0) {
            // make visible
            let totals = document.getElementById("bh_totals");

            if (totals) {
                totals.classList.remove('hidden');
            }

            Shared.calculateTotals('total_length', setTotalLength);
        }
    }

    const removeBottomHoleRow = (index) => {
        remove(index);
        // recalculate total length when removing one Bottom Hole row.
        setTimeout(() => {
            Shared.calculateTotals('total_length', setTotalLength);
        }, 500);
    }

    return (
        <AccordionItem className="card-header bg-primary" title="Bottom Hole">
            <div className="card">
                {totalLength > 0 && fields.length > 0 &&
                    <div className="form-row hidden" id="bh_totals">
                        <div className="col-md-3 mb-3 total_block">
                            <p className="form-control background_total_block_color"> Total Length : {totalLength}</p>
                        </div>
                    </div>
                }
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Size</th>
                                        <th>Length</th>
                                        <th>Fish Neck Size</th>
                                        <th>Hole</th>
                                        <th>
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <FormCreatableSelect
                                                    id={`bottom_hole[${index}].description`}
                                                    control={control}
                                                    customStyles={Shared.customStylesSmall}
                                                    options={mobileBottomHoleDescriptions}
                                                    value={item.description?.value ? item.description.value : item.id}
                                                    rules={{required: "required"}}
                                                    errors={errors}
                                                />
                                            </td>
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`bottom_hole[${index}].size`}
                                                name={`bottom_hole[${index}].size`}
                                                value={(item.size) ? item.size : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="total_length hours-column-width"
                                                id={`bottom_hole[${index}].length`}
                                                name={`bottom_hole[${index}].length`}
                                                value={(item.length) ? item.length : ''}
                                                onKeyUp={(e) => calculateTotalLengthInRow(
                                                    e,
                                                    `bottom_hole[${index}].length`
                                                )}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`bottom_hole[${index}].fish_neck_size`}
                                                name={`bottom_hole[${index}].fish_neck_size`}
                                                value={(item.fish_neck_size) ? item.fish_neck_size : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                                errors={errors}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`bottom_hole[${index}].hole_name`}
                                                    name={`bottom_hole[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`bottom_hole[${index}].hole_name`}
                                                    name={`bottom_hole[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={
                                                        selectedBottomHoleHole
                                                            ?
                                                                selectedBottomHoleHole
                                                            :
                                                                (collectedHoles.length === 1)
                                                                    ?
                                                                        collectedHoles[0].value
                                                                    :
                                                                        item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedBottomHoleHole)
                                                    }
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => removeBottomHoleRow(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default React.memo(BottomHole);
