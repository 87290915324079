import React, {useState} from 'react';
import TableReport from "../../common/table/table";
import DirectEntriesTableColumns from "./directEntriesTableColumns";
import MessageEmptyData from "../../common/messageEmptyData";
import ModalConfirmation from "../../common/modal/ModalConfirmation";
import apiClient from "../../../utils/apiClient";
import {toast} from "react-toastify";
import {removeObjFromArray} from "../../../utils/arrayHelpers";

/**
 * Body report component for DirectEntry Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const DirectEntriesBody = (prop) => {
    const {filters, tableData, setTableData, setEditModal, selectedRow, setSelectedRow} = prop;
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    const DeleteHandler = () => {
        apiClient
            .get('deleteDirectEntry/' + selectedRow.direct_entry_id)
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "direct_entry_id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deleteModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={DirectEntriesTableColumns(setSelectedRow, setEditModal, setDeleteModal)}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'direct_entry'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Remove Direct Entry"
                onConfirm={DeleteHandler}
            />
        </div>
    );
}

export default DirectEntriesBody;
