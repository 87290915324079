import React from 'react';
import Table from "../../../common/table/table";
import ShopTimeEntryWeeklySummaryCodeTable from "./shopTimeEntryWeeklySummaryCodeTable";
import ShopTimeEntryWeeklySummaryEquipmentTable from "./shopTimeEntryWeeklySummaryEquipmentTable";
import MessageEmptyData from "../../../common/messageEmptyData";
import PieChart from "../../../charts/PieChart";
import {isUndefined} from "lodash";

/**
 * ShopTimeEntryReviewBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ShopTimeEntryWeeklySummaryBody = (prop) => {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (isUndefined(tableData.cost_codes)) {
        return null;
    }

    if (tableData.cost_codes.table.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-4">
                    <Table
                        columns={ShopTimeEntryWeeklySummaryCodeTable()}
                        data={tableData?.cost_codes?.table}
                        exportOptions={{pdf: false, xlsx: true, csv: true}}
                        getExportFileName={() => 'shop_time_entry_weekly_summary'}
                        withGlobalSearch={true}
                        scrollable={true}
                    />
                </div>
                <div className="col-sm-8">
                    <PieChart
                        title='Cost Code Weekly Summary'
                        labels={tableData?.cost_codes?.chart_labels}
                        values={tableData?.cost_codes?.chart_data}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <Table
                        columns={ShopTimeEntryWeeklySummaryEquipmentTable()}
                        data={tableData?.equipments?.table}
                        exportOptions={{pdf: false, xlsx: true, csv: true}}
                        getExportFileName={() => 'shop_time_entry_weekly_summary'}
                        withGlobalSearch={true}
                        scrollable={true}
                    />
                </div>
                <div className="col-sm-8">
                    <PieChart
                        title='Equipment Weekly Summary'
                        labels={tableData?.equipments?.chart_labels}
                        values={tableData?.equipments?.chart_data}
                    />
                </div>
            </div>
        </div>
    );
}

export default ShopTimeEntryWeeklySummaryBody;
