import React, {useState} from 'react';
import FormInputText from "../../../forms/form-control/FormInputText";
import {FaKey, FaPlus, FaTrash} from "react-icons/fa";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";

/**
 * Client Employees Table Component
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeesTable = (props) => {
    const {register, errors, fields, append, remove} = props;
    const [selectedRow, setSelectedRow] = useState({});
    const [resetPasswordModal, setResetPasswordModal] = useState({isOpen: false, message: <p></p>});

    const onResetPassword = () => {
        apiClient
            .post('resetUserPassword', {id: selectedRow.id})
            .then(response => {
                toast.success(response.data.message);
            }).then(function () {
                //close resetPasswordModal
                setResetPasswordModal({...resetPasswordModal, isOpen: false})
            });
    }

    return (
        <>
            <table className="table table-hover table-striped col-md-11">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Title</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th className='text-right'>
                            <FaPlus
                                color='#22af47'
                                size={20}
                                title="Add User"
                                onClick={() => append(
                                {
                                    first_name: "",
                                    last_name: "",
                                    title: "",
                                    email: "",
                                    phone: "",
                                    id: ""
                                })}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {fields.map((item, index) => (
                        <tr key={item.fieldArrayId}>
                            <td>
                                <FormInputText
                                    id={`employees[${index}].first_name`}
                                    register={register({required: "required"})}
                                    errors={errors}
                                    value={item.first_name}
                                />
                            </td>
                            <td>
                                <FormInputText
                                    id={`employees[${index}].last_name`}
                                    register={register({required: "required"})}
                                    errors={errors}
                                    value={item.last_name}
                                />
                            </td>
                            <td>
                                <FormInputText
                                    className="title-width"
                                    id={`employees[${index}].title`}
                                    register={register()}
                                    errors={errors}
                                    value={item.title}
                                />
                            </td>
                            <td>
                                <FormInputText
                                    className="email-width"
                                    id={`employees[${index}].email`}
                                    register={register({required: "required"})}
                                    errors={errors}
                                    value={item.email}
                                />
                            </td>
                            <td>
                                <FormInputText
                                    className="phone-width"
                                    id={`employees[${index}].phone`}
                                    register={register()}
                                    errors={errors}
                                    value={item.phone}
                                />
                            </td>
                            <td>
                                <FormInputText
                                    id={`employees[${index}].id`}
                                    type="hidden"
                                    register={register()}
                                    value={item.id}
                                />
                                <FaTrash
                                    color='#A51E37'
                                    size={20}
                                    title="Remove"
                                    onClick={() => remove(index)}
                                />
                                {' '}
                                {item.id &&
                                    <FaKey
                                        color='#A51E37'
                                        size={20}
                                        title="Reset Password"
                                        onClick={() => {
                                            const msg = <p>Are you sure that you want to reset password
                                                for <b>{item.first_name} {item.last_name}</b>?</p>;
                                            setSelectedRow(item);
                                            setResetPasswordModal({
                                                message: msg,
                                                isOpen: true,
                                            })
                                        }}
                                    />
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ModalConfirmation
                modal={resetPasswordModal}
                setModal={setResetPasswordModal}
                title="Reset User Password"
                onConfirm={onResetPassword}
            />
        </>
    );
}

export default EmployeesTable;
