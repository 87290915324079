import React, {useState, useMemo} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import ReportFilters from "./reportFilters";
import ReportBody from "./reportBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import {formatNumberToDisplayWithCommas} from '../../../../components/common/shared';

function renderTableCell(cell) {
    if (cell.column.Header !== '' && cell.value !== null) {
        return formatNumberToDisplayWithCommas(cell.value);
    }
    return cell.value;
}

/**
 * Revenue By Rig Report Weekly
 * @returns {JSX.Element}
 * @constructor
 */
const RevenueByRigWeekly = () => {
    const [tableHeaderDiscipline1, setTableHeaderDiscipline1] = useState([]);
    const [tableHeaderDiscipline2, setTableHeaderDiscipline2] = useState([]);
    const [tableHeaderDiscipline3, setTableHeaderDiscipline3] = useState([]);
    const [tableHeaderDiscipline4, setTableHeaderDiscipline4] = useState([]);
    const [tableRowsDiscipline1, setTableRowsDiscipline1] = useState([]);
    const [tableRowsDiscipline2, setTableRowsDiscipline2] = useState([]);
    const [tableRowsDiscipline3, setTableRowsDiscipline3] = useState([]);
    const [tableRowsDiscipline4, setTableRowsDiscipline4] = useState([]);
    const [totalTableHeader, setTotalTableHeader] = useState([]);
    const [totalTableRows, setTotalTableRows] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    const tableDataDiscipline1Header = useMemo(() => tableHeaderDiscipline1.map(header => ({
        ...header,
        Cell: renderTableCell
    })), [tableHeaderDiscipline1]);

    const tableDataDiscipline2Header = useMemo(() => tableHeaderDiscipline2.map(header => ({
        ...header,
        Cell: renderTableCell
    })), [tableHeaderDiscipline2]);

    const tableDataDiscipline3Header = useMemo(() => tableHeaderDiscipline3.map(header => ({
        ...header,
        Cell: renderTableCell
    })), [tableHeaderDiscipline3]);

    const tableDataDiscipline4Header = useMemo(() => tableHeaderDiscipline4.map(header => ({
        ...header,
        Cell: renderTableCell
    })), [tableHeaderDiscipline4]);

    const tableDataTotalHeader = useMemo(() => totalTableHeader.map(header => ({
        ...header,
        Cell: renderTableCell
    })), [totalTableHeader]);

    const reportFilterProps = {
        setReportFilters,
        setTableHeaderDiscipline1,
        setTableHeaderDiscipline2,
        setTableHeaderDiscipline3,
        setTableHeaderDiscipline4,
        setTableRowsDiscipline1,
        setTableRowsDiscipline2,
        setTableRowsDiscipline3,
        setTableRowsDiscipline4,
        setTotalTableHeader,
        setTotalTableRows
    };

    const reportBodyProps = {
        reportFilters,
        tableHeaderDiscipline1: tableDataDiscipline1Header,
        tableHeaderDiscipline2: tableDataDiscipline2Header,
        tableHeaderDiscipline3: tableDataDiscipline3Header,
        tableHeaderDiscipline4: tableDataDiscipline4Header,
        tableRowsDiscipline1,
        tableRowsDiscipline2,
        tableRowsDiscipline3,
        tableRowsDiscipline4,
        totalTableHeader: tableDataTotalHeader,
        totalTableRows
    };

    return (
        <>
            <Breadcrumb title="Revenue By Rig Weekly" parent="Reports"/>
            <ReportFilters {...reportFilterProps} />
            <ReportBody {...reportBodyProps} />
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
};

export default RevenueByRigWeekly;
