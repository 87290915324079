export const newVendorsTableColumns = [
    {
        Header: 'Company',
        accessor: 'company'
    },
    {
        Header: 'Vendor',
        accessor: 'vendor_name',
    },
    {
        Header: 'User',
        accessor: 'user_name'
    },
    {
        Header: 'Date',
        accessor: 'date'
    },
];
