export const processInvoicesTableColumns = [
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'Rig',
        accessor: 'rig',
    },
    {
        Header: 'Holes',
        accessor: 'holes',
    },
    {
        Header: 'Client',
        accessor: 'client',
    },
    {
        Header: 'Job Number',
        accessor: 'job_number',
    },
    {
        Header: 'Invoice Status',
        accessor: 'invoice_status',
    },
    {
        Header: 'Invoice Number',
        accessor: 'invoice_number',
    },
    {
        Header: 'Revenue',
        accessor: 'revenue',
    },
];
