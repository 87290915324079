import React, {useState} from 'react';
import Table from "../../../common/table/table";
import PayPeriodsTable from "./payPeriodsTable";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import * as ArrayHelpers from "../../../../utils/arrayHelpers";

/**
 * PayPeriodsBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const PayPeriodsBody = (prop) => {
    const {filters, setFilters, tableData, setTableData, selectedRow, setSelectedRow} = prop;
    const [lockModal, setLockModal] = useState({isOpen: false, message: <p></p>});
    const [unlockModal, setUnlockModal] = useState({isOpen: false, message: <p></p>});

    const LockHandler = () => {
        apiClient
            .post('lockPayPeriod', {'id': selectedRow.id})
            .then(response => {
                const newTableData = ArrayHelpers.replaceObjFromArray(
                    tableData,
                    "id",
                    response.data.id,
                    response.data.row
                );
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close lockModal
                setUnlockModal({...unlockModal, isOpen: false})
            });
    }

    const UnlockHandler = () => {
        apiClient
            .post('unlockPayPeriod', {'id': selectedRow.id})
            .then(response => {
                const newTableData = ArrayHelpers.replaceObjFromArray(
                    tableData,
                    "id",
                    response.data.id,
                    response.data.row
                );
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close reactivateModal
                setLockModal({...lockModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    PayPeriodsTable(setFilters, setSelectedRow, setLockModal, setUnlockModal)
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'pay_periods'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={lockModal}
                setModal={setLockModal}
                title="Unlock Pay Period"
                onConfirm={UnlockHandler}
            />
            <ModalConfirmation
                modal={unlockModal}
                setModal={setUnlockModal}
                title="Lock Pay Period"
                onConfirm={LockHandler}
            />
        </div>
    );
}

export default PayPeriodsBody;
