import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import PayPeriodDateRangeFilter from "../../../common/form-filters/PayPeriodDateRangeFilter";
import EmployeePayableHoursAllHoursBody from "./employeePayableHoursAllHoursBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * DailyEmployee payable hours all hours component
 * @returns {JSX.Element}
 * @constructor
 */

const EmployeePayableHoursAllHours = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Employee Payable Hours - All Hours" parent="Payroll"/>
            <PayPeriodDateRangeFilter {...{setFilters, setTableData, OnSubmitUrl:"getEmployeePayableAllHours"}}/>
            <EmployeePayableHoursAllHoursBody {...{filters, tableData}} />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default EmployeePayableHoursAllHours;
