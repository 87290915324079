import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash, FaFileMedical} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import {useFieldArray} from "react-hook-form";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import * as Shared from "../../../../common/shared";
import apiClient from "../../../../../utils/apiClient";
import {toast} from "react-toastify";
import POItemsModal from "../POItemsModal";

const DailyConsumables = (props) => {
    const {
        errors, register, control, collectedHoles, consumableDescriptions, selectedJobId, daily, action,
        totalConsumables, setTotalConsumables, selectedJobNumber, setValue
    } = props;
    const {fields, append, remove} = useFieldArray({control, name: 'consumables'});
    const [selectedConsumableHole, setSelectedConsumableHole] = useState('');
    const [poItemsData, setPoItemsData] = useState([]);
    const [poItemsModal, setPoItemsModal] = useState({isOpen: false});

    useEffect(() => {
        if (action === 'edit') {
            append(daily.daily_consumables);

            setTimeout(() => {
                daily.daily_consumables.forEach((item, index) => {
                    // populate select
                    setValue(
                        `consumables[${index}].cost_code`,
                        {value: item.cost_code, label: item.desc}
                    );
                });
            }, 500);

            setTimeout(() => {
                Shared.calculateTotalsInCards(daily.daily_consumables, setTotalConsumables);
            }, 200);
        }
    }, [action, append, daily, setTotalConsumables, setValue]);

    // PO Items
    const getPOItems = () => {
        let jobNumber = (action === 'edit') ? daily.job.job_number : selectedJobNumber;

        if (jobNumber) {
            apiClient
                .post('getPOItemsByJobNumber', {'job_number': jobNumber})
                .then(response => {
                    if (response.data) {
                        setPoItemsData(response.data);
                    }
                });
        }
    }

    const calculateMarkupPrice = (e, priceId, chargedId, markupPercentID, markupPriceID, totalId) => {
        e.preventDefault();

        if (isNaN(e.target.value) === false) {
            // there should be logic to apply .000 to a number
        } else {
            // remove everything except digits and dot
            e.target.value = e.target.value.replace(/\D/g,'');
        }

        let percent = (document.getElementById(markupPercentID) !== null) ? document.getElementById(markupPercentID).value : 0;
        let price = (document.getElementById(priceId) !== null) ? document.getElementById(priceId).value : 0;
        let charged = (document.getElementById(chargedId) !== null) ? document.getElementById(chargedId).value : 0;

        if (charged !== 0) {            
            let percentagePart =  Number(percent) / 100;
            let markupPrice = Number(charged) * Number(price) * percentagePart;
            document.getElementById(markupPriceID).value = Number(markupPrice).toFixed(3);

            let totalRaw = Number(charged) * Number(price);
            let total = Number(totalRaw) + Number(markupPrice);
            document.getElementById(totalId).value = (total > 0) ? Number(total).toFixed(3) : '0.000';
        }

        Shared.calculateTotals('total_consumables', setTotalConsumables);
    }

    const selectConsumablesRow = (name, value, id) => {
        const jobId = (action === 'edit') ? daily.job_id : selectedJobId;

        if (value && jobId) {
            apiClient
                .post('getJobChargesPriceByCodeAndJobId', {'job_id': jobId, 'code': value})
                .then(response => {
                    document.getElementById(id).value = Number(response.data).toFixed(2);
                });
        }
    }

    const removeConsumablesRow = (index, item) => {
        // remove UI element
        remove(index);
        // remove elements from fields
        fields.splice(index, 1);
        // calculate total for consumables
        Shared.calculateTotalsInCards(fields, setTotalConsumables);

        // reset po item status, so it is back on the list
        if (Number(item.cost_code) === 2700) {
            apiClient
                .post('reactivatePOItem', {'line': item.line, 'po_number': item.po_number})
                .then(response => {
                    toast.success(response.data.message);
                });
        }
    }

    useEffect(() => {
        if (!fields.length) {
            setTotalConsumables(0);
        }
    }, [fields.length, setTotalConsumables])

    return (
        <>
            <AccordionItem className="card-header bg-primary" title="Consumables">
                <div className="card">
                    <div className="form-row" id="totals">
                        <div className="col-md-3 mb-3 total_block">
                            <p className="form-control background_total_block_color" id="total_consumables">
                                Total: {
                                    Number(totalConsumables)
                                    .toLocaleString('en-US', {style: 'currency', currency: 'USD'})
                                }
                            </p>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Notes</th>
                                            <th>Units</th>
                                            <th>Charged</th>
                                            <th>Cost</th>
                                            <th>Markup %</th>
                                            <th>Markup Price</th>
                                            <th>Total</th>
                                            <th>Hole</th>
                                            <th className="action-cell">
                                                <FaPlus
                                                    color='#22af47'
                                                    size={20}
                                                    title="Add New"
                                                    onClick={() => append({
                                                        'price': '0.000',
                                                        'markup': '0',
                                                        'markup_price': '0.000',
                                                        'total': '0.000'
                                                    })}
                                                />
                                                <FaEraser
                                                    color='#A51E37'
                                                    className="marginLeft"
                                                    size={20}
                                                    title="Remove All"
                                                    onClick={() => remove()}
                                                />
                                                <FaFileMedical
                                                    color='#6890A2FF'
                                                    className="marginLeft"
                                                    size={20}
                                                    title="Add PO Items"
                                                    onClick={() => {
                                                        // get po items
                                                        getPOItems();
                                                        setPoItemsModal({
                                                            isOpen: true
                                                        });
                                                    }}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map((item, index) => (
                                            <tr key={item.id}>
                                                <SearchableSelectInTable
                                                    id={`consumables[${index}].cost_code`}
                                                    options={consumableDescriptions}
                                                    rules={{ required: "required" }}
                                                    onChange={
                                                        (value) => selectConsumablesRow(
                                                            `consumables[${index}].cost_code`,
                                                            value,
                                                            `consumables[${index}].price`
                                                        )
                                                    }
                                                    value={(action === 'edit') ? item.cost_code?.value : ''}
                                                    control={control}
                                                    errors={errors}
                                                    customStyles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            width: 250,
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            width: 250,
                                                            position: 'sticky',
                                                        })
                                                    }}
                                                />
                                                <InputInTable
                                                    className="notes-column-width"
                                                    id={`consumables[${index}].description`}
                                                    name={`consumables[${index}].description`}
                                                    value={item.description}
                                                    register={register}
                                                />
                                                <InputInTable
                                                    className="column-width-75"
                                                    id={`consumables[${index}].units`}
                                                    name={`consumables[${index}].units`}
                                                    type="number"
                                                    value={item.units}
                                                    onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                <InputInTable
                                                    className="column-width-75"
                                                    id={`consumables[${index}].charged`}
                                                    name={`consumables[${index}].charged`}
                                                    value={item.charged}
                                                    onChange={(e) => calculateMarkupPrice(
                                                        e,
                                                        `consumables[${index}].price`,
                                                        `consumables[${index}].charged`,
                                                        `consumables[${index}].markup`,
                                                        `consumables[${index}].markup_price`,
                                                        `consumables[${index}].total`
                                                    )}
                                                    type="number"
                                                    onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                <InputInTable
                                                    className="price-column-width"
                                                    id={`consumables[${index}].price`}
                                                    name={`consumables[${index}].price`}
                                                    type="number"
                                                    value={Number(item.price).toFixed(3)}
                                                    onChange={(e) => calculateMarkupPrice(
                                                        e,
                                                        `consumables[${index}].price`,
                                                        `consumables[${index}].charged`,
                                                        `consumables[${index}].markup`,
                                                        `consumables[${index}].markup_price`,
                                                        `consumables[${index}].total`
                                                    )}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                <InputInTable
                                                    className="column-width-75"
                                                    id={`consumables[${index}].markup`}
                                                    name={`consumables[${index}].markup`}
                                                    type="number"
                                                    value={item.markup}
                                                    onChange={(e) => calculateMarkupPrice(
                                                        e,
                                                        `consumables[${index}].price`,
                                                        `consumables[${index}].charged`,
                                                        `consumables[${index}].markup`,
                                                        `consumables[${index}].markup_price`,
                                                        `consumables[${index}].total`
                                                    )}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                <InputInTable
                                                    className="markup-price-column-width"
                                                    id={`consumables[${index}].markup_price`}
                                                    name={`consumables[${index}].markup_price`}
                                                    register={register({required: "required"})}
                                                    value={Number(item.markup_price).toFixed(2)}
                                                    errors={errors}
                                                />
                                                <InputInTable
                                                    className="total_consumables price-column-width"
                                                    id={`consumables[${index}].total`}
                                                    name={`consumables[${index}].total`}
                                                    value={Number(item.total).toFixed(3)}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                {action === 'create' &&
                                                    <SelectInTable
                                                        id={`consumables[${index}].hole_name`}
                                                        name={`consumables[${index}].hole_name`}
                                                        options={collectedHoles}
                                                        value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                }
                                                {action === 'edit' &&
                                                    <SelectInTable
                                                        id={`consumables[${index}].hole_name`}
                                                        name={`consumables[${index}].hole_name`}
                                                        value={
                                                            selectedConsumableHole
                                                                ?
                                                                    selectedConsumableHole
                                                                :
                                                                    (collectedHoles.length === 1)
                                                                        ?
                                                                            collectedHoles[0].value
                                                                        :
                                                                            item.hole_name
                                                        }
                                                        onChange={
                                                            (e) => Shared.setSelectedValue(e.target.value, setSelectedConsumableHole)
                                                        }
                                                        options={collectedHoles}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                }

                                                <td>
                                                    <FaTrash
                                                        color='#A51E37'
                                                        size={20}
                                                        title="Remove"
                                                        onClick={() => removeConsumablesRow(index, item)}
                                                    />
                                                </td>
                                                <InputInTable
                                                    id={`consumables[${index}].line`}
                                                    name={`consumables[${index}].line`}
                                                    type="hidden"
                                                    cellClass="hidden"
                                                    value={item.line}
                                                    register={register({required: false})}
                                                    errors={errors}
                                                />
                                                <InputInTable
                                                    id={`consumables[${index}].po_number`}
                                                    name={`consumables[${index}].po_number`}
                                                    type="hidden"
                                                    cellClass="hidden"
                                                    value={item.po_number}
                                                    register={register({required: false})}
                                                    errors={errors}
                                                />
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </AccordionItem>
            <POItemsModal
                append={append}
                modal={poItemsModal}
                setModal={setPoItemsModal}
                poItemsData={poItemsData}
                setPoItemsData={setPoItemsData}
            />
        </>
    );
}

export default React.memo(DailyConsumables);
