/**
 * List of 24 hours
 * @returns {*[]|*}
 */
export const getHours = () => {
    const hours = [];
    for (let i = 0; i <= 24; i += 0.25) {
        const value = i.toFixed(2);
        const label = value;
        hours.push({ value, label });
    }
    return hours;
}


/**
 * List of 12 hours
 * @returns {*[]|*}
 */
export const get12Hours = () => {
    const hours = [];
    for (let i = 0; i <= 12; i += 0.25) {
        const value = i.toFixed(2);
        const label = value;
        hours.push({ value, label });
    }
    return hours;
}