import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import JobCostPOFilters from "./jobCostPOFilters";
import JobCostPOBody from "./jobCostPOBody";
import POItemsBody from "./poItemsBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Job Cost PO ViewPoint Report
 * @returns {JSX.Element}
 * @constructor
 */
const JobCostPO = () => {
    const [tableData, setTableData] = useState([]);
    const [poItemsData, setPOItemsData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    const jobCostPOFilterProps = {
        setReportFilters,
        setTableData,
    }

    const jobCostPOBodyProps = {
        tableData,
        reportFilters
    }

    const poItemsProps = {
        poItemsData,
        setPOItemsData
    }

    return (
        <>
            <Breadcrumb title="Job Cost PO" parent="ViewPoint"/>
            <JobCostPOFilters {...jobCostPOFilterProps}/>
            <JobCostPOBody {...jobCostPOBodyProps}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
            <Breadcrumb title="PO Items" parent="ViewPoint"/>
            <POItemsBody {...poItemsProps}/>
        </>
    );
}

export default JobCostPO;
