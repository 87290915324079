export const adpUploadPerDiemTableColumns = [
    {
        Header: 'Employee',
        accessor: 'Employee',
    },
    {
        Header: 'Batch Desc',
        accessor: 'Crew',
    },
    {
        Header: 'LastName',
        accessor: 'LastName',
    },
    {
        Header: 'PAY #',
        accessor: 'PaySeq2',
        width: 80
    },
    {
        Header: 'TEMP COST # job',
        accessor: 'tempCostJob',
    },
    {
        Header: 'PER DIEM',
        accessor: 'Amt',
    },
    {
        Header: 'Cert',
        accessor: 'Cert',
    },
    {
        Header: 'SEQ',
        accessor: 'PaySeq',
    }
];
