export const tableSummaryColumns = [
    {
        Header: '',
        accessor: 'label',
    },
    {
        Header: 'Summary',
        accessor: 'value',
    },
];

export const tableRigSummaryColumns = [
    {
        Header: 'Rig',
        accessor: 'rig_name',
    },
    {
        Header: 'Approved',
        accessor: 'total_approved',
    },
    {
        Header: 'Unapproved',
        accessor: 'total_unapproved',
    },
    {
        Header: 'Total',
        accessor: 'total',
    },
];
