import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import DailyHolesFilters from "./DailyHolesFilters";
import DailyHolesBody from "./DailyHolesBody";
import EditHoleModal from "./EditDailyHolesModal";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * DirectEntry Report Component
 * @returns {JSX.Element}
 * @constructor
 */
const DailyHoles = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [editModal, setEditModal] = useState(false);
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Daily Holes" parent="Dailies"/>
            <DailyHolesFilters
                filters={filters}
                setTableData={setTableData}
                setFilters={setFilters}
            />
            <DailyHolesBody
                filters={filters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setEditModal={setEditModal}
                setSelectedRow={setSelectedRow}
            />
            <EditHoleModal
                title="Edit Daily Hole"
                modal={editModal}
                setModal={setEditModal}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default DailyHoles;
