import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import ClientsFilters from "./clientsFilters";
import ClientsBody from "./clientsBody";
import LoadingIndicator from "../../common/loadingIndicator";

const Clients = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Clients" parent="Commercial"/>
            <ClientsFilters
                filters={filters}
                setTableData={setTableData}
                setFilters={setFilters}
            />
            <ClientsBody
                filters={filters}
                setFilters={setFilters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default Clients;
