import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import ProcessInvoicesFilters from "./processInvoicesFilters";
import ProcessInvoicesBody from "./processInvoicesBody";
import moment from 'moment';
import LoadingIndicator from "../../common/loadingIndicator";

/**
 * Process Invoices Report Component
 * @returns {JSX.Element}
 * @constructor
 */
const ProcessInvoices = () => {
    const [tableData, setTableData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        date_from: moment().startOf('month').format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        discipline_id: '',
        client_id: '',
        job_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Process Invoices" parent="Accounting"/>
            <ProcessInvoicesFilters reportFilters={reportFilters} setTableData={setTableData} setReportFilters={setReportFilters}/>
            <ProcessInvoicesBody reportFilters={reportFilters} tableData={tableData} setTableData={setTableData}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default ProcessInvoices;
