import React from 'react';
import {FaRegFileAlt, FaRegFileExcel, FaRegFilePdf} from "react-icons/fa";

const TableExportButtons = (prop) => {

    const { exportData, exportOptions } = prop;

    return (
        <div className="m-t-5">
            { exportOptions.pdf &&
                <FaRegFilePdf title='Export To PDF' size='1.7em' color='#A51E37' onClick={ () => { exportData("pdf", true) }}/>
            }
            { exportOptions.xlsx &&
                <FaRegFileExcel title='Export To Excel' size='1.7em' color='#A51E37' onClick={ () => { exportData("xlsx", true) }}/>
            }
            { exportOptions.csv &&
                <FaRegFileAlt title='Export To CSV' size='1.7em' color='#A51E37' onClick={ () => { exportData("csv", true) }}/>
            }
        </div>
    );
}

export default React.memo(TableExportButtons);
