import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import ShopTimeEntryMissingTimeBody from "./shopTimeEntryMissingTimeBody";
import PayPeriodFormFilter from "../../../common/form-filters/PayPeriodFormFilter";

/**
 * ShopTimeEntrySummary Component
 * @returns {JSX.Element}
 * @constructor
 */
const ShopTimeEntryMissingTime = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Shop Time Entry Missing Time" parent="Time Entry"/>
            <PayPeriodFormFilter {...{setFilters, setTableData, OnSubmitUrl:"getShopTimeEntryMissingTime"}}/>
            <ShopTimeEntryMissingTimeBody {...{filters, tableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default ShopTimeEntryMissingTime;
