import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import FormSelectPlain from "../../../forms/form-control/FormSelectPlain";

/**
 * AssetsFilters component
 * @returns {JSX.Element}
 * @constructor
 */
const AssetsFilters = (prop) => {
    const {register, handleSubmit, errors} = useForm();
    const {filters, setFilters, setTableData} = prop;
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        //get Disciplines
        apiClient
            .get('getEquipmentCategories')
            .then(response => {
                const categoryItems = response.data.map(({id, name}) => ({id: id, name: name}));
                const alteredCategories = [
                    {id: "0", name: "All"},
                    ...categoryItems
                ];

                setCategories(alteredCategories);
            });
    }, []);

    const updateFilters = (id, value) => {
        setFilters(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    // filter selection handler
    const filterSelectionHandler = (e) => {
        const {id, value} = e.target;
        updateFilters(id, value);
    }

    const onSubmit = () => {
        updateFilters('isLoading', true);
        updateFilters('isSubmitted', true);

        apiClient
            .post('getAssets', filters)
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
            updateFilters('isLoading', false);
        });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="status"
                    label="Status"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    onChange={filterSelectionHandler}
                    options={[{value: 1, label: "Active"}, {value: 0, label: "Inactive"}]}
                    register={register({required: {value: true, message: "required"}})}
                    errors={errors}
                />
                <FormSelectPlain
                    id="category_id"
                    label="Department"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    onChange={filterSelectionHandler}
                    options={categories}
                    register={register({required: false})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default AssetsFilters;
