import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import ReportBody from "./reportBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import apiClient from "../../../../utils/apiClient";
import {isEmpty} from "lodash";

/**
 * Active jobs report component
 * @returns {JSX.Element}
 * @constructor
 */

const ActiveJobsReport = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // get list of active jobs
    useEffect(() => {
        setIsLoading(true);
        apiClient.get('getActiveJobsReport')
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
        .then(function () {
            setIsLoading(false);
        });
    }, []);

    return (
        <>
            <Breadcrumb title="Active Jobs" parent="Reports"/>
            <ReportBody {...{tableData}} />
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
};

export default ActiveJobsReport;
