import React, {useState} from 'react';
import Table from "../../../common/table/table";
import JobsTable from "./JobsTable";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";
import {toast} from "react-toastify";

/**
 * JobsBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const JobsBody = (prop) => {
    const {filters, tableData, setTableData, setSelectedFilesRow, setFormModalFiles} = prop;
    const [disapproveModal, setDisapproveModal] = useState({isOpen: false, message: <p></p>});
    const [approveModal, setApproveModal] = useState({isOpen: false, message: <p></p>});
    const [selectedRow, setSelectedRow] = useState({});

    const ApproveHandler = () => {
        apiClient
            .post('reactivateJobById', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .then(function () {
                //close modal
                setApproveModal({...approveModal, isOpen: false})
            });
    }

    const DisapproveHandler = () => {
        apiClient
            .post('deactivateJobById', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .then(function () {
                setDisapproveModal({...disapproveModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    JobsTable(
                        setSelectedRow, setApproveModal, setDisapproveModal, setSelectedFilesRow, setFormModalFiles
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'jobs'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={approveModal}
                setModal={setApproveModal}
                title="Reactivate Job"
                onConfirm={ApproveHandler}
            />
            <ModalConfirmation
                modal={disapproveModal}
                setModal={setDisapproveModal}
                title="Deactivate Job"
                onConfirm={DisapproveHandler}
            />
        </div>
    );
}

export default JobsBody;
