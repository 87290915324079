import React, {useEffect, useState} from 'react';
import apiClient from "../../../utils/apiClient";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const EditInvoiceChargesModal = (props) => {
    const { modal, setModal, jobId, initialInvoiceCharges, onSave, invoiceId } = props;

    const toggle = () => setModal({ ...modal, isOpen: !modal.isOpen });
    const [invoiceCharges, setInvoiceCharges] = useState([]);
    const [selectedInvoiceChargeIds, setSelectedInvoiceChargeIds] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (jobId && modal.isOpen) {
            setIsLoading(true);
            
            const initialInvoiceChargeIds = initialInvoiceCharges.map(invoiceCharge => Number(invoiceCharge.id));

            Promise.all([
                apiClient
                    .post('getInvoiceChargesByJob', {'job_id': jobId})
                    .then(response => {
                        return response.data;
                    }),

                apiClient
                    .post('getInvoiceChargesByInvoice', {'id': invoiceId})
                    .then(response => {
                        return response.data;
                    }),
            ]).then(([invoiceCharges, activeInvoiceCharges]) => {
                setSelectedInvoiceChargeIds(initialInvoiceChargeIds);
                setInvoiceCharges([...activeInvoiceCharges, ...invoiceCharges]);
            })
            .finally(() => setIsLoading(false));
            
        }
    }, [jobId, initialInvoiceCharges, modal.isOpen, invoiceId]);

    const onInvoiceChargeChange = (invoiceCharge) => {
        if (selectedInvoiceChargeIds.includes(invoiceCharge.id)) {
            setSelectedInvoiceChargeIds(ids => ids.filter(id => id !== invoiceCharge.id));
        } else {
            setSelectedInvoiceChargeIds(ids => [...ids, invoiceCharge.id]);
        }
    }

    const onSaveButtonClick = () => {
        const selectedInvoiceCharges = invoiceCharges.filter(invoiceCharge => selectedInvoiceChargeIds.includes(invoiceCharge.id));
        onSave(selectedInvoiceCharges);
        toggle()
    }

    return (
        <Modal
            isOpen={modal.isOpen}
            toggle={toggle}
            className="modal-body"
            centered={true}
            size="lg"
        >
            <ModalHeader toggle={toggle}>Add Invoice Charge</ModalHeader>
            <ModalBody>
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Units</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceCharges.map((item) => (
                            <tr key={item.id}>
                                <td>
                                    <input checked={selectedInvoiceChargeIds.includes(item.id)} type="checkbox" onChange={() => onInvoiceChargeChange(item)} />
                                </td>
                                <td>{item.date}</td>
                                <td>{item.description}</td>
                                <td>{item.price}</td>
                                <td>{item.units}</td>
                                <td>{item.total}</td>
                            </tr>
                        ))}
                        {invoiceCharges.length === 0 && isLoading &&
                            <>
                                <tr>
                                    <td colSpan={7}>
                                        Loading
                                    </td>
                                </tr>
                            </>
                        }
                        {invoiceCharges.length === 0 && !isLoading &&
                            <>
                                <tr>
                                    <td colSpan={7}>
                                        Invoice charges not found
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" type="button" onClick={onSaveButtonClick}>
                    Ok
                </Button>
                <Button color="secondary" type="button" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};
