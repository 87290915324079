import { useMemo } from "react";

const TableItem = ({ row, prepareRow, headerGroups }) => {
    prepareRow(row);

    const headers = useMemo(() => {
        return headerGroups[0].headers || [];
    }, [headerGroups]);

    return (
        <div className="s-item">
            {headers.map((h) => {
                if (Array.isArray(h.columns) && h.columns?.length > 0) {
                    return (
                        <div key={h.id} className="s-col-group">
                            <span>{h.render('Header')}</span>
                            {h.columns.map((hc) => (
                                <div
                                    key={hc.id}
                                    className="s-row"
                                >
                                    <span>{hc.render('Header')}</span>
                                    <span>
                                        {row?.cells?.find(
                                            (rc) => rc.column?.id === hc.id ||
                                            rc.column?.id === hc['Header']
                                        )?.render('Cell')}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )
                }
                return (
                    <div key={h.id} className="s-row">
                        <span>{h.render('Header')}</span>
                        <span>
                            {row?.cells?.find(
                                (rc) => rc.column?.id === h.id ||
                                rc.column?.id === h['Header']
                            )?.render('Cell')}
                        </span>
                    </div>
                )
            })}
        </div>
    )
}

export default TableItem;