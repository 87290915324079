import React, {useState, Fragment, useEffect} from 'react';
import UserMenu from './userMenu';
import Notification from './notification';
import SearchHeader from './searchHeader';
// import { Link } from 'react-router-dom';
import { AlignLeft, Maximize, Bell, MoreHorizontal } from 'react-feather';
import { useAuthenticate } from '../../../hooks/auth';
import { BREAKPOINTS } from '../../../utils/constants';

const Header = ({ openCloseSidebar, setSidebar }) => {
    // const [rightSidebar, setRightSidebar] = useState(true);
    const { user } = useAuthenticate();
    const [headerbar, setHeaderbar] = useState(true);

    // function showRightSidebar() {
    //   if (rightSidebar) {
    //     setRightSidebar(!rightSidebar)
    //     document.querySelector(".right-sidebar").classList.add('show');
    //   } else {
    //     setRightSidebar(!rightSidebar)
    //     document.querySelector(".right-sidebar").classList.remove('show');
    //   }
    // }

    //full screen function
    function goFull() {
        if (document.fullScreenElement || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    // hide menu for mobile view
    useEffect(() => {
        if (window.innerWidth <= BREAKPOINTS.sm) {
            setSidebar(true);
            openCloseSidebar();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
  return (
    <Fragment>
        <div className="page-main-header d-print-none s-header" id="header-menu">
            <div className="main-header-right row">
                <div className="mobile-sidebar d-block">
                    <div className="media-body text-right switch-sm">
                        <label className="switch mb-0">
                            <div onClick={() => openCloseSidebar()}>
                                <AlignLeft className="s-icon-lg s-header-icon" />
                                <AlignLeft
                                    size={32}
                                    className="s-icon-sm s-header-icon"
                                />
                            </div>
                        </label>
                    </div>
                </div>
                <div className="nav-right col p-0">
                    <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
                        <li>
                            <SearchHeader />
                        </li>
                        <li>
                            <a onClick={goFull} className="text-dark" href="#!">
                                <Maximize
                                    size={18}
                                    className="s-icon-lg s-header-icon"
                                />
                                <Maximize
                                    size={24}
                                    className="s-icon-sm s-header-icon"
                                />
                            </a>
                        </li>
                        <li className="onhover-dropdown">
                            {user?.group_id === 1 &&
                                <>
                                    <Notification/>
                                        <Bell
                                            size={18}
                                            className="s-icon-lg s-header-icon"
                                        />
                                        <Bell
                                            size={24}
                                            className="s-icon-sm s-header-icon"
                                        />
                                    {/*<span className="dot"/>*/}
                                    <Notification />
                                </>
                            }
                        </li>
                        <UserMenu />
                    </ul>
                    <div
                        className="d-lg-none mobile-toggle pull-right"
                        onClick={() => setHeaderbar(!headerbar)}
                    >
                        <MoreHorizontal className="s-icon-lg s-header-icon" />
                        <MoreHorizontal
                            size={32}
                            className="s-icon-sm s-header-icon"
                        />
                    </div>
                </div>
                {/*<script id="result-template" type="text/x-handlebars-template">*/}
                {/*  <div className="ProfileCard u-cf">*/}
                {/*    <div className="ProfileCard-avatar">*/}
                {/*      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">*/}
                {/*      </path>*/}
                {/*        <polygon points="12 15 17 21 7 21 12 15"></polygon>*/}
                {/*      </svg>*/}
                {/*    </div>*/}
                {/*    <div className="ProfileCard-details">*/}
                {/*      <div className="ProfileCard-realName"></div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</script>*/}
                <script id="empty-template" type="text/x-handlebars-template">
                    <div className="EmptyMessage">Your search turned up 0 results. This most likely means the backend is down, yikes!</div>
                </script>
            </div>
        </div>
    </Fragment>
  );
}

export default Header;
