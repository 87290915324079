import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import apiClient from "../../../utils/apiClient";
import {useForm} from "react-hook-form";
import {addObjToArray, replaceObjFromArray} from "../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormInputText from "../../forms/form-control/FormInputText";
import {upperFirst} from "lodash";

/**
 * Modal form to create and edit a record
 * @returns {JSX.Element}
 * @constructor
 */
const BillingPeriodsFormModal = (props) => {
    const {modal, setModal, title, selectedRow, tableData, setTableData} = props;
    const {register, handleSubmit, errors} = useForm();
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    const onSubmit = (data) => {
        if (modal.action === 'create') {
            onCreate(data);
        }

        if (modal.action === 'edit') {
            onEdit(data);
        }
    }

    const onCreate = (data) => {
        apiClient
            .post('billingPeriods', data)
            .then(response => {
                setTableData(
                    addObjToArray(
                        tableData, response.data.record
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    const onEdit = (data) => {
        apiClient
            .put('billingPeriods/' + selectedRow.id, data)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} size={'md'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{`${upperFirst(modal.action)} ${title}`}</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputText
                            id="period_name"
                            label="Term Name"
                            className="col-md-12 mb-12"
                            value={selectedRow.period_name}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default React.memo(BillingPeriodsFormModal);
