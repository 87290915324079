import styled from "styled-components";

export const TableStyle = styled.div`
  table {
    thead {
      tr {
        :first-child {
          th:nth-child(1) {
            text-align: center;
            border-bottom: 5px solid;
            border-bottom-color: lightgrey;
          }

          th:nth-child(2) {
            text-align: center;
            border-bottom: 5px solid;
            border-bottom-color: black;
          }

          th:nth-child(3) {
            text-align: center;
            border-bottom: 5px solid;
            border-bottom-color: #900;
          }

          th:nth-child(4) {
            text-align: center;
            border-bottom: 5px solid;
            border-bottom-color: #829DA8;
          }

        ,
        th: nth-child(5) {
          text-align: left;
          border-bottom: 5px solid;
          border-bottom-color: black;
        }
        }
      }
    }

    tbody {
      tr:not(:first-child) {
        background: white;
      }
      tr {
        :last-child {
          td {
            border-top: 5px double black;
            font-weight: bold;
          }
        }
      }
      tr {
        td {
          a {
            color: dodgerblue;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
  }

  /**
  .toolbox-red__layer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #A51E36;
  }
  **/

  /**
  .toolbox-red__link {
    z-index: 100;
  }
   */
`;
