import styled from "styled-components";

export const FinancialDashboardTargetTableStyle = styled.div`
  table {
     thead{
          tr{
              th{
                  span{
                    color: white;
                  }                
                  background-color: grey;
                  :nth-child(1) {
                      width: 15%;
                  },
                  :nth-child(2) {
                      width: 17%;
                  }                       
                  :nth-child(3) {
                      width: 17%;
                  }                         
                  :nth-child(4) {
                      width: 17%;
                  }                         
                  :nth-child(5) {
                      width: 17%;
                  }                         
                  :nth-child(6) {
                      width: 17%;
                  }        
              } 
          }
    }
    tbody{
        tr {
            :nth-last-child(2) {
                font-weight: bold;
            }
            :last-child {
                td {
                    border-top: 5px double black;
                    font-weight: bold;
                }
            }
        }
    }
  }
`;

export const FinancialDashboardWeekTableStyle = styled.div`
  table {
     thead{
          tr{
              th{ 
                  span{
                      color: white;
                  }
                  background-color: grey;                
                  :nth-child(1) {
                      width: 15%;
                  },
                  :nth-child(2) {
                      width: 17%;
                  }                       
                  :nth-child(3) {
                      width: 17%;
                  }                         
                  :nth-child(4) {
                      width: 17%;
                  }                         
                  :nth-child(5) {
                      width: 17%;
                  }                         
                  :nth-child(6) {
                      width: 17%;
                  }        
              } 
          }
    }
    tbody{
        tr {
            :last-child {
                td {
                    border-top: 5px double black;
                    font-weight: bold;
                }
            }
        }
    }
  }
`;
