import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import ClientDailiesFilters from "./clientDailiesFilters";
import ClientDailiesBody from "./clientDailiesBody";
import LoadingIndicator from "../../common/loadingIndicator";
import ClientDailiesFormModal from "./clientDailiesFormModal";
import moment from "moment";

/**
 * Client Dailies Component
 * @returns {JSX.Element}
 * @constructor
 */
const ClientDailies = () => {
    const [tableData, setTableData] = useState([]);
    const [formModal, setFormModal] = useState({isOpen: false});
    const [selectedRow, setSelectedRow] = useState({});
    const [filters, setFilters] = useState({
        date_from: moment().startOf('month').format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Dailies" parent="Client Portal"/>
            <ClientDailiesFilters
                filters={filters}
                setTableData={setTableData}
                setFilters={setFilters}
            />
            <ClientDailiesBody
                filters={filters}
                setFilters={setFilters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setFormModal={setFormModal}
            />
            <ClientDailiesFormModal
                title="Client Notes"
                modal={formModal}
                setModal={setFormModal}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default ClientDailies;
