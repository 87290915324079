import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import FinancialDashboardFilters from "./financialDashboardFilters";
import FinancialDashboardBody from "./financialDashboardBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * FinancialDashboard Report
 * @returns {JSX.Element}
 * @constructor
 */
const FinancialDashboard = () => {
    const [tableTargetData, setTableTargetData] = useState([]);
    const [tableWeekData, setTableWeekData] = useState([]);
    const [PercentageChartDataDiscipline1, setPercentageChartDataDiscipline1] = useState(0);
    const [PercentageChartDataDiscipline2, setPercentageChartDataDiscipline2] = useState(0);
    const [PercentageChartDataDiscipline3, setPercentageChartDataDiscipline3] = useState(0);
    const [PercentageChartDataDiscipline4, setPercentageChartDataDiscipline4] = useState(0);
    const [PercentageChartDataTotal, setPercentageChartDataTotal] = useState(0);
    const [reportFilters, setReportFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    const reportFilterProps = {
        setReportFilters,
        setTableTargetData,
        setTableWeekData,
        setPercentageChartDataDiscipline1,
        setPercentageChartDataDiscipline2,
        setPercentageChartDataDiscipline3,
        setPercentageChartDataDiscipline4,
        setPercentageChartDataTotal
    };

    const reportBodyProps = {
        tableTargetData,
        tableWeekData,
        PercentageChartDataDiscipline1,
        PercentageChartDataDiscipline2,
        PercentageChartDataDiscipline3,
        PercentageChartDataDiscipline4,
        PercentageChartDataTotal,
        reportFilters
    };

    return (
        <>
            <Breadcrumb title="Financial Dashboard" parent="Toolbox"/>
            <FinancialDashboardFilters {...reportFilterProps}/>
            <FinancialDashboardBody {...reportBodyProps}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default FinancialDashboard;
