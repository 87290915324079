import React from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

/**
 * Driller Chart component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DrillerChart = (props) => {

    const {labels, dataset, title} = props;

    const data = {
        labels: labels,
        datasets: [
            {
                data: dataset,
                backgroundColor: 'rgb(165, 30, 55)',
            },
        ],
    }

    const options = {
        scales: {
            y: {
                title: 'Feet Per Hour',
                beginAtZero: true,
            },
            x: {
                grid: {
                    display: false,
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                position: 'top',
                color: 'black',
                text: title,
                font: {
                    size: 20,
                    family: "work-Sans, sans-serif",
                }
            },
        }
    }

    return <Bar data={data} options={options}/>
}

export default DrillerChart
