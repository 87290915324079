import React from "react";
import exportToHTML from "../../../../utils/exportToHtml";
import {FaCheck, FaCheckSquare} from "react-icons/fa";
import { formatNumberToDisplayWithCommas } from "../../../common/shared";

export const getTransactionRegisterColumns = (setSelectedRow, setApproveModal, setDisapproveModal,) => {
    return [
        {
            Header: 'Client',
            width: 150,
            accessor: 'client',
        },
        {
            Header: 'Job #',
            accessor: 'job_number',
        },
        {
            Header: 'Status',
            accessor: 'invoice_status',
        },
        {
            Header: 'Invoice #',
            accessor: 'invoice_number',
            Cell: (cell) => {
                const invoiceId = cell.row.original.invoice_id;
    
                return (!invoiceId)
                    ?
                        null
                    :
                        (
                            <a
                                className="actions"
                                rel="noopener noreferrer"
                                href="#/"
                                onClick={() => exportToHTML('exportInvoiceById', invoiceId)}
                            >
                                <b>{cell.value}</b>
                            </a>
                        );
            }
        },
        {
            Header: 'Invoice Date',
            accessor: 'date',
        },
        {
            Header: 'Work Period Start',
            accessor: 'work_period_start',
        },
        {
            Header: 'Work Period End',
            accessor: 'work_period_end',
        },
        {
            Header: 'Approved by',
            width: 150,
            accessor: 'approved_by',
        },
        {
            Header: 'Approved Date',
            accessor: 'approved_at',
        },
        {
            Header: 'Invoice Amount',
            accessor: 'revenue',
            Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
        },
        {
            Header: 'Modified Date',
            accessor: 'modified_at',
        },
        {
            Header: 'Revenue Amount',
            accessor: 'daily_revenue',
            Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
        },
        {
            Header: 'In VP',
            width: 40,
            Cell: (cell) => {
                const finalizedBy = cell.row.original.finalized_by;
    
                return (!finalizedBy)
                    ?
                        null
                    :
                        <FaCheck
                            color='#22af47'
                            title="In VP"
                        />
            }
        },
        {
            Header: 'Invoice Paid',
            accessor: 'invoice_paid',
        },
        {
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        {
                            row.invoice_paid_status ? (
                                <FaCheckSquare
                                    className="actions"
                                    title="Disapprove Transaction"
                                    color='#22af47'
                                    onClick={() => {
                                        const msg = 'Are you sure you want to disapprove this transaction?';
                                        setSelectedRow(row);
                                        setDisapproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                            ) : (
                                <FaCheck
                                    className="actions"
                                    title="Approve Transaction"
                                    onClick={() => {
                                        const msg = 'Are you sure you want to approve this transaction?';
                                        setSelectedRow(row);
                                        setApproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                            )
                        }
                    </>
                );
            }
        }
    ];
}
