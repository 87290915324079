const ShopTimeEntryMissingTimeTable = () => {

    return [
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Date',
            accessor: 'date',
        },
        {
            Header: 'Total Time',
            accessor: 'total_hours',
        },
        {
            Header: 'Total Per Diem',
            accessor: 'total_per_diem',
        },
        {
            Header: 'Approved By',
            Cell: (cell) => {
                const row = cell.row.original;
                return (row.approved_by) ? row.approved_by_first_name + ' ' + row.approved_by_last_name : '';
            }
        },
        {
            Header: 'Approved Date',
            accessor: 'approve_date',
        },
    ];
}

export default ShopTimeEntryMissingTimeTable;
