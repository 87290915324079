import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import DailiesFilters from "./dailiesFilters";
import DailiesBody from "./dailiesBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import moment from 'moment';

const DEFAULT_START_DATE = moment().subtract(1, 'month');
const DEFAULT_END_DATE = moment();

/**
 * Dailies Component
 * @returns {JSX.Element}
 * @constructor
 */
const Dailies = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [filters, setFilters] = useState({
        start_date: DEFAULT_START_DATE.format('YYYY-MM-DD'),
        end_date: DEFAULT_END_DATE.format('YYYY-MM-DD'),
        isLoading: false,
        isSubmitted: false,
    });
    const [tableDataDisciplineId, setTableDataDisciplineId] = useState(null);

    return (
        <>
            <Breadcrumb title="Dailies" parent="Data Entry"/>
            <DailiesFilters
                filters={filters}
                setTableData={setTableData}
                setFilters={setFilters}
                setTableDataDisciplineId={setTableDataDisciplineId}
            />
            <DailiesBody
                filters={filters}
                setFilters={setFilters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                tableDataDisciplineId={tableDataDisciplineId}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default Dailies;
