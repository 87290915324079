/**
 * return table columns definition
 * @returns json
 */
export const getTotalTableColumns = () => {
    return [
        {
            Header: 'Shift Stats',
            columns: [
                {
                    Header: 'Shifts',
                    accessor: 'shifts',
                    disableSortBy: true,
                    width: 50
                },
                {
                    Header: 'Shift Hrs',
                    accessor: 'shift_hrs',
                    disableSortBy: true,
                    width: 50
                },
                // temporary disabled per manager's request
                // {
                //     Header: 'Billable Hrs',
                //     accessor: 'billable_hrs',
                //     disableSortBy: true,
                //     width: 50
                // },
                {
                    Header: 'Drill Hrs',
                    accessor: 'drill_hrs',
                    disableSortBy: true,
                    width: 50
                }
            ],
        },
        {
            Header: 'Revenue Profile',
            columns: [
                {
                    Header: 'Revenue',
                    disableSortBy: true,
                    accessor: 'revenue_total'
                },
                {
                    Header: 'Shift Avg',
                    disableSortBy: true,
                    accessor: 'shift_avg'
                },
                {
                    Header: 'Rev/FT',
                    disableSortBy: true,
                    accessor: 'rev_ft'
                },
                {
                    Header: 'Rev/Shift Hr',
                    disableSortBy: true,
                    accessor: 'rev_shift_hrs'
                },
                {
                    Header: 'Rev/Drill Hr',
                    disableSortBy: true,
                    accessor: 'rev_drill_hrs'
                }
            ],
        },
        {
            Header: 'Production',
            columns: [
                {
                    Header: 'FT Depth',
                    disableSortBy: true,
                    accessor: 'depth_total',
                    width: 50
                },
                {
                    Header: 'FT/drill Hr',
                    disableSortBy: true,
                    accessor: 'ft_drill_hrs'
                },
                {
                    Header: 'FT/shift Hr',
                    disableSortBy: true,
                    accessor: 'ft_shift_hrs'
                },
                {
                    Header: 'FT/shift',
                    disableSortBy: true,
                    accessor: 'ft_shift'
                }
            ],
        },
        {
            Header: 'R&M',
            columns: [
                {
                    Header: 'R&M',
                    disableSortBy: true,
                    accessor: 'r_m'
                },
                {
                    Header: 'Up',
                    disableSortBy: true,
                    accessor: 'uptime'
                }
            ],
        },
    ];
}
