import React from "react";
import {FaEye, FaPencilAlt, FaEyeSlash, FaTrash} from "react-icons/fa";

const AssetsTable = (setFilters, setSelectedRow, setReactivateModal, setDeactivateModal, setDeleteModal) => {
    return [
        {
            Header: 'Equipment Type',
            accessor: 'type',
        },
        {
            Header: 'Asset #',
            accessor: 'asset_number',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <a
                        href={`/equipment/assets/asset/asset/edit/${row.id}`}
                        className={'font-weight-bold'}
                        rel="noopener noreferrer"
                        target="_blank">
                        {cell.value}
                    </a>
                );
            }
        },
        {
            Header: 'In VP',
        },
        {
            Header: 'VP Active',
        },
        {
            Header: 'Make',
            accessor: 'make',
        },
        {
            Header: 'Model',
            accessor: 'model',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'VIN/Serial #',
            accessor: 'vin',
        },
        {
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;
                let active = (
                    <>
                        <a
                            href={`/equipment/assets/asset/asset/edit/${row.id}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FaPencilAlt className="actions" title="Edit Asset"/>
                        </a>
                        {' '}
                        <FaEyeSlash
                            className="actions"
                            title="Deactivate Asset"
                            onClick={() => {
                                const details = row.type + ' ' + row.asset_number;
                                const msg = 'Are you sure that you want to deactivate asset: ' + details;
                                setSelectedRow(row);
                                setDeactivateModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                    </>
                );

                let inactive = (
                    <>
                        <FaEye
                            className="actions"
                            title="Reactivate Asset"
                            onClick={() => {
                                const details = row.type + ' ' + row.asset_number;
                                const msg = 'Are you sure that you want to reactivate asset: ' + details;
                                setSelectedRow(row);
                                setReactivateModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                        {' '}
                        <FaTrash
                            className="actions"
                            title="Delete Asset"
                            onClick={() => {
                                const details = row.type + ' ' + row.asset_number;
                                const msg = 'Are you sure that you want to delete asset : ' + details;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                );

                return (row.status === 1) ? active : inactive;
            }
        }
    ];
}

export default AssetsTable;
