import React, {useEffect, useState} from 'react';
import * as Icon from 'react-feather'
import {Link} from "react-router-dom";
import apiClient from "../../../utils/apiClient";
import { BREAKPOINTS } from '../../../utils/constants';

/**
 * Display menu based on user information
 * @returns {JSX.Element}
 * @constructor
 */
const Menu = ({ openCloseSidebar }) => {
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        apiClient
            .get('getGroupMenu')
            .then(response => {
                setMenuItems(response.data);
            });
    }, []);

    // Click Toggle menu
    const toggleNavActive = (item) => {
        if (!item.active) {
            menuItems.forEach(a => {
                if (menuItems.includes(item)) {
                    a.active = false;
                }

                if (!a.children) {
                    return false;
                }

                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                        b.active = false;
                    }

                    if (!b.children) {
                        return false;
                    }

                    b.children.forEach(c => {
                        if (b.children.includes(item)) {
                            c.active = false;
                        }
                    })
                })
            });
        }
        item.active = !item.active

        if (!item.children && window.innerWidth <= BREAKPOINTS.md) {
            openCloseSidebar();
        }
    }

    // render menu icon
    const MenuIcon = ({name}) => {
        const IconComponent = Icon[name];
        return <IconComponent/>;
    }

    return (
        <>
            {menuItems.map((menuItem, i) =>
                    <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                        {
                            (menuItem.type === 'sub')
                            ?
                                <a className="sidebar-header" href="#/" onClick={() => toggleNavActive(menuItem)}>
                                    <MenuIcon name={menuItem.icon}/>
                                    <span className="s-menu-font-size">{menuItem.title}</span>
                                    <i className="fa fa-angle-right pull-right"/>
                                </a>
                            :
                                ''
                        }
                        {
                            (menuItem.type === 'link')
                                ?
                                    <Link
                                        to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                                        className={`sidebar-header ${menuItem.active ? 'active' : ''}`}
                                        onClick={() => toggleNavActive(menuItem)}
                                    >
                                        <MenuIcon name={menuItem.icon}/>
                                        <span className="s-menu-font-size">{menuItem.title}</span>
                                        {
                                            menuItem.children
                                                ?
                                                    <i className="fa fa-angle-right pull-right"/>
                                                :
                                                    ''
                                        }
                                    </Link>
                                :
                                    ''
                        }
                        {
                            menuItem.children
                                ?
                                    <ul
                                        className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                                        style={menuItem.active ? {opacity: 1, transition: 'opacity 500ms ease-in'} : {}}
                                    >
                                        {menuItem.children.map((childrenItem, index) =>
                                            <li
                                                key={index}
                                                className={childrenItem.children ? childrenItem.active ? 'active' : '' : ''}
                                            >
                                            {
                                                (childrenItem.type === 'sub')
                                                    ?
                                                        // eslint-disable-next-line
                                                        <a href="#" onClick={() => toggleNavActive(childrenItem)} className="s-menu-font-size">
                                                            <i className="fa fa-circle"/>{childrenItem.title} <i
                                                            className="fa fa-angle-right pull-right"/></a>
                                                    :
                                                        ''
                                            }
                                            {
                                                (childrenItem.type === 'link')
                                                    ?
                                                        <Link
                                                            to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                                            className={childrenItem.active ? 'active s-menu-font-size' : 's-menu-font-size'}
                                                            onClick={() => toggleNavActive(childrenItem)}
                                                        >
                                                            <i className="fa fa-circle"/>{childrenItem.title}
                                                        </Link>
                                                    :
                                                        ''
                                            }
                                            {
                                                childrenItem.children
                                                    ?
                                                        <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'}`}>
                                                            {childrenItem.children.map((childrenSubItem, key) =>
                                                                <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                                                    {(childrenSubItem.type === 'link') ?
                                                                        <Link
                                                                            to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                                            className={childrenSubItem.active ? 'active s-menu-font-size' : 's-menu-font-size'}
                                                                            onClick={() => toggleNavActive(childrenSubItem)}
                                                                        >
                                                                            <i className="fa fa-circle"/>{childrenSubItem.title}
                                                                        </Link>
                                                                        : ''}
                                                                </li>
                                                            )}
                                                        </ul>
                                                    :
                                                        ''
                                            }
                                            </li>
                                        )}
                                    </ul>
                                :
                                    ''
                        }
                    </li>
            )}
        </>
    );
}

export default Menu;





