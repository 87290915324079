import React, { Fragment } from 'react';
import { isNull } from 'lodash';
import FormSelect from "../../forms/form-control/FormSelect";
import FormInputDate from "../../forms/form-control/FormInputDate";
import FormInputText from "../../forms/form-control/FormInputText";
import FormInputNumber from "../../forms/form-control/FormInputNumber";
import { FaPlus, FaTrash } from "react-icons/fa";
import { BREAKPOINTS } from '../../../utils/constants';
import MobileEntryItem from './MobileEntryItem';
import FormSearchableSelect from "../../forms/form-control/FormSearchableSelect";

/**
 * CreateDirectEntry Table Component
 * @returns {JSX.Element}
 * @constructor
 */
const CreateDirectEntryTable = (props) => {
    const {
        userId, register, errors, fields, append, remove, ecOptions, jobs, states, payrollDates, control
    } = props;

    // check if user is selected
    if (isNull(userId)) {
        return null;
    }

    if (window.innerWidth <= BREAKPOINTS['lg']) {
        return (
            <div className="s-flex s-flex-col s-flex-gy mt-4">
                {fields.map((f, i) => {
                    if (i === f.length - 1) {
                        <MobileEntryItem {...{ ...props, key: f.id, i }} />
                    }

                    return (
                        <Fragment key={f.id}>
                            <MobileEntryItem {...{ ...props, key: f.id, i }} />
                            <hr className="s-divider" />
                        </Fragment>
                    );
                })}
            </div>
        );
    }

    return (
        <>
            <div className="form-row">
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Pay Sequence</th>
                            <th>EC</th>
                            <th>Work Date</th>
                            <th>Payroll Date</th>
                            <th>Job</th>
                            <th>State</th>
                            <th>Hours</th>
                            <th>Per Diem</th>
                            <th>Equipment #</th>
                            <th>
                                <FaPlus
                                    color='#22af47'
                                    size={20}
                                    title="Add Time"
                                    onClick={() => append({})}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    <FormSelect
                                        id={`direct_entries[${index}].pay_sequence`}
                                        options={[{id: 1, name: 'Current'}, {id: 2, name: 'Previous'}]}
                                        register={register({required: "required"})}
                                        className="col-md-q m-b-3"
                                        errors={errors}
                                    />
                                </td>
                                <td>
                                    <FormSelect
                                        id={`direct_entries[${index}].ec_id`}
                                        options={ecOptions}
                                        register={register({required: "required"})}
                                        className="col-md-q m-b-3"
                                        errors={errors}
                                    />
                                </td>
                                <td>
                                    <FormInputDate
                                        id={`direct_entries[${index}].work_date`}
                                        register={register({required: "required"})}
                                        className="col-md-q m-b-3"
                                        errors={errors}
                                    />
                                </td>
                                <td>
                                    <FormSelect
                                        id={`direct_entries[${index}].payroll_date`}
                                        className="col-md-q"
                                        options={payrollDates}
                                        register={register({required: "required"})}
                                        errors={errors}
                                    />
                                </td>
                                <td>
                                    <FormSearchableSelect
                                        id={`direct_entries[${index}].job_id`}
                                        className="col-md-q m-b-3 width-150"
                                        options={jobs}
                                        register={register}
                                        control={control}
                                        errors={errors}
                                    />
                                </td>
                                <td>
                                    <FormSelect
                                        id={`direct_entries[${index}].state_id`}
                                        className="col-md-q m-b-3"
                                        options={states}
                                        register={register({required: "required"})}
                                        errors={errors}
                                    />
                                </td>
                                <td>
                                    <FormInputNumber
                                        id={`direct_entries[${index}].hours`}
                                        className="hours-column-width"
                                        register={register({
                                            min: {value: 0, message: "min value is 0"},
                                            max: {value: 24.01, message: "max value is 24.00"}
                                        })}
                                        errors={errors}
                                    />
                                </td>
                                <td>
                                    <FormInputNumber
                                        id={`direct_entries[${index}].per_diem`}
                                        className="col-md-q"
                                        register={register({
                                            min: {value: 0, message: "Per Diem min value is 0"},
                                            max: {value: 99999999.99, message: "Per Diem max value is 99999999.99"}
                                        })}
                                        errors={errors}
                                    />
                                </td>
                                <td>
                                    <FormInputText
                                        id={`direct_entries[${index}].equipment_number`}
                                        register={register}
                                        errors={errors}
                                    />
                                </td>
                                <td>
                                    <FaTrash
                                        color='#A51E37'
                                        title="Remove"
                                        onClick={() => remove(index)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default CreateDirectEntryTable;
