import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import apiClient from "../../../../utils/apiClient";
import FormInputText from "../../../forms/form-control/FormInputText";
import {useForm} from "react-hook-form";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {toast} from "react-toastify";
import { useAuthenticate } from "../../../../hooks/auth";

/**
 * Active Dailies Send Report Modal
 * @returns {JSX.Element}
 * @constructor
 */
const ModalSendDailyEmailReport = (props) => {
    const {modal, setModal, selectedRow} = props;
    const {register, handleSubmit, reset, errors} = useForm();

    const onSubmit = (data) => {
        data['daily_id'] = selectedRow.daily_id;

        apiClient
            .post('sendDailyReportByEmail', data)
            .then(response => {
                toast.success(response.data.message);
                toggle();
                reset();
            });
    }

    // toggle for open/close the modal form
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    // get user information
    const { user } = useAuthenticate();

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} >
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>Send Daily Report Email</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputText
                            id="from"
                            label="From"
                            className="col-md-12 mb-3"
                            type="email"
                            register={register({ required: { value: true, message: "required" }})}
                            errors={errors}
                            value={user.email}
                        />
                    </div>
                    <div className="form-row">
                        <FormInputText
                            id="to"
                            label="To"
                            className="col-md-12 mb-3"
                            register={register({
                                required: { value: true, message: "required"},
                                pattern: {
                                    value: /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/i,
                                    message: "use commas as separators for multiple email recipients"
                                }
                            })}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <FormSubmitButton btnClassName="btn-primary" label="Send" />
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default ModalSendDailyEmailReport;
