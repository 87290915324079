export const tableColumns = [
    {
        Header: 'Discipline',
        accessor: 'discipline',
        width: 100,
    },
    {
        Header: 'Name',
        accessor: 'user_name',
    },
    {
        Header: 'Title',
        accessor: 'user_group',
    },
    {
        Header: 'VP ID',
        accessor: 'user_erp',
    },
    {
        Header: 'Job',
        accessor: 'job_number',
    },
    {
        Header: 'Location',
        accessor: 'location',
    },
    {
        Header: 'Client',
        accessor: 'client',
    },
    {
        Header: 'Rig',
        accessor: 'rig_name',
    },
    {
        Header: 'Shift',
        accessor: 'daily_shift',
        width: 70,
    },
    {
        Header: 'Date',
        accessor: 'daily_date',
        width: 100,
    },
];
