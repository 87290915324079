import React from "react";
import {FaPencilAlt, FaPlus, FaTrash} from "react-icons/fa";

const CostCodesTableColumns = (setSelectedRow, setFormModal, setDeleteModal) => {
    return [
        {
            Header: 'Code',
            accessor: 'code',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Revenue Group',
            accessor: 'revenue_group',
        },
        {
            Header: 'Lost Time Group',
            accessor: 'lost_time_group',
        },
        {
            Header: 'Shift Hours',
            accessor: 'is_shift_hours',
        },
        {
            Header: 'Mobile Entry',
            accessor: 'is_mobile_entry',
        },
        {
            id: 'actions',
            Header: () => {
                return (
                    <FaPlus
                        color='#22af47'
                        size={18}
                        title="Create"
                        onClick={() => {
                            setSelectedRow({});
                            setFormModal({isOpen: true, action: 'create'});
                        }}
                    />
                )
            },
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaPencilAlt
                            className="actions"
                            title="Edit"
                            onClick={() => {
                                setSelectedRow(row);
                                setFormModal({isOpen: true, action: 'edit'});
                            }}
                        />
                        {' '}
                        <FaTrash
                            className="actions"
                            title="Delete"
                            onClick={() => {
                                const msg = <p>Are you sure you want to delete <b>{row.code} - {row.description}</b>?</p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                )
            }
        }
    ];
}

export default CostCodesTableColumns;



