import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import EmailDistributionListFilters from "./emailDistributionListFilters";
import EmailList from "../../../common/data-tables/email-list/EmailList";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * EmailDistributionList component
 * @returns {JSX.Element}
 * @constructor
 */

const EmailDistributionList = () => {
    const [tableData, setTableData] = useState([]);

    const [reportFilters, setReportFilters] = useState({
        discipline_id: '',
        rig_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Email distribution list" parent="Dailies"/>
            <EmailDistributionListFilters {...{
                reportFilters,
                setReportFilters,
                setTableData
            }} />
            <EmailList
                type='final'
                title='Client Distribution List'
                reportFilters={reportFilters}
                tableData={tableData}
                setTableData={setTableData}
            />
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default EmailDistributionList;
