const ShopTimeEntryHistoryTable = () => {
    return [
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Date',
            accessor: 'date',
        },
        {
            Header: 'Job',
            accessor: 'job_number',
        },
        {
            Header: 'Hours Worked',
            accessor: 'hours',
        },
        {
            Header: 'Reason',
            accessor: 'ec_name',
        },
        {
            Header: 'Per Diem',
            accessor: 'per_diem',
        },
        {
            Header: 'Equipment #',
            accessor: 'equipment_number',
        },
        {
            Header: 'Cost Code',
            accessor: 'cost_codes_description',
        },
        {
            Header: 'Work Description',
            accessor: 'notes',
        },
    ];
}

export default ShopTimeEntryHistoryTable;
