import { useReducer } from "react";
import { initialState, AppStateContext, reducer } from "../app";

export const WithApp = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState.state);

    return (
        <AppStateContext.Provider value={{ state, dispatch }}>
            {children}
        </AppStateContext.Provider>
    );
};
