import styled from 'styled-components';

export const RevenueWeeklyTrackingStyle = styled.div`
    table {
        thead {
            tr {
                :first-child {
                    th:nth-child(n) {
                        text-align: center;
                        border-right: 2px solid;
                        border-right-color: lightgrey;
                    } 
                }
                :nth-child(2) {
                    text-align: center;
                    border-bottom: 3px solid;
                    border-bottom-color: black;
                }
            }
        }
        tbody {
            tr {
                td:first-child:not(:empty) {
                    background: white;
                    font-weight: bold;
                }
            }
            tr {
                :last-child {
                    td {
                        border-top: 5px double black;
                        font-weight: bold;
                    }
                }
            }
            tr {
                td {
                    a {
                        color: dodgerblue;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }
            }
            td {
                :nth-child(odd) {
                    background: lightgrey;
                }
            }
        }
    }
`;
