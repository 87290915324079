import React from 'react';
import Creatable from 'react-select/creatable';
import {Controller} from "react-hook-form";
import {get, isFunction, isNil} from "lodash";

/**
 * Creatable Dropdown
 * ----
 * Options: Array of options that populate the select menu
 * options = [
 *              { value: 'chocolate', label: 'Chocolate' },
 *              { value: 'strawberry', label: 'Strawberry' },
 *              { value: 'vanilla', label: 'Vanilla' }
 *            ]
 * -----
 * rules: Validation rules in the same format as for register.
 * rules={{ required: "State is required" }}
 * -----
 * control:(required) control object is from invoking useForm
 * -----
 * onChange: function that receive the selected value or null
 *   const onChange = (selectedOption) => {
 *       console.log(`Option selected:`, selectedOption);
 *   };
 *
 *   if you want to pass extract parameters to onChange,
 *   you can use the followed definition
 *   onChange={ (v) => otherFunction(v, param1, param2, ...)}
 * -----
 * value: number that represent the pre-selected value
 *    value={1}
 * -----
 * customStyles:
 * see https://react-select.com/styles#styles
 *
 * Full example:
 *
 *      <FormCreatableSelect
 *          id={`direct_entries[${index}].state_id`}
 *          options={states}
 *          value={1}
 *          className="col-md-2"
 *          rules={{ required: "State is required" }}
 *          control={control}
 *          errors={errors}
 *          onChange={onChange}
 *      />
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormCreatableSelect = (props) => {

    const {id, label, value, options, className, rules, errors, control, onChange, customStyles} = props;
    let defaultValue = null;

    // set default value
    if (value) {
        defaultValue = options.find(item => item.value === value);
    }

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <Controller
                name={id}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={ props =>
                    <Creatable
                        name={props.name}
                        options={options}
                        value={props.value}
                        styles={customStyles}
                        onChange={(e) => {
                            props.onChange(e);
                            if (isFunction(onChange)) {
                                onChange(
                                    isNil(e) ? e : e.value
                                );
                            }
                        }}
                    />
                }
            />
            {errors && <span className="font-danger">{ get(errors, id)?.message }</span>}
        </div>
    );
}

export default React.memo(FormCreatableSelect);
