import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import EmployeesReviewFilters from "./employeesReviewFilters";
import EmployeesReviewBody from "./employeesReviewBody";
import LoadingIndicator from "../../common/loadingIndicator";

/**
 * Employees Review component
 * @returns {JSX.Element}
 * @constructor
 */

const EmployeesReview = () => {
    const [tableData, setTableData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        discipline_id: '',
        rig_id: '',
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Employees Review" parent="Time Entry"/>
            <EmployeesReviewFilters {...{tableData, setTableData, reportFilters, setReportFilters}} />
            <EmployeesReviewBody {...{reportFilters, tableData}} />
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default EmployeesReview;
