import React, {useState} from 'react';
import TableReport from "../../common/table/table";
import MessageEmptyData from "../../common/messageEmptyData";
import ModalConfirmation from "../../common/modal/ModalConfirmation";
import apiClient from "../../../utils/apiClient";
import {toast} from "react-toastify";
import ClientInvoicesTable from "./clientInvoicesTable";
import {replaceObjFromArray} from "../../../utils/arrayHelpers";

/**
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ClientInvoicesBody = (prop) => {
    const {filters, setFilters, tableData, setTableData, selectedRow, setSelectedRow, user} = prop;
    const [approveModal, setApproveModal] = useState({isOpen: false, message: <p></p>});
    const [disapproveModal, setDisapproveModal] = useState({isOpen: false, message: <p></p>});

    const ApproveHandler = () => {
        apiClient
            .post('clientApproveInvoice/' + selectedRow.id)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.row
                    )
                );
                toast.success(response.data.message);
            })
            .then(function () {
                //close deactivateModal
                setApproveModal({...approveModal, isOpen: false})
            });
    }

    const DisapproveHandler = () => {
        apiClient
            .post('clientDisproveInvoice/' + selectedRow.id)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.row
                    )
                );
                toast.success(response.data.message);
            })
            .then(function () {
                //close deactivateModal
                setDisapproveModal({...disapproveModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={
                    ClientInvoicesTable(
                        setFilters, setSelectedRow, setApproveModal, setDisapproveModal, user
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'invoices'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={approveModal}
                setModal={setApproveModal}
                title="Approve Invoice"
                onConfirm={ApproveHandler}
            />
            <ModalConfirmation
                modal={disapproveModal}
                setModal={setDisapproveModal}
                title="Disapprove Invoice"
                onConfirm={DisapproveHandler}
            />
        </div>
    );
}

export default ClientInvoicesBody;
