import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import moment from "moment";

/**
 * Filter component for daily report summary
 * @returns {JSX.Element}
 * @constructor
 */
const DailyReportSummaryFilters = (prop) => {
    const {register, handleSubmit, errors, setValue, getValues} = useForm();
    const [disciplines, setDisciplines] = useState([]);
    const [rigs, setRigs] = useState([]);
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [payPeriods, setPayPeriods] = useState([]);
    const [dailyDates, setDailyDates] = useState([]);
    const {tableData, setTableData, setReportFilters} = prop;
    const [endDate, setEndDate] = useState(0);

    // submit handler for report filter
    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient
            .post('getDailySummaryByDate', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    }

    // get list of daily dates
    useEffect(() => {
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                let year = Number(moment(response.data.end_date).format('YYYY'));
                setEndDate(year);
            });

        // get Disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(
                    response.data.disciplines.map(
                        ({id, name}) => ({id: id, name: name})
                    )
                );
        });

        // get FinancialPeriods
        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => ({
                            id: id, name: name + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });

        // get daily dates
        apiClient
            .get('getLastMonthDailyDates')
            .then(response => {
                setDailyDates(
                    response.data.map(
                        ({date}) => ({id: date, name: date})
                    )
                );
            });

        // get PayPeriods
        apiClient.post('getPayPeriodsByYear', {'year': endDate})
            .then(response => {
                setPayPeriods(
                    response.data.map(
                        ({id, start_date, end_date, is_current}) => ({
                            id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });

        // set yesterday date for date range components
        setValue('date_from', moment().subtract(1, "day").format("YYYY-MM-DD"));
        setValue('date_to', moment().subtract(1, "day").format("YYYY-MM-DD"));

    }, [setValue, endDate]);

    // discipline selection handler
    const disciplineSelectionHandler = (e) => {
        // get Rigs by Discipline
        apiClient
            .post('getAllActiveRigsByDisciplineId', {'discipline_id': e.target.value})
            .then(response => {
                const rigItems = response.data.rigs.map(
                    ({id, name}) => ({id: id, name: name})
                );
                setRigs(rigItems);
            });
    }

    return (
        <form
            className="needs-validation s-filters-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="s-filters">
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="s-filter"
                    onChange={disciplineSelectionHandler}
                    options={disciplines}
                    register={register}
                    errors={errors}
                />
                <FormSelect
                    id="rig_id"
                    label="Rig"
                    className="s-filter"
                    options={rigs}
                    register={register}
                    errors={errors}
                />
                <FormSelect
                    id="date"
                    label="Date"
                    className="s-filter"
                    options={dailyDates}
                    register={register}
                    errors={errors}
                    onChange={() => {
                        setValue('financial_period_id', '');
                        setValue('date_from', '');
                        setValue('date_to', '');
                        setValue('pay_period_id', '');
                    }}
                />
                <FormSelect
                    id="pay_period_id"
                    label="Weekly Data Period"
                    className="s-filter"
                    options={payPeriods}
                    register={register}
                    errors={errors}
                    onChange={() => {
                        setValue('financial_period_id', '');
                        setValue('date_from', '');
                        setValue('date_to', '');
                        setValue('date', '');
                    }}
                />
                <FormSelect
                    id="financial_period_id"
                    label="Monthly Data Period"
                    className="s-filter"
                    options={financialPeriods}
                    register={register}
                    onChange={() => {
                        setValue('pay_period_id', '');
                        setValue('date_from', '');
                        setValue('date_to', '');
                        setValue('date', '');
                    }}
                    errors={errors}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="s-filter"
                    onChange={() => {
                        setValue('pay_period_id', '');
                        setValue('financial_period_id', '');
                        setValue('date', '');
                        if (isEmpty(getValues('date_to'))) {
                            setValue('date_to', getValues('date_from'));
                        }

                    }}
                    register={register}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="s-filter"
                    onChange={() => {
                        setValue('pay_period_id', '');
                        setValue('financial_period_id', '');
                        setValue('date', '');
                    }}
                    register={register}
                />
                
                <FormSubmitButton
                    label="Show"
                    className="s-button s-button-xl"
                />
                
                {/* For mobile view */}
                {!isEmpty(tableData) ? (
                    <div className="s-buttons s-justify-between">
                        {/*do not delete, temporary disabled*/}
                        {/*<FormSubmitButton*/}
                        {/*    type="button"*/}
                        {/*    label="Send Email"*/}
                        {/*    btnClassName="btn-success"*/}
                        {/*    onClick={() => {*/}
                        {/*        setSendReportModal({*/}
                        {/*            isOpen: true*/}
                        {/*        });*/}
                        {/*    }}*/}
                        {/*/>*/}

                        <FormSubmitButton
                            label="Show"
                            className="s-button"
                        />
                    </div>
                ) : (
                    <div className="s-buttons s-justify-end">
                        <FormSubmitButton
                            label="Show"
                            className="s-button"
                        />
                    </div>
                )}
            </div>
            {/* do not delete, blocked for now */}
            {/*{!isEmpty(tableData) && (*/}
            {/*    <FormSubmitButton*/}
            {/*        type="button"*/}
            {/*        label="Send Email"*/}
            {/*        btnClassName="btn-success s-button-xl"*/}
            {/*        onClick={() => {*/}
            {/*            setSendReportModal({*/}
            {/*                isOpen: true*/}
            {/*            });*/}
            {/*        }}*/}
            {/*    />*/}
            {/*)}*/}
        </form>
    );
}

export default DailyReportSummaryFilters;
