import React, {useEffect, useState} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormInputText from "../../../forms/form-control/FormInputText";
import {useForm} from "react-hook-form";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormInputNumber from "../../../forms/form-control/FormInputNumber";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import {replaceObjFromArray} from "../../../../utils/arrayHelpers";

/**
 * EditRig Modal
 * @returns {JSX.Element}
 * @constructor
 */
const EditRigModal = (props) => {
    const {modal, setModal, selectedRow, tableData, setTableData} = props;
    const {register, handleSubmit, errors} = useForm();
    const [disciplines, setDisciplines] = useState([]);
    const [types, setTypes] = useState([]);
    const [supervisors, setSupervisors] = useState([]);

    useEffect(() => {
        // get list of disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
        });
        // get list of rig types
        apiClient
            .get('getRigTypes')
            .then(response => {
                setTypes(response.data);
        });
        // get list of supervisor users
        apiClient
            .get('getSupervisors')
            .then(response => {
                setSupervisors(
                    response.data.map(
                        ({id, first_name, last_name}) => ({value: id, label: first_name + ' ' + last_name})
                    )
                );
        });
    }, []);

    const onSave = (data) => {
        apiClient
            .post('editRig', data)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    // toggle for open/close the modal form
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-lg modal-body" centered={true} >
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSave)}
            >
                <ModalHeader toggle={toggle}>Edit Rig</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputText
                            id="id"
                            type="hidden"
                            value={selectedRow.id}
                            register={register}
                        />
                        <FormInputText
                            id="name"
                            label="Name"
                            type="text"
                            disabled={true}
                            className="col-md-4 mb-3"
                            value={selectedRow.name}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputText
                            id="make"
                            label="Make"
                            type="text"
                            className="col-md-4 mb-3"
                            value={selectedRow.make}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputText
                            id="model"
                            label="Model"
                            type="text"
                            className="col-md-4 mb-3"
                            value={selectedRow.model}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row">
                        <FormInputNumber
                            id="year"
                            label="Year"
                            className="col-md-4 mb-3"
                            value={selectedRow.year}
                            register={register({
                                min: {value: 1, message: "min value is 1"},
                                max: {value: 3000, message: "max value is 3000"},
                                required: "required"
                            })}
                            errors={errors}
                        />
                        <FormSelect
                            id="discipline_id"
                            label="Discipline"
                            className="col-1/2 mb-3"
                            value={selectedRow.discipline_id}
                            options={disciplines}
                            register={register({required: "Discipline is required"})}
                            errors={errors}
                        />
                        <FormSelect
                            id="rig_type_id"
                            label="Type"
                            className="col-md-4 mb-3"
                            value={selectedRow.rig_type_id}
                            options={types}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelect
                            id="supervisor_id"
                            label="Tool Pusher"
                            className="col-md-3 mb-3"
                            value={selectedRow.supervisor_id}
                            options={supervisors}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputText
                            id="description"
                            label="Description"
                            type="text"
                            className="col-md-6 mb-3"
                            value={selectedRow.description}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default EditRigModal;
