import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import TransactionRegisterPlusFilters from "./TransactionRegisterPlusFilters";
import TransactionRegisterPlusBody from "./TransactionRegisterPlusBody";
import moment from 'moment';
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * TransactionRegisterDetail Report
 * @returns {JSX.Element}
 * @constructor
 */
const TransactionRegisterDetail = () => {
    const [tableData, setTableData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        date_from: moment().startOf('month').format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        client_id: '',
        job_id: '',
        invoice_status_id: '',
        discipline_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Transaction Register Detail" parent="Reports"/>
            <TransactionRegisterPlusFilters
                reportFilters={reportFilters}
                setTableData={setTableData}
                setReportFilters={setReportFilters}
            />
            <TransactionRegisterPlusBody reportFilters={reportFilters} tableData={tableData}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default TransactionRegisterDetail;
