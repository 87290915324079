import React from 'react';

/**
 * SubmitButton
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SubmitButton = (props) => {
    const {
        label,
        className,
        onClick,
        type = "submit",
        btnClassName = `btn-primary`
    } = props;

    return (
        <div className={className}>
            <button
                className={`btn ${btnClassName}`}
                type={type}
                onClick={onClick}
            >
                {label}
            </button>
        </div>
    );
};

export default React.memo(SubmitButton);
