import {AccordionItem} from "react-light-accordion";
import InputDate from "../../../../forms/form-control/daily/InputDate";
import Select from "../../../../forms/form-control/daily/Select";
import React, {useEffect, useState} from "react";
import apiClient from "../../../../../utils/apiClient";
import Creatable from 'react-select/creatable';
import * as Shared from "../../../../common/shared";
import {get, isEmpty} from "lodash";
import FormSearchableSelect from "../../../../forms/form-control/FormSearchableSelect";
import FormSelect from "../../../../forms/form-control/FormSelect";

const GeneralInformation = (props) => {
    const {
        rigs, errors, control, register, setActivityDescriptions, setDrilledDescriptions, setGeneralDescriptions,
        setConsumableDescriptions, setReamedDescriptions, setSelectedJobId, setValue, setHoles, disciplineId,
        action, fieldDaily, rigsAvailable, selectedJobId, setFieldEmployeesTemplate, setFieldHolesTemplate,
        setFieldCasingsTemplate, setSelectedRigName, setSelectedJobNumber, setSelectedClientName,
        setSelectedLocationName, setFieldBitsTemplate, setJobState, getValues,
    } = props;

    const [selectedRig, setSelectedRig] = useState('');
    const [jobs, setJobs] = useState([]);
    const [locations, setLocations] = useState([]);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        // open all cards
        Shared.toggleAllAccordionItems();

        if (action === 'create') {
            // set default date to today
            document.getElementById('general_info.date').valueAsDate = new Date();
        }

        // when editing field daily
        if (action === 'edit') {
            setSelectedJobId(fieldDaily.job_id);
            // build holes for creatable select
            if (fieldDaily.job_id > 0 && disciplineId > 0) {
                // get holes by job and discipline
                apiClient
                    .post('getHolesByJobAndDisciplineId',
                        {
                            'job_id': fieldDaily.job_id,
                            'discipline_id': disciplineId
                        })
                    .then(response => {
                        let holes = response.data.map(({name}) => ({value: name, label: name}));
                        setHoles(holes);
                    });
            }

            if (fieldDaily.rig_id > 0) {
                apiClient
                    .post('getActiveJobsByRigId', {'rig_id': fieldDaily.rig_id})
                    .then(response => {
                        const jobItems = response.data.jobs.map(
                            ({id, job_number}) => ({value: id, label: job_number})
                        );

                        setJobs(jobItems);
                    });
            }

            if (fieldDaily.job_id > 0) {
                apiClient
                    .post('getActiveClientByJobId', {'id': fieldDaily.job_id})
                    .then(response => {
                        if (isEmpty(response.data.active_client)) {
                            setClients([]);
                            return;
                        }
                        let active_client = response.data.active_client;
                        let client = [{value: active_client.id, label: active_client.client_name}];
                        setClients(client);
                    });

                apiClient
                    .post('getLocationsByJobId', {'job_id': fieldDaily.job_id})
                    .then(response => {
                        const locationItems = response.data.locations.map(
                            ({id, name}) => ({value: id, label: name})
                        );

                        setLocations(locationItems);
                    });
            }
        }
    }, [action, setSelectedJobId, fieldDaily, setLocations, setClients, setJobs, disciplineId, setHoles]);

    useEffect(() => {
        // while editing field daily
        if (action === 'edit') {
            setValue(`general_info.location_id`, {
                value: fieldDaily.location_id,
                label: (fieldDaily.location !== null) ? fieldDaily.location.name : 'not provided'}
            );
        }
    }, [action, fieldDaily.location, fieldDaily.location_id, setValue])

    const handleRigSelect = (rigId, rigName) => {
        setSelectedRigName(rigName);
        if (rigId > 0) {
            setSelectedRig(rigId);
            apiClient
                .post('getActiveJobsByRigId', {'rig_id': rigId})
                .then(response => {
                    // assign state id to later define if it is CA
                    setJobState(response.data?.jobs[0]?.state_id);
                    const jobItems = response.data.jobs.map(
                        ({id, job_number}) => ({value: id, label: job_number})
                    );

                    setJobs(jobItems);
                });

            setSelectedJobId('');
            setSelectedJobNumber('')
            setValue(`general_info.job_id`, null);

            setSelectedClientName('');
            setValue(`general_info.client_id`, null);

            setSelectedLocationName('');
            setValue(`general_info.location_id`, null);
        }
    }

    const handleJobSelect = (jobId, jobName) => {
        if (jobId > 0) {
            setSelectedJobId(jobId);
            setSelectedJobNumber(jobName);
            
            setSelectedClientName('');
            setValue(`general_info.client_id`, null);

            setSelectedLocationName('');
            setValue(`general_info.location_id`, null);

            // Locations
            apiClient
                .post('getLocationsByJobId', {'job_id': jobId})
                .then(response => {
                    const locationItems = response.data.locations.map(
                        ({id, name}) => ({value: id, label: name})
                    );
                    setLocations(locationItems);
                });

            // Clients
            apiClient
                .post('getActiveClientByJobId', {'id': jobId})
                .then(response => {
                    if (isEmpty(response.data.active_client)) {
                        setClients([]);
                        return;
                    }
                    let active_client = response.data.active_client;
                    let client = [{value: active_client.id, label: active_client.client_name}];
                    setClients(client);
                });

            if (action === 'create') {
                // Activity Descriptions
                apiClient
                    .post('getActivityDescriptionsByJobId', {'job_id': jobId})
                    .then(response => {
                        setActivityDescriptions(
                            response.data.map(
                                ({code, description, revenue_group}) => (
                                    {value: code, label: description, revenue_group: revenue_group}
                                )
                            )
                        );
                    });

                // General Descriptions
                apiClient
                    .post('getGeneralDescriptionsByJobId', {'job_id': jobId})
                    .then(response => {
                        const generalItems = response.data.map(
                            ({code, description}) => ({value: code, label: description})
                        );
                        setGeneralDescriptions(generalItems);
                    });

                // Consumable Descriptions
                apiClient
                    .post('getConsumableDescriptionsByJobId', {'job_id': jobId})
                    .then(response => {
                        const consumableItems = response.data.map(
                            ({code, description}) => ({value: code, label: description})
                        );
                        setConsumableDescriptions(consumableItems);
                    });

                // Drilled Descriptions
                apiClient
                    .post('getDrilledDescriptionsByJobId', {'job_id': jobId})
                    .then(response => {
                        const drilledItems = response.data.map(
                            ({code, description}) => ({value: code, label: description})
                        );
                        setDrilledDescriptions(drilledItems);
                    });

                // Reamed Descriptions
                apiClient
                    .post('getReamedDescriptionsByJobId', {'job_id': jobId})
                    .then(response => {
                        const reamedItems = response.data.map(
                            ({code, description}) => ({value: code, label: description})
                        );
                        setReamedDescriptions(reamedItems);
                    });

                if (disciplineId) {
                    // get holes by job and discipline
                    apiClient
                        .post('getHolesByJobAndDisciplineId',
                            {
                                'job_id': jobId,
                                'discipline_id': disciplineId
                            })
                        .then(response => {
                            let holes = response.data.map(
                                ({name}) => ({value: name, label: name})
                            );
                            setHoles(holes);
                        });
                }

                setFieldEmployeesTemplate([]);
                setFieldHolesTemplate([]);
                setFieldCasingsTemplate([]);
                setFieldBitsTemplate([]);
            }
        }
    }

    const handleClientSelect = (clientId, ClientName) => {
        if (clientId > 0 && selectedJobId > 0 && selectedRig > 0) {
            setSelectedClientName(ClientName);
            // get Employees template
            apiClient
                .post('getFieldDailyEmployeesTemplate',
                    {
                        'job_id': selectedJobId,
                        'rig_id': selectedRig,
                        'client_id' : clientId
                    })
                .then(response => {
                    let employees = response.data.map(
                        ({user, user_id, is_driller}) => ({user_id: {value: user_id, label: user.first_name + ' ' + user.last_name}, is_driller: is_driller})
                    );

                    setFieldEmployeesTemplate(employees);
                });

            // get Holes template
            apiClient
                .post('getFieldDailyHolesTemplate',
                    {
                        'job_id': selectedJobId,
                        'rig_id': selectedRig,
                        'client_id' : clientId
                    })
                .then(response => {
                    let holes = response.data.map(
                        ({name, angle, runs, core_retrieved}) => (
                            {
                                name: name,
                                angle: angle,
                                runs: runs, core_retrieved
                            }
                        )
                    );

                    setFieldHolesTemplate(holes);
                });

            // get Casings template
            apiClient
                .post('getFieldDailyCasingsTemplate',
                    {
                        'job_id': selectedJobId,
                        'rig_id': selectedRig,
                        'client_id' : clientId
                    })
                .then(response => {
                    let casings = response.data.map(
                        ({type, depth, size, hole_name}) => (
                            {
                                type: type,
                                depth: depth,
                                size: size,
                                hole_name: hole_name
                            }
                        )
                    );

                    setFieldCasingsTemplate(casings);
                });
        }
    }

    const handleLocationSelect = (v) => {
        setValue(`general_info.location_id`, {value: v.value, label: v.label});
        setSelectedLocationName(v.label);
    }

    return (
            <AccordionItem className="card-header bg-primary" title="General Information">
                <div className="collapse show">
                    <div className="form-row margin">
                        <InputDate
                            label="Date"
                            className="col-md-2 mb-3"
                            id="general_info.date"
                            name="general_info.date"
                            value={action === 'edit' ? fieldDaily.date : ''}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        {action === 'create' &&
                            <Select
                                label="Shift"
                                id="general_info.daily_shift"
                                name="general_info.daily_shift"
                                className="col-md-1 mb-3"
                                options={[{value: "Day", label: "Day"}, {value: "Night", label: "Night"}]}
                                register={register({required: "required"})}
                                errors={errors}
                            />
                        }
                        {action === 'edit' &&
                            <FormSelect
                                id="general_info.daily_shift"
                                label="Shift"
                                className="col-md-1 mb-3"
                                value={fieldDaily.daily_shift}
                                options={[{value: "Day", label: "Day"}, {value: "Night", label: "Night"}]}
                                register={register({required: "required"})}
                                errors={errors}
                            />
                        }

                        { ((action ==='create' && !isEmpty(rigs)) || (action ==='edit' && !isEmpty(rigsAvailable))) &&
                            <FormSearchableSelect
                                id={`general_info.rig_id`}
                                label="Rig"
                                options={action === 'edit' ? rigsAvailable: rigs}
                                rules={{required: "required"}}
                                className="col-md-1 mb-3"
                                control={control}
                                value={fieldDaily.rig_id}
                                isDisabled={action==='edit' ? true : null}
                                errors={errors}
                                customStyles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        position: 'sticky',
                                    }),
                                }}
                                onChange={ (v,l) => handleRigSelect(v,l) }
                            />
                        }

                        {(action === 'create' || (action === 'edit' && !isEmpty(jobs))) &&
                            <FormSearchableSelect
                                id={`general_info.job_id`}
                                label="Job"
                                options={jobs}
                                rules={{required: "required"}}
                                className="col-md-2 mb-3"
                                control={control}
                                value={fieldDaily.job_id}
                                isDisabled={action === 'edit' ? true : null}
                                errors={errors}
                                customStyles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        position: 'sticky',
                                    }),
                                }}
                                onChange={(v, l) => handleJobSelect(v, l)}
                            />
                        }

                        {(action === 'create' || (action === 'edit' && !isEmpty(clients))) &&
                            <FormSearchableSelect
                                id={`general_info.client_id`}
                                label="Client"
                                options={clients}
                                rules={{required: "required"}}
                                className="col-md-3 mb-3"
                                control={control}
                                value={fieldDaily.client_id}
                                isDisabled={action === 'edit' ? true : null}
                                errors={errors}
                                customStyles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        position: 'sticky',
                                    }),
                                }}
                                onChange={(v, l) => handleClientSelect(v, l)}
                            />
                        }

                        <div className="col-md-3 mb-3">
                            <label htmlFor={`general_info.location_id`}>Location</label>
                            <Creatable
                                id={`general_info.location_id`}
                                name={`general_info.location_id`}
                                styles={Shared.customStylesSmall}
                                onChange={(v) => handleLocationSelect(v)}
                                defaultValue={
                                    (action === 'edit')
                                        ?
                                            {
                                                value: fieldDaily.location_id,
                                                label: (fieldDaily.location !== null) ? fieldDaily.location.name : 'not provided'
                                            }
                                        :
                                            ''
                                }
                                value={getValues('general_info.location_id')}
                                options={locations}
                                register={register(`general_info.location_id`, { required: "required" })}
                                errors={errors}
                            />
                            {errors && <span className="red">{ get(errors, `general_info.location_id`)?.message }</span>}
                        </div>
                    </div>
                </div>
            </AccordionItem>
    );
}

export default GeneralInformation;
