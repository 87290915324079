import React from 'react';
import TableReport from "../../../common/table/table";
import employeePayableHoursAllHoursColumns from "./employeePayableHoursAllHoursColumns.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import {TableStyle} from "./tableStyle";

/**
 * Body report component for Employee Payable Hours - All Hours Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const EmployeePayableHoursAllHoursBody = (prop) => {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableStyle>
                <TableReport
                    columns={employeePayableHoursAllHoursColumns}
                    data={tableData}
                    exportOptions={{pdf: false, xlsx: true, csv: true}}
                    getExportFileName={() => 'employee_payable_hours_all_hours'}
                    withGlobalSearch={true}
                    scrollable={true}
                />
            </TableStyle>
        </div>
    );
}

export default EmployeePayableHoursAllHoursBody;
