import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

/**
 * Modal for confirmation messages
 * @returns {JSX.Element}
 * @constructor
 */
const ModalConfirmation = (props) => {

    const {
        modal,
        setModal,
        title,
        onConfirm
    } = props;

    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} >
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>{modal.message}</ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onConfirm}>Confirm</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalConfirmation;
