import React, {useEffect, useState} from 'react';
import Table from "../../../common/table/table";
import ParentsTable from "./parentsTable.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import EditParentModal from "./editParentModal";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";
import {updateStateValue} from "../../../../utils/stateHelpers";

/**
 * ParentsBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ParentsBody = (prop) => {
    const {filters, setFilters, tableData, setTableData, selectedRow, setSelectedRow} = prop;
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});
    const [editParentModal, setEditParentModal] = useState({isOpen: false});
    const [editParentData, setEditParentData] = useState();
    const [parentId, setParentId] = useState('');

    useEffect(() => {
        // get Parents
        updateStateValue(setFilters, 'isLoading', true);
        updateStateValue(setFilters, 'isSubmitted', true);
        apiClient.get('getParents')
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
                updateStateValue(setFilters, 'isLoading', false);
        });

    }, [setFilters, setTableData]);

    const DeleteHandler = () => {
        apiClient
            .post('deleteParent', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    ParentsTable(
                        setFilters, setSelectedRow, setDeleteModal, setEditParentModal, setEditParentData, setParentId
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'parents'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Delete Parent"
                onConfirm={DeleteHandler}
            />
            <EditParentModal
                modal={editParentModal}
                setModal={setEditParentModal}
                selectedRow={selectedRow}
                editParentData={editParentData}
                parentId={parentId}
                tableData={tableData}
                setTableData={setTableData}
                setEditParentModal={setEditParentModal}
                editParentModal={editParentModal}
            />
        </div>
    );
}

export default ParentsBody;
