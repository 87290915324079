import {FaEye, FaEyeSlash, FaPencilAlt, FaTrash} from "react-icons/fa";
import React from "react";

const ClientsColumns = (
    setSelectedRow, setDeactivateModal, setReactivateModal, setDeleteModal
) => {
    return [
        {
            Header: 'Name',
            accessor: 'client_name',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <a
                        href={`/admin/users/create_client/edit/${row.id}`}
                        className={'font-weight-bold'}
                        rel="noopener noreferrer"
                        target="_blank">
                        {cell.value}
                    </a>
                );
            }
        },
        {
            Header: 'VP ID',
            accessor: 'erp_id',
            width: 60
        },
        {
            Header: 'Contact Name',
            accessor: 'billing_contact_name'
        },
        {
            Header: 'Billing Email',
            accessor: 'billing_email',
            width: 250
        },
        {
            Header: 'Payment Terms',
            accessor: 'billing_period',
            width: 80
        },
        {
            Header: 'Notes',
            accessor: 'notes'
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;

                let active = (
                    <>
                        <a
                            href={`/admin/users/create_client/edit/${row.id}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FaPencilAlt className="actions" title="Edit Client"/>
                        </a>
                        {' '}
                        <FaEyeSlash
                            className="actions"
                            title="Deactivate Client"
                            onClick={() => {
                                const msg = <p>Are you sure you want to deactivate <b>{row.client_name} ?</b></p>;
                                setSelectedRow(row);
                                setDeactivateModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                );

                let inactive = (
                    <>
                        <FaEye
                            className="actions"
                            title="Reactivate Client"
                            onClick={() => {
                                const msg = <p>Are you sure you want to reactivate <b>{row.client_name} ?</b></p>;
                                setSelectedRow(row);
                                setReactivateModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                        {' '}
                        <FaTrash
                            className="actions"
                            title="Delete Client"
                            onClick={() => {
                                const msg = <p>Are you sure you want to delete <b>{row.client_name} ?</b></p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                );

                return (row.status === 1) ? active : inactive;
            }
        }
    ];
}

export default ClientsColumns;



