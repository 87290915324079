import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import ReportFilters from "./reportFilters";
import ReportBody from "./reportBody";
import moment from 'moment';
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Employee Report Component
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeeReport = () => {

    const [tableData, setTableData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        date_from: moment().subtract(7, "day").format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        client_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Employee Report" parent="Reports"/>
            <ReportFilters {...{reportFilters, setTableData, setReportFilters}}/>
            <ReportBody {...{reportFilters, tableData}} />
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
};

export default EmployeeReport;
