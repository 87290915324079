import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {isCurrentYear} from "../../../../utils/dateHelpers";
import {useCallback} from 'react';

/**
 * DisciplineTargetsFilters component
 * @returns {JSX.Element}
 * @constructor
 */
const DisciplineTargetsFilters = (prop) => {
    const {register, handleSubmit, errors, setValue} = useForm();
    const {filters, setFilters, setTableData} = prop;
    const [years, setYears] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState({});

    useEffect(() => {
        // years
        apiClient
            .get('getFinancialPeriodsYears')
            .then(response => {
                setYears(Object.keys(response.data).map(
                    (id) => {
                        if (isCurrentYear(id)) {
                            setCurrentPeriod({value: id, label: id + ' (Current)'})
                        }
                        return {
                            value: id, label: id + (isCurrentYear(id) ? " (Current)" : "")
                        }
                    })
                );
            })
            .catch((error) => {
                console.error(error);
            });
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });
    }, []);

    const updateFilters = useCallback((id, value) => {
        setFilters(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, [setFilters])

    // filter selection handler
    const filterSelectionHandler = (e) => {
        const {id, value} = e.target;
        updateFilters(id, value);
    }

    const onSubmit = () => {
        updateFilters('isLoading', true);
        updateFilters('isSubmitted', true);

        apiClient
            .post('getDisciplineTargetsByYearAndDisciplineID', filters)
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
            updateFilters('isLoading', false);
        });
    }

    useEffect(() => {
        //check if financial period was selected
        const timer = setTimeout(() => {
            setValue('year', currentPeriod.value);
            updateFilters("year", currentPeriod.value);
        }, 200);

        return () => clearTimeout(timer);
    }, [currentPeriod, setValue, updateFilters]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="year"
                    label="Year"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.year}
                    onChange={filterSelectionHandler}
                    options={years}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSelect
                    label="Discipline"
                    id="discipline_id"
                    name="discipline_id"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={disciplines}
                    onChange={filterSelectionHandler}
                    value={filters.discipline_id}
                    register={register}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default DisciplineTargetsFilters;
