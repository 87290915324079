import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import AdpUploadPerDiemBody from "./adpUploadPerDiemBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import PayPeriodFormFilter from "../../../common/form-filters/PayPeriodFormFilter";

/**
 * Process Invoices Report Component
 * @returns {JSX.Element}
 * @constructor
 */
const ADPUploadPerDiem = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="ADP Upload Per Diem" parent="Reports"/>
            <PayPeriodFormFilter {...{setFilters, setTableData, OnSubmitUrl:"getADPUploadPerDiem"}}/>
            <AdpUploadPerDiemBody filters={filters} tableData={tableData} setTableData={setTableData}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default ADPUploadPerDiem;
