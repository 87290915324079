import React from 'react';
import {get} from "lodash";

/**
 * Input for text
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InputInTable = (props) => {
    const {
        id,
        label,
        value,
        type = "text",
        onKeyUp,
        onInput,
        onBlur,
        onChange,
        className,
        disabled = 'false',
        errors,
        register,
        cellClass,
    } = props;

    return (
        <td className={cellClass}>
            {label && <label htmlFor={id}>{label}</label>}
            <input
                id={id}
                name={id}
                className={`form-control ${className}`}
                type={type}
                step="any"
                readOnly={disabled === 'true' ? true : ''}
                defaultValue={value || (type === 'number' ? 0 : '')}
                onKeyUp={onKeyUp}
                onBlur={onBlur}
                onChange={onChange}
                onInput={onInput}
                ref={register}
            />
            {errors && <span>{get(errors, id)?.message}</span>}
        </td>
    );
}

export default React.memo(InputInTable);
