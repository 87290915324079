const ShopTimeEntrySummaryTable = () => {
    return [
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Date',
            accessor: 'date',
        },
        {
            Header: 'Total Hours',
            accessor: 'total_hours',
        },
        {
            Header: 'Total Per Diem',
            accessor: 'total_per_diem',
        },
    ];
}

export default ShopTimeEntrySummaryTable;
