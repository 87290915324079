import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../utils/stateHelpers";
import {getCurrentPeriodFromArray} from "../../../utils/arrayHelpers"
import {isEmpty, isUndefined} from "lodash";
import moment from "moment";
import FormInputDate from "../../forms/form-control/FormInputDate";

/**
 * PayPeriodFormFilter component
 * @returns {JSX.Element}
 * @constructor
 */
const PayPeriodDateRangeFormFilter = (prop) => {
    const {setFilters, setTableData, pathFilter, OnSubmitUrl} = prop;
    const {register, handleSubmit, errors, setValue} = useForm();
    const [payPeriods, setPayPeriods] = useState([]);
    const [currentPayPeriod, setCurrentPayPeriod] = useState({});
    const [endDate, setEndDate] = useState(0);

    // get list of pay periods
    useEffect(() => {
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                let year = Number(moment(new Date(response.data.end_date)).format('YYYY'));
                setEndDate(year);
            });

        apiClient
            .post('getPayPeriodsByYear', {'year': endDate})
            .then(response => {
                response.data.map(
                    ({id, start_date, end_date, is_current}) => {
                        if (is_current) {
                            let startDateRaw = new Date(start_date);
                            let endDateRaw = new Date(end_date);
                            let startDate = moment(startDateRaw).format('yyyy-MM-DD');
                            let endDate = moment(endDateRaw).format('yyyy-MM-DD');

                            return setCurrentPayPeriod ({
                                id: id, start_date: startDate, end_date:endDate
                            });
                        }
                    }
                );

                setPayPeriods(
                    response.data.map(
                        ({id, start_date, end_date, is_current}) => ({
                            id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')
                        })

                    )
                );
            });
    }, [endDate]);

    const setDate = (value) => {
        if (value) {
            apiClient
                .post('getPayPeriodsById', {'id': value})
                .then(response => {
                    let start = response.data.start_date;
                    let end = response.data.end_date;
                    let starRaw = new Date(start);
                    let endRaw = new Date(end);
                    let startDate = moment(starRaw).format('yyyy-MM-DD');
                    let endDate = moment(endRaw).format('yyyy-MM-DD');

                    document.getElementById('date_from').value = startDate;
                    document.getElementById('date_to').value = endDate;
                });
        }
    }

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        data['approve_filter'] = pathFilter;

        apiClient
            .post(OnSubmitUrl, data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
            updateStateValue(setFilters,'isLoading', false);
        });
    }

    const defaultPayPeriod = getCurrentPeriodFromArray(payPeriods);

    const onDateFieldChange = () => {
        setValue('pay_period_id', undefined);
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="row">
                {!isUndefined(defaultPayPeriod) && <>
                    <FormSelect
                        id="pay_period_id"
                        label="Pay Period"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                        value={defaultPayPeriod.id}
                        options={payPeriods}
                        onChange={(e) => setDate(e.target.value)}
                        register={register({required: false})}
                        errors={errors}
                    />
                    <FormInputDate
                        id="date_from"
                        label="From"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        value={currentPayPeriod.start_date}
                        register={register({required: "required"})}
                        onChange={onDateFieldChange}
                        errors={errors}
                    />
                    <FormInputDate
                        id="date_to"
                        label="To"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        value={currentPayPeriod.end_date}
                        register={register({required: "required"})}
                        onChange={onDateFieldChange}
                        errors={errors}
                    />
                    <FormSubmitButton
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        label="Show"
                    />
                </>}
            </div>
        </form>
    );
}

export default PayPeriodDateRangeFormFilter;
