import React, {useEffect, useState} from 'react';
import FormInputDate from "../../../forms/form-control/FormInputDate";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import {useForm} from "react-hook-form";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";

/**
 * Filter component for Employee report
 * @returns {JSX.Element}
 * @constructor
 */
const ReportFilters = (prop) => {
    const {reportFilters, setReportFilters, setTableData} = prop;
    const {register, handleSubmit} = useForm();
    const [clients, setClients] = useState([]);

    // filter selection handler
    const filterSelectionHandler = e => {
        const {id, value} = e.target;
        updateStateValue(setReportFilters, id, value);
    }

    // get list of active clients
    useEffect(() => {
        apiClient.get('getActiveClientsList')
            .then(response => {
                setClients(
                    response.data.activeClientsList.map(
                        ({id, client_name}) => ({id: id, name: client_name})
                    )
                );
            });
    }, []);

    // submit handler for report filter
    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);
        apiClient.post('getEmployeeReportData', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .then(function () {
                updateStateValue(setReportFilters, 'isLoading', false);
            });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="client_id"
                    label="Client"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                    value={reportFilters.client_id}
                    onChange={filterSelectionHandler}
                    options={clients}
                    register={register}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={reportFilters.date_from}
                    onChange={filterSelectionHandler}
                    register={register}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={reportFilters.date_to}
                    onChange={filterSelectionHandler}
                    register={register}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default ReportFilters;
