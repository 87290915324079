import React from "react";
import * as Shared from "../../../common/shared";
import {isEmpty, isUndefined} from "lodash";
import moment from "moment";

const DailyTotalsTable = (props) => {
    const {
        watch, daily, action, totalActivities, totalConsumables, totalGeneralCharges, totalDrilled, totalDrilledVariance,
        totalReamedVariance, totalReamedPrice, totalDrilledPrice, selectedJobNumber, selectedRigName,
        selectedLocationName, user // totalDrillingHours
    } = props;
    const TOOL_PUSHER = 11;
    const DRILLER_ASSISTANT = 9;
    const DRILLER = 7;
    let columnTitle = <></>;
    let columnValue = <></>;

    // if we create FD and user is in groups down below
    if (action === 'create') {
        if (user?.group_id === TOOL_PUSHER || user?.group_id === DRILLER || user?.group_id === DRILLER_ASSISTANT) {
            columnTitle = <th>Discipline</th>;
            columnValue = <td className="bold-red">{user?.discipline_name}</td>;
        }
    }

    if (action === 'edit') {
        columnTitle = <th>Discipline</th>;
        columnValue = <td className="bold-red">{(typeof daily.discipline !== 'undefined') ? daily.discipline.name : ''}</td>;
    }

    let generalInfo = {
        rig_name: '',
        job_number: '',
        location_name: ''
    };

    const watchForm = watch([
        "general_info.date",
        "general_info.daily_shift",
    ]);

    if (action === 'create') {
        generalInfo.rig_name = selectedRigName
        generalInfo.job_number = selectedJobNumber;
        generalInfo.location_name = selectedLocationName;
    }

    if (action === 'edit' && !isEmpty(daily)) {
        generalInfo.rig_name = daily.rig.name
        generalInfo.job_number = daily.job.job_number
        generalInfo.location_name = daily.location.name;
    }

    return (
        <div className="sticky-top card">
            <div className="card-body">
        {/*<div>*/}
        {/*    <pre>*/}
        {/*      <code>{JSON.stringify(totalDrillingHours, null, 2)}</code>*/}
        {/*    </pre>*/}
        {/*</div>*/}
                    <table className="table">
                        <thead>
                            <tr>
                                {columnTitle}
                                <th>Total Revenue</th>
                                <th>Drill Revenue</th>
                                <th>Total Footage</th>
                                <th>Drilled Variance</th>
                                <th>Reamed Variance</th>
                                {/*<th>Total Drill Hours</th>*/}
                                <th>Date</th>
                                <th>Shift</th>
                                <th>Rig</th>
                                <th>Job</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {columnValue}
                                <td className="bold-blue">
                                    {Shared.calculateTotalRevenue(
                                        totalActivities, totalConsumables, totalGeneralCharges,
                                        totalReamedPrice, totalDrilledPrice
                                    )}
                                </td>
                                <td className="bold-blue">
                                    {Shared.calculateDrilledRevenue(totalDrilledPrice, totalReamedPrice)}
                                </td>
                                <td className="bold-blue">
                                    {Number(totalDrilled).toFixed(2)}
                                </td>
                                <td className="bold-blue">{totalDrilledVariance}</td>
                                <td className="bold-blue">{totalReamedVariance}</td>
                                {/*<td className="bold-blue col-5">*/}
                                {/*    <ul class="text-nowrap">*/}
                                {/*        {totalDrillingHours.map((totalDrilling) => (*/}
                                {/*            <li>{totalDrilling.hole_name} : {totalDrilling.units}</li>*/}
                                {/*        ))}*/}
                                {/*    </ul>*/}
                                {/*</td>*/}
                                <td>{!isUndefined(watchForm['general_info.date']) && moment(watchForm['general_info.date']).format("MM/DD/YYYY")}</td>
                                <td>{!isUndefined(watchForm['general_info.daily_shift']) && watchForm['general_info.daily_shift']}</td>
                                <td>{generalInfo.rig_name}</td>
                                <td>{generalInfo.job_number}</td>
                                <td>{generalInfo.location_name}</td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    );
}
export default DailyTotalsTable;
