import React from 'react';
import { get } from 'lodash';

/**
 * InputDate
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InputDate = (props) => {

    const {id, name, label, value, className, onChange, errors, register} = props;

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <input
                className="form-control"
                id={id}
                name={name}
                type="date"
                defaultValue={value || ''}
                onChange={onChange}
                ref={register}
            />
            {errors && <span>{ get(errors, id)?.message }</span>}
        </div>
    );
}

export default React.memo(InputDate);
