import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import ReportFilters from "./reportFilters";
import ReportBody from "./reportBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Daily Discipline Revenue Detail Report
 * @returns {JSX.Element}
 * @constructor
 */
const DailyDisciplineRevenueDetail = () => {
    const [tableDataDiscipline1, setTableDataDiscipline1] = useState([]);
    const [tableDataDiscipline2, setTableDataDiscipline2] = useState([]);
    const [tableDataDiscipline3, setTableDataDiscipline3] = useState([]);
    const [tableDataDiscipline4, setTableDataDiscipline4] = useState([]);
    const [tableTotalData, setTableTotalData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    const reportFilterProps = {
        setReportFilters,
        setTableDataDiscipline1,
        setTableDataDiscipline2,
        setTableDataDiscipline3,
        setTableDataDiscipline4,
        setTableTotalData
    };

    const reportBodyProps = {
        reportFilters,
        tableDataDiscipline1,
        tableDataDiscipline2,
        tableDataDiscipline3,
        tableDataDiscipline4,
        tableTotalData
    };

    return (
        <>
            <Breadcrumb title="Daily Discipline Revenue Detail" parent="Reports"/>
            <ReportFilters {...reportFilterProps} />
            <ReportBody  {...reportBodyProps}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
};

export default DailyDisciplineRevenueDetail;
