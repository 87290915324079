import React from "react";
import moment from "moment/moment";

const TableToRender = (props) => {
    const {tableData, tableTotal} = props;

    return (tableData.length > 0)
        ?
             (
                <>
                    <div className="table-responsive">
                        <div className="text-center bold">
                            <h5>{moment(tableData[0]?.date).format('dddd, MMMM Do')}</h5>
                        </div>
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr className="table_header_background_color">
                                    <th>Equip / Job #</th>
                                    <th>Cost Code</th>
                                    <th>Work Description</th>
                                    <th>Hours</th>
                                    <th>PTO</th>
                                    <th>Per Diem</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{(item.job_number) ? item?.job_number : item?.equipment_number}</td>
                                        <td>{item?.cost_code_description}</td>
                                        <td>{item?.notes}</td>
                                        <td>{Number(item.hours).toFixed(2)}</td>
                                        <td>{(item?.ec_id === 6) ? Number(item.hours).toFixed(2) : ''}</td>
                                        <td>{item?.per_diem}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right">Total:</td>
                                    <td>{Number(tableTotal.total_hours).toFixed(2)}</td>
                                    <td>{Number(tableTotal.total_pto_hours).toFixed(2)}</td>
                                    <td>{tableTotal.total_per_diem}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )
        :
            <></>
}

export default TableToRender;