export default function ShopTimeEntryWeeklySummaryCodeTable() {
    return [
        {
            Header: 'Cost Code',
            accessor: 'code',
        },
        {
            Header: 'Hours',
            accessor: 'hours',
        },
        {
            Header: '% Time',
            accessor: 'percentage',
        }
    ];
}