import React from 'react';

/**
 * Display spinner to indicate loading
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const LoadingIndicator = (props) => {
    const {isLoading} = props;

    if (!isLoading) {
        return null;
    }

    return (
        <div className="app-loading-overlay">
            <div className="app-loading-spinner whirly-loader"></div>
        </div>
    );
};

export default React.memo(LoadingIndicator);
