import React from 'react';
import TableReport from "../../../common/table/table";
import {TableStyle} from "./tableStyle";
import {tableColumns} from "./tableColumns.js";
import PercentageChart from "../../../charts/PercentageChart";
import BarChart from "../../../charts/BarChart";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from "lodash";

/**
 * Body report component for Daily Discipline Revenue
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ReportBody = (prop) => {
    const percentageChartLabel = ['% To Target', ''];
    const {
        reportFilters,
        tableData,
        barChartLabels,
        barChartDataDiscipline1,
        barChartDataDiscipline2,
        barChartDataDiscipline3,
        barChartDataDiscipline4,
        PercentageChartDataDiscipline1,
        PercentageChartDataDiscipline2,
        PercentageChartDataDiscipline3,
        PercentageChartDataDiscipline4,
        PercentageChartDataTotal,
    } = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableData)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6">
                    <div className="card">
                        <div className="table-responsive">
                            <TableStyle>
                                <TableReport
                                    columns={tableColumns}
                                    data={tableData}
                                    exportOptions={{pdf: true, xlsx: true, csv: false}}
                                    getExportFileName={() => 'daily_discipline_revenue'}
                                />
                            </TableStyle>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="row">
                        <div className="col-sm-4">
                            <PercentageChart
                                title='Surface Core'
                                labels={percentageChartLabel}
                                value={PercentageChartDataDiscipline1}
                            />
                        </div>
                        <div className="col-sm-4">
                            <PercentageChart
                                title='Underground Core'
                                labels={percentageChartLabel}
                                value={PercentageChartDataDiscipline2}
                            />
                        </div>
                        <div className="col-sm-4">
                            <PercentageChart
                                title='RCX'
                                labels={percentageChartLabel}
                                value={PercentageChartDataDiscipline3}
                            />
                        </div>
                        <div className="col-sm-4">
                            <PercentageChart
                                title='Rotary'
                                labels={percentageChartLabel}
                                value={PercentageChartDataDiscipline4}
                            />
                        </div>
                        <div className="col-sm-4">
                            <PercentageChart
                                title='Total'
                                labels={percentageChartLabel}
                                value={PercentageChartDataTotal}
                            />
                        </div>
                    </div>
                    <div className="card">
                        <BarChart title='Surface Core Revenue' labels={barChartLabels} dataset={barChartDataDiscipline1}/>
                        <BarChart title='Underground Core Revenue' labels={barChartLabels} dataset={barChartDataDiscipline2}/>
                        <BarChart title='RCX Revenue' labels={barChartLabels} dataset={barChartDataDiscipline3}/>
                        <BarChart title='Rotary Revenue' labels={barChartLabels} dataset={barChartDataDiscipline4}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportBody;
