import React, {useState} from 'react';
import LocationsBody from "./LocationsBody";
import Breadcrumb from "../../common/breadcrumb";
import LoadingIndicator from "../../common/loadingIndicator";
import StatusFormFilter from "../../common/form-filters/StatusFormFilter";

const Locations = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        status: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Locations" parent="Jobs"/>
            <StatusFormFilter {...{filters, setFilters, setTableData, url: "getLocationsByStatus"}} />
            <LocationsBody {...{filters, tableData, setTableData}} />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default Locations;
