/**
 * update one parameter on state object
 * @param setFunction
 * @param id
 * @param value
 */
export const updateStateValue = (setFunction, id, value) => {
    setFunction(prevState => ({
        ...prevState,
        [id]: value
    }));
}
