import { ShiftReportTooltip } from "./shiftReportTooltip";

const shiftReportTableColumns = (tableData) => {
    let tableColumns = [];

    if (tableData.rig === "Summary") {
        return tableData.table_header;
    } else {
        tableData.table_header.forEach((item) => {
            if (item.Header !== "Total" && item.Header !== "User Group") {
                tableColumns.push({
                    Header: item.Header,
                    accessor: item.accessor,
                    Cell: (cell) => {
                        const row = cell.row.original;

                        if (row.group_name === "Total") {
                            return (
                                <ShiftReportTooltip
                                    date={cell.column.id}
                                    rig={tableData.rig_id}
                                    shift={tableData.shift}
                                    value={cell.value}
                                />
                            );
                        } else {
                            return <div>{cell.value}</div>;
                        }
                    },
                });
            } else {
                tableColumns.push({
                    Header: item.Header,
                    accessor: item.accessor,
                });
            }
        });
    }

    return tableColumns;
}

export default shiftReportTableColumns;
