import * as Shared from "../../../common/shared";

export const financialDashboardTableColumns = [
    {
        Header: '',
        accessor: 'label',
    },
    {
        Header: 'Surface Core',
        accessor: 'discipline_1',
        Cell: (cell) => {
            let discipline_1 = cell.row.original.discipline_1;
            return Shared.formatNumberToDisplayWithCommas(discipline_1);
        }
    },
    {
        Header: 'Underground Core',
        accessor: 'discipline_2',
        Cell: (cell) => {
            let discipline_2 = cell.row.original.discipline_2;
            return Shared.formatNumberToDisplayWithCommas(discipline_2);
        }
    },
    {
        Header: 'RCX',
        accessor: 'discipline_3',
        Cell: (cell) => {
            let discipline_3 = cell.row.original.discipline_3;
            return Shared.formatNumberToDisplayWithCommas(discipline_3);
        }
    },
    {
        Header: 'Rotary',
        accessor: 'discipline_4',
        Cell: (cell) => {
            let discipline_4 = cell.row.original.discipline_4;
            return Shared.formatNumberToDisplayWithCommas(discipline_4);
        }
    },
    {
        Header: 'Total',
        accessor: 'total',
        Cell: (cell) => {
            let total = cell.row.original.total;
            return Shared.formatNumberToDisplayWithCommas(total);
        }
    },
];
