import React from 'react';
import Table from "../../../common/table/table";
import ShopTimeEntryDetailTable from "./shopTimeEntryDetailTable.js";
import MessageEmptyData from "../../../common/messageEmptyData";

/**
 * ShopTimeEntryReviewBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ShopTimeEntryDetailBody = (prop) => {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={ShopTimeEntryDetailTable()}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'shop_time_entry_detail'}
                withGlobalSearch={true}
                scrollable={true}
            />
        </div>
    );
}

export default ShopTimeEntryDetailBody;
