import React from 'react';
import { Link } from 'react-router-dom';
import { isUndefined } from 'lodash';

function sortNumericStringColumn(row1, row2, columnId) {
    const value1 = row1.values[columnId]?.replace(/\D/g,'');
    const value2 = row2.values[columnId]?.replace(/\D/g,'');

    if (value1 == null) {
        return -1;
    } else if (value2 == null) {
        return 1;
    }

    if (!isNaN(Number(value1)) && !isNaN(Number(value2))) {
        return Number(value1) - Number(value2);
    }

    return value1.localeCompare(value2);
}

/**
 * return table columns definition
 * @param title
 * @param reportStartDate
 * @param reportEndDate
 * @param periodId
 * @returns json
 */
export const getTableColumns = ( title, reportStartDate, reportEndDate, periodId) => {
    return [
        {
            Header: title,
            columns: [
                {
                    Header: 'Rig',
                    accessor: 'rig_name',
                },
                {
                   Header: 'Description',
                   accessor: 'rig_description',
                },
                {
                    Header: 'Job #',
                    accessor: 'job',
                },
                {
                    Header: 'Client',
                    accessor: 'client',
                    Cell: (cell) => {
                        const jobId = cell.row.original.job_id;
                        if (isUndefined(jobId)) {
                            return null;
                        }
                        return (
                            <Link

                                to={`/commercial/job/edit_job?id=${jobId}&mode=view`}
                                target="_blank"
                            >
                                {cell.value}
                            </Link>
                        );
                   },
                },
            ],
        },
        {
            Header: 'Shift Stats',
            columns: [
                {
                    Header: 'Shifts',
                    accessor: 'shifts',
                    width: 50
                },
                {
                    Header: 'Shift Hrs',
                    accessor: 'shift_hrs',
                    width: 50,
                    Cell: (cell) => {
                        const rigId = cell.row.original.rig_id;
                        const jobId = cell.row.original.job_id;
                        const {id, type} = periodId;

                        if (isUndefined(rigId) && isUndefined(jobId)) {
                            return cell.value;
                        }

                        return (
                            <Link
                                to={`/reports/employee/shift_report/${reportStartDate}/${reportEndDate}/${id}/${type}`}
                                target="_blank"
                            >
                                {cell.value}
                            </Link>
                        )
                    },
                    sortType: sortNumericStringColumn,
                },
                // temporary removed per manager's request
                // {
                //     Header: 'Billable Hrs',
                //     accessor: 'billable_hrs',
                //     width: 50,
                // },
                {
                    Header: 'Billed / Unbilled',
                    accessor: 'billed_vs_unbilled',
                    width: 50,
                    Cell: (cell) => {
                        const isMarked = cell.row.original.billed_vs_unbilled_is_marked;
                        const rigId = cell.row.original.rig_id;
                        const jobId = cell.row.original.job_id;
                        const shiftHrs = cell.row.original.shift_hrs;

                        if (isUndefined(rigId) && isUndefined(jobId) ) {
                            return cell.value;
                        }

                        return (
                            isMarked && cell.value ? (
                                <>
                                    <Link
                                        to={`/reports/revenue_forecast/rig_activities/${reportStartDate}/${reportEndDate}/${rigId}/${jobId}/${shiftHrs}`}
                                        target="_blank"
                                        className="toolbox-red__link position-relative text-white"
                                    >
                                        {cell.value}
                                    </Link>
                                    <div className="toolbox-red__layer"/>
                                </>
                            ) : (
                                <Link
                                    to={`/reports/revenue_forecast/rig_activities/${reportStartDate}/${reportEndDate}/${rigId}/${jobId}/${shiftHrs}`}
                                    target="_blank"
                                >
                                    {cell.value}
                                </Link>
                            )
                        )
                    }
                },
                {
                    Header: 'Drill Hrs',
                    accessor: 'drill_hrs',
                    width: 50,
                },
            ],
        },
    {
        Header: 'Revenue Profile',
        columns: [
            {
                Header: 'Revenue',
                accessor: 'revenue_total',
                Cell: (cell) => {
                const jobId = cell.row.original.job_id;

                if (isUndefined(jobId)) {
                    return cell.value;
                }

                return (
                  <Link
                        to={`/reports/revenue_forecast/revenue_job_cost_tracking/${reportStartDate}/${reportEndDate}/${jobId}`}
                        target='_blank'
                  >
                  {cell.value}
                  </Link>
                );
                },
                sortType: sortNumericStringColumn,
            },
            {
                Header: 'Shift Avg',
                accessor: 'shift_avg',
                Cell: (cell) => {
                    const isMarked = cell.row.original.shift_avg_is_marked;
                    const jobId = cell.row.original.job_id;
                    const {id, type} = periodId;

                    if (isUndefined(jobId)) {
                        return cell.value;
                    }

                    return isMarked && cell.value ? (
                        <>
                            <Link
                                to={`/reports/revenue_forecast/revenue_weekly_tracking/${jobId}/${id}/${type}`}
                                target="_blank"
                                className="toolbox-red__link position-relative text-white"
                            >
                                {cell.value}
                            </Link>
                            <div className="toolbox-red__layer"/>
                        </>
                    ) : (
                        <Link
                            to={`/reports/revenue_forecast/revenue_weekly_tracking/${jobId}/${id}/${type}`}
                            target="_blank"
                        >
                            {cell.value}
                        </Link>
                    )
                },
                sortType: sortNumericStringColumn,
            },
            {
                Header: 'Rev/FT',
                accessor: 'rev_ft',
                sortType: sortNumericStringColumn,
            },
            {
                Header: 'Rev/Shift Hr',
                accessor: 'rev_shift_hrs',
                sortType: sortNumericStringColumn,
            },
            {
              Header: 'Rev/Drill Hr',
              accessor: 'rev_drill_hrs',
            },
        ],
    },
    {
        Header: 'Production',
        columns: [
            {
                Header: 'FT Depth',
                accessor: 'depth_total',
                width: 50,
                sortType: sortNumericStringColumn,
            },
            {
                Header: '% Drilling',
                accessor: 'drill_percentage',
            },
            {
                Header: 'FT/drill Hr',
                accessor: 'ft_drill_hrs',
                sortType: sortNumericStringColumn,
            },
            {
                Header: 'FT/shift Hr',
                accessor: 'ft_shift_hrs',
            },
            {
                Header: 'FT/shift',
                accessor: 'ft_shift',
                sortType: sortNumericStringColumn,
            },
        ],
    },
    {
        Header: 'R&M',
        columns: [
            {
              Header: 'R&M',
              accessor: 'r_m',
            },
            {
                Header: 'Up',
                accessor: 'uptime',
                Cell: (cell) => {
                    const jobId = cell.row.original.job_id;

                    if (isUndefined(jobId)) {
                        return cell.value;
                    }

                    return (
                        <Link
                        to={`/reports/revenue_forecast/downtime/${reportStartDate}/${reportEndDate}/${jobId}`}
                        target='_blank'
                        >
                        {cell.value}
                        </Link>
                    );
                },
            },
        ],
    },
  ];
}
