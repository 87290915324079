import React from "react";
import apiClient from "../../../../utils/apiClient";
import {Link} from "react-router-dom";
import {FaPencilAlt, FaTrash} from "react-icons/fa";

const ParentsTable = (
    setFilters, setSelectedRow, setDeleteModal, setEditParentModal, setEditParentData, setParentId
) => {
    const getParentData = (id) => {
        apiClient
            .post('getParentById', {'id': id})
            .then(response => {
                setEditParentData(response.data);
                setParentId(response.data.id);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const onClickParentName = (e, row) => {
        e.preventDefault();
        setEditParentData([]);
        getParentData(row.id);
        setEditParentModal({
            isOpen: true
        });
    }

    return [
        {
            Header: 'Parent Name',
            accessor: 'parent_name',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <Link
                        className={'font-weight-bold'}
                        onClick={(e) => onClickParentName(e, row)}
                    >
                        {cell.value}
                    </Link>
                );
            }
        },
        {
            Header: 'VP ID',
            accessor: 'erp_id',
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaPencilAlt
                            className="actions"
                            title="Edit Parent"
                            onClick={() => {
                                setEditParentData([]);
                                getParentData(row.id);
                                setEditParentModal({
                                    isOpen: true
                                });
                            }}
                        />
                        {' '}
                        <FaTrash
                            className="actions"
                            title="Delete Parent"
                            onClick={() => {
                                const msg = 'Are you sure that you want to delete parent: ' + row.parent_name;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                    </>
                );
            }
        }
    ];
}

export default ParentsTable;
