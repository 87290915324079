import React from 'react';
import {Controller} from 'react-hook-form';
import {get, isFunction, isNil} from 'lodash';
import Creatable from 'react-select/creatable';

/**
 * Creatable Searchable Dropdown
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CreatableSelectInTable = (props) => {
    const {id, value, options, rules, errors, control, onChange, className, customStyles} = props;
    let defaultValue = null;

    // set default value
    if (value.value) {
        defaultValue = (options.length > 0) ? options.find(item => item.value === value.value) : '';
    }

    return (
        <td>
            <Controller
                className={className}
                name={id}
                id={id}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={ props =>
                    <Creatable
                        name={props.id}
                        options={options}
                        value={props.value}
                        styles={customStyles}
                        onChange={(e) => {
                            props.onChange(e);
                            if (isFunction(props.onChange)) {
                                onChange(
                                    isNil(e) ? e : e.value
                                );
                            }
                        }}
                    />
                }
            />
            {errors && <span className="font-danger">{get(errors, id)?.message}</span>}
        </td>
    );
}

export default React.memo(CreatableSelectInTable);
