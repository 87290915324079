import React from 'react';
import {get} from "lodash";

/**
 * Input for text
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TextAreaInForm = (props) => {
    const {
        id,
        name,
        label,
        className,
        errors,
        value,
        rows,
        cols,
        register
    } = props;

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <textarea
                className="form-control"
                id={id}
                name={name}
                rows={rows}
                defaultValue={value || ''}
                cols={cols}
                ref={register}
            />
            {errors && <span>{ get(errors, id)?.message }</span>}
        </div>
    );
};

export default React.memo(TextAreaInForm);
