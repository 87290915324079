import React, {useEffect, useState} from 'react';
import apiClient from "../../../../utils/apiClient";
import AssetForm from "./assetForm";

const Asset = (props) => {
    const assetId = (props.match.params.id > 0) ? props.match.params.id : 0;
    const action = (props.match.params.action) ? props.match.params.action : '';
    const title = (action === 'create') ? "Create" : "Edit";
    const [assetEditData, setAssetEditData] = useState([]);

    useEffect(() => {
        if (action === 'edit' && assetId > 0) {
            apiClient
                .post('getAssetById', {"id": assetId})
                .then(response => {
                    setAssetEditData(response.data);
                });
        }
    }, [action, assetId]);

    return (
        <>
            {action === 'create' &&
                <AssetForm
                    action={action}
                    title={title}
                />
            }
            {action === 'edit' && assetId > 0 &&
                <AssetForm
                    action={action}
                    assetEditData={assetEditData}
                    title={title}
                    assetId={assetId}
                />
            }
        </>
    );
}

export default Asset;
