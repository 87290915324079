import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import { useForm } from 'react-hook-form'
import {toast} from "react-toastify";
import apiClient from "../../../../utils/apiClient";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import FormInputText from "../../../forms/form-control/FormInputText";

const ApiRateLimit = () => {
    const {register, handleSubmit, errors} = useForm();
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        apiClient
            .get('ApiRateLimit')
            .then(response => {
                setSettings(response.data);
            });
    }, [setSettings]);

    const onSubmit = (data) => {
        apiClient
            .put('ApiRateLimit/1', data)
            .then(response => {
                if (response instanceof Error) {
                    toast.error(response.response.data.message);
                }
                if (response.status === 200) {
                    toast.success(response.data.message);
                }
        });
    }

    return (
        <>
            <Breadcrumb title="API Rate Limit" parent="Client Portal Settings"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    className="needs-validation"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div className="row">
                                        <FormInputText
                                            id="number_of_requests"
                                            label="Number of API requests client can make per minute"
                                            value={settings.number_of_requests}
                                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                            type="number"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                    </div>
                                    <FormSubmitButton
                                        label="Save"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ApiRateLimit;
