import {AccordionItem} from "react-light-accordion";
import React, {useEffect} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import apiClient from "../../../../../utils/apiClient";
import {useFieldArray} from "react-hook-form";
import CreatableSelectInTable from '../../../../forms/form-control/daily/CreatableSelectInTable';

const Hole = (props) => {
    const {
        errors, register, control, disciplineId, setCollectedHoles, setValue, holes, setFieldBottomHoleTemplate,
        collectedHoles, getValues, fieldDaily, action, fieldHolesTemplate, setFieldBHATemplate,
        setFieldDrillBitsTemplate, setFieldBitsTemplate
    } = props;
    const kindOfHole = [1, 2].includes(disciplineId) ? "hole_core" : "hole_rotary";
    const {fields, append, remove} = useFieldArray({control, name: kindOfHole});

    // get BHA template
    const getFieldBHATemplate = (value, action) => {
        if (value && action === 'create') {
            apiClient
                .post('getFieldDailyBHATemplate',
                    {
                        'hole_name': value,
                    })
                .then(response => {
                    setFieldBHATemplate(response.data);
                });
        }
    }

    // get bottom hole template
    const getFieldBottomHoleTemplate = (value, action) => {
        if (value && action === 'create') {
            apiClient
                .post('getFieldDailyBottomHoleTemplate',
                    {
                        'hole_name': value,
                    })
                .then(response => {
                    setFieldBottomHoleTemplate(response.data);
                });
        }
    }

    // get drill bits template
    const getFieldDrillBitsTemplate = (value, action) => {
        if (value && action === 'create') {
            apiClient
                .post('getFieldDailyDrillBitsTemplate',
                    {
                        'hole_name': value,
                    })
                .then(response => {
                    setFieldDrillBitsTemplate(response.data);
                });
        }
    }

    // get bits template
    const getFieldBitsTemplate = (value, action) => {
        if (value && action === 'create') {
            apiClient
                .post('getFieldDailyBitsTemplate',
                    {
                        'hole_name': value,
                    })
                .then(response => {
                    setFieldBitsTemplate(response.data);
                });
        }
    }

    const removeHoleRow = (index) => {
        remove(index);
        collectedHoles.splice(index, 1);
        setCollectedHoles(collectedHoles);
    }

    useEffect(() => {
        const fieldArr = fields;
        if (action === 'edit') {
            // add fields
            append(fieldDaily.field_holes);

            // assign values to the fields
            // timeout is needed due to async runtime
            setTimeout(() => {
                fieldDaily.field_holes.forEach((item, index) => {
                    setValue(`${kindOfHole}[${index}].name`, {value: item.name, label: item.name});
                });
            }, 200);

            // build options for holes dropdown in cards
            let options = fieldDaily.field_holes.map((value) => ({value: value.name, label: value.name}));
            setCollectedHoles(options);
        }

        // when using template
        if (action === 'create' && fieldHolesTemplate.length > 0) {
            // delete all field values and populate field array.
            remove();
            if (fieldArr.length > 0) {
                append(fieldArr);
            } else {
                append(fieldHolesTemplate);
            }
            fieldHolesTemplate.forEach((item, index) => {
                // run BHA template of hole name
                if (index === 0) {
                    getFieldBHATemplate(item.name, action);
                    getFieldBottomHoleTemplate(item.name, action);

                    if ([1, 2].includes(disciplineId)) {
                        getFieldBitsTemplate(item.name, action);
                    } else {
                        getFieldDrillBitsTemplate(item.name, action);
                    }
                }
                setValue(
                    `${kindOfHole}[${index}].name`,
                    {value: item.name, label: item.name}
                );
            });

            let options = fieldHolesTemplate.map((value) => ({value: value.name, label: value.name}));
            setCollectedHoles(options);
        }

        if (action === 'create' && fieldHolesTemplate.length === 0) {
            remove();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        fieldHolesTemplate, append, fieldDaily.field_holes, disciplineId,
        setCollectedHoles, action, kindOfHole, setValue, remove
    ]);

    return (
        <AccordionItem className="card-header bg-primary" title="Hole">
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Angle</th>
                                        {[1, 2].includes(disciplineId) &&
                                            <>
                                                <th>Runs</th>
                                                <th>Core Retrieved</th>
                                            </>
                                        }
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <CreatableSelectInTable
                                                id={`${kindOfHole}[${index}].name`}
                                                className={kindOfHole}
                                                options={holes}
                                                rules={{required: "required"}}
                                                control={control}
                                                customStyles={Shared.customStylesWide}
                                                errors={errors}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                            (item.name)
                                                                ?
                                                                    {value: item.name, label: item.name}
                                                                :
                                                                    ''
                                                        :
                                                            (fieldHolesTemplate.length > 0)
                                                                ?
                                                                    {
                                                                        value: item.name ? item.name : '',
                                                                        label: item.name ? item.name : ''
                                                                    }
                                                                :
                                                                    ''
                                                }
                                                onChange={
                                                    (v) => {
                                                        Shared.buildFieldDailyHoles(
                                                            v,
                                                            setValue,
                                                            `${kindOfHole}[${index}].name`,
                                                            getValues,
                                                            disciplineId,
                                                            setCollectedHoles
                                                        );
                                                        getFieldBHATemplate(v, action);
                                                        getFieldBottomHoleTemplate(v, action);
                                                    }
                                                }
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`${kindOfHole}[${index}].angle`}
                                                value={(item.angle) ? item.angle : ''}
                                                name={`${kindOfHole}[${index}].angle`}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                                register={register({required: false})}
                                            />
                                            {[1, 2].includes(disciplineId) &&
                                                <>
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`${kindOfHole}[${index}].runs`}
                                                        name={`${kindOfHole}[${index}].runs`}
                                                        value={(item.runs) ? item.runs : ''}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                                        register={register({required: false})}
                                                    />
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`${kindOfHole}[${index}].core_retrieved`}
                                                        value={(item.core_retrieved) ? item.core_retrieved : ''}
                                                        name={`${kindOfHole}[${index}].core_retrieved`}
                                                        register={register({required: false})}
                                                    />
                                                </>
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => removeHoleRow(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default Hole;
