import React, {useEffect, useState} from 'react';
import apiClient from "../../../utils/apiClient";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { isEmpty } from 'lodash';

export const EditInvoiceDailiesModal = (props) => {
    const { modal, setModal, jobId, initialDailies, onSave, invoiceId } = props;

    const toggle = () => setModal({ ...modal, isOpen: !modal.isOpen });
    const [dailies, setDailies] = useState([]);
    const [selectedDailyIds, setSelectedDailyIds] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (jobId && modal.isOpen) {
            setIsLoading(true);

            const initialDailyIds = initialDailies.map(daily => Number(daily.id));

            Promise.all([
                apiClient
                    .post('getActiveDailiesByJob', {'job_id': jobId})
                    .then(response => {
                        return isEmpty(response.data) ? [] : response.data;
                    }),

                apiClient
                    .post('getActiveDailiesByInvoice', {'id': invoiceId, 'job_id': jobId})
                    .then(response => {
                        return response.data.dailies;
                    }),
            ]).then(([dailies, invoiceDailies]) => {
                setSelectedDailyIds(initialDailyIds);
                setDailies([...invoiceDailies, ...dailies]);
            })
            .finally(() => setIsLoading(false));
            
        }
    }, [jobId, initialDailies, modal.isOpen, invoiceId]);

    const onDailyChange = (daily) => {
        if (selectedDailyIds.includes(daily.id)) {
            setSelectedDailyIds(ids => ids.filter(id => id !== daily.id));
        } else {
            setSelectedDailyIds(ids => [...ids, daily.id]);
        }
    }

    const onSaveButtonClick = () => {
        const selectedDailies = dailies.filter(daily => selectedDailyIds.includes(daily.id));
        onSave(selectedDailies);
        toggle()
    }

    return (
        <Modal
            isOpen={modal.isOpen}
            toggle={toggle}
            className="modal-body"
            centered={true}
            size="lg"
        >
            <ModalHeader toggle={toggle}>Add Daily</ModalHeader>
            <ModalBody>
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Daily</th>
                            <th>Shift</th>
                            <th>Rig</th>
                            <th>Revenue</th>
                            <th>Hole</th>
                            <th>Modified By</th>
                            <th>Modified Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dailies.map((item) => (
                            <tr key={item.id}>
                                <td>
                                    <input checked={selectedDailyIds.includes(item.id)} type="checkbox" onChange={() => onDailyChange(item)} />
                                </td>
                                <td>{item.date}</td>
                                <td>{item.shift}</td>
                                <td>{item.rig_name}</td>
                                <td>{item.revenue}</td>
                                <td>{item.hole}</td>
                                <td>{item.modified_by}</td>
                                <td>{item.modified_at}</td>
                            </tr>
                        ))}
                        {dailies.length === 0 && isLoading &&
                            <>
                                <tr>
                                    <td colSpan={8}>
                                        Loading
                                    </td>
                                </tr>
                            </>
                        }
                        {dailies.length === 0 && !isLoading &&
                            <>
                                <tr>
                                    <td colSpan={8}>
                                        Dailies not found
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" type="button" onClick={onSaveButtonClick}>
                    Ok
                </Button>
                <Button color="secondary" type="button" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};
