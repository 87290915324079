import { Switch, Route } from "react-router-dom";
import Login from "../auth/login";

export const PublicPages = () => {

    return (
        <Switch>
            <Route path="/">
                <Login />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
        </Switch>
    );
};
