import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";

/**
 * Filter component for email distribution list
 * @returns {JSX.Element}
 * @constructor
 */
const EmailDistributionListFilters = (prop) => {
    const {register, handleSubmit, errors} = useForm();
    const {reportFilters, setReportFilters, setTableData} = prop;
    const [disciplines, setDisciplines] = useState([]);
    const [rigs, setRigs] = useState([]);

    // filter selection handler
    const filterSelectionHandler = e => {
        const {id, value} = e.target;
        updateStateValue(setReportFilters, id, value);
    }

    // submit handler for report filter
    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient
            .post('getDistributionEmails', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data.final);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    }

    // get list of rigs
    useEffect(() => {
        // get list of disciplines and pay periods
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
        });

        //check if discipline was selected
        if (isEmpty(reportFilters.discipline_id)) {
            setRigs([]);
            updateStateValue(setReportFilters, 'rig_id', null);
            return;
        }

        apiClient
            .post('getAllActiveRigsByDisciplineId',
            {'discipline_id': reportFilters.discipline_id})
            .then(response => {
                setRigs(
                    response.data.rigs.map(
                        ({id, name}) => ({id: id, name: name})
                    )
                );
            });
    }, [setReportFilters, reportFilters.discipline_id]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={disciplines}
                    register={register({required: "required"})}
                    errors={errors}
                    onChange={filterSelectionHandler}
                />
                <FormSelect
                    id="rig_id"
                    label="Rig"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={rigs}
                    register={register({required: "required"})}
                    errors={errors}
                    onChange={filterSelectionHandler}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default EmailDistributionListFilters;
