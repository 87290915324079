import React from 'react';

/**
 * Form Readonly plain text
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormReadonlyPlainText = (props) => {

    const {id, label, value, className} = props;

    return (
        <div className={className}>
            <label htmlFor={id}>{label}</label>
            <input
                className="form-control-plaintext font-weight-bold"
                type="text"
                readOnly
                id={id}
                name={id}
                value={value || ''}
            />
        </div>
    );
};

export default React.memo(FormReadonlyPlainText);
