export default function OfficeTimeEntrySummaryTable() {
    return [
        {
            Header: 'VP ID',
            accessor: 'erp_id',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Hours',
            Cell: (cell) => {
                const row = cell.row.original;
                return (row.hours !== null) ? Number(row.hours).toFixed(2) : '';
            }
        },
    ];
}
