import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import RigsFilters from "./rigsFilters";
import RigsBody from "./rigsBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Rigs Component
 * @returns {JSX.Element}
 * @constructor
 */
const Rigs = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        status: '',
        discipline_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Rigs" parent="Admin"/>
            <RigsFilters {...{filters, setFilters, setTableData}}/>
            <RigsBody {...{filters, setFilters, tableData, setTableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default Rigs;
