import React, { useState, useEffect, useRef } from 'react';
import nationalLogo from '../assets/images/Background_Login/national_login_logo.png';
import nationalLogoM from '../assets/images/Background_Login/national_login_logo_m.png';
import apiClient from "../utils/apiClient";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";
import { useForm } from "react-hook-form";
import FormInputPassword from "../components/forms/form-control/FormInputPassword";
import {useHistory} from "react-router-dom";
import { useAuthenticate } from '../hooks/auth';

const Login = (props) => {
    const { signin } = useAuthenticate();
    const [token, setToken] = useState(null);
    const [loginEmail, setLoginEmail] = useState(null);
    // react hooks to manipulate background image size
    const action = props.match.params.action;
    const history = useHistory();
    const [windowWidth, setWindowWidth ] = useState(window.innerWidth);
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
    let imageLogoUrl = (windowWidth >= 600) ? nationalLogo : nationalLogoM;

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        setShowResetPasswordForm(action === 'passwd');
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    },[setShowResetPasswordForm, action]);

    const loginAuth = async () => {
        let form = document.forms[0];
        signin(form.email.value, form.password.value).then((res)=> {
            if (res?.user_has_default_password) {
                setShowResetPasswordForm(true)
                setToken(res.token)
                setLoginEmail(form.email.value)
                history.push(`${process.env.PUBLIC_URL}/login/passwd`);
            } else {
                form.reset();
                history.push(`${process.env.PUBLIC_URL}/`);
            }
        });
    }

    return (
        <div className="s-login-page">
            <div className="s-login-container">
                <img
                    src={imageLogoUrl}
                    alt="logo"
                />
                {showResetPasswordForm ? (
                    <ChangePasswordForm history={history} token={token} loginEmail={loginEmail} />
                ) : (
                    <form className="s-form">
                        <div className="s-form-group">
                            <i className="email-icon fa fa-user fa-lg" />
                            <input
                                className="form-control input-field"
                                type="email"
                                name="email"
                            />
                        </div>
                        <div className="s-form-group">
                            <i className="password-icon fa fa-lock fa-lg" />
                            <input
                                className="form-control input-field"
                                type="password"
                                name="password"
                            />
                        </div>
                        <div className="s-form-submit">
                            <button
                                className="btn btn-pill s-drill-button"
                                type="button"
                                onClick={() => loginAuth()}
                            >
                                Enter
                            </button>
                        </div>
                    </form>
                )}
            </div>
            <ToastContainer />
        </div>
    );
}

const ChangePasswordForm = ({history, token, loginEmail}) => {
    const { signin } = useAuthenticate()
    const {register, handleSubmit, errors, watch} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        shouldFocusError: true,
    });
    const password = useRef({});
    password.current = watch("new_password", "");
    const onChangePassword = async (data) => {
        apiClient
            .post('changePasswordUser', data, {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    signin(loginEmail, data.new_password)
                        .then(()=> {
                            history.push(`${process.env.PUBLIC_URL}/`);
                    })
                }
            });
    }

    return (
        <form className="s-form" onSubmit={handleSubmit(onChangePassword)}>
            <div className="text-center white">
                You must change your password
            </div>
            <FormInputPassword
                id="new_password"
                className="pt-0 white"
                label="New Password"
                type="password"
                register={register({
                    required: {value: true, message: 'required'},
                    pattern: {value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, message: 'Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.'}
                })}
                errors={errors}
            />
            <FormInputPassword
                id="confirmation_password"
                className="pt-0 white"
                label="Confirm New Password"
                type="password"
                register={register({
                    validate: value => value === password.current || 'Please confirm the password'
                })}
                errors={errors}
            />
            <div className="s-form-submit">
                <button
                    className="btn btn-pill s-drill-button"
                    type="submit"
                >Enter
                </button>
            </div>
        </form>
    );
}

export default withRouter(Login);