import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {useForm, useFieldArray} from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import CreateDirectEntryTable from "./createDirectEntryTable";
import {getHours} from "../../../utils/listHelpers";
import {toast} from "react-toastify";
import LoadingIndicator from "../../common/loadingIndicator";
import FormSearchableSelect from "../../forms/form-control/FormSearchableSelect";

/**
 * CreateDirectEntry Component
 * @returns {JSX.Element}
 * @constructor
 */
const CreateDirectEntryForm = () => {
    const [userId, setUserId] = useState(null);
    const [payrollDates, setPayrollDates] = useState([]);
    const [users, setUsers] = useState([]);
    const [ecOptions, setEcOptions] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [states, setStates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {register, control, handleSubmit, errors, reset, setValue} = useForm({
        defaultValues: {
            direct_entries: [{}], // Set first direct entry empty by default
        }
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: "direct_entries"
    });

    // save form handler
    const onSubmit = (data) => {

        const body = {
            ...data,
            direct_entries: data.direct_entries.map(entry => ({
                ...entry,
                job_id: entry.job_id?.value,
            }))
        };

        setIsLoading(true);

        apiClient
            .post('createDirectEntries/' + userId, body)
            .then(response => {
                reset();
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                setIsLoading(false);
            });
    }

    // get list of options
    useEffect(() => {
        //get payroll dates
        apiClient
            .get('getPayrollDates')
            .then(response => {
                setPayrollDates(
                    response.data.map(
                        ({date, is_current}) => ({
                            id: date, name: date + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });

        //get users
        apiClient
            .get('getDrillersAndMechanics')
            .then(response => {
                setUsers(
                    response.data.map(
                        ({id, first_name, last_name}) => ({value: id, label: first_name + ' ' + last_name})
                    )
                );
            });

        //get EC Options
        apiClient
            .get('getEcOptionsForDirectEntry')
            .then(response => {
                setEcOptions(response.data);
            });

        //get active jobs
        apiClient
            .get('getAllActiveJobs')
            .then(response => {
                setJobs(
                    response.data.jobs.map(
                        ({id, job_number}) => ({value: id, label: job_number})
                    )
                );
            });

        //get states
        apiClient
            .get('getUSAStates')
            .then(response => {
                setStates(
                    response.data.states.map(
                        ({id, abbreviation}) => ({value: id, label: abbreviation})
                    )
                );
            });
    }, []);

    return (
        <>
            <Breadcrumb title="Create Direct Entry" parent="Time Entry"/>
            <form
                className="needs-validation container-fluid"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-row row marginBottom30">
                    <FormSearchableSelect
                        id="user_id"
                        options={users}
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                        rules={{required: "required"}}
                        control={control}
                        errors={errors}
                        onChange={(e) => setUserId(e)}
                    />
                </div>
                <CreateDirectEntryTable
                    userId={userId}
                    register={register}
                    control={control}
                    errors={errors}
                    fields={fields}
                    append={append}
                    remove={remove}
                    ecOptions={ecOptions}
                    jobs={jobs}
                    states={states}
                    payrollDates={payrollDates}
                    hours={getHours()}
                />
                <FormSubmitButton
                    label="Create"
                />
            </form>
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default CreateDirectEntryForm;
