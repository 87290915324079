import React, {useEffect} from "react";
import {useFieldArray} from "react-hook-form";
import {AccordionItem} from "react-light-accordion";
import {FaPlus, FaTrash, FaEraser} from "react-icons/fa";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import {getHours} from "../../../../../utils/listHelpers";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";

const DailyEmployee = (props) => {
    const {
        fieldEmployees, control, errors, register, fieldEmployeesAvailable,
        setValue, fieldDaily, action, fieldEmployeesTemplate, jobState //setWarningModal
    } = props;
    const {fields, append, remove} = useFieldArray({control, name: "employee"});

    useEffect(() => {
        if (action === 'edit') {
            append(fieldDaily.field_employees);

            setTimeout(() => {
                fieldDaily.field_employees.forEach((item, index) => {
                    // populate name select
                    setValue(
                        `employee[${index}].user_id`,
                        {value: item.user_id, label: item.first_name + ' ' + item.last_name}
                    );
                });
            }, 1000);
        }

        if (action === 'create' && fieldEmployeesTemplate.length > 0) {
            append(fieldEmployeesTemplate);
        }

        if (action === 'create' && fieldEmployeesTemplate.length === 0) {
            remove();
        }

    }, [fieldEmployeesTemplate, action, append, remove, fieldDaily, setValue]);

    // Disabled for now do not delete
    // const showAlert = (e) => {
    //     if (e.target.value < 12 || e.target.value > 12.5) {
    //         const msg = 'Are you sure that you have entered a correct value?';
    //         setWarningModal({
    //             message: msg,
    //             isOpen: true,
    //         });
    //     }
    // }

    return (
        <AccordionItem className="card-header bg-primary" title="Employee">
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Driller</th>
                                        <th>Hours</th>
                                        <th>Per Diem</th>
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => {
                                        const employee = fieldEmployeesAvailable?.find(
                                                (emp)=> emp.value === item.user_id?.value
                                        );

                                        return (
                                            <tr className="employee_records" key={item.id}>
                                                <SearchableSelectInTable
                                                    id={`employee[${index}].user_id`}
                                                    options={(action === 'edit') ? fieldEmployeesAvailable : fieldEmployees}
                                                    rules={{ required: "required" }}
                                                    control={control}
                                                    errors={errors}
                                                    value={(action === 'edit') ? employee : item.user_id?.value}
                                                    customStyles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            width: 700,
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            width: 700,
                                                            position: 'sticky',
                                                        })
                                                    }}
                                                />
                                                <SelectInTable
                                                    className="short-select-width"
                                                    id={`employee[${index}].is_driller`}
                                                    name={`employee[${index}].is_driller`}
                                                    value={item.is_driller}
                                                    options={[{value: "0", label: "No"}, {value: "1", label: "Yes"}]}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                { jobState === 5
                                                    ?
                                                        <InputInTable
                                                            className="hours-column-width"
                                                            id={`employee[${index}].hours`}
                                                            name={`employee[${index}].hours`}
                                                            value={(action === 'edit') ? item.hours : ''}
                                                            register={register({required: "required"})}
                                                            errors={errors}
                                                        />
                                                    :
                                                        <SelectInTable
                                                            className="short-select-width"
                                                            id={`employee[${index}].hours`}
                                                            name={`employee[${index}].hours`}
                                                            value={(action === 'edit') ? item.hours : ''}
                                                            options={getHours()}
                                                            // disabled for now don't delete
                                                            // onChange={(e) => showAlert(e)}
                                                            register={register({required: false})}
                                                        />
                                                }
                                                <InputInTable
                                                    className="hours-column-width"
                                                    id={`employee[${index}].per_diem`}
                                                    name={`employee[${index}].per_diem`}
                                                    value={(action === 'edit') ? item.per_diem : ''}
                                                    register={register({required: false})}
                                                />
                                                <td>
                                                    <FaTrash
                                                        color='#A51E37'
                                                        size={20}
                                                        title="Remove"
                                                        onClick={() => remove(index)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default DailyEmployee;
