import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import PayPeriodsFilters from "./payPeriodsFilters";
import PayPeriodsBody from "./payPeriodsBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * PayPeriods Component
 * @returns {JSX.Element}
 * @constructor
 */
const PayPeriods = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Pay Periods" parent="Payroll"/>
            <PayPeriodsFilters
                filters={filters}
                setTableData={setTableData}
                setFilters={setFilters}
            />
            <PayPeriodsBody
                filters={filters}
                setFilters={setFilters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default PayPeriods;
