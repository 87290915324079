import React from 'react';
import { get } from 'lodash';

/**
 * FormInputDate
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormInputDate = (props) => {

    const {id, label, value, className, onChange, errors, register, onBlur, ...rest} = props;

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <input
                className="form-control"
                id={id}
                name={id}
                type="date"
                max="9999-12-31"
                min="2000-01-01"
                defaultValue={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                ref={register}
                {...rest}
            />
            {errors && <span className="s-color-error">{ get(errors, id)?.message }</span>}
        </div>
    );
}

export default React.memo(FormInputDate);
