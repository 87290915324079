import React from 'react';
import FullWorkHistoryTable from "./fullWorkHistoryTable";
import TableReport from "../../../common/table/table";
import MessageEmptyData from "../../../common/messageEmptyData";

/**
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const FullWorkHistoryBody = (prop) => {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
                <TableReport
                    columns={FullWorkHistoryTable()}
                    data={tableData}
                    exportOptions={{pdf: false, xlsx: true, csv: true}}
                    getExportFileName={() => 'full_work_history'}
                    withGlobalSearch={true}
                    scrollable={true}
                />
        </div>
    );
}

export default FullWorkHistoryBody;
