import React from 'react';
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from "lodash";
import TableReport from "../../../common/table/table";
import {newVendorsTableColumns} from "./newVendorsTableColumns";

/**
 * newVendorsBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function newVendorsBody(prop) {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableData)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <div className="container-fluid">
                <TableReport
                    columns={newVendorsTableColumns}
                    data={tableData}
                    exportOptions={{pdf: false, xlsx: true, csv: true}}
                    withGlobalSearch={true}
                    scrollable={true}
                    getExportFileName={() => 'new_vendors'}
                />
            </div>
        </div>
    );
}