export const tableColumns = [
    {
        Header: 'Discipline',
        accessor: 'discipline',
    },
    {
        Header: 'Job Number',
        accessor: 'job_number',
    },
    {
        Header: 'Job Name',
        accessor: 'job_name',
        width: 250,
    },
    {
        Header: 'Rig',
        accessor: 'rig_name',
    },
    {
        Header: 'Client',
        accessor: 'client_name',
        width: 300,
    },
    {
        Header: 'City',
        accessor: 'city',
    },
    {
        Header: 'State',
        accessor: 'state',
    },
];
