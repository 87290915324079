import React, {useState} from 'react';
import Table from "../../common/table/table";
import ClientsColumns from "./ClientsColumns";
import MessageEmptyData from "../../common/messageEmptyData";
import ModalConfirmation from "../../common/modal/ModalConfirmation";
import apiClient from "../../../utils/apiClient";
import {toast} from "react-toastify";
import * as ArrayHelpers from "../../../utils/arrayHelpers";

/**
 * ClientsBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ClientsBody = (prop) => {
    const {filters, tableData, setTableData, selectedRow, setSelectedRow} = prop;
    const [deactivateModal, setDeactivateModal] = useState({isOpen: false, message: <p></p>});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});
    const [reactivateModal, setReactivateModal] = useState({isOpen: false, message: <p></p>});

    // deactivate Client
    const DeactivateHandler = () => {
        apiClient
            .post('deactivateClient', {'id': selectedRow.id})
            .then(response => {
                const newTableData = ArrayHelpers.removeObjFromArray(tableData, "id", selectedRow.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeactivateModal({...deactivateModal, isOpen: false})
            });
    }

    // remove Client
    const deleteClientHandler = () => {
        apiClient.post('deleteClient', {'id': selectedRow.id})
            .then(response => {
                const newTableData = ArrayHelpers.removeObjFromArray(tableData, "id", selectedRow.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close modal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    // reactivate Client
    const ReactivateHandler = () => {
        apiClient.post('reactivateClient', {'id': selectedRow.id})
            .then(response => {
                const newTableData = ArrayHelpers.removeObjFromArray(tableData, "id", selectedRow.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close modal
                setReactivateModal({...reactivateModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    ClientsColumns(setSelectedRow, setDeactivateModal, setReactivateModal, setDeleteModal)
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'clients'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={deactivateModal}
                setModal={setDeactivateModal}
                title="Deactivate Client"
                onConfirm={DeactivateHandler}
            />
            <ModalConfirmation
                modal={reactivateModal}
                setModal={setReactivateModal}
                title="Reactivate Client"
                onConfirm={ReactivateHandler}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Delete Client"
                onConfirm={deleteClientHandler}
            />
        </div>
    );
}

export default ClientsBody;
