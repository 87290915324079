import {FaEye, FaEyeSlash} from "react-icons/fa";
import React from "react";

const LocationsTable = (
    setSelectedRow, setApproveModal, setDisapproveModal
) => {
    return [
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Discipline',
            accessor: 'discipline.name'
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        { (row.status === 1) &&
                            <>
                                <FaEyeSlash
                                    className="actions"
                                    title="Deactivate"
                                    onClick={() => {
                                        const msg = <p>Are you sure you want to deactivate <b>{row.name}</b>?</p>;
                                        setSelectedRow(row);
                                        setDisapproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                            </>
                        }
                        { (row.status === 0) &&
                            <>
                                <FaEye
                                    className="actions"
                                    title="Reactivate"
                                    onClick={() => {
                                        const msg = <p>Are you sure you want to reactivate <b>{row.name}</b>?</p>;
                                        setSelectedRow(row);
                                        setApproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                            </>
                        }
                    </>
                );
            }
        }
    ];
}

export default LocationsTable;



