import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import EmployeeRegisterFilters from "./employeeRegisterFilters";
import EmployeeRegisterBody from "./employeeRegisterBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * EmployeeRegister Report Component
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeeRegister = () => {

    const [tableData, setTableData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        discipline_id: '',
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Employee Register" parent="Reports"/>
            <EmployeeRegisterFilters reportFilters={reportFilters} setTableData={setTableData} setReportFilters={setReportFilters}/>
            <EmployeeRegisterBody reportFilters={reportFilters} tableData={tableData}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
};

export default EmployeeRegister;
