export const tableColumns = [
    {
        Header: 'Rig',
        accessor: 'rig_name',
    },
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'Daily Shift',
        accessor: 'daily_shift',
    },
    {
        Header: 'Daily Revenue',
        accessor: 'revenue',
    },
    {
        Header: 'Unit Charged Price',
        accessor: 'total_charged',
    },
    {
        Header: 'Variance',
        accessor: 'variance',
    },
];
