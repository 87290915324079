import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import moment from 'moment/moment';

/**
 * Filter component for Invoice Charges
 * @returns {JSX.Element}
 * @constructor
 */
const TableFilters = (prop) => {
    const {register, handleSubmit, errors} = useForm({
        defaultValues: {
            date_from: moment(new Date(), "MM/DD/YYYY").subtract(7, 'd').format("YYYY-MM-DD"),
            date_to: moment(new Date(), "MM/DD/YYYY").format("YYYY-MM-DD"),
        }
    });
    const {setFilters, setTableData} = prop;
    const [disciplines, setDisciplines] = useState([]);
    const [rigs, setRigs] = useState([]);

    const handleDisciplineSelect = (disciplineId) => {
        // get list of rigs
        apiClient
            .post('getAllActiveRigsByDisciplineId', {'discipline_id': disciplineId})
            .then(response => {
                setRigs(
                    response.data.rigs.map(
                        ({id, name}) => ({id: id, name: name})
                    )
                );
            });
    }

    // submit handler for report filter
    const onSubmit = (data) => {
        updateStateValue(setFilters, 'isLoading', true);
        updateStateValue(setFilters, 'isSubmitted', true);

        let invoiceEndPoint = (isEmpty(data['rig_id']))
            ?
                'getInvoiceChargesByDisciplineId'
            :
                'getInvoiceChargesByDisciplineIdAndRigId';

        apiClient
            .post( invoiceEndPoint, data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            }).then(function () {
            updateStateValue(setFilters, 'isLoading', false);
        });
    }

    // get list of disciplines
    useEffect(() => {
        // get list of disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });
    }, []);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row">
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={disciplines}
                    register={register({required: "required"})}
                    errors={errors}
                    onChange={(e) => handleDisciplineSelect(e.target.value)}
                />
                <FormSelect
                    id="rig_id"
                    label="Rigs"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={rigs}
                    register={register({required: false})}
                    errors={errors}
                />
                <FormInputDate
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    id="date_from"
                    label="From"
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormInputDate
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    id="date_to"
                    label="To"
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default TableFilters;
