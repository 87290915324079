import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import CostCodesReportFilters from "./costCodesReportFilters";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Cost Codes Report
 * @returns {JSX.Element}
 * @constructor
 */
const CostCodesReport = () => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <Breadcrumb title="Cost Codes Report" parent="Reports"/>
            <CostCodesReportFilters setIsLoading={setIsLoading} />
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default CostCodesReport;
