import React, {useEffect, useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import apiClient from "../../../utils/apiClient";
import {useForm} from "react-hook-form";
import FormInputFile from "../../forms/form-control/FormInputFile";
import {isEmpty} from "lodash";
import TableReport from "../table/table";
import FileManagerTableColumns from "./FileManagerTableColumns";
import ModalConfirmation from "../modal/ModalConfirmation";
import {removeObjFromArray, replaceObjFromArray} from "../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormInputText from "../../forms/form-control/FormInputText";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import { useAuthenticate } from '../../../hooks/auth';

/**
 * Modal form for download/delete files
 * @returns {JSX.Element}
 * @constructor
 */
const FileManager = (props) => {
    const {fileableId, fileableType, setFileableId, modalTitle, modal, setModal, allowUpload=true, allowShareFiles=false, accept} = props;
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const {register, handleSubmit, reset, errors} = useForm();
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});
    const [shareModal, setShareModal] = useState({isOpen: false, message: <p></p>});
    const { user } = useAuthenticate();

    const toggle = () => {
        if(modal.isOpen){
            setFileableId({});
        }
        setModal({...modal, isOpen: !modal.isOpen});
    };

    // load list of files
    useEffect(() => {
        if (fileableId) {
            apiClient
                .post('getFiles',
                {fileable_id: fileableId, fileable_type: fileableType})
                .then(response => {
                    if (isEmpty(response.data)) {
                        setTableData([]);
                        return;
                    }
                    setTableData(response.data);
                });
        }
    }, [fileableId, fileableType]);

    // delete handler
    const onDelete = () => {
        apiClient
            .post('deleteFile', {id: selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {})
            .then(function () {
                //close deleteModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    // download handler
    const onDownload = (row) => {
        //get pdf export file and open it in a new window
        apiClient('downloadFile', {
            method: 'POST',
            responseType: 'blob', //Force to receive data in a Blob Format
            data: {id: row.id}
        })
            .then(response => {
                const blob = new Blob([response.data], {type: response.data.type});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', row.original_name);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(function () {});
    }

    // upload handler
    const onUpload = (data) => {
        //build formData
        const formData = new FormData();
        for (const key in data) {
            switch (key) {
                case 'files':
                    for (const file of data[key]) {
                        formData.append('files[]', file, file.name);
                    }
                    break;
                default:
                    formData.append(key, data[key]);
            }
        }

        //Upload files
        apiClient
            .post('uploadFile', formData)
            .then(response => {
                // update table row
                setTableData(
                    [...tableData, ...response.data]
                );
               reset();
            }).catch(function () {});
    }

    // share handler
    const onShare = () => {
        apiClient
            .post('shareFile', {id: selectedRow.id})
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
            })
            .then(function () {
                setShareModal({...shareModal, isOpen: false})
            });
    }

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} size={'lg'}>
            <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
            <ModalBody>
                { allowUpload &&
                    <form
                        className="needs-validation"
                        onSubmit={handleSubmit(onUpload)}
                    >
                        <div className="form-row mb-2">
                            <FormInputText
                                id="fileable_id"
                                type="hidden"
                                value={fileableId}
                                register={register}
                            />
                            <FormInputText
                                id="fileable_type"
                                type="hidden"
                                value={fileableType}
                                register={register}
                            />
                            <FormInputFile
                                id="files"
                                label="Add Files"
                                className="col-md-10"
                                register={register({required: "required"})}
                                accept={accept}
                                errors={errors}
                            />
                            <FormSubmitButton
                                className="col-md-2 "
                                label="Upload"
                            />
                        </div>
                    </form>
                }
                <TableReport
                    columns={FileManagerTableColumns(
                        setSelectedRow, setDeleteModal, setShareModal, onDownload, allowShareFiles, user
                    )}
                    data={tableData}
                />
                <ModalConfirmation
                    modal={deleteModal}
                    setModal={setDeleteModal}
                    title="Remove File"
                    onConfirm={onDelete}
                />
                <ModalConfirmation
                    modal={shareModal}
                    setModal={setShareModal}
                    title="Share File"
                    onConfirm={onShare}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

export default React.memo(FileManager);
