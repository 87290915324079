export const adpUploadHoursTableColumns = [
    {
        Header: 'Employee',
        accessor: 'Employee',
        width: 75,
    },
    {
        Header: 'Batch Desc',
        accessor: 'Cert2',
        width: 60,
    },
    {
        Header: 'LastName',
        accessor: 'LastName',
        width: 100,
    },
    {
        Header: 'PAY #',
        accessor: 'PaySeq2',
        width: 50,
    },
    {
        Header: 'TEMP COST # job',
        accessor: 'tempCostJob',
        width: 230,
    },
    {
        Header: 'TEMP COST # EQ',
        accessor: 'equipment',
        width: 230,
    },
    {
        Header: 'TEMPCOST RATE',
        accessor: 'rate',
        width: 100,
    },
    {
        Header: 'STATE',
        accessor: 'workedState',
        width: 80,
    },
    {
        Header: 'REG',
        accessor: 'Reg',
        width: 80,
    },
    {
        Header: 'OT',
        accessor: 'OT',
        width: 60,
    },
    {
        Header: 'DT',
        accessor: 'DT',
        width: 60,
    },
    {
        Header: 'CAT',
        accessor: 'CATravel',
        width: 60,
    },
    {
        Header: 'Training HRS',
        accessor: 'LayoverHours',
    },
    {
        Header: 'PTO HRS',
        accessor: 'PTOHours',
        width: 80,
    },
    {
        Header: 'HOLHRS',
        accessor: 'HOLHours',
    },
    {
        Header: 'Cert',
        accessor: 'Cert',
        width: 60,
    },
    {
        Header: 'SEQ',
        accessor: 'PaySeq',
        width: 60,
    },
    {
        Header: 'Class',
        accessor: 'Class',
        width: 60,
    },
];
