import React from 'react';
import {get} from "lodash";

/**
 * Form input for file
 *
 * (optional) accept attribute value is a string that defines the file types the file input should accept
 * Ex: accept="image/png, image/jpeg"
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormInputFile = (props) => {

    let {id, label, accept, className, onChange, errors, register } = props;

    return (
        <div className={className}>
            {label && <label htmlFor={id}>{label}</label>}
            <input
                id={id}
                name={id}
                className="form-control"
                type="file"
                {...(accept && {accept: accept})}
                multiple={true}
                onChange={onChange}
                ref={register}
            />
            {errors && <span>{get(errors, id)?.message}</span>}
        </div>
    );
};

export default React.memo(FormInputFile);
