import React from "react";
import {FaFile} from "react-icons/fa";

const ClientJobsTable = (setSelectedFilesRow, setFormModalFiles) => {
    return [
        {
            Header: 'Job Name',
            accessor: 'job_name',
        },
        {
            Header: 'Job #',
            accessor: 'job_number',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'PO #',
            accessor: 'po_number',
        },
        {
            Header: 'Service Agreement',
            accessor: 'service_agreement',
        },
        {
            Header: 'Operations Manager',
            accessor: 'operations_manager',
        },
        {
            Header: 'Billing Email',
            accessor: 'billing_email',
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <FaFile
                        className="actions ml-1"
                        title="Files"
                        onClick={() => {
                            setSelectedFilesRow(row);
                            setFormModalFiles({isOpen: true});
                        }}
                    />
                );
            }
        }
    ];
}

export default ClientJobsTable;

