import { useEffect } from "react"
import { useAuthenticate } from "../hooks/auth"

export const UserInfoProvider = ({children}) => {
    const {token, setUserInfo} = useAuthenticate();

    useEffect(()=> {
        setUserInfo(token);
    }, [setUserInfo, token]);

    return (
        <>
            {children}
        </>
    );
}