import {AccordionItem} from "react-light-accordion";
import React from "react";
import * as Shared from "../../../../common/shared";
import InputInDiv from "../../../../forms/form-control/daily/InputInDiv";

const MudTest = (props) => {
    const { register, fieldDaily, action } = props;

    return (
        <AccordionItem className="card-header bg-primary" title="Mud Test">
            <div className="collapse show">
                <div className="form-row margin">
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.viscosity_1"
                        label="Viscosity 3"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                        ?
                                            fieldDaily.field_mud_testing[0].viscosity_1
                                        :
                                            ''
                                :
                                    ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.viscosity_2"
                        label="Viscosity 6"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].viscosity_2
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.viscosity_3"
                        label="Viscosity 9"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].viscosity_3
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.weight_1"
                        label="Weight 3"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].weight_1
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.weight_2"
                        label="Weight 6"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].weight_2
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.weight_3"
                        label="Weight 9"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].weight_3
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.water_loss_1"
                        label="Water Loss 3"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].water_loss_1
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.water_loss_2"
                        label="Water Loss 6"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].water_loss_2
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.water_loss_3"
                        label="Water Loss 9"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].water_loss_3
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.ph_1"
                        label="PH 3"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].ph_1
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.ph_2"
                        label="PH 6"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].ph_2
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.ph_3"
                        label="PH 9"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].ph_3
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.sand_content_1"
                        label="Sand Content 3"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].sand_content_1
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.sand_content_2"
                        label="Sand Content 6"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].sand_content_2
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.sand_content_3"
                        label="Sand Content 9"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].sand_content_3
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.wall_cake_1"
                        label="Wall Cake 3"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].wall_cake_1
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.wall_cake_2"
                        label="Wall Cake 6"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].wall_cake_2
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-2 mb-3"
                        id="mud_test.wall_cake_3"
                        label="Wall Cake 9"
                        value={
                            (action === 'edit')
                                ?
                                (fieldDaily.field_mud_testing.length > 0)
                                    ?
                                    fieldDaily.field_mud_testing[0].wall_cake_3
                                    :
                                    ''
                                :
                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
            </div>
        </AccordionItem>
    );
}

export default MudTest;
