import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import AssetsFilters from "./assetsFilters";
import AssetsBody from "./assetsBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Assets Component
 * @returns {JSX.Element}
 * @constructor
 */
const Assets = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Assets" parent="Equipment"/>
            <AssetsFilters
                filters={filters}
                setTableData={setTableData}
                setFilters={setFilters}
            />
            <AssetsBody
                filters={filters}
                setFilters={setFilters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default Assets;
