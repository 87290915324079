import React, {useEffect} from 'react';
import {isEmpty} from "lodash";
import {FaTrash} from "react-icons/fa";
import FormInputText from "../../forms/form-control/FormInputText";
import {useFieldArray} from "react-hook-form";
import apiClient from "../../../utils/apiClient";

/**
 * createInvoiceTableCharges component
 * @returns {JSX.Element}
 * @constructor
 */
const CreateInvoiceTableCharges = (props) => {
    const {filters, register, control, setTotalInvoiceCharges} = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "invoice_charges"
    });

    useEffect(() => {
        if (filters.job_id) {
            apiClient
                .post('getInvoiceChargesByJob', {'job_id': filters.job_id})
                .then(response => {
                    remove();
                    if (!isEmpty(response.data)) {
                        append(response.data);

                        let totalInvoiceCharges = 0;

                        response.data.forEach((row) => {
                            totalInvoiceCharges += Number(row.total);
                        });

                        setTotalInvoiceCharges(totalInvoiceCharges);
                    }
                })
        } else {
            remove();
        }
    }, [append, remove, filters.job_id, setTotalInvoiceCharges]);

    const RemoveChargeRow = (index) => {
        let FieldsObject = fields;
        // remove from UI
        remove(index);
        // remove from fields
        delete FieldsObject[index];

        // calculate total
        let totalRevenue = 0;
        fields.forEach((row) => {
            totalRevenue += Number(row.total);
        });

        setTotalInvoiceCharges(totalRevenue);
    }

    return (
        <table className="table table-hover table-striped col-md-11">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Units</th>
                    {/*<th>Charged</th>*/}
                    <th>Total</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {fields.map((item, index) => (
                    <tr key={item.id}>
                        <td>{item.date}</td>
                        <td>{item.description}</td>
                        <td>{item.price}</td>
                        <td>{item.units}</td>
                        <td>{item.total}</td>
                        <td>
                            <FormInputText
                                id={`invoice_charges[${index}].id`}
                                type="hidden"
                                value={item.id}
                                register={register({required: "required"})}
                            />
                            <FaTrash
                                color='#A51E37'
                                title="Remove"
                                onClick={() => RemoveChargeRow(index)}
                            />
                        </td>
                    </tr>
                ))}
                {fields.length > 0 &&
                    <>
                        <tr>
                            <td colSpan={8}>
                                <span className="font-weight-bold">Total : </span>
                                <b className="red">{
                                    fields.reduce(
                                        (a, v) => Number(a) + Number(v.total), 0
                                    ).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
                                }</b>
                            </td>
                        </tr>
                    </>
                }
                {fields.length === 0 &&
                    <>
                        <tr>
                            <td colSpan={8}>
                                Invoice charges not found for selected job
                            </td>
                        </tr>
                    </>
                }
            </tbody>
        </table>
    );
}

export default CreateInvoiceTableCharges;
