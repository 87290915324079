import React from 'react';
import TableReport from "../../../common/table/table";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from 'lodash';
import {TableStyle} from "./tableStyle";
import ShopTimeEntryToApproveModal from "./shopTimeEntryToApproveModal";

/**
 * Body component for review to approve
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ShopTimeEntryToApproveBody = (prop) => {
    const {
        filters, hoursTableHeader, hoursTableRows, perDiemTableHeader, perDiemTableRows, selectedRow,
        setApproveModal, approveModal, setHoursTableRows
    } = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (isEmpty(hoursTableRows)) {
        return <MessageEmptyData/>;
    }

    if (isEmpty(perDiemTableRows)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid overflow">
            <div className="col">
                <h5 className="marginTop">Time:</h5>
            </div>
            <TableStyle>
                <TableReport
                    columns={hoursTableHeader}
                    data={hoursTableRows}
                    exportOptions={{pdf: false, xlsx: true, csv: true}}
                    getExportFileName={() => 'time_entries_hours'}
                    withGlobalSearch={true}
                    scrollable={true}
                />
            </TableStyle>
            <div className="col">
                <h5 className="marginTop">Per Diem:</h5>
            </div>
            <TableStyle>
                <TableReport
                    columns={perDiemTableHeader}
                    data={perDiemTableRows}
                    exportOptions={{pdf: false, xlsx: true, csv: true}}
                    getExportFileName={() => 'time_entries_per_diem'}
                    withGlobalSearch={true}
                    scrollable={true}
                />
            </TableStyle>
            <ShopTimeEntryToApproveModal
                modal={approveModal}
                setModal={setApproveModal}
                selectedRow={selectedRow}
                setHoursTableRows={setHoursTableRows}
                hoursTableRows={hoursTableRows}
            />
        </div>
    );
}

export default ShopTimeEntryToApproveBody;
