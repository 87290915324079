const directEntryColumns = () => {
    return [
        {
            Header: 'Emp #',
            accessor: 'erp_id',
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Rig',
            accessor: 'rig_name',
        },
        {
            Header: 'Type',
            accessor: 'type',
            width: 50,
        },
        {
            Header: 'Pay Seq',
            accessor: 'pay_sequence',
            width: 60,
        },
        {
            Header: 'Payroll Date',
            accessor: 'payroll_date',
            width: 85
        },
        {
            Header: 'Work Date',
            accessor: 'work_date',
            width: 85
        },
        {
            Header: 'Job',
            accessor: 'job_number',
            width: 90
        },
        {
            Header: 'Phase',
            accessor: 'phase',
            width: 100
        },
        {
            Header: 'Craft',
            accessor: 'craft',
            width: 50
        },
        {
            Header: 'Class',
            accessor: 'class',
            width: 50
        },
        {
            Header: 'Crew',
            accessor: 'crew',
            width: 60,
        },
        {
            Header: 'Hours',
            accessor: 'hours',
            width: 50
        },
        {
            Header: 'EC',
            accessor: 'ec_id',
            width: 30
        },
        {
            Header: 'Per Diem',
            accessor: 'per_diem',
            width: 70
        },
        {
            Header: 'Equip #',
            accessor: 'equipment_number',
        },
        {
            Header: 'Equip CC',
            accessor: 'equipment_code',
        },
        {
            Header: 'Certified',
            accessor: 'certified',
        },
        {
            Header: 'State',
            accessor: 'state',
            width: 40
        },
        {
            Header: 'Shift',
            accessor: 'shift',
            width: 40,
        },
    ];
}

export default directEntryColumns;

