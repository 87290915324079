import {formatNumberToDisplayWithCommas} from '../../../common/shared';

/**
 * return table columns definition
 * @param title
 * @returns json
 */
export const getTableColumns = (title) => {
    return [
        {
            Header: title,
            columns: [
                {
                    Header: 'Date',
                    accessor: 'row_label',
                },
                {
                    Header: 'Shifts',
                    accessor: 'shifts',
                },
                {
                    Header: 'Shift Hrs',
                    accessor: 'shift_hrs',
                    Cell: (cell) => {
                        const row = cell.row.original;
                        return (typeof row?.shift_hrs === 'number') ? formatNumberToDisplayWithCommas(cell.value) : row?.shift_hrs ?? null;
                    }
                },
                {
                    Header: 'Depth',
                    accessor: 'depth',
                    Cell: (cell) => {
                        const row = cell.row.original;
                        return (typeof row?.depth === 'number') ? formatNumberToDisplayWithCommas(cell.value) : row?.depth ?? null;
                    }
                },
                {
                    Header: 'Shift Avg',
                    accessor: 'shift_avg',
                    Cell: (cell) => {
                        const row = cell.row.original;
                        return (typeof row?.shift_avg === 'number') ? formatNumberToDisplayWithCommas(cell.value) : row?.shift_avg ?? null;
                    }
                },
                // {
                //     Header: 'Daily Avg',
                //     accessor: 'daily_avg',
                // },
                // {
                //     Header: 'Req Avg',
                //     accessor: 'req_avg',
                // },
                {
                    Header: 'Revenue',
                    accessor: 'revenue',
                    Cell: (cell) => {
                        const row = cell.row.original;
                        return (typeof row?.revenue === 'number') ? formatNumberToDisplayWithCommas(cell.value) : row?.revenue ?? null;
                    }
                },
            ]
        },
    ];
}


