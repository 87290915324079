import React from 'react';
import {AlertCircle} from "react-feather";

/**
 * Generic message to display when data is empty
 * @returns {JSX.Element}
 */
const MessageEmptyData = () => {
    return (
        <div className="media mt-3">
            <div className="media-body s-media-body">
                <AlertCircle/>
                <h6 className="mb-0">No data found!</h6>
            </div>
        </div>
    );
}

export default MessageEmptyData;
