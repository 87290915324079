import React, {useState} from 'react';
import Table from "../../../common/table/table";
import DisciplineTargetsTable from "./disciplineTargetTable";
import MessageEmptyData from "../../../common/messageEmptyData";
import EditDisciplineTargetModal from "./editDisciplineTargetModal";

/**
 * DisciplineTargetsBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const DisciplineTargetsBody = (prop) => {
    const {filters, tableData, setTableData, selectedRow, setSelectedRow} = prop;
    const [editDisciplineTargetModal, setEditDisciplineTargetModal] = useState({isOpen: false});

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={DisciplineTargetsTable(setSelectedRow, setEditDisciplineTargetModal)}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'discipline_targets'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <EditDisciplineTargetModal
                modal={editDisciplineTargetModal}
                setModal={setEditDisciplineTargetModal}
                selectedRow={selectedRow}
                setEditDisciplineTargetModal={setEditDisciplineTargetModal}
                editDisciplineTargetModal={editDisciplineTargetModal}
                tableData={tableData}
                setTableData={setTableData}
            />
        </div>
    );
}

export default DisciplineTargetsBody;
