const tableColumns = [
    {
        Header: 'Emp #',
        accessor: 'erp_id',
    },
    {
        Header: 'First Name',
        accessor: 'first_name',
    },
    {
        Header: 'Last Name',
        accessor: 'last_name',
    },
    {
        Header: 'Crew',
        accessor: 'crew',
    },
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'State',
        accessor: 'state',
    },
    {
        Header: 'Certified',
        accessor: 'certified',
    },
    {
        Header: 'Rig',
        accessor: 'rig_name',
    },
    {
        Header: 'Shift',
        accessor: 'shift',
    },
    {
        Header: 'Job',
        accessor: 'job_number',
    },
    {
        Header: 'Location',
        accessor: 'location',
    },
    {
        Header: 'Hours',
        accessor: 'hours',
    },
    {
        Header: 'Per Diem',
        accessor: 'per_diem',
    },
    {
        Header: 'EC',
        accessor: 'ec',
    },
];

export default tableColumns

