import {FaPlus, FaTrash} from "react-icons/fa";
import React from "react";

const AllocateJobsTable = (filters, setSelectedRow, setFormModal, setDeleteModal) => {
    return [
        {
            Header: 'Job Number',
            accessor: 'job_number',
            width: 200,
        },
        {
            id: 'actions',
            Header: () => {
                if (filters.user_id) {
                    return (
                        <FaPlus
                            color='#22af47'
                            size={18}
                            title="Create"
                            onClick={() => {
                                setSelectedRow({});
                                setFormModal({isOpen: true});
                            }}
                        />
                    )
                } else {
                    return <></>;
                }
            },
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaTrash
                            className="actions"
                            title="Delete"
                            onClick={() => {
                                const msg = <p>Are you sure you want to delete this Job <b>{row.job_number}</b> from list?</p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                )
            }
        }
    ];
}

export default AllocateJobsTable;



