import React from "react";
import {Link} from "react-router-dom";
import exportToHTML from "../../../utils/exportToHtml";
import {FaCheck, FaCheckSquare, FaFileInvoice, FaFileInvoiceDollar, FaPencilAlt, FaTrash} from "react-icons/fa";

const InvoicesTable = (
    setSelectedRow, setApproveModal, setDisapproveModal, setDeleteModal
) => {
    return [
        {
            Header: 'Invoice #',
            accessor: 'invoice_number',
            width: 80,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <Link
                        className={'font-weight-bold'}
                        to={`/invoicing/edit_invoice/${row.id}`}
                        target="_blank">
                        {cell.value}
                    </Link>
                );
            }
        },
        {
            Header: 'PO #',
            accessor: 'po_number'
        },
        {
            Header: 'Status',
            accessor: 'invoice_status',
            width: 50,
        },
        {
            Header: 'Date',
            accessor: 'date',
            width: 80,
        },
        {
            Header: 'Revenue',
            accessor: 'revenue',
            Cell: (cell) => {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 2,
                });


                return formatter.format(cell.value)
            },
        },
        {
            Header: 'Client',
            accessor: 'client_name',
        },
        {
            Header: 'Job',
            accessor: 'job_number',
            width: 90,
        },
        {
            Header: 'Holes',
            accessor: 'holes'
        },
        {
            Header: 'Rigs',
            accessor: 'rigs',
            width: 80,
        },
        {
            Header: 'Work Period',
            accessor: 'work_period'
        },
        {
            Header: 'Approved',
            accessor: 'approved'
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaFileInvoiceDollar
                            title="View Invoice"
                            size='1.1em'
                            color='#A51E37'
                            onClick={ () => { exportToHTML('exportInvoiceById',row.id) }}
                        />
                        {' '}
                        <FaFileInvoice
                            title="View Invoice Details Report"
                            size='1.1em'
                            color='#A51E37'
                            onClick={ () => { exportToHTML('exportInvoiceDetailsById',row.id) }}
                        />
                        {' '}
                        <Link
                            to={`/invoicing/edit_invoice/${row.id}`}
                            target="_blank">
                                <FaPencilAlt title="Edit"/>
                        </Link>
                        {' '}
                        { (row.approved === 'No') &&
                            <FaCheck
                                className="actions"
                                title="Approve Invoice"
                                onClick={() => {
                                    const msg = <p>Are you sure that you want to approve <b>{row.invoice_number}</b>?</p>;
                                    setSelectedRow(row);
                                    setApproveModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        { (row.approved !== 'No') &&
                            <FaCheckSquare
                                className="actions"
                                title="Disapprove Invoice"
                                color='#22af47'
                                onClick={() => {
                                    const msg = <p>Are you sure that you want to disapprove <b>{row.invoice_number}</b>?</p>;
                                    setSelectedRow(row);
                                    setDisapproveModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        {' '}
                        <FaTrash
                            className="actions"
                            title="Delete Invoice"
                            onClick={() => {
                                const msg = <p>Are you sure that you want to remove <b>{row.invoice_number}</b>?</p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                        }}/>
                    </>
                );
            }
        }
    ];
}

export default InvoicesTable;
