import React, {useEffect, useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import FormSelect from "../../forms/form-control/FormSelect";
import apiClient from "../../../utils/apiClient";
import {useForm} from "react-hook-form";
import FormInputDate from "../../forms/form-control/FormInputDate";
import FormInputNumber from "../../forms/form-control/FormInputNumber";
import FormReadonlyPlainText from "../../forms/form-control/FormReadonlyPlainText";
import {replaceObjFromArray} from "../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormInputText from "../../forms/form-control/FormInputText";

/**
 * Modal for edit record
 * @returns {JSX.Element}
 * @constructor
 */
const DirectEntriesEditFormModal = (props) => {
    const {modal, setModal, title, selectedRow, tableData, setTableData, payrollDates} = props;
    const {register, handleSubmit, errors} = useForm();
    const [ecOptions, setEcOptions] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [states, setStates] = useState([]);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        //get EC Options
        apiClient
            .get('getEcOptionsForDirectEntry')
            .then(response => {
                setEcOptions(response.data);
            });

        //get active jobs
        apiClient
            .get('getAllActiveJobs')
            .then(response => {
                setJobs(
                    response.data.jobs.map(
                        ({id, job_number}) => ({id: id, name: job_number})
                    )
                );
            });

        //get states
        apiClient
            .get('getUSAStates')
            .then(response => {
                setStates(response.data.states);
            });
    }, [selectedRow.job_id]);


    const onSubmit = (data) => {
        apiClient
            .post('editDirectEntry/' + selectedRow.direct_entry_id, data)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "direct_entry_id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-body" centered={true} size={'lg'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormReadonlyPlainText
                            className="col-md-4 mb-4"
                            label="User Name"
                            value={selectedRow.first_name + ' ' + selectedRow.last_name}
                        />
                        <FormReadonlyPlainText
                            className="col-md-4 mb-4"
                            label="Group"
                            value={selectedRow.group}
                        />
                    </div>
                    <div className="form-row">
                        <FormSelect
                            id="pay_sequence"
                            label="Pay Sequence"
                            className="col-md-q mb-4"
                            value={selectedRow.pay_sequence}
                            options={[{id: 1, name: 'Current'}, {id: 2, name: 'Previous'}]}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelect
                            id="ec_id"
                            label="EC"
                            className="col-md-q mb-4"
                            value={selectedRow.ec_id}
                            options={ecOptions}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputDate
                            id="work_date"
                            label="Work Date"
                            className="col-md-q mb-4"
                            value={selectedRow.work_date}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelect
                            id="payroll_date"
                            label="Payroll Date"
                            className="col-md-q mb-4"
                            value={selectedRow.payroll_date}
                            options={payrollDates}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row">
                        <FormSelect
                            id="job_id"
                            label="Job"
                            className="col-md-q mb-4"
                            value={selectedRow.job_id}
                            options={jobs}
                            register={register}
                            errors={errors}
                        />
                        <FormSelect
                            id="state_id"
                            label="State"
                            className="col-md-q mb-4"
                            value={selectedRow.state_id}
                            options={states}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputNumber
                            label="Hours"
                            id="hours"
                            className="hours-column-width"
                            value={selectedRow.hours}
                            register={register({
                                required: {value: true, message: 'required'},
                                min: {value: 0, message: "min value is 0"},
                                max: {value: 24.01, message: "max value is 24.00"}
                            })}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row">
                        <FormInputText
                            id="equipment_number"
                            label="Equipment #"
                            className="col-md-q mb-4"
                            value={selectedRow.equipment_number}
                            register={register}
                            errors={errors}
                        />
                        <FormInputNumber
                            id="per_diem"
                            label="Per Diem"
                            className="col-md-q mb-4"
                            value={selectedRow.per_diem}
                            register={register({
                                    min: {value: 0, message: "Per Diem min value is 0"},
                                    max: {value: 99999999.99, message: "Per Diem max value is 99999999.99"}
                                }
                            )}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default React.memo(DirectEntriesEditFormModal);
