import React from "react";
import {FaEye, FaEyeSlash, FaFile, FaPencilAlt} from "react-icons/fa";

const JobsTable = (
    setSelectedRow, setApproveModal, setDisapproveModal, setSelectedFilesRow, setFormModalFiles
) => {
    return [
        {
            Header: 'Job #',
            accessor: 'job_number',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <a
                        href={`/commercial/job/edit_job?id=${row.id}`}
                        className={'font-weight-bold'}
                        rel="noopener noreferrer"
                        target="_blank">
                        {cell.value}
                    </a>
                );
            }
        },
        {
            Header: 'Client',
            accessor: 'clients.client_name',
        },
        {
            Header: 'Job Name',
            accessor: 'job_name'
        },
        {
            Header: 'Scope Of Work',
            accessor: 'scope_of_work',
        },
        {
            Header: 'MSA',
            Cell: (cell) => {
                const row = cell.row.original;
                return (row.service_agreement) ? row.service_agreement : '';
            }
        },
        {
            Header: 'Start Date',
            accessor: 'job_start_date',
        },
        {
            Header: 'End Date',
            accessor: 'projected_close_date',
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        {(row.status === 1) &&
                            <>
                                <a
                                    href={`/commercial/job/edit_job?id=${row.id}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <FaPencilAlt
                                        color='#A51E37'
                                        title="Edit Job"
                                    />
                                </a>
                                {' '}
                                <FaEyeSlash
                                    className="actions"
                                    title="Deactivate Job"
                                    onClick={() => {
                                        const msg = <p>Are you sure you want to deactivate <b>{row.job_number}</b>?</p>;
                                        setSelectedRow(row);
                                        setDisapproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                            </>
                        }
                        {(row.status === 0) &&
                            <>
                                <FaEye
                                    className="actions"
                                    title="Reactivate Job"
                                    onClick={() => {
                                        const msg = <p>Are you sure you want to reactivate <b>{row.job_number}</b>?</p>;
                                        setSelectedRow(row);
                                        setApproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                            </>
                        }
                        <FaFile
                            className="actions ml-1"
                            title="Files"
                            onClick={() => {
                                setSelectedFilesRow(row);
                                setFormModalFiles({isOpen: true});
                            }}
                        />
                    </>
                );
            }
        }
    ];
}

export default JobsTable;



