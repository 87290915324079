import React from "react";
import {Link} from 'react-router-dom';
import {isUndefined} from 'lodash';

/**
 * return table columns definition
 * @param title
 * @param reportStartDate
 * @param reportEndDate
 * @param periodId
 * @returns json
 */
export const getTableColumns = (title, reportStartDate, reportEndDate, periodId) => {
    return [
        {
            Header: title,
            columns: [
                {
                    Header: 'Rig',
                    accessor: 'rig_name',
                },
                {
                    Header: 'Description',
                    accessor: 'rig_description',
                },
                {
                    Header: 'Job',
                    accessor: 'job',
                    Cell: (cell) => {
                        const jobId = cell.row.original.job_id;
        
                        if (isUndefined(jobId)) {
                            return null;
                        }
        
                        return (
                            <Link
                                to={`/reports/revenue_forecast/revenue_job_cost_tracking/${reportStartDate}/${reportEndDate}/${jobId}`}
                                target='_blank'
                            >
                                {cell.value}
                            </Link>
                        );
                    },
                },
                {
                    Header: 'Client',
                    accessor: 'client',
                    Cell: (cell) => {
                        const jobId = cell.row.original.job_id;
                        if (isUndefined(jobId)) {
                            return null;
                        }
                        return (
                            <Link

                                to={`/commercial/job/edit_job?id=${jobId}&mode=view`}
                                target="_blank"
                            >
                                {cell.value}
                            </Link>
                        )
                    }
                },
            ],
        },
        {
            Header: 'Shift Stats',
            columns: [
                {
                    Header: 'Driller',
                    accessor: 'user',
                },
                {
                    Header: 'Shift',
                    accessor: 'shift',
                },
                {
                    Header: 'Total Shifts',
                    accessor: 'shifts',
                },
                {
                    Header: 'Shift Hrs',
                    accessor: 'shift_hrs',
                    Cell: (cell) => {
                        const rigId = cell.row.original.rig_id;
                        const jobId = cell.row.original.job_id;

                        if (isUndefined(rigId) && isUndefined(jobId)) {
                            return cell.value;
                        }
                        const {id, type} = periodId;
                        return (
                            <Link
                                to={`/reports/employee/shift_report/${reportStartDate}/${reportEndDate}/${id}/${type}`}
                                target="_blank"
                            >
                                {cell.value}
                            </Link>
                        )
                    }
                },
                // temporary disable per manager's request
                // {
                //     Header: 'Billable Hrs',
                //     accessor: 'billable_hrs',
                // },
                {
                    Header: 'Billed/Unbilled',
                    accessor: 'billed_vs_unbilled',
                    Cell: (cell) => {
                        const isMarked = cell.row.original.billed_vs_unbilled_is_marked;
                        const rigId = cell.row.original.rig_id;
                        const jobId = cell.row.original.job_id;
                        const shiftHrs = cell.row.original.shift_hrs;

                        if (isUndefined(rigId) && isUndefined(jobId) ) {
                            return cell.value;
                        }

                        return (
                            isMarked && cell.value ? (
                                <>
                                    <Link
                                        to={`/reports/revenue_forecast/rig_activities/${reportStartDate}/${reportEndDate}/${rigId}/${jobId}/${shiftHrs}`}
                                        target="_blank"
                                        className="toolbox-red__link position-relative text-white"
                                    >
                                        {cell.value}
                                    </Link>
                                    <div className="toolbox-red__layer"></div>
                                </>
                            ) : (
                                <Link
                                    to={`/reports/revenue_forecast/rig_activities/${reportStartDate}/${reportEndDate}/${rigId}/${jobId}/${shiftHrs}`}
                                    target="_blank"
                                >
                                    {cell.value}
                                </Link>
                            )
                            
                        )
                    }
                },
            ]
        },
        {
            Header: 'Revenue',
            columns: [
                // disabled for now, don't delete
                // {
                //     Header: 'Total Revenue',
                //     accessor: 'revenue_total',
                //     Cell: (cell) => {
                //         const jobId = cell.row.original.job_id;
                //
                //         if (isUndefined(jobId)) {
                //             return cell.value;
                //         }
                //         return (
                //             <Link
                //
                //                 to={`/reports/revenue_forecast/revenue_job_cost_tracking/${reportStartDate}/${reportEndDate}/${jobId}`}
                //                 target="_blank"
                //             >
                //                 {cell.value}
                //             </Link>
                //         )
                //     }
                // },
                // {
                //     Header: 'Shift Avg',
                //     accessor: 'shift_avg',
                //     Cell: (cell) => {
                //         const isMarked = cell.row.original.shift_avg_is_marked;
                //         const jobId = cell.row.original.job_id;
                //         const {id, type} = periodId;
                //
                //         if (isUndefined(jobId)) {
                //             return cell.value;
                //         }
                //
                //         return isMarked && cell.value ? (
                //             <>
                //                 <Link
                //                     to={`/reports/revenue_forecast/revenue_weekly_tracking/${jobId}/${id}/${type}`}
                //                     target="_blank"
                //                     className="toolbox-red__link position-relative text-white"
                //                 >
                //                     {cell.value}
                //                 </Link>
                //                 <div className="toolbox-red__layer"/>
                //             </>
                //         ) : (
                //             <Link
                //                 to={`/reports/revenue_forecast/revenue_weekly_tracking/${jobId}/${id}/${type}`}
                //                 target="_blank"
                //             >
                //                 {cell.value}
                //             </Link>
                //         )
                //     }
                // },
            ]
        },
        {
            Header: 'Production',
            columns: [
                {
                    Header: 'Footage',
                    accessor: 'depth_total',
                },
                {
                    Header: '% Drilling',
                    accessor: 'drill_percentage',
                    Cell: (cell) => {
                        const isMarked = cell.row.original.drill_percentage_is_marked;
                        
                        return isMarked && cell.value ? (
                            <>
                                <div className="toolbox-red__link position-relative text-white">{cell.value}</div>
                                <div className="toolbox-red__layer"/>
                            </>
                        ) : <>{cell.value}</>
                    }
                },
                {
                    Header: 'Drill Hrs',
                    accessor: 'drill_hrs',
                },
                {
                    Header: 'FT/shift',
                    accessor: 'ft_shift',
                    Cell: (cell) => {
                        const isMarked = cell.row.original.ft_shift_is_marked;
                        
                        return isMarked && cell.value ? (
                            <>
                                <div className="toolbox-red__link position-relative text-white">{cell.value}</div>
                                <div className="toolbox-red__layer"></div>
                            </>
                        ) : <>{cell.value}</>
                    }
                },
            ]
        },
        {
            Header: 'Equipment',
            columns: [
                {
                    Header: 'R&M',
                    accessor: 'r_m',
                },
                {
                    Header: 'Up',
                    accessor: 'uptime',
                    Cell: (cell) => {
                        const jobId = cell.row.original.job_id;

                        if (isUndefined(jobId)) {
                            return null;
                        }
                        return (
                            <Link
                                to={`/reports/revenue_forecast/downtime/${reportStartDate}/${reportEndDate}/${jobId}`}
                                target="_blank"
                            >
                                {cell.value}
                            </Link>
                        )
                    }
                },
            ]
        }
    ];
}
