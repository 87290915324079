import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import apiClient from "../../../utils/apiClient";
import {useForm} from "react-hook-form";
import {replacePartialObjFromArray} from "../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormInputTextArea from "../../forms/form-control/FormInputTextArea";

/**
 * Modal form to create and edit a record
 * @returns {JSX.Element}
 * @constructor
 */
const ClientDailiesFormModal = (props) => {
    const {modal, setModal, title, selectedRow, tableData, setTableData} = props;
    const {register, handleSubmit, errors} = useForm();
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    const onSubmit = (data) => {
        apiClient
            .post('addClientNotes/' + selectedRow.daily_id, data)
            .then(response => {
                setTableData(
                    replacePartialObjFromArray(
                        tableData, "daily_id", response.data.id, {client_notes: response.data.record.client_notes}
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} size={'md'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputTextArea
                            id="client_notes"
                            rows={3}
                            className="col-md-12 mb-12"
                            value={selectedRow.client_notes}
                            register={register({required: false})}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default React.memo(ClientDailiesFormModal);
