import React, {useState, useEffect} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import {isEmpty} from "lodash"
import TableReport from "../../../common/table/table";
import Table from "./jobsToBypassTable";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";
import JobsToBypassCreateModal from "./jobsToBypassCreateModal";

const JobsToBypass = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [formModal, setFormModal] = useState({isOpen: false, action: 'create'});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    // delete function
    const deleteJob = () => {
        console.log(selectedRow)

        apiClient
            .post('deleteJobToBypass', {id: selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    useEffect(() => {
        apiClient
            .get('jobsToBypass')
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }

                setTableData(response.data);
            });
    }, [setTableData]);

    return (
        <>
            <Breadcrumb title="Jobs To Bypass" parent="Admin"/>
            <div className="container-fluid">
                <TableReport
                    columns={Table(setSelectedRow, setFormModal, setDeleteModal)}
                    data={tableData}
                    getExportFileName={() => 'jobs_to_bypass'}
                    exportOptions={{pdf: false, xlsx: true, csv: true}}
                    withGlobalSearch={true}
                    scrollable={true}
                />
                <JobsToBypassCreateModal
                    title="Add Job"
                    modal={formModal}
                    setModal={setFormModal}
                    tableData={tableData}
                    setTableData={setTableData}
                />
                <ModalConfirmation
                    modal={deleteModal}
                    setModal={setDeleteModal}
                    title="Delete Job"
                    onConfirm={deleteJob}
                />
            </div>
        </>
    );
}

export default JobsToBypass;
