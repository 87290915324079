import React from 'react';
import ReactSelect from 'react-select'
import {Controller} from "react-hook-form";
import {get, isFunction, isNil} from "lodash";

/**
 * Searchable Dropdown
 * ----
 * Options: Array of options that populate the select menu
 * options = [
 *              { value: 'chocolate', label: 'Chocolate' },
 *              { value: 'strawberry', label: 'Strawberry' },
 *              { value: 'vanilla', label: 'Vanilla' }
 *            ]
 * -----
 * rules: Validation rules in the same format as for register.
 * rules={{ required: "State is required" }}
 * -----
 * control:(required) control object is from invoking useForm
 * -----
 * onChange: function that receive the selected value or null
 *   const onChange = (selectedOption) => {
 *       console.log(`Option selected:`, selectedOption);
 *   };
 *
 *   if you want to pass extract parameters to onChange,
 *   you can use the followed definition
 *   onChange={ (v) => otherFunction(v, `param1`, v, param2, ..)}
 * -----
 * value: number that represent the pre-selected value
 *    value={1}
 * -----
 * customStyles:
 * see https://react-select.com/styles#styles
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SearchableSelectInTable = (props) => {
    const {id, options, rules, errors, control, onChange, customStyles, value} = props;
    let defaultValue = null;

    // set default value
    if (value) {
        defaultValue = options?.find(item => item.value === value);
    }   
    
    return (
        <td>
            <Controller
                name={id}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={ props =>
                    <ReactSelect
                        name={props.name}
                        options={options}
                        value={props.value}
                        styles={customStyles}
                        onChange={(e) => {
                            props.onChange(e);
                            if (isFunction(onChange)) {
                                if (isNil(e)) {
                                    onChange(e);
                                } else {
                                    onChange(e.value, e.label);
                                }
                            }
                        }}
                    />
                }
            />
            {errors && <span className="font-danger">{ get(errors, id)?.message }</span>}
        </td>
    );
}

export default React.memo(SearchableSelectInTable);