import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import DirectEntriesBody from "./directEntriesBody";
import DirectEntriesEditFormModal from "./directEntriesEditFormModal";
import LoadingIndicator from "../../common/loadingIndicator";
import PayrollDateRangeFilter from "../../common/form-filters/PayrollDateRangeFilter";
import apiClient from "../../../utils/apiClient";

/**
 * DirectEntries Component
 * @returns {JSX.Element}
 * @constructor
 */
const DirectEntries = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [editModal, setEditModal] = useState(false);
    const [payrollDates, setPayrollDates] = useState([]);
    const [filters, setFilters] = useState({
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    // get list of options
    useEffect(() => {
        //get payroll dates
        apiClient
            .get('getPayrollDates')
            .then(response => {
                setPayrollDates(
                    response.data.map(
                        ({date, is_current}) => ({
                            id: date, name: date + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });
    }, []);

    return (
        <>
            <Breadcrumb title="Direct Entries" parent="Time Entry"/>
            <PayrollDateRangeFilter {...{setFilters, setTableData, OnSubmitUrl:"getDirectEntries"}}/>
            <DirectEntriesBody
                filters={filters}
                tableData={tableData}
                setTableData={setTableData}
                setEditModal={setEditModal}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />
            <DirectEntriesEditFormModal
                title="Edit Direct Entry"
                modal={editModal}
                setModal={setEditModal}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                payrollDates={payrollDates}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default DirectEntries;
