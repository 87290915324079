import { useEffect, useState } from "react";
import apiClient from "../../../../utils/apiClient";
import Breadcrumb from "../../../common/breadcrumb";
import getTableColumns from "./rig_activities_column.js";
// import { RigActivitiesField } from "./rig_activities_field";
import TableReport from "../../../common/table/table";
import PieChart from "../../../charts/PieChart";
import {RigActivitiesTableStyle} from "./rig_activities_tableStyle";
// import moment from "moment";

const RigActivities = (props) => {
    const { rigId, jobId, date_from, date_to, shiftHrs } = props.match.params;
    const [rigActivitiesData, setRigActivitiesData] = useState(null);

    useEffect(() => {
        apiClient
            .post(
                "getRigActivities",
                { rig_id: rigId, job_id: jobId, date_from: date_from, date_to: date_to, shift_hrs: shiftHrs }
            ).then((res) => {
                setRigActivitiesData(res.data);
            });
    }, [rigId, jobId, date_from, date_to, shiftHrs]);

    return (
        <>
            <Breadcrumb title="Rig Activities" parent="Reports" />
            {/*temporary disabled */}
            {/*<h2 className="text-center">Rig {rigActivitiesData?.rig_name} - {moment(date_from).format('MMMM')}</h2>*/}
            <h2 className="text-center">Rig {rigActivitiesData?.rig_name}</h2>
            {/*<div className="row mt-5">*/}
            {/*    <RigActivitiesField*/}
            {/*        data={rigActivitiesData?.rig_activities_month}*/}
            {/*        title="Current Month"*/}
            {/*    />*/}
            {/*    <RigActivitiesField*/}
            {/*        data={rigActivitiesData?.rig_activities_quarter}*/}
            {/*        title="Previous 3 Mo."*/}
            {/*    />*/}
            {/*    <RigActivitiesField*/}
            {/*        data={rigActivitiesData?.rig_activities_year}*/}
            {/*        title="12 Mo."*/}
            {/*    />*/}
            {/*</div>*/}
            {rigActivitiesData?.rig_activities_unbilled ? (
                <div className="mt-5">
                    <h4 className="text-center">Unbilled Activities</h4>
                    <RigActivitiesTableStyle>
                        <TableReport
                            columns={getTableColumns()}
                            data={rigActivitiesData?.rig_activities_unbilled}
                        />
                    </RigActivitiesTableStyle>
                </div>
            ) : null}
            {rigActivitiesData?.rig_activities_chart ? (
                <div className="mt-5" style={{height: '300px'}}>
                    <PieChart
                        title="Billed/Unbilled Shift Hours %"
                        labels={rigActivitiesData?.rig_activities_chart?.labels}
                        values={rigActivitiesData?.rig_activities_chart?.data}
                    />
                </div>
            ) : null}
        </>
    );
}

export default RigActivities;
