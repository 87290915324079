import React, {useEffect, useState} from 'react';
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import apiClient from "../../../../utils/apiClient";
import {useForm} from "react-hook-form";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";

/**
 * Filter component for Daily Discipline Revenue Detail
 * @param prop
 * @returns {JSX.Element}
 * @constructor
 */
const ReportFilters = (prop) => {
    const {register, handleSubmit, errors, setValue} = useForm();
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState({});
    const {
        setReportFilters,
        setTableDataDiscipline1,
        setTableDataDiscipline2,
        setTableDataDiscipline3,
        setTableDataDiscipline4,
        setTableTotalData
    } = prop;

    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient
            .post('getDailyDisciplineRevenueDetail', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableDataDiscipline1([]);
                    setTableDataDiscipline2([]);
                    setTableDataDiscipline3([]);
                    setTableDataDiscipline4([]);
                    return;
                }
                setTableDataDiscipline1(response.data.surface_core);
                setTableDataDiscipline2(response.data.underground_core);
                setTableDataDiscipline3(response.data.rcx);
                setTableDataDiscipline4(response.data.rotary);
                setTableTotalData(response.data.total);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    }

    // get list of financial periods
    useEffect(() => {
        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => {
                            if (is_current) {
                                setCurrentPeriod({id: id, name: name + ' (Current)'})
                            }
                            return {
                                id: id, name: name + (is_current ? ' (Current)' : '')
                            }
                        }
                    )
                );
            });
    }, []);

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            setValue('financial_period_id', currentPeriod.id);
        }, 200);
    }, [currentPeriod, setValue]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    id="financial_period_id"
                    label="Financial Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={financialPeriods}
                    register={register({required: "Financial Period is required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default ReportFilters;
