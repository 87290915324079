export const employeeRegisterTableColumns = [
    {
        Header: 'Name',
        accessor: 'user_name',
    },
    {
        Header: 'ViewPoint Id',
        accessor: 'employee_id',
    },
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'State',
        accessor: 'state',
    },
    {
        Header: 'Rig',
        accessor: 'rig_name',
    },
    {
        Header: 'Shift',
        accessor: 'daily_shift',
    },
    {
        Header: 'Job',
        accessor: 'job_number',
    },
    {
        Header: 'Location',
        accessor: 'location',
    },
    {
        Header: 'Hours',
        accessor: 'hours',
    },
    {
        Header: 'Travel Hours',
        accessor: 'travel_hours',
    },
    {
        Header: 'Total',
        accessor: 'total',
    },
    {
        Header: 'Per Diem',
        accessor: 'per_diem',
    },
];



