import React from 'react';
import TableReport from "../../../common/table/table";
import {FinancialDashboardTargetTableStyle, FinancialDashboardWeekTableStyle} from "./financialDashboardTableStyle";
import {financialDashboardTableColumns} from "./financialDashboardTableColumns.js";
import PercentageChart from "../../../charts/PercentageChart";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from "lodash";

/**
 * Body report component for Financial Dashboard report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const FinancialDashboardBody = (prop) => {
    const percentageChartLabel = ['% To Target', ''];
    const {
        reportFilters,
        tableTargetData,
        tableWeekData,
        PercentageChartDataDiscipline1,
        PercentageChartDataDiscipline2,
        PercentageChartDataDiscipline3,
        PercentageChartDataDiscipline4,
        PercentageChartDataTotal,
    } = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableTargetData) && isEmpty(tableWeekData)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <div className="row mt-3">
                <div className="col-sm-12">
                    <FinancialDashboardTargetTableStyle>
                        <TableReport
                            columns={financialDashboardTableColumns}
                            data={tableTargetData}
                            exportOptions={{pdf: true, xlsx: true, csv: false}}
                            getExportFileName={() => 'financial_dashboard_target'}
                        />
                    </FinancialDashboardTargetTableStyle>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-12">
                    <FinancialDashboardWeekTableStyle>
                        <TableReport
                            columns={financialDashboardTableColumns}
                            data={tableWeekData}
                            exportOptions={{pdf: true, xlsx: true, csv: false}}
                            getExportFileName={() => 'financial_dashboard_week'}
                        />
                    </FinancialDashboardWeekTableStyle>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-2">
                    <PercentageChart
                        title='Surface Core'
                        labels={percentageChartLabel}
                        value={PercentageChartDataDiscipline1}
                    />
                </div>
                <div className="col-sm-3">
                    <PercentageChart
                        title='Underground Core'
                        labels={percentageChartLabel}
                        value={PercentageChartDataDiscipline2}
                    />
                </div>
                <div className="col-sm-2">
                    <PercentageChart
                        title='RCX'
                        labels={percentageChartLabel}
                        value={PercentageChartDataDiscipline3}
                    />
                </div>
                <div className="col-sm-2">
                    <PercentageChart
                        title='Rotary'
                        labels={percentageChartLabel}
                        value={PercentageChartDataDiscipline4}
                    />
                </div>
                <div className="col-sm-2">
                    <PercentageChart
                        title='Total'
                        labels={percentageChartLabel}
                        value={PercentageChartDataTotal}
                    />
                </div>
            </div>
        </div>
    );
}

export default FinancialDashboardBody;
