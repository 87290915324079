import React from 'react';
import { get } from 'lodash';
/**
 * Select
 * options accept array of object with below parameters
 *  ex:
 *   - [{id: "", name: "" },...],
 *   - [{value: "", label:""},...],
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Select = (props) => {

    const {id, label, name, value, options, className, onChange, errors, register, disabled = 'false'} = props;

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <select
                id={id}
                name={name}
                className="form-control"
                defaultValue={value || ''}
                onChange={onChange}
                ref={register}
                disabled={disabled === 'true' ? disabled : ''}
            >
                <option value=''>- Select -</option>
                {
                    options.length > 0
                        ?
                        options.map((item, i) =>
                            <option
                                key={i}
                                value={item.id || item.value}
                            >
                                {item.name || item.label}
                            </option>
                        )
                        :
                        ''
                }
            </select>
            {errors && <span>{ get(errors, id)?.message }</span>}
        </div>
    );
}

export default React.memo(Select);
