import React, { useMemo } from 'react';
import TableReport from "../../../common/table/table";
import {WeeklyProductionTableStyle} from "./weeklyProductionTableStyle.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from "lodash";
import LineChart from "../../../charts/LineChart";
import { formatNumberToDisplayWithCommas } from '../../../common/shared.js';

function renderTableCell(cell) {
    if (cell.cell.row.original.label === 'Footage Drilled' && cell.column.Header !== '') {
        return formatNumberToDisplayWithCommas(cell.value);
    }
    return String(cell.value);
}

/**
 * Body report component for Weekly Production Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const WeeklyProductionBody = (prop) => {
    const {
        reportFilters,
        tableDataDiscipline1,
        tableDataDiscipline2,
        tableDataDiscipline3,
        tableDataDiscipline4,
    } = prop;

    const tableDataDiscipline1Header = useMemo(() => tableDataDiscipline1?.tableHeader?.map(header => ({
        ...header,
        Cell: renderTableCell
    })), [tableDataDiscipline1]);

    const tableDataDiscipline2Header = useMemo(() => tableDataDiscipline2?.tableHeader?.map(header => ({
        ...header,
        Cell: renderTableCell
    })), [tableDataDiscipline2]);

    const tableDataDiscipline3Header = useMemo(() => tableDataDiscipline3?.tableHeader?.map(header => ({
        ...header,
        Cell: renderTableCell
    })), [tableDataDiscipline3]);


    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableDataDiscipline1) || isEmpty(tableDataDiscipline2) ||
        isEmpty(tableDataDiscipline3) || isEmpty(tableDataDiscipline4)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <div className="row mt-5">
                <div className="col">
                    <h4 class="text-uppercase font-weight-bolder">Surface Core</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <WeeklyProductionTableStyle>
                        <TableReport
                            columns={tableDataDiscipline1Header}
                            data={tableDataDiscipline1.tableRows}
                            exportOptions={{pdf: true, xlsx: true, csv: true}}
                            getExportFileName={() => 'weekly_production_surface_core'}
                        />
                    </WeeklyProductionTableStyle>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <LineChart
                        title='Drill %'
                        labels={tableDataDiscipline1.datasets.labels}
                        dataset={tableDataDiscipline1.datasets.chart1}
                    />
                </div>
                <div className="col-sm-3">
                    <LineChart
                        title='Footage Drilled'
                        labels={tableDataDiscipline1.datasets.labels}
                        dataset={tableDataDiscipline1.datasets.chart2}
                    />
                </div>
                <div className="col-sm-3">
                    <LineChart
                        title='Avg Ft/Drill Hour'
                        labels={tableDataDiscipline1.datasets.labels}
                        dataset={tableDataDiscipline1.datasets.chart3}
                    />
                </div>
                <div className="col-sm-3">
                    <LineChart
                        title='Avg Ft/Shift Hour'
                        labels={tableDataDiscipline1.datasets.labels}
                        dataset={tableDataDiscipline1.datasets.chart4}
                    />
                </div>
            </div>
            <div className="row mt-lg-5">
                <div className="col">
                    <h4 className="text-uppercase font-weight-bolder">Underground Core</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <WeeklyProductionTableStyle>
                        <TableReport
                            columns={tableDataDiscipline2Header}
                            data={tableDataDiscipline2.tableRows}
                            exportOptions={{pdf: true, xlsx: true, csv: true}}
                            getExportFileName={() => 'weekly_production_underground_core'}
                        />
                    </WeeklyProductionTableStyle>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <LineChart
                        title='Drill %'
                        labels={tableDataDiscipline2.datasets.labels}
                        dataset={tableDataDiscipline2.datasets.chart1}
                    />
                </div>
                <div className="col-sm-3">
                    <LineChart
                        title='Footage Drilled'
                        labels={tableDataDiscipline2.datasets.labels}
                        dataset={tableDataDiscipline2.datasets.chart2}
                    />
                </div>
                <div className="col-sm-3">
                    <LineChart
                        title='Avg Ft/Drill Hour'
                        labels={tableDataDiscipline2.datasets.labels}
                        dataset={tableDataDiscipline2.datasets.chart3}
                    />
                </div>
                <div className="col-sm-3">
                    <LineChart
                        title='Avg Ft/Shift Hour'
                        labels={tableDataDiscipline2.datasets.labels}
                        dataset={tableDataDiscipline2.datasets.chart4}
                    />
                </div>
            </div>
            <div className="row mt-lg-5">
                <div className="col">
                    <h4 className="text-uppercase font-weight-bolder">RCX</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <WeeklyProductionTableStyle>
                        <TableReport
                            columns={tableDataDiscipline3Header}
                            data={tableDataDiscipline3.tableRows}
                            exportOptions={{pdf: true, xlsx: true, csv: true}}
                            getExportFileName={() => 'weekly_production_rcx'}
                        />
                    </WeeklyProductionTableStyle>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <LineChart
                        title='Drill %'
                        labels={tableDataDiscipline3.datasets.labels}
                        dataset={tableDataDiscipline3.datasets.chart1}
                    />
                </div>
                <div className="col-sm-3">
                    <LineChart
                        title='Footage Drilled'
                        labels={tableDataDiscipline3.datasets.labels}
                        dataset={tableDataDiscipline3.datasets.chart2}
                    />
                </div>
                <div className="col-sm-3">
                    <LineChart
                        title='Avg Ft/Drill Hour'
                        labels={tableDataDiscipline3.datasets.labels}
                        dataset={tableDataDiscipline3.datasets.chart3}
                    />
                </div>
                <div className="col-sm-3">
                    <LineChart
                        title='Avg Ft/Shift Hour'
                        labels={tableDataDiscipline3.datasets.labels}
                        dataset={tableDataDiscipline3.datasets.chart4}
                    />
                </div>
            </div>
            <div className="row mt-lg-5">
                <div className="col">
                    <h4 className="text-uppercase font-weight-bolder">Rotary</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <WeeklyProductionTableStyle>
                        <TableReport
                            columns={tableDataDiscipline4.tableHeader}
                            data={tableDataDiscipline4.tableRows}
                            exportOptions={{pdf: true, xlsx: true, csv: true}}
                            getExportFileName={() => 'weekly_production_rotary'}
                        />
                    </WeeklyProductionTableStyle>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <LineChart
                        title='Billed %'
                        labels={tableDataDiscipline4.datasets.labels}
                        dataset={tableDataDiscipline4.datasets.chart1}
                    />
                </div>
                <div className="col-sm-3">
                    <LineChart
                        title='Avg Up%'
                        labels={tableDataDiscipline4.datasets.labels}
                        dataset={tableDataDiscipline4.datasets.chart2}
                    />
                </div>
            </div>
        </div>
    );
}

export default WeeklyProductionBody;
