import React, {useEffect, useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import apiClient from "../../../../utils/apiClient";
import {useForm} from "react-hook-form";
import {addObjToArray} from "../../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormSearchableSelect from "../../../forms/form-control/FormSearchableSelect";

/**
 * Modal form to create a record
 * @returns {JSX.Element}
 * @constructor
 */
const AllocateJobsCreateModal = (props) => {
    const {modal, setModal, title, tableData, setTableData, filters} = props;
    const {control, handleSubmit, errors} = useForm();
    const [jobs, setJobs] = useState([]);
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    useEffect(() => {
        if (filters.client_id) {
            apiClient
                .post('getActiveJobsByClientId', {client_id: filters.client_id})
                .then(response => {
                    setJobs(
                        response.data.jobs.map(
                            ({id, job_number}) => ({value: id, label: job_number})
                        )
                    );
                });
        }
    }, [filters]);

    const onSubmit = (data) => {
        console.log(filters);
        apiClient
            .post('AllocateJobs', {
                'job_id': data.job_id.value,
                'user_id': Number(filters.user_id),
                'client_id': Number(filters.client_id)
            })
            .then(response => {
                let objectString = {
                    'id': response.data.record.id,
                    'job_number': response.data.record.job.job_number
                };
                setTableData(addObjToArray(tableData, objectString));
                toast.success(response.data.message);
                toggle();
            });
    }

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} size={'md'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{`${title}`}</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormSearchableSelect
                            id="job_id"
                            options={jobs}
                            className="col-md-8"
                            rules={{required: "required"}}
                            control={control}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default React.memo(AllocateJobsCreateModal);
