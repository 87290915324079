import {FaTrash} from "react-icons/fa";
import React from 'react';

export const POItemsTable = (setDeleteModal, setSelectedRow) => {
    return [
        {
            Header: 'PO #',
            accessor: 'po_number',
            width: 70,
        },
        {
            Header: 'Line',
            accessor: 'line',
            width: 50,
        },
        {
            Header: 'Vendor ID',
            accessor: 'vendor_id',
            width: 90,
        },
        {
            Header: 'Name',
            accessor: 'vendor_name',
        },
        {
            Header: 'Job',
            accessor: 'job_number',
            width: 90,
        },
        {
            Header: 'Job Description',
            accessor: 'job_description',
        },
        {
            Header: 'Ordered By',
            accessor: 'ordered_by',
            width: 90,
        },
        {
            Header: 'Order Date',
            accessor: 'order_date',
            width: 90,
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Price',
            accessor: 'price',
            width: 100,
        },
        {
            Header: 'Charged',
            accessor: 'is_charged',
            width: 90,
            Cell: (cell) => {
                const row = cell.row.original;

                if (typeof row.is_charged !== 'undefined') {
                    switch (row.is_charged) {
                        case 1:
                            return 'Yes';
                        case 0:
                            return 'No';
                        default:
                            return null;
                    }
                } else {
                    return null;
                }
            }
        },
        {
            Header: 'Daily',
            accessor: 'daily_id',
            width: 100,
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaTrash
                            title="Delete PO Items"
                            color='#A51E37'
                            size={20}
                            onClick={() => {
                                const details = row.description;
                                const msg = 'Are you sure that you want to delete PO Item : ' + details;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                );
            }
        }
    ];
}