import { createContext } from "react";

export const AuthActions = {
    setUser: "SET_USER",
    clearUser: "CLEAR_USER",
    getUser: "GET_USER",
    setUserInfo: "SET_USER_INFO"
};

export function saveState(state) {
    localStorage.setItem("token", state.token ? state.token : "");
}

function loadState() {
    const token = localStorage.getItem("token");
    if (!token) {
        return {token: '', user: null};
    }

    return {token};
}

const savedState = loadState();
export const initialState = {
    state: savedState ?? {
        user: null,
        user_has_default_password: false,
        token: ''
    },
    dispatch: (action) => {},
};

export const UserContext = createContext(initialState);

export function reducer(state, action) {
    switch (action.type) {
        case AuthActions.setUser:
            return {
                ...state,
                ...action.payload,
            };
        case AuthActions.clearUser:
            return {
                ...state,
                token: null,
            };
        case AuthActions.getUser:
            return {
                ...state,
            };
        case AuthActions.setUserInfo:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
