import React from "react";
import apiClient from "../../../utils/apiClient";
import {updateStateValue} from "../../../utils/stateHelpers";
import {FaCheck, FaCheckSquare, FaRegFilePdf, FaRegFileAlt, FaFileCsv} from "react-icons/fa";
import { formatNumberToDisplayWithCommas } from "../../../components/common/shared";

const ClientDailiesTable = (
    setFilters, setSelectedRow, setApproveModal, setDisapproveModal, setFormModal
) => {
    const exportToCSV = (row) => {
        if (row.daily_id) {
            //get csv export file and open it in a new window
            apiClient('getCSVExportByDailyId', {
                method: 'POST',
                responseType: 'blob', //Force to receive data in a Blob Format
                data: {
                    'daily_id': row.daily_id,
                    'hole': row.hole,
                    'runs': row.runs,
                    'core_retrieved': row.core_retrieved,
                }
            })
                .then(response => {
                    const blob = new Blob([response.data], {type: response.data.type});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'daily_' + row.date + '_' + row.shift + '.csv');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                })
                .catch(function () {
                })
                .then(function () {
                });
        }
    }

    return [
        {
            Header: 'Rig',
            accessor: 'rig',
            width: 60,
        },
        {
            Header: 'Shift',
            accessor: 'shift',
            width: 40,
        },
        {
            Header: 'Date',
            accessor: 'date',
            width: 90,
        },
        {
            Header: 'Job',
            accessor: 'job',
            width: 100,
        },
        {
            Header: 'Project',
            accessor: 'location',
        },
        {
            Header: 'Hole',
            accessor: 'hole',
        },
        {
            Header: 'From',
            accessor: 'depth_from',
        },
        {
            Header: 'To',
            accessor: 'depth_to',
        },
        {
            Header: 'Depth Total',
            accessor: 'depth_total',
            Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
        },
        {
            Header: 'Daily Total',
            accessor: 'revenue',
            Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
        },
        {
            Header: 'Shift Hours',
            accessor: 'hours',
        },
        {
            Header: 'Drilling Hours',
            accessor: 'drilling_hours',
        },
        {
            Header: 'Runs',
            accessor: 'runs',
        },
        {
            Header: 'Core Retrieved',
            accessor: 'core_retrieved',
        },
        {
            Header: 'Invoice #',
            accessor: 'invoice',
        },
        {
            Header: 'Approved By',
            accessor: 'client_approved_by',
        },
        {
            Header: 'Approval Date',
            accessor: 'client_approved_at',
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;

                return (
                    <>
                        {row.client_approved > 0 ?
                                <FaCheckSquare
                                    className="actions"
                                    title="Disapprove Daily"
                                    color='#22af47'
                                    onClick={() => {
                                        const details = row.date + ' ' + row.rig + ' ' + row.job;
                                        const msg = 'Are you sure that you want to disapprove daily : ' + details;
                                        setSelectedRow(row);
                                        setDisapproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                            :
                                <FaCheck
                                    className="actions"
                                    title="Approve Daily"
                                    onClick={() => {
                                        const details = row.date + ' ' + row.rig + ' ' + row.job;
                                        const msg = 'Are you sure that you want to approve daily : ' + details;
                                        setSelectedRow(row);
                                        setApproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                        }
                        {' '}
                        <FaRegFileAlt
                            className="actions"
                            title="Add Notes"
                            color={(row.client_notes) ? 'green' : null}
                            onClick={() => {
                                setSelectedRow(row);
                                setFormModal({isOpen: true, action: 'edit'});
                            }}
                        />
                        {' '}
                        <FaRegFilePdf
                            className="actions"
                            title="View in PDF"
                            onClick={() => {
                                updateStateValue(setFilters, 'isLoading', true);
                                //get pdf export file and open it in a new window
                                apiClient(`/pdfExportClientDailyDetailsById/${row.daily_id}`, {
                                    method: 'GET',
                                    responseType: 'blob' //Force to receive data in a Blob Format
                                })
                                    .then(response => {
                                        //Create a Blob from the PDF Stream
                                        const file = new Blob(
                                            [response.data],
                                            {type: 'application/pdf'});
                                        //Build a URL from the file
                                        const fileURL = URL.createObjectURL(file);
                                        //Open the URL on new Window
                                        window.open(fileURL);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    }).then(function () {
                                    updateStateValue(setFilters, 'isLoading', false);
                                });
                            }}
                        />
                        {' '}
                        <FaFileCsv
                            className="actions"
                            title="Export To CSV"
                            onClick={() => {
                                exportToCSV(row);
                            }}
                        />
                    </>
                );

            }
        }
    ];
}

export default ClientDailiesTable;

