import React, {useState} from 'react';
import Breadcrumb from "../../../common/breadcrumb";
import LoadingIndicator from "../../../common/loadingIndicator";
import RigStatusBody from "./rigStatusBody";
import DisciplineDateRangeFilter from "../../../common/form-filters/DisciplineDateRangeFilter";
import moment from "moment";

/**
 * RigStatus report Component
 * @returns {JSX.Element}
 * @constructor
 */
const RigStatus = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        discipline_id: 1,
        date_from: moment().subtract(7, "month").format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Rig Status" parent="Reports" />
            <DisciplineDateRangeFilter {...{filters, setFilters, setTableData, OnSubmitUrl:"getRigStatus"}}/>
            <RigStatusBody {...{filters, tableData}} />
            <LoadingIndicator isLoading={filters.isLoading} />
        </>
    );
}

export default RigStatus;
