import React from 'react';
import TableReport from "../../../common/table/table";
import {getTableColumns} from "./toolPusherToolboxColumns.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from 'lodash';
import {TableStyle} from "./tableStyle";

/**
 * Body component for ToolPusher Toolbox Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ToolPusherToolboxBody = (prop) => {
    const {
        reportFilters,
        tableDataDiscipline1,
        tableDataDiscipline2,
        tableDataDiscipline3,
        tableDataDiscipline4,
        reportStartDate,
        reportEndDate,
        periodId
    } = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableDataDiscipline1) &&
        isEmpty(tableDataDiscipline2) &&
        isEmpty(tableDataDiscipline3) &&
        isEmpty(tableDataDiscipline4)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">

            {tableDataDiscipline1.map((coreTable, index) => (
                <TableStyle key={index}>
                    <TableReport
                        columns={
                            coreTable.supervisor
                                ?
                                    getTableColumns(`Surface Core - ${coreTable.supervisor}`, reportStartDate, reportEndDate, periodId)
                                :
                                    getTableColumns('Surface Core', reportStartDate, reportEndDate, periodId)
                        }
                        data={coreTable.table}
                        withGlobalSearch={false}
                    />
                </TableStyle>
            ))}

            {tableDataDiscipline2.map((coreTable, index) => (
                <TableStyle key={index}>
                    <TableReport
                        columns={
                            coreTable.supervisor
                                ?
                                getTableColumns(`Underground Core - ${coreTable.supervisor}`, reportStartDate, reportEndDate, periodId)
                                :
                                getTableColumns('Underground Core', reportStartDate, reportEndDate, periodId)
                        }
                        data={coreTable.table}
                        withGlobalSearch={false}
                    />
                </TableStyle>
            ))}

            {tableDataDiscipline3.map((rotaryTable, index) => (
                <TableStyle key={index}>
                    <TableReport
                        columns={
                            rotaryTable.supervisor
                                ?
                                    getTableColumns(`RCX - ${rotaryTable.supervisor}`, reportStartDate, reportEndDate, periodId)
                                :
                                    getTableColumns('RCX', reportStartDate, reportEndDate, periodId)
                        }

                        data={rotaryTable.table}
                        withGlobalSearch={false}
                    />
                </TableStyle>
            ))}

            {tableDataDiscipline4.map((rotaryTable, index) => (
                <TableStyle key={index}>
                    <TableReport
                        columns={
                            rotaryTable.supervisor
                                ?
                                getTableColumns(`Rotary - ${rotaryTable.supervisor}`, reportStartDate, reportEndDate, periodId)
                                :
                                getTableColumns('Rotary', reportStartDate, reportEndDate, periodId)
                        }

                        data={rotaryTable.table}
                        withGlobalSearch={false}
                    />
                </TableStyle>
            ))}

        </div>
    );
}

export default ToolPusherToolboxBody;
