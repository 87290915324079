import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../utils/stateHelpers";
import {getCurrentPeriodFromArray} from "../../../utils/arrayHelpers"
import {isEmpty, isUndefined} from "lodash";

/**
 * FinancialPeriodFilter component
 * @returns {JSX.Element}
 * @constructor
 */
const FinancialPeriodFilter = (prop) => {
    const {setFilters, setTableData, OnSubmitUrl} = prop;
    const {register, handleSubmit, errors} = useForm();
    const [financialPeriods, setFinancialPeriods] = useState([]);

    // get list of pay periods
    useEffect(() => {
        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => ({
                            id: id, name: name + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });
    }, []);

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post(OnSubmitUrl, data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
                updateStateValue(setFilters,'isLoading', false);
            });
    }

    const defaultFinancialPeriod = getCurrentPeriodFromArray(financialPeriods);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row">
                <FormSelect
                    id="financial_period_id"
                    label="Monthly Data Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={(!isUndefined(defaultFinancialPeriod)) ? defaultFinancialPeriod?.id : 0}
                    options={financialPeriods}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default FinancialPeriodFilter;
