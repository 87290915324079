import React from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import {updateStateValue} from "../../../utils/stateHelpers";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";

/**
 * Status Form Filter Component
 * @returns {JSX.Element}
 * @constructor
 */
const JobsFilters = (prop) => {
    const {filters, setFilters, setTableData, url} = prop;
    const {register, handleSubmit, errors} = useForm();

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post(url, data)
            .then(response => {
                setTableData(response.data);
            })
            .catch(function () {})
            .then(function () {
                updateStateValue(setFilters,'isLoading', false);
            });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="status"
                    label="Status"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.status}
                    onChange={(e) => {updateStateValue(setFilters, e.target.id, e.target.value)}}
                    options={[{value: 1, label: "Active"}, {value: 0, label: "Inactive"}]}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default JobsFilters;
