import React from 'react';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import {Pie} from 'react-chartjs-2';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

/**
 * PercentageChart component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PercentageChart = (props) => {

    const {labels, value, title} = props;

    //Define datasets
    const dataSet = [value, (100 - value)];

    const data = {
        labels: labels,
        datasets: [
            {
                data: dataSet,
                backgroundColor: ['rgb(165, 30, 55)', 'gray'],
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                position: 'top',
                color: 'black',
                text: title,
                font: {
                    size: 20,
                    family: "work-Sans, sans-serif",
                }
            },
            tooltip: {
                callbacks: {
                  label: (Item) => Item.label + ': ' + (Item.formattedValue) + '%'
                }
            }
        }
    }

    return <Pie data={data} options={options}/>
}

export default PercentageChart
