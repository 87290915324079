import React from 'react';
import {get} from "lodash";

/**
 * Input for text
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InputInDiv = (props) => {
    const {
        id,
        label,
        value,
        type = "text",
        onKeyUp,
        onInput,
        className,
        disabled = 'false',
        errors,
        register
    } = props;

    return (
        <div className={className}>
            {label && <label htmlFor={id}>{label}</label>}
            <input
                id={id}
                name={id}
                className="form-control"
                type={type}
                readOnly={disabled === 'true' ? true : ''}
                defaultValue={value || ''}
                onKeyUp={onKeyUp}
                onInput={onInput}
                ref={register}
            />
            {errors && <span>{get(errors, id)?.message}</span>}
        </div>
    );
};

export default React.memo(InputInDiv);
