import React, {useState} from 'react';
import Breadcrumb from "../../../common/breadcrumb";
import LoadingIndicator from "../../../common/loadingIndicator";
import ClientReportRigStatusBody from "./clientReportRigStatusBody";
import ClientFormFilter from "../../../common/form-filters/ClientFormFilter";

/**
 * Client report Component
 * @returns {JSX.Element}
 * @constructor
 */
const ClientReportRigStatus = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Rig Status" parent="Reports" />
            <ClientFormFilter {...{setFilters, setTableData, onSubmitUrl:"getClientReportRigStatus"}}/>
            <ClientReportRigStatusBody {...{filters, tableData}} />
            <LoadingIndicator isLoading={filters.isLoading} />
        </>
    );
}

export default ClientReportRigStatus;