import React from 'react';
import {get, isNull, isUndefined} from 'lodash';
/**
 * Select
 * options accept array of object with below parameters
 *  ex:
 *   - [{id: "", name: "" },...],
 *   - [{value: "", label:""},...],
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Select = (props) => {
    const {id, label, name, value, options, onChange, errors, register, className,  disabled = ''} = props;

    return (
        <td>
            { label && <label htmlFor={id}>{label}</label>}
            <select
                id={id}
                name={name}
                className={`form-control ${className}`}
                defaultValue={(isUndefined(value) || isNull(value)) ? '' : value}
                onChange={onChange}
                ref={register}
                disabled={disabled === 'disabled' ? disabled : ''}
            >
                <option value=''>- Select -</option>
                {
                    options
                        ?
                            options.map((item, i) =>
                                <option
                                    key={i}
                                    value={item.id || item.value}
                                >
                                    {item.name || item.label}
                                </option>
                            )
                        :
                            ''
                }
            </select>
            {errors && <span>{get(errors, id)?.message}</span>}
        </td>
    );
}

export default React.memo(Select);
