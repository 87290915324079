import React from 'react';
import {get} from "lodash";

/**
 * Form input for textarea
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormInputTextArea = (props) => {
    const {
        id,
        label,
        value,
        rows,
        cols,
        className,
        onChange,
        errors,
        register,
        isControlled = false,
        ...rest
    } = props;

    return (
        <div className={className}>
            {label && <label htmlFor={id}>{label}</label>}
            <textarea
                id={id}
                name={id}
                className="form-control"
                rows={rows}
                cols={cols}
                // set value or defaultValue
                {...(isControlled ? {value: value} : {defaultValue: value})}
                onChange={onChange}
                ref={register}
                {...rest}
            />
            {errors && <span>{get(errors, id)?.message}</span>}
        </div>
    );
}

export default FormInputTextArea;
