import React from 'react';
import Accordion from "react-light-accordion";
import JobChargesAccordionCard from "./jobChargesAccordionCard";

/**
 * CreateJobChargesAccordion Component
 * @returns {JSX.Element}
 * @constructor
 */
const JobChargesAccordion = (props) => {
    const {
        isDataLoaded, selectedJob, register, errors, control, setValue, rigActivitiesCostCodes, footageSection,
        drillingCostCodes, footageCostCodes, reamingCostCodes, generalWorkPerDiemLargeChargesCostCodes,
        surveyCostCodes, standbyCostCodes, rigMovesCostCodes, lumpSumsOtherCharges, equipmentCostCodes,
        waterHaulCostCodes, auxiliaryEquipmentCostCodes, consumablesCostCodes, rigActivitiesSection,
        drillingSection, reamingSection, generalWorkSection, surveysSection, standbySection, rigMovesSection,
        lumpSumsSection, equipmentSection, waterHaulSection, auxiliaryEquipmentSection, consumablesSection,
        repairAndMaintenanceCostCodes, repairAndMaintenanceSection
    } = props;

    if (!isDataLoaded) {
        return null;
    }

    // check of selectedJob is selected
    if (selectedJob === 0) {
        return null;
    }

    /**
     *  this prevents submit form on click of Accordion
     *  react-light accordion has no property provided to control button type
     */
    const handleClick = (target) => {
        if (target.type === 'submit') {
            target.setAttribute("type", "button");
        }
    }

    // return accordion cards
    return (
        <>
            <Accordion atomic="false">
                <div onClick={(e) => handleClick(e.target)}>
                    <JobChargesAccordionCard
                        title="Rig Activities & Work Time"
                        fieldArrayName="rig_activities_and_work_time"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={rigActivitiesCostCodes}
                        fieldArrayValues={rigActivitiesSection}
                    />
                    <JobChargesAccordionCard
                        title="Drilling"
                        fieldArrayName="drilling"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={drillingCostCodes}
                        fieldArrayValues={drillingSection}
                    />
                    <JobChargesAccordionCard
                        title="Footage"
                        fieldArrayName="footage"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={footageCostCodes}
                        fieldArrayValues={footageSection}
                    />
                    <JobChargesAccordionCard
                        title="Reaming"
                        fieldArrayName="reaming"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={reamingCostCodes}
                        fieldArrayValues={reamingSection}
                    />
                    <JobChargesAccordionCard
                        title="General Work / Per Diem / Larger Charges"
                        fieldArrayName="generalWorkPerDiemLargeCharges"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={generalWorkPerDiemLargeChargesCostCodes}
                        fieldArrayValues={generalWorkSection}
                    />
                    <JobChargesAccordionCard
                        title="Survey"
                        fieldArrayName="survey"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={surveyCostCodes}
                        fieldArrayValues={surveysSection}
                    />
                    <JobChargesAccordionCard
                        title="Standby"
                        fieldArrayName="standby"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={standbyCostCodes}
                        fieldArrayValues={standbySection}
                    />
                    <JobChargesAccordionCard
                        title="Rig Moves"
                        fieldArrayName="rig_moves"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={rigMovesCostCodes}
                        fieldArrayValues={rigMovesSection}
                    />
                    <JobChargesAccordionCard
                        title="Lump Sums & Other Charges"
                        fieldArrayName="lump_sums_other_charges"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={lumpSumsOtherCharges}
                        fieldArrayValues={lumpSumsSection}
                    />
                    <JobChargesAccordionCard
                        title="Equipment"
                        fieldArrayName="equipment"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={equipmentCostCodes}
                        fieldArrayValues={equipmentSection}
                    />
                    <JobChargesAccordionCard
                        title="Water Haul"
                        fieldArrayName="water_haul"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={waterHaulCostCodes}
                        fieldArrayValues={waterHaulSection}
                    />
                    <JobChargesAccordionCard
                        title="Auxiliary Equipment"
                        fieldArrayName="auxiliary_equipment"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={auxiliaryEquipmentCostCodes}
                        fieldArrayValues={auxiliaryEquipmentSection}
                    />
                    <JobChargesAccordionCard
                        title="Repair & Maintenance"
                        fieldArrayName="repair_maintenance"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={repairAndMaintenanceCostCodes}
                        fieldArrayValues={repairAndMaintenanceSection}
                    />
                    <JobChargesAccordionCard
                        title="Consumables"
                        fieldArrayName="consumables"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        costCodes={consumablesCostCodes}
                        fieldArrayValues={consumablesSection}
                    />
                </div>
            </Accordion>
        </>
    );
}

export default React.memo(JobChargesAccordion);
