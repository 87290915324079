import React, {useState} from 'react';
import TableReport from "../../../common/table/table";
import DailyHolesTable from "./DailyHolesTable.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import * as ArrayHelpers from "../../../../utils/arrayHelpers";

/**
 * Body report component for DirectEntry Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const DailyHolesBody = prop => {
    const {filters, tableData, setTableData, selectedRow, setSelectedRow, setEditModal} = prop;
    const [disapproveModal, setDisapproveModal] = useState({isOpen: false, message: <p></p>});

    const DisapproveHandler = () => {
        apiClient
            .post('disapproveDaily', {'id': selectedRow.daily_id})
            .then(response => {
                const newTableData = ArrayHelpers.replaceDailyObjectsFromArray(tableData, "daily_id", response.data.id, response.data.row);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDisapproveModal({...disapproveModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={DailyHolesTable(setSelectedRow, setEditModal)}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'holes'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={disapproveModal}
                setModal={setDisapproveModal}
                title="Remove Direct Entry"
                onConfirm={DisapproveHandler}
            />
        </div>
    );
};

export default DailyHolesBody;
