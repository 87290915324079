import React from 'react';
import Table from "../../../common/table/table";
import OfficeTimeEntrySummaryTable from "./officeTimeEntrySummaryTable";
import {OfficeTimeEntrySummaryTableStyle} from "./officeTimeEntrySummaryTableStyle";
import MessageEmptyData from "../../../common/messageEmptyData";

/**
 * OfficeTimeEntrySummaryBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function OfficeTimeEntrySummaryBody (prop) {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <OfficeTimeEntrySummaryTableStyle>
                <Table
                    columns={OfficeTimeEntrySummaryTable()}
                    data={tableData}
                    exportOptions={{pdf: false, xlsx: true, csv: true}}
                    getExportFileName={() => 'office_time_entry_summary'}
                    withGlobalSearch={true}
                />
            </OfficeTimeEntrySummaryTableStyle>
        </div>
    );
}