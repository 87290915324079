import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import OfficeTimeEntryReviewBody from "./officeTimeEntryReviewBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import PayPeriodFormFilter from "../../../common/form-filters/PayPeriodFormFilter";

/**
 * ShopTimeEntryReview Component
 * @returns {JSX.Element}
 * @constructor
 */
const OfficeTimeEntryReview = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Office Time Entry Review" parent="Time Entry"/>
            <PayPeriodFormFilter {...{setFilters, setTableData, OnSubmitUrl:"getOfficeTimeEntriesByPayPeriodId"}}/>
            <OfficeTimeEntryReviewBody {...{filters, setFilters, tableData, setTableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default OfficeTimeEntryReview;
