import { useContext, useCallback } from "react";
import { AuthActions, UserContext } from "../contexts/auth";
import { AuthApis } from "../utils/service";

export const useSelector = (selectorFn) => {
    const value = useContext(UserContext);
    return selectorFn(value.state);
};

export const useDispatch = () => {
    const value = useContext(UserContext);
    return value.dispatch;
};

export const useAuthenticate = () => {
    const user = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);
    const dispatch = useDispatch();

    const signin = useCallback(
        async (email, pass) => {
            const response = await AuthApis.signin(email, pass);
            if (response.user_has_default_password) {
                return response;
            }

            dispatch({
                type: AuthActions.setUser,
                payload: {
                    user: {
                        client_id: response.info.client_id,
                        deleted_at: response.info.deleted_at,
                        discipline_id: response.info.discipline_id,
                        email: response.info.email,
                        erp_id: response.info.erp_id,
                        first_name: response.info.first_name,
                        group_id: response.info.group_id,
                        hire_date: response.info.hire_date,
                        id: response.info.id,
                        last_name: response.info.last_name,
                        office_region: response.info.office_region,
                        phone: response.info.phone,
                        status: response.info.status,
                        suffix: response.info.suffix,
                        supervisor_id: response.info.supervisor_id,
                        title: response.info.title,
                    },
                    user_has_default_password: false,
                    token: response.token,
                },
            });
        },
        [dispatch]
    );

    const signout = useCallback(
        async (token) => {
            try {
                await AuthApis.signout(token);
            } catch (e) {
                console.log(e.response?.data?.message);
            }

            dispatch({ type: AuthActions.clearUser });
        },
        [dispatch]
    );

    const setUserInfo = useCallback(
        async (token) => {
            const response = await AuthApis.authGet(token);

            dispatch({
                type: AuthActions.setUserInfo,
                payload: {
                    user: {
                        client_id: response.user.client_id,
                        deleted_at: response.user.deleted_at,
                        discipline_id: response.user.discipline_id,
                        discipline_name: (typeof response.user.discipline !== 'undefined') ? response.user.discipline?.name : '',
                        email: response.user.email,
                        erp_id: response.user.erp_id,
                        first_name: response.user.first_name,
                        group_id: response.user.group_id,
                        hire_date: response.user.hire_date,
                        id: response.user.id,
                        last_name: response.user.last_name,
                        office_region: response.user.office_region,
                        phone: response.user.phone,
                        status: response.user.status,
                        suffix: response.user.suffix,
                        supervisor_id: response.user.supervisor_id,
                        title: response.user.title,
                    },
                },
            });
        },
        [dispatch]
    );

    return {
        user,
        token,
        signin,
        signout,
        setUserInfo,
    };
};
