import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import DirectEntryBody from "./directEntryBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import PayrollDateRangeFilter from "../../../common/form-filters/PayrollDateRangeFilter";

/**
 * DirectEntry Report Component
 * @returns {JSX.Element}
 * @constructor
 */
const DirectEntry = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        payroll_date: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Direct Entry" parent="Reports"/>
            <PayrollDateRangeFilter {...{setFilters, setTableData, OnSubmitUrl:"getDirectEntryDetail"}}/>
            <DirectEntryBody
                filters={filters}
                tableData={tableData}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default DirectEntry;
