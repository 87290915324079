import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import RevenueByHourFilters from "./revenueByHourFilters";
import RevenueByHourBody from "./revenueByHourBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Revenue By Hour Report
 * @returns {JSX.Element}
 * @constructor
 */
const RevenueByHour = () => {
    const [barChartLabels, setBarChartLabels] = useState([]);
    const [barChartDataDiscipline1, setBarChartDataDiscipline1] = useState([]);
    const [barChartDataDiscipline2, setBarChartDataDiscipline2] = useState([]);
    const [barChartDataDiscipline3, setBarChartDataDiscipline3] = useState([]);
    const [barChartDataDiscipline4, setBarChartDataDiscipline4] = useState([]);
    const [reportStartDate, setStartDate] = useState([]);
    const [reportEndDate, setEndDate] = useState([]);
    const [periodId, setPeriodId] = useState({
        id: '',
        type: 'pay_period'
    });
    const [reportFilters, setReportFilters] = useState({
        financial_period_id: '',
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    const reportFilterProps = {
        setReportFilters,
        setBarChartLabels,
        setBarChartDataDiscipline1,
        setBarChartDataDiscipline2,
        setBarChartDataDiscipline3,
        setBarChartDataDiscipline4,
        setStartDate,
        setEndDate,
        setPeriodId
    };

    const reportBodyProps = {
        barChartLabels,
        barChartDataDiscipline1,
        barChartDataDiscipline2,
        barChartDataDiscipline3,
        barChartDataDiscipline4,
        reportFilters,
        reportStartDate,
        reportEndDate,
        periodId
    };

    return (
        <>
            <Breadcrumb title="Revenue By Hour" parent="Reports"/>
            <RevenueByHourFilters {...reportFilterProps} />
            <RevenueByHourBody {...reportBodyProps}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
};

export default RevenueByHour;
