import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";

/**
 * TimeEntryFilter component
 * @returns {JSX.Element}
 * @constructor
 */
const ShopTimeEntryDetailFilter = (prop) => {
    const {filters, setFilters, setTableData,} = prop;
    const {register, handleSubmit, errors} = useForm();
    const [payPeriods, setPayPeriods] = useState([]);
    const [endDate, setEndDate] = useState(0);

    // get list of pay periods
    useEffect(() => {
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                // it is ugly but safari on mobile device doesn't want to operate with this date format
                // temporary solution for now until time entry mobile app will be build
                // do not remove commented out code!
                const endDateArray = response.data.end_date.split('-');

                // let year = Number(moment(new Date(response.data.end_date)).format('YYYY'));
                // let year = new Date(response.data.end_date).getFullYear();
                let year = 2000 + Number(endDateArray[2]);
                setEndDate(year);
            });

        apiClient
            .post('getPayPeriodsByYear', {'year': endDate})
            .then(response => {
                const payPeriods = response.data.map(
                    ({id, start_date, end_date, is_current}) => {

                        if (is_current) {
                            updateStateValue(setFilters, 'pay_period_id', id);
                        }

                        return {
                            id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')
                        }
                    }
                );
                setPayPeriods(payPeriods);
            });
    }, [endDate, setFilters]);

    const filterSelectionHandler = (e) => {
        const {id, value} = e.target;
        updateStateValue(setFilters, id, value);
    }

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post('getShopTimeDetail', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
                updateStateValue(setFilters,'isLoading', false);
        });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="pay_period_id"
                    label="Pay Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.pay_period_id}
                    onChange={filterSelectionHandler}
                    options={payPeriods}
                    register={register({required: "required"})}
                    errors={errors}
                    isControlled
                />
                <FormSelect
                    id="filter"
                    label="Status"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.status}
                    onChange={filterSelectionHandler}
                    options={[{value: 1, label: "Approved"}, {value: 0, label: "Disapproved"}]}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default ShopTimeEntryDetailFilter;
