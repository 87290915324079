import React from "react";
import { useAuthenticate } from '../../../../hooks/auth';
import apiClient from "../../../../utils/apiClient";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {
    FaPencilAlt, FaCheck, FaEyeSlash, FaCheckSquare, FaRegFilePdf, FaEnvelopeOpenText, FaEye, FaTrash
} from "react-icons/fa";
import { formatNumberToDisplayWithCommas } from "../../../common/shared";

const CORE_DISCIPLINE_IDS = ['1', '2'];

const REAM_COLUMNS = [
    {
        Header: 'Ream From',
        accessor: 'ream_from',
        Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
    },
    {
        Header: 'Ream To',
        accessor: 'ream_to',
        Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
    },
    {
        Header: 'Ream Total',
        accessor: 'ream_total',
        Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
    },
]

const CORE_DISCIPLINE_COLUMNS = [
    {
        Header: 'Runs',
        accessor: 'runs',
    },
    {
        Header: 'Core Retrieved',
        accessor: 'core_retrieved',
    }
]

const DailiesTable = (
    setFilters, setSelectedRow, setApproveModal, setDisapproveModal, setDeactivateModal,
    setSendReportModal, setReactivateModal, setDeleteModal, selectedDisciplineId,
) => {

    const isCoreDisciplineSelected = CORE_DISCIPLINE_IDS.includes(selectedDisciplineId);

    return [
        {
            Header: 'Date',
            accessor: 'date',
            width: 70,
            Cell: (cell) => {
                const row = cell.row.original;
                let result = '';
                result = (row.status > 0)
                    ?
                        (
                            <a
                                href={`/data_entry/daily/daily/edit/${row.daily_id}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <b>{cell.value}</b>
                            </a>
                        )
                    :
                        cell.value;
                return result;
            }
        },
        {
            Header: 'Shift',
            accessor: 'shift',
            width: 40,
        },
        {
            Header: 'Rig',
            accessor: 'rig',
        },
        {
            Header: 'Job',
            accessor: 'job',
            width: 85,
        },
        {
            Header: 'Hole',
            accessor: 'hole',
        },
        {
            Header: 'Depth From',
            accessor: 'depth_from',
            Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
        },
        {
            Header: 'Depth To',
            accessor: 'depth_to',
            Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
        },
        {
            Header: 'Depth Total',
            accessor: 'depth_total',
            Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
        },
        ...(isCoreDisciplineSelected ? [] : REAM_COLUMNS),
        {
            Header: 'Variance',
            accessor: 'variance',
        },
        ...(isCoreDisciplineSelected ? CORE_DISCIPLINE_COLUMNS : []),
        {
            Header: 'Hours',
            accessor: 'hours',
            width: 60,
        },
        {
            Header: 'Revenue',
            accessor: 'revenue',
            Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
        },
        {
            Header: 'Invoice',
            accessor: 'invoice',
        },
        {
            Header: 'Status',
            accessor: 'invoice_status',
            width: 60,
            Cell: (cell) => {
                const row = cell.row.original;

                if (typeof row.invoice_status !== 'undefined') {
                    switch (row.invoice_status) {
                        case 1:
                            return 'Draft';
                        case 2:
                            return 'Sent';
                        case 3:
                            return 'Final';
                        default:
                            return null;
                    }
                } else {
                    return null;
                }
            }
        },
        {
            Header: 'Created By',
            accessor: 'created_by',
        },
        {
            Header: 'Modified By',
            accessor: 'modified_by',
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                // to manipulate permissions
                const { user } = useAuthenticate();
                const row = cell?.row.original;
                let active = (
                    <>
                        <a
                            href={`/data_entry/daily/daily/edit/${row.daily_id}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FaPencilAlt className="actions" title="Edit Daily"/>
                        </a>
                        {' '}
                        {row.supervisor_approved > 0 ?
                                <FaCheckSquare
                                    className="actions"
                                    title="Disapprove Daily"
                                    color='#22af47'
                                    onClick={() => {
                                        const details = row.date + ' ' + row.rig + ' ' + row.job;
                                        const msg = 'Are you sure that you want to disapprove daily : ' + details;
                                        setSelectedRow(row);
                                        setDisapproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                            :
                                <FaCheck
                                    className="actions"
                                    title="Approve Daily"
                                    onClick={() => {
                                        const details = row.date + ' ' + row.rig + ' ' + row.job;
                                        const msg = 'Are you sure that you want to approve daily : ' + details;
                                        setSelectedRow(row);
                                        setApproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                        }
                        {' '}
                        { user?.group_id === 1 &&
                            <FaEyeSlash
                                className="actions"
                                title="Deactivate Daily"
                                onClick={() => {
                                    const details = row.date + ' ' + row.rig + ' ' + row.job;
                                    const msg = 'Are you sure that you want to deactivate daily : ' + details;
                                    setSelectedRow(row);
                                    setDeactivateModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        {' '}
                        <FaRegFilePdf
                            className="actions"
                            title="Export"
                            onClick={() => {
                                updateStateValue(setFilters, 'isLoading', true);
                                //get pdf export file and open it in a new window
                                apiClient(`/pdfExportDailyDetailsById/${row.daily_id}`, {
                                    method: 'GET',
                                    responseType: 'blob' //Force to receive data in a Blob Format
                                })
                                    .then(response => {
                                        //Create a Blob from the PDF Stream
                                        const file = new Blob(
                                            [response.data],
                                            {type: 'application/pdf'});
                                        //Build a URL from the file
                                        const fileURL = URL.createObjectURL(file);
                                        //Open the URL on new Window
                                        window.open(fileURL);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    }).then(function () {
                                    updateStateValue(setFilters, 'isLoading', false);
                                });
                            }}
                        />
                        {' '}
                        <FaEnvelopeOpenText
                            className="actions"
                            title="Send Daily Report"
                            onClick={() => {
                                setSelectedRow(row);
                                setSendReportModal({
                                    isOpen: true
                                });
                            }}
                        />
                    </>
                );

                let inactive = (
                    <>
                        <FaEye
                            className="actions"
                            title="Reactivate Daily"
                            onClick={() => {
                                const details = row.date + ' ' + row.rig + ' ' + row.job;
                                const msg = 'Are you sure that you want to reactivate daily : ' + details;
                                setSelectedRow(row);
                                setReactivateModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                        {' '}
                        <FaTrash
                            className="actions"
                            title="Delete Daily"
                            onClick={() => {
                                const details = row.date + ' ' + row.rig + ' ' + row.job;
                                const msg = 'Are you sure that you want to delete daily : ' + details;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                );

                if (row.status === 1) {
                    return active;
                }

                if (user?.group_id === 1 && row.status === 0) {
                    return inactive;
                } else {
                    return <></>;
                }
            }
        }
    ];
}

export default DailiesTable;

