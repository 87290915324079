import React from 'react';

/**
 * FormNoteAlert
 * @param props
 * class type: alert-info, alert-danger
 * @returns {JSX.Element}
 * @constructor
 */
const FormNoteAlert = (props) => {
    const {label, className} = props;

    return (
        <div
            className={`alert text-center ${className}`}
            role="alert">{label}
        </div>
    );
}

export default FormNoteAlert;
