import React from 'react';
import {get} from 'lodash';
/**
 * FormSelectAllCountries
 * options accept array of object with below parameters
 *  ex:
 *   - [{id: "", name: "" },...],
 *   - [{value: "", label:""},...],
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormSelectAllCountries = (props) => {

    const {
        id, label, value, options, className, onChange, errors, register, disabled, isControlled = false,
    } = props;

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <select
                id={id}
                name={id}
                className="form-control"
                {...(isControlled ? {value: value} : {defaultValue: value})}
                onChange={onChange}
                ref={register}
                disabled={disabled}
            >
                <option value=''>- Select -</option>
                {options}
            </select>
            {errors && <span>{ get(errors, id)?.message }</span>}
        </div>
    );
}

export default React.memo(FormSelectAllCountries);
