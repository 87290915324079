import React from 'react';
import { get, isUndefined } from 'lodash';
/**
 * FormSelect
 * options accept array of object with below parameters
 *  ex:
 *   - [{id: "", name: "" },...],
 *   - [{value: "", label:""},...],
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormSelect = (props) => {

    const {
        id, label, value, options, className, onChange, errors, register, disabled, isControlled = false,
    } = props;

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <select
                id={id}
                name={id}
                className="form-control"
                {...(isControlled ? {value: value || ''} : {defaultValue: value || ''})}
                onChange={onChange}
                ref={register}
                disabled={disabled}
            >
                {options.map((item, i) =>
                    <option
                        key={i}
                        value={ isUndefined(item.id) ? item.value : item.id }
                    >
                        { isUndefined(item.name) ? item.label : item.name }
                    </option>
                )}
            </select>
            {errors && <span>{ get(errors, id)?.message }</span>}
        </div>
    );
}

export default React.memo(FormSelect);
