import React, {Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { useForm } from 'react-hook-form'
import {toast} from "react-toastify";
import apiClient from "../../../utils/apiClient";
import FormInputText from "../../forms/form-control/FormInputText";
import CancelButton from "../../forms/form-control/daily/CancelButton";
import SubmitButton from "../../forms/form-control/daily/SubmitButton";
import FormInputNumber from "../../forms/form-control/FormInputNumber";

const CreateParent = () => {
    const {register, handleSubmit, errors, reset} = useForm();

    // clean form
    const toggleCancel = () => {
        reset();
    }

    const onSubmit = data => {
        if (data !== '') {
            apiClient
                .post('createParent', JSON.stringify(data))
                .then(response => {
                    reset();
                    toast.success(response.data.message);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            errors.showMessages();
        }
    }

    return (
        <>
            <Fragment>
                <Breadcrumb
                    title="Create Parent"
                    parent="Commercial"
                />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form
                                        id="create_parent_form"
                                        className="needs-validation"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="form-row">
                                            <FormInputText
                                                id="parent_name"
                                                label="Name"
                                                className="col-md-4 mb-3"
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            <FormInputNumber
                                                id="erp_id"
                                                label="VP ID"
                                                className="col-md-1 mb-3"
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <SubmitButton
                                                label="Create"
                                            />
                                            <CancelButton
                                                label="Cancel"
                                                onClick={toggleCancel}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    );
}

export default React.memo(CreateParent);
