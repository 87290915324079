import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import ShopTimeEntryHistoryBody from "./shopTimeEntryHistoryBody";
import PayPeriodFormFilter from "../../../common/form-filters/PayPeriodFormFilter";

/**
 * ShopTimeEntryHistory Component
 * @returns {JSX.Element}
 * @constructor
 */
const ShopTimeEntryHistory = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Shop Time Entry Work History" parent="Time Entry"/>
            <PayPeriodFormFilter {...{setFilters, setTableData, OnSubmitUrl:"getShopTimeWorkHistory"}}/>
            <ShopTimeEntryHistoryBody {...{filters, tableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default ShopTimeEntryHistory;
