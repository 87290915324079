import React, {useState} from 'react';
import TableReport from "../../../common/table/table";
import DailiesTable from "./dailiesTable.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import * as ArrayHelpers from "../../../../utils/arrayHelpers";
import ModalSendDailyEmailReport from "./modalSendDailyEmailReport";

/**
 * Body report component for DirectEntry Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const DailiesBody = (prop) => {
    const {filters, setFilters, tableData, setTableData, selectedRow, setSelectedRow, tableDataDisciplineId} = prop;
    const [deactivateModal, setDeactivateModal] = useState({isOpen: false, message: <p></p>});
    const [approveModal, setApproveModal] = useState({isOpen: false, message: <p></p>});
    const [disapproveModal, setDisapproveModal] = useState({isOpen: false, message: <p></p>});
    const [sendReportModal, setSendReportModal] = useState({isOpen: false});
    const [reactivateModal, setReactivateModal] = useState({isOpen: false, message: <p></p>});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    const DeactivateHandler = () => {
        apiClient
            .post('deactivateDaily', {'id': selectedRow.daily_id})
            .then(response => {
                const newTableData = ArrayHelpers.removeDailyObjFromArray(tableData, "daily_id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeactivateModal({...deactivateModal, isOpen: false})
            });
    }

    const ReactivateHandler = () => {
        apiClient
            .post('activateDaily', {'id': selectedRow.daily_id})
            .then(response => {
                const newTableData = ArrayHelpers.removeDailyObjFromArray(tableData, "daily_id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setReactivateModal({...reactivateModal, isOpen: false})
            });
    }

    const ApproveHandler = () => {
        apiClient
            .post('approveDaily', {'id': selectedRow.daily_id})
            .then(response => {
                const newTableData = ArrayHelpers.replaceDailyObjectsFromArray(tableData, "daily_id", response.data.id, response.data.row);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setApproveModal({...approveModal, isOpen: false})
            });
    }

    const DisapproveHandler = () => {
        apiClient
            .post('disapproveDaily', {'id': selectedRow.daily_id})
            .then(response => {
                const newTableData = ArrayHelpers.replaceDailyObjectsFromArray(tableData, "daily_id", response.data.id, response.data.row);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDisapproveModal({...disapproveModal, isOpen: false})
            });
    }

    const DeleteHandler = () => {
        apiClient
            .post('deleteDaily', {'daily_id': selectedRow.daily_id})
            .then(response => {
                const newTableData = ArrayHelpers.removeDailyObjFromArray(tableData, "daily_id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={
                    DailiesTable(
                        setFilters, setSelectedRow, setApproveModal, setDisapproveModal,
                        setDeactivateModal, setSendReportModal, setReactivateModal, setDeleteModal,
                        tableDataDisciplineId,
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'dailies'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={deactivateModal}
                setModal={setDeactivateModal}
                title="Deactivate Daily"
                onConfirm={DeactivateHandler}
            />
            <ModalConfirmation
                modal={approveModal}
                setModal={setApproveModal}
                title="Approve Daily"
                onConfirm={ApproveHandler}
            />
            <ModalConfirmation
                modal={disapproveModal}
                setModal={setDisapproveModal}
                title="Disapprove Daily"
                onConfirm={DisapproveHandler}
            />
            <ModalSendDailyEmailReport
                modal={sendReportModal}
                setModal={setSendReportModal}
                selectedRow={selectedRow}
            />
            <ModalConfirmation
                modal={reactivateModal}
                setModal={setReactivateModal}
                title="Reactivate Daily"
                onConfirm={ReactivateHandler}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Delete Daily"
                onConfirm={DeleteHandler}
            />
        </div>
    );
}

export default DailiesBody;
