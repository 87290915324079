import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import ShopTimeEntryWeeklySummaryBody from "./shopTimeEntryWeeklySummaryBody";
import PayPeriodFormFilter from "../../../common/form-filters/PayPeriodFormFilter";

/**
 * ShopTimeEntryWeeklySummary Component
 * @returns {JSX.Element}
 * @constructor
 */
export default function ShopTimeEntryWeeklySummary () {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Shop Time Entry Weekly Summary" parent="Time Entry"/>
            <PayPeriodFormFilter {...{setFilters, setTableData, OnSubmitUrl:"getShopTimeWeeklySummary"}}/>
            <ShopTimeEntryWeeklySummaryBody {...{filters, tableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}


