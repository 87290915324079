import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import TransactionRegisterFilters from "./transactionRegisterFilters";
import TransactionRegisterBody from "./transactionRegisterBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * TransactionRegister Report
 * @returns {JSX.Element}
 * @constructor
 */
const TransactionRegister = () => {

    const [tableData, setTableData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        date_from: '',
        date_to: '',
        client_id: '',
        job_number: '',
        invoice_status: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Transaction Register" parent="Reports"/>
            <TransactionRegisterFilters reportFilters={reportFilters} setTableData={setTableData} setReportFilters={setReportFilters}/>
            <TransactionRegisterBody reportFilters={reportFilters} tableData={tableData} setTableData={setTableData} />
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default TransactionRegister;
