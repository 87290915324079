import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FieldDailyAccordion from "./FieldDailyAccordion";
import {getHours} from "../../../../utils/listHelpers";
import {toast} from "react-toastify";
import LoadingIndicator from "../../../common/loadingIndicator";
import FormSelectDaily from "../../../forms/form-control/FormSelectDaily";
import CancelButton from "../../../forms/form-control/daily/CancelButton";
import SubmitButton from "../../../forms/form-control/daily/SubmitButton";
import ModalWarning from "../../../common/modal/ModalWarning";
import FieldDailyTotalsTable from "./FieldDailyTotalsTable";
import {isUndefined} from "lodash";
import { useAuthenticate } from '../../../../hooks/auth';
import ModalConfirmation from "../../../common/modal/ModalConfirmation";

/**
 * FieldDaily Component
 * Edit or Create based on url handle
 *
 * @returns {JSX.Element}
 * @constructor
 */
const FieldDaily = (props) => {
    const fieldDailyId = (props.match.params.id > 0) ? props.match.params.id : 0;
    const action = (props.match.params.action) ? props.match.params.action : '';
    const [disciplineId, setDisciplineId] = useState(0);
    const [mobileBottomHoleDescriptions, setMobileBottomHoleDescriptions] = useState([])
    const [fieldDaily, setFieldDaily] = useState([]);
    const [warningModal, setWarningModal] = useState({isOpen: false, message: <p></p>});
    const [totalHoursConfirmationModal, setTotalHoursConfirmationModal] = useState({isOpen: false, message: <p></p>});
    const {register, control, handleSubmit, errors, reset, setValue, getValues, watch} = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [disciplines, setDisciplines] = useState([]);
    const [rigs, setRigs] = useState([]);
    const [fieldEmployees, setFieldEmployees] = useState([]);
    const [fieldEmployeesTemplate, setFieldEmployeesTemplate] = useState([]);
    const [fieldHolesTemplate, setFieldHolesTemplate] = useState([]);
    const [fieldCasingsTemplate, setFieldCasingsTemplate] = useState([]);
    const [fieldDrillBitsTemplate, setFieldDrillBitsTemplate] = useState([]);
    const [fieldBitsTemplate, setFieldBitsTemplate] = useState([]);
    const [fieldBHATemplate, setFieldBHATemplate] = useState([]);
    const [fieldBottomHoleTemplate, setFieldBottomHoleTemplate] = useState([]);
    const [selectedJobId, setSelectedJobId] = useState('');
    const [rigsAvailable, setRigsAvailable] = useState([]);
    const [fieldEmployeesAvailable, setFieldEmployeesAvailable] = useState([]);
    const [activityDescriptions, setActivityDescriptions] = useState([]);
    const [drilledDescriptions, setDrilledDescriptions] = useState([]);
    const [reamedDescriptions, setReamedDescriptions] = useState([]);
    const [generalDescriptions, setGeneralDescriptions] = useState([]);
    const [consumableDescriptions, setConsumableDescriptions] = useState([]);
    const [selectedRigName, setSelectedRigName] = useState('');
    const [selectedJobNumber, setSelectedJobNumber] = useState('');
    const [selectedClientName, setSelectedClientName] = useState('');
    const [selectedLocationName, setSelectedLocationName] = useState('');
    const [jobState, setJobState] = useState('');
    const [totalHours, setTotalHours] = useState(0);
    const { user } = useAuthenticate();
    const TOOL_PUSHER = 11;
    const DRILLER_ASSISTANT = 9;
    const DRILLER = 7;

    // Discipline selection handler
    const disciplineSelectionHandler = (disciplineId) => {
        if (disciplineId !== null) {
            setDisciplineId(disciplineId);
        }

        // Employees
        apiClient
            .post('getActiveFieldEmployees', {'discipline_id': disciplineId})
            .then(response => {
                const fieldEmployees = response.data.activeFieldEmployees.map(
                    ({id, first_name, last_name}) => ({value: id, label: first_name + ' ' + last_name})
                );

                setFieldEmployees(fieldEmployees);
            });

        // Rigs
        apiClient
            .post('getAllActiveRigsByDisciplineId', {'discipline_id': disciplineId})
            .then(response => {
                const rigItems = response.data.rigs.map(
                    ({id, name}) => ({value: id, label: name})
                );
                setRigs(rigItems);
            });
    }

    const toggleCancel = () => {
        window.close();
    }

    // saveFieldDaily
    const saveFieldDaily = (data, ignoreTotalHoursCheck = false) => {
        const url = (action === 'edit') ? 'editFieldDaily' : 'createFieldDaily';

        if (action === 'create') {
            if (user?.group_id === TOOL_PUSHER || user?.group_id === DRILLER || user?.group_id === DRILLER_ASSISTANT) {
                data['discipline_id'] = user?.discipline_id;
            }
            data['general_info']['rig_id'] = data['general_info']['rig_id'].value;
            data['general_info']['job_id'] = data['general_info']['job_id'].value;
            data['general_info']['client_id'] = data['general_info']['client_id'].value;
        }

        // when editing field daily
        if (action === 'edit') {
            data['id'] = props.match.params.id;
            data['discipline_id'] = disciplineId;
            // due to that those are disabled at general information card
            data['general_info']['job_id'] = fieldDaily.job_id;
            data['general_info']['client_id'] = fieldDaily.client_id;
            data['general_info']['rig_id'] = fieldDaily.rig_id;
        }

        if (isUndefined(data.employee)) {
            const msg = 'Please Enter Employee Information';
            setWarningModal({
                message: msg,
                isOpen: true,
            });

            return null;
        }

        const isSetDriller = data.employee.some((emp) => emp.is_driller === '1');

        if (!isSetDriller) {
            const msg = 'Please select driller employee';
                setWarningModal({
                    message: msg,
                    isOpen: true,
                });

            return null;
        }

        data['app_version'] = 1; // 1 - web app, 2 - mobile app

        // need to validate reamed and drilling  values for sanity
        let drillingSize = (typeof data.drilling !== 'undefined') ? data.drilling.length : 0;
        let reamingSize = (typeof data.reaming !== 'undefined') ? data.reaming.length : 0;
        let reamingOccurrences = [];
        let drillingOccurrences = [];

        for (let i = 0; i < reamingSize; i ++) {
            if (Number(data.reaming[i].reaming_to) < Number(data.reaming[i].reaming_from)) {
                reamingOccurrences.push(1);
            }
        }

        for (let i = 0; i < drillingSize; i ++) {
            if (Number(data.drilling[i].drilling_to) < Number(data.drilling[i].drilling_from)) {
                drillingOccurrences.push(1);
            }
        }

        if (reamingOccurrences.length > 0) {
            const msg = '"Reamed To" can not be less than "Reamed From", validate in Reaming card please';
            setWarningModal({
                message: msg,
                isOpen: true,
            });
            return null;
        }

        if (drillingOccurrences.length > 0) {
            const msg = '"Drilled To" can not be less than "Drilled From", validate in Drilling card please';
            setWarningModal({
                message: msg,
                isOpen: true,
            });
            return null;
        }

        if ([3, 4].includes(Number(data.discipline_id))) {
            if (isUndefined(data.hole_rotary)) {
                const msg = 'Please Enter Hole Information';
                setWarningModal({
                    message: msg,
                    isOpen: true,
                });
                return null;
            }
        }

        if ([1, 2].includes(Number(data.discipline_id))) {
            if (isUndefined(data.hole_core)) {
                const msg = 'Please Enter Hole Information';
                setWarningModal({
                    message: msg,
                    isOpen: true,
                });
                return null;
            }
        }

        const totalHours = data.activities?.reduce((total, activity) => total + Number(activity.total_hours), 0);

        if (totalHours != null && (totalHours < 12 || totalHours > 12.5) && !ignoreTotalHoursCheck) {
            const msg = totalHours < 12 ?
                <p>Total shift hours less than 12</p> :
                <p>Total shift hours greater than 12.5</p>;

            setTotalHoursConfirmationModal({
                message: msg,
                isOpen: true,
            })
        } else {
            setIsLoading(true);

            apiClient
                .post(url, JSON.stringify(data))
                .then(response => {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch(function () {
                    //In case of error do nothing!!
                    //let apiClient to handle the error
                })
                .then(function () {
                    setIsLoading(false);
                });
        }
    }

    const handleSaveButtonClick = (data) => {
        saveFieldDaily(data, false);
    }

    const handleTotalHoursConfirmButtonClick = () => {
        setTotalHoursConfirmationModal(modal => ({...modal, isOpen: false}));
        saveFieldDaily(getValues(), true);
    }

    // get list of options
    useEffect(() => {
        if (action === 'create') {
            if (user?.group_id === TOOL_PUSHER || user?.group_id === DRILLER || user?.group_id === DRILLER_ASSISTANT) {
                disciplineSelectionHandler(user?.discipline_id);
            }

            //get disciplines
            apiClient
                .get('getDisciplines')
                .then(response => {
                    const disciplines = response.data.disciplines.map(
                        ({id, name}) => ({value: id, label: name})
                    );
                    setDisciplines(disciplines);
                });

            apiClient
                .get('mobileBottomHoleDescriptions')
                .then(response => {
                    const descriptionItems = response?.data.map(
                        ({id, description}) => ({value: id, label: description})
                    );
                    setMobileBottomHoleDescriptions(descriptionItems);
                 });
        }

        // when editing field daily
        if (action === 'edit' && fieldDailyId > 0) {
            /**
             * TODO: temporary fix to avoid the page to scroll down
             * The real problem is to figure out why general charge price is focus
             * once the focus problem is resolved this setTimeout can be removed
             **/
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 500);

            // get list of options getActiveFieldById
            apiClient
                .post('getActiveFieldDailyById', {'field_daily_id': fieldDailyId})
                .then(response => {
                    setFieldDaily(response.data);
                    setDisciplineId(response.data.discipline_id);
                    // assign state id to later define if it is CA
                    setJobState(response.data.job.state_id);

                    // BottomHole Descriptions
                    apiClient
                        .get('mobileBottomHoleDescriptions')
                        .then(response => {
                            const descriptionItems = response?.data.map(
                                ({id, description}) => ({value: id, label: description})
                            );
                            setMobileBottomHoleDescriptions(descriptionItems);
                        });

                    // Employees
                    apiClient
                        .post('getActiveFieldEmployees', {'discipline_id': response.data.discipline_id})
                        .then(response => {
                            const fieldEmployees = response.data.activeFieldEmployees.map(
                                ({id, first_name, last_name}) => ({value: id, label: first_name + ' ' + last_name})
                            );

                            setFieldEmployeesAvailable(fieldEmployees);
                        });

                    // Rigs
                    apiClient
                        .post('getAllActiveRigsByDisciplineId', {'discipline_id': response.data.discipline_id})
                        .then(response => {
                            setRigsAvailable(
                                response.data.rigs.map(
                                    ({id, name}) => ({value: id, label: name})
                                )
                            );
                        });

                    // Activity Descriptions
                    apiClient
                        .post('getActivityDescriptionsByJobId', {'job_id': response.data.job_id})
                        .then(response => {
                            setActivityDescriptions(
                                response.data.map(
                                    ({code, description, revenue_group}) => (
                                        {value: code, label: description, revenue_group: revenue_group}
                                    )
                                )
                            );
                        });

                    // General Descriptions
                    apiClient
                        .post('getGeneralDescriptionsByJobId', {'job_id': response.data.job_id})
                        .then(response => {
                            setGeneralDescriptions(
                                response.data.map(
                                    ({code, description}) => ({value: code, label: description})
                                )
                            );
                        });

                    // Consumable Descriptions
                    apiClient
                        .post('getConsumableDescriptionsByJobId', {'job_id': response.data.job_id})
                        .then(response => {
                            setConsumableDescriptions(
                                response.data.map(
                                    ({code, description}) => ({value: code, label: description})
                                )
                            );
                        });

                    // Drilled Descriptions
                    apiClient
                        .post('getDrilledDescriptionsByJobId', {'job_id': response.data.job_id})
                        .then(response => {
                            setDrilledDescriptions(
                                response.data.map(
                                    ({code, description}) => ({value: code, label: description})
                                )
                            );
                        });

                    // Reamed Descriptions
                    apiClient
                        .post('getReamedDescriptionsByJobId', {'job_id': response.data.job_id})
                        .then(response => {
                            setReamedDescriptions(response.data.map(
                                ({code, description}) => ({value: code, label: description})
                            ));
                        });
                });
        }
    }, [action, fieldDailyId, user]);

    useEffect(() => {
        if (action === "create") {
            setTimeout(() => window.scrollTo(0, 0), 500);
        }
    }, [
        fieldEmployeesTemplate.length, fieldHolesTemplate.length, fieldCasingsTemplate.length,
        fieldBitsTemplate.length, fieldDrillBitsTemplate.length, action
    ]);

    return (
        <>
            {action === 'create' &&
                <>
                    <Breadcrumb title="Create Field Daily" parent="Field Dailies"/>
                    <form
                        className="needs-validation"
                        onSubmit={handleSubmit(handleSaveButtonClick)}
                    >
                        <FieldDailyTotalsTable
                            watch={watch}
                            user={user}
                            fieldDaily={fieldDaily}
                            action={action}
                            selectedJobNumber={selectedJobNumber}
                            selectedRigName={selectedRigName}
                            selectedClientName={selectedClientName}
                            selectedLocationName={selectedLocationName}
                            totalHours={totalHours}
                        />
                        {
                            (user?.group_id !== TOOL_PUSHER && user?.group_id !== DRILLER && user?.group_id !== DRILLER_ASSISTANT) &&
                            <div className="form-row padding30">
                                <FormSelectDaily
                                    id='discipline_id'
                                    label='Discipline'
                                    name='discipline_id'
                                    className="mb-3"
                                    onChange={(e) => disciplineSelectionHandler(e.target.value)}
                                    options={disciplines}
                                    register={register({required: "required"})}
                                />
                            </div>
                        }
                        <div className="card" id="accordion_card">
                            <div className="card-body">
                                <div className="default-according panel-accordion" id="accordionclose">
                                    <FieldDailyAccordion
                                        disciplineId={Number(disciplineId)}
                                        rigs={rigs}
                                        fieldEmployees={fieldEmployees}
                                        register={register}
                                        control={control}
                                        errors={errors}
                                        setValue={setValue}
                                        getValues={getValues}
                                        reset={reset}
                                        hours={getHours()}
                                        setWarningModal={setWarningModal}
                                        warningModal={warningModal}
                                        rigsAvailable={rigsAvailable}
                                        fieldEmployeesAvailable={fieldEmployeesAvailable}
                                        activityDescriptions={activityDescriptions}
                                        setActivityDescriptions={setActivityDescriptions}
                                        drilledDescriptions={drilledDescriptions}
                                        reamedDescriptions={reamedDescriptions}
                                        generalDescriptions={generalDescriptions}
                                        consumableDescriptions={consumableDescriptions}
                                        fieldDaily={fieldDaily}
                                        action={action}
                                        setDrilledDescriptions={setDrilledDescriptions}
                                        setReamedDescriptions={setReamedDescriptions}
                                        setGeneralDescriptions={setGeneralDescriptions}
                                        setConsumableDescriptions={setConsumableDescriptions}
                                        setSelectedJobId={setSelectedJobId}
                                        selectedJobId={selectedJobId}
                                        setFieldEmployeesTemplate={setFieldEmployeesTemplate}
                                        setFieldHolesTemplate={setFieldHolesTemplate}
                                        fieldEmployeesTemplate={fieldEmployeesTemplate}
                                        fieldHolesTemplate={fieldHolesTemplate}
                                        fieldCasingsTemplate={fieldCasingsTemplate}
                                        setFieldCasingsTemplate={setFieldCasingsTemplate}
                                        fieldBHATemplate={fieldBHATemplate}
                                        setFieldBHATemplate={setFieldBHATemplate}
                                        fieldDrillBitsTemplate={fieldDrillBitsTemplate}
                                        fieldBitsTemplate={fieldBitsTemplate}
                                        setFieldDrillBitsTemplate={setFieldDrillBitsTemplate}
                                        setFieldBitsTemplate={setFieldBitsTemplate}
                                        setFieldBottomHoleTemplate={setFieldBottomHoleTemplate}
                                        fieldBottomHoleTemplate={fieldBottomHoleTemplate}
                                        setSelectedRigName={setSelectedRigName}
                                        setSelectedJobNumber={setSelectedJobNumber}
                                        setSelectedClientName={setSelectedClientName}
                                        setSelectedLocationName={setSelectedLocationName}
                                        mobileBottomHoleDescriptions={mobileBottomHoleDescriptions}
                                        jobState={jobState}
                                        setJobState={setJobState}
                                        totalHours={totalHours}
                                        setTotalHours={setTotalHours}
                                    />
                                </div>
                            </div>
                        </div>
                        {disciplineId > 0 ?
                            <div className="form-row">
                                <SubmitButton
                                    label="Create"
                                />
                                <CancelButton
                                    label="Cancel"
                                    onClick={toggleCancel}
                                />
                            </div>
                            :
                            <></>
                        }
                    </form>
                </>
            }
            {action === 'edit' && fieldDailyId > 0 &&
                <>
                    <Breadcrumb title="Edit Field Daily" parent="Field Dailies"/>
                    <form
                        className="needs-validation"
                        onSubmit={handleSubmit(handleSaveButtonClick)}
                    >
                        <FieldDailyTotalsTable
                            watch={watch}
                            fieldDaily={fieldDaily}
                            action={action}
                            selectedJobNumber={selectedJobNumber}
                            selectedRigName={selectedRigName}
                            selectedClientName={selectedClientName}
                            selectedLocationName={selectedLocationName}
                            totalHours={totalHours}
                        />
                        {/*<div className="form-row padding30">*/}
                        {/*    <h4>Discipline : {disciplineName}</h4>*/}
                        {/*</div>*/}
                        <div className="card" id="accordion_card">
                            <div className="card-body">
                                <div className="default-according panel-accordion" id="accordionclose">
                                    <FieldDailyAccordion
                                        disciplineId={Number(disciplineId)}
                                        rigs={rigs}
                                        fieldEmployees={fieldEmployees}
                                        register={register}
                                        control={control}
                                        errors={errors}
                                        setValue={setValue}
                                        getValues={getValues}
                                        reset={reset}
                                        hours={getHours()}
                                        setWarningModal={setWarningModal}
                                        warningModal={warningModal}
                                        rigsAvailable={rigsAvailable}
                                        fieldEmployeesAvailable={fieldEmployeesAvailable}
                                        activityDescriptions={activityDescriptions}
                                        setActivityDescriptions={setActivityDescriptions}
                                        drilledDescriptions={drilledDescriptions}
                                        reamedDescriptions={reamedDescriptions}
                                        generalDescriptions={generalDescriptions}
                                        consumableDescriptions={consumableDescriptions}
                                        fieldDaily={fieldDaily}
                                        action={action}
                                        setDrilledDescriptions={setDrilledDescriptions}
                                        setReamedDescriptions={setReamedDescriptions}
                                        setGeneralDescriptions={setGeneralDescriptions}
                                        setConsumableDescriptions={setConsumableDescriptions}
                                        setSelectedJobId={setSelectedJobId}
                                        selectedJobId={selectedJobId}
                                        setSelectedRigName={setSelectedRigName}
                                        setSelectedJobNumber={setSelectedJobNumber}
                                        setSelectedClientName={setSelectedClientName}
                                        setSelectedLocationName={setSelectedLocationName}
                                        mobileBottomHoleDescriptions={mobileBottomHoleDescriptions}
                                        jobState={jobState}
                                        setJobState={setJobState}
                                        totalHours={totalHours}
                                        setTotalHours={setTotalHours}
                                    />
                                </div>
                            </div>
                        </div>
                        {disciplineId > 0 ?
                            <div className="form-row">
                                <SubmitButton
                                    label="Save"
                                />
                                <CancelButton
                                    label="Cancel"
                                    onClick={toggleCancel}
                                />
                            </div>
                            :
                            <></>
                        }
                    </form>
                </>
            }
            <ModalWarning
                modal={warningModal}
                setModal={setWarningModal}
                title="Warning!!!"
            />
            <ModalConfirmation
                modal={totalHoursConfirmationModal}
                setModal={setTotalHoursConfirmationModal}
                title="Total shift hours out of range"
                onConfirm={handleTotalHoursConfirmButtonClick}
            />
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default FieldDaily;
