import React, {useEffect, useState} from 'react';
import Table from "../../../common/table/table";
import UsersTable from "./usersTable.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import EditUserModal from "./editUserModal";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";
import { useAuthenticate } from '../../../../hooks/auth';

/**
 * UsersBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const UsersBody = (prop) => {
    const {filters, setFilters, tableData, setTableData, selectedRow, setSelectedRow} = prop;
    const [deactivateModal, setDeactivateModal] = useState({isOpen: false, message: <p></p>});
    const [resetPasswordModal, setResetPasswordModal] = useState({isOpen: false, message: <p></p>});
    const [editUserModal, setEditUserModal] = useState({isOpen: false});
    const [reactivateModal, setReactivateModal] = useState({isOpen: false, message: <p></p>});
    const [editUserData, setEditUserEditData] = useState();
    const [userTypes, setUserTypes] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [userId, setUserId] = useState('');
    const { user } = useAuthenticate();

    useEffect(() => {

        // Disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });

        // User Types
        apiClient
            .get('getGroups')
            .then(response => {
                let optionItems = [];
                response.data.forEach(({id, group_name}) => {
                    if (group_name !== 'Client') {
                        // when system Admin
                        if (user?.group_id === 1) {
                            optionItems.push({id: id, name: group_name});
                        } else {
                            if (group_name !== 'Executive' && group_name !== 'System Administrator') {
                                optionItems.push({id: id, name: group_name});
                            }
                        }
                    }
                });

                setUserTypes(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [user]);


    const DeactivateHandler = () => {
        apiClient
            .post('deactivateUser', {'id': selectedRow.user_id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "user_id", response.data.id);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeactivateModal({...deactivateModal, isOpen: false})
            });
    }

    const ReactivateHandler = () => {
        apiClient
            .post('activateUser', {'id': selectedRow.user_id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "user_id", response.data.id);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close reactivateModal
                setReactivateModal({...reactivateModal, isOpen: false})
            });
    }

    const onResetPassword = () => {
        apiClient
            .post('resetUserPassword', {id: selectedRow.user_id})
            .then(response => {
                toast.success(response.data.message);
            }).then(function () {
            setResetPasswordModal({...resetPasswordModal, isOpen: false})
        });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    UsersTable(
                        setFilters, setSelectedRow, setDeactivateModal, setReactivateModal,
                        setEditUserModal, setEditUserEditData, setUserId, setResetPasswordModal
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'users'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={deactivateModal}
                setModal={setDeactivateModal}
                title="Deactivate User"
                onConfirm={DeactivateHandler}
            />
            <ModalConfirmation
                modal={reactivateModal}
                setModal={setReactivateModal}
                title="Reactivate User"
                onConfirm={ReactivateHandler}
            />
            <ModalConfirmation
                modal={resetPasswordModal}
                setModal={setResetPasswordModal}
                title="Reset Password"
                onConfirm={onResetPassword}
            />
            <EditUserModal
                modal={editUserModal}
                setModal={setEditUserModal}
                selectedRow={selectedRow}
                editUserData={editUserData}
                disciplines={disciplines}
                userTypes={userTypes}
                userId={userId}
                tableData={tableData}
                setTableData={setTableData}
                setEditUserModal={setEditUserModal}
                editUserModal={editUserModal}
            />
        </div>
    );
}

export default UsersBody;
