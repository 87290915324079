import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import FieldDailyHolesFilters from "./FieldDailyHolesFilters";
import FieldDailyHolesBody from "./FieldDailyHolesBody";
import EditFieldDailyHolesModal from "./EditFieldDailyHolesModal";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * FieldDailyHoles Component
 * @returns {JSX.Element}
 * @constructor
 */
const FieldDailyHoles = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [editModal, setEditModal] = useState(false);
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Field Daily Holes" parent="Field Dailies"/>
            <FieldDailyHolesFilters
                filters={filters}
                setTableData={setTableData}
                setFilters={setFilters}
            />
            <FieldDailyHolesBody
                filters={filters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setEditModal={setEditModal}
                setSelectedRow={setSelectedRow}
            />
            <EditFieldDailyHolesModal
                title="Edit Field Daily Hole"
                modal={editModal}
                setModal={setEditModal}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default FieldDailyHoles;
