import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import SubmitInvoicesFilters from "./submitInvoicesFilters";
import SubmitInvoicesBody from "./submitInvoicesBody";
import moment from 'moment';
import LoadingIndicator from "../../common/loadingIndicator";

/**
 * SubmitInvoices Report Component
 * @returns {JSX.Element}
 * @constructor
 */
const SubmitInvoices = () => {
    const [tableData, setTableData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        date_from: moment().startOf('month').format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        discipline_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Submit Invoices" parent="Accounting"/>
            <SubmitInvoicesFilters reportFilters={reportFilters} setTableData={setTableData} setReportFilters={setReportFilters}/>
            <SubmitInvoicesBody reportFilters={reportFilters} tableData={tableData} setTableData={setTableData}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default SubmitInvoices;
