import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import CreateInvoiceForm from "./createInvoiceForm";
import LoadingIndicator from "../../common/loadingIndicator";

/**
 * Create Invoice Component
 * @returns {JSX.Element}
 * @constructor
 */
const CreateInvoice = () => {
    const [filters, setFilters] = useState({
        job_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Create Invoice" parent="Invoicing"/>
            <CreateInvoiceForm {...{filters, setFilters}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default CreateInvoice;
