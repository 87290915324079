import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import DailyReportSummaryFilters from "./dailyReportSummaryFilters";
import DailyReportSummaryBody from "./dailyReportSummaryBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import ModalSendDailyEmailReport from "./modalSendDailyEmailReport";

/**
 * Daily Report Summary report component
 * @returns {JSX.Element}
 * @constructor
 */

const DailyReportSummary = () => {
    const [tableData, setTableData] = useState([]);
    const [sendReportModal, setSendReportModal] = useState({isOpen: false});
    const [reportFilters, setReportFilters] = useState({
        date: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Daily Report Summary" parent="Toolbox"/>
            <div className="s-daily-report-summary">
                <DailyReportSummaryFilters {...{tableData, setTableData, setReportFilters, setSendReportModal}} />
                <DailyReportSummaryBody {...{reportFilters, tableData}} />
            </div>
            <ModalSendDailyEmailReport modal={sendReportModal} setModal={setSendReportModal} reportFilters={reportFilters} />
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default DailyReportSummary;
