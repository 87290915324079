import React, {useState} from 'react';
import ModalConfirmation from "../../modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {addObjToArray, removeObjFromArray} from "../../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {useForm} from "react-hook-form";
import FormInputText from "../../../forms/form-control/FormInputText";
import TableReport from "../../table/table";
import EmailListTableColumns from "./EmailListTableColumns";

/**
 * Email list table component that allow to add/remove records from distribution_emails
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const EmailList = (prop) => {
    const {
        type,
        title,
        reportFilters,
        tableData,
        setTableData,
        withGlobalSearch = false
    } = prop;
    const {register, handleSubmit, reset, errors} = useForm();
    const [selectedRow, setSelectedRow] = useState({});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    if (!reportFilters.isSubmitted) {
        return null;
    }

    // submit handler to add email
    const onSubmit = (data) => {
        // add properties to data obj
        data.type = type;
        data.rig_id = reportFilters.rig_id;
        apiClient
            .post('addDistributionEmail', data)
            .then(response => {
                const newTableData = addObjToArray(tableData, response.data.distributionEmail);
                setTableData(newTableData);
                reset();
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            });
    }

    // handler to remove email
    const DeleteHandler = () => {
        apiClient
            .post('deleteDistributionEmail', {distribution_email_id: selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deleteModal
                setDeleteModal({...deleteModal, isOpen: false});
            });
    }

    return (
        <div className="container-fluid m-t-10">
            <h5>{title}</h5>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-row">
                    <FormInputText
                        id="email"
                        type="email"
                        className="col-md-4 mb-4"
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <FormSubmitButton
                        className="col-md-1"
                        btnClassName="btn-success m-b-10"
                        label="Add"
                    />
                </div>
            </form>
            <TableReport
                columns={EmailListTableColumns(setSelectedRow, setDeleteModal)}
                data={tableData}
                withGlobalSearch={withGlobalSearch}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Remove Email"
                onConfirm={DeleteHandler}
            />
        </div>
    );
}

export default EmailList;
