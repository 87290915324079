import React, {useState} from 'react';
import Table from "../../common/table/table";
import InvoicesTable from "./InvoicesTable";
import MessageEmptyData from "../../common/messageEmptyData";
import ModalConfirmation from "../../common/modal/ModalConfirmation";
import apiClient from "../../../utils/apiClient";
import {removeObjFromArray, replaceObjFromArray} from "../../../utils/arrayHelpers";
import {toast} from "react-toastify";

/**
 * InvoicesBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const InvoicesBody = (prop) => {
    const {filters, tableData, setTableData} = prop;
    const [selectedRow, setSelectedRow] = useState({});
    const [disapproveModal, setDisapproveModal] = useState({isOpen: false, message: <p></p>});
    const [approveModal, setApproveModal] = useState({isOpen: false, message: <p></p>});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    const onApprove = () => {
        apiClient
            .post('approveInvoice', {'id': selectedRow.id})
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
            })
            .then(function () {
                setApproveModal({...approveModal, isOpen: false})
            });
    }

    const onDisapprove = () => {
        apiClient
            .post('disapproveInvoice', {'id': selectedRow.id})
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
            })
            .then(function () {
                setDisapproveModal({...disapproveModal, isOpen: false})
            });
    }

    const onDelete = () => {
        apiClient
            .post('deleteInvoice', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .then(function () {
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    InvoicesTable(
                        setSelectedRow, setApproveModal, setDisapproveModal, setDeleteModal
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'invoices'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={approveModal}
                setModal={setApproveModal}
                title="Approve Invoice"
                onConfirm={onApprove}
            />
            <ModalConfirmation
                modal={disapproveModal}
                setModal={setDisapproveModal}
                title="Disapprove Invoice"
                onConfirm={onDisapprove}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Remove Invoice"
                onConfirm={onDelete}
            />
        </div>
    );
}

export default InvoicesBody;
