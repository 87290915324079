import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import OfficeTimeEntrySummaryBody from "./officeTimeEntrySummaryBody";
import PayPeriodFormFilter from "../../../common/form-filters/PayPeriodFormFilter";

/**
 * OfficeTimeEntrySummary Component
 * @returns {JSX.Element}
 * @constructor
 */
export default function OfficeTimeEntrySummary () {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Office Time Entry Summary" parent="Time Entry"/>
            <PayPeriodFormFilter {...{setFilters, setTableData, OnSubmitUrl:"getOfficeTimeEntrySummary"}}/>
            <OfficeTimeEntrySummaryBody {...{filters, tableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}
