import React from 'react';
import TableReport from "../../../common/table/table";
import directEntryColumns from "./directEntryColumns.js";
import MessageEmptyData from "../../../common/messageEmptyData";

/**
 * Body report component for DirectEntry Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const DirectEntryBody = (prop) => {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={directEntryColumns()}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'direct_entry'}
                withGlobalSearch={true}
                scrollable={true}
            />
        </div>
    );
}

export default DirectEntryBody;
