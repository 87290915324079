import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {useForm, useFieldArray} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import EmployeesTable from "./EmployeesTable";
import {toast} from "react-toastify";
import LoadingIndicator from "../../../common/loadingIndicator";
import FormInputText from "../../../forms/form-control/FormInputText";
import FormSelect from "../../../forms/form-control/FormSelect";
import {updateStateValue} from "../../../../utils/stateHelpers";
import FormInputTextArea from "../../../forms/form-control/FormInputTextArea";
import FormSelectAllCountries from "../../../forms/form-control/FormSelectAllCountries";
import {useHistory} from "react-router-dom";

/**
 * ClientForm Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CreateClient = (props) => {
    const action = props.match.params.action;
    const clientId = props.match.params.id;
    const history = useHistory();
    const [states, setStates] = useState([]);
    const [billingPeriods, setBillingPeriods] = useState([]);
    const [parents, setParents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {register, control, handleSubmit, errors} = useForm();
    const {fields, append, remove} = useFieldArray({control, keyName: 'fieldArrayId', name: "employees"});
    const [client, setClient] = useState({
        client_name: '',
        client_parent_id: '',
        erp_id: '',
        billing_address: '',
        billing_city: '',
        billing_zip: '',
        billing_contact_name: '',
        billing_email: '',
        billing_contact_number: '',
        state_id: '',
        billing_period_id: '',
        notes: '',
    });

    // filter selection handler
    const onChangeHandler = (e) => {
        const {id, value} = e.target;
        updateStateValue(setClient, id, value);
    }

    // save form handler
    const onSubmit = (data) => {
        setIsLoading(true);
        const url = (action === 'create') ? 'addClient' : 'updateClient/' + clientId;
        apiClient
            .post(url, data)
            .then(response => {
                toast.success(response.data.message);
                onCancel();
            })
            .catch(function () {
            })
            .then(function () {
                setIsLoading(false);
            });
    }

    // get list of options
    useEffect(() => {
        //get all countries states
        apiClient
            .get('getALLStates')
            .then(response => {
                const optionItems = response.data.states.map((state, index) =>
                    (index === 0)
                        ?
                            (<optgroup key={index} label='United States :'/>)
                        :
                            (index > 0 && index <= 49)
                            ?
                                (<option key={index} value={state.id}>{state.abbreviation + ' - ' + state.name}</option>)
                            :
                                (index === 50)
                                ?
                                    (<optgroup key={index} label='Canada Provinces :'/>)
                                :
                                    (<option key={index} value={state.id}>{state.abbreviation + ' - ' + state.name}</option>)
                );

                setStates(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // Billing Periods
        apiClient
            .get('billingPeriods')
            .then(response => {
                setBillingPeriods(
                    response.data.periods.map(
                        ({id, period_name}) => ({value: id, label: period_name})
                    )
                );
            });

        // Parents
        apiClient
            .get('getParents')
            .then(response => {
                setParents(
                    response.data.map(
                        ({id, parent_name}) => ({value: id, label: parent_name})
                    )
                );
            });

        if (action === 'edit') {
            setIsLoading(true);
            apiClient
                .post('getClientById', {id: clientId}).then(response => {
                    setClient(response.data.client);
                    remove();
                    append(response.data.client.employees);
                })
                .then(function () {
                    setIsLoading(false);
                });
        }
    }, [action, append, clientId, remove]);

    // cancel form handler
    const onCancel = () => {
        let url = (action === 'edit')
            ?
                "/commercial/clients/clients"
            :
                "/admin/users/create_client/create";
        history.push(url);
    }

    return (
        <>
            <Breadcrumb title={`${action} Client`} parent="Operations"/>
            <form
                className="needs-validation container-fluid"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-row s-gy">
                    <FormInputText
                        id="client_name"
                        label="Client Name"
                        type="text"
                        className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        value={client.client_name}
                        register={register({required: "required"})}
                        errors={errors}
                        isControlled={true}
                        onChange={onChangeHandler}
                    />
                    <FormSelect
                        id="client_parent_id"
                        label="Parent"
                        className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        value={client.client_parent_id}
                        onChange={onChangeHandler}
                        options={parents}
                        register={register}
                        errors={errors}
                        isControlled={true}
                    />
                    <FormInputText
                        id="erp_id"
                        label="VP ID"
                        type="text"
                        className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        value={client.erp_id}
                        register={register({required: "required"})}
                        errors={errors}
                        isControlled={true}
                        onChange={onChangeHandler}
                    />
                    <div className="col-lg-3"></div>

                    <FormInputText
                        id="billing_address"
                        label="Business Address"
                        type="text"
                        className="col-xs-12 col-sm-6 col-md-5 col-lg-4"
                        value={client.billing_address}
                        register={register}
                        isControlled={true}
                        onChange={onChangeHandler}
                    />
                    <FormInputText
                        id="billing_city"
                        label="City"
                        type="text"
                        className="col-xs-12 col-sm-6 col-md-2 col-lg-2"
                        value={client.billing_city}
                        register={register}
                        isControlled={true}
                        onChange={onChangeHandler}
                    />
                    <FormSelectAllCountries
                        id="state_id"
                        label="State"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        value={client.state_id}
                        onChange={onChangeHandler}
                        options={states}
                        register={register}
                        isControlled={true}
                    />
                    <FormInputText
                        id="billing_zip"
                        label="Zip"
                        type="text"
                        className="col-xs-12 col-sm-6 col-md-2 col-lg-1"
                        value={client.billing_zip}
                        register={register}
                        isControlled={true}
                        onChange={onChangeHandler}
                    />
                    <div className="col-lg-3"></div>

                    <FormInputText
                        id="billing_contact_name"
                        label="AP Contact Name"
                        type="text"
                        className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        value={client.billing_contact_name}
                        register={register}
                        isControlled={true}
                        onChange={onChangeHandler}
                    />
                    <FormInputText
                        id="billing_email"
                        label="Email"
                        type="text"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                        value={client.billing_email}
                        register={register({required: "required"})}
                        errors={errors}
                        isControlled={true}
                        onChange={onChangeHandler}
                    />
                    <FormInputText
                        id="billing_contact_number"
                        label="Contact Number"
                        type="text"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        value={client.billing_contact_number}
                        register={register}
                        isControlled={true}
                        onChange={onChangeHandler}
                    />
                    <FormSelect
                        id="billing_period_id"
                        label="Payment Terms"
                        className="col-xs-12 col-sm-6 col-md-2 col-lg-1"
                        value={client.billing_period_id}
                        onChange={onChangeHandler}
                        options={billingPeriods}
                        register={register({required: "required"})}
                        errors={errors}
                        isControlled={true}
                    />
                    <div className="col-lg-3"></div>
                    
                    <FormInputTextArea
                        id="notes"
                        label="Notes"
                        type="text"
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-9"
                        value={client.notes}
                        register={register}
                        isControlled={true}
                        onChange={onChangeHandler}
                    />
                </div>

                <div className="form-row m-t-10">
                    <h5 className="padding30">Users</h5>
                </div>
                <div className="form-row">
                    <EmployeesTable
                        register={register}
                        control={control}
                        errors={errors}
                        fields={fields}
                        append={append}
                        remove={remove}
                    />
                </div>
                <div className="row">
                    
                </div>
                <div className="form-row m-t-10">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-2">
                        <div className="s-flex s-justify-between">
                            <FormSubmitButton
                                btnClassName="btn-primary"
                                label="Save"
                            />
                            <FormSubmitButton
                                btnClassName="btn-secondary"
                                type="button"
                                label="Cancel"
                                onClick={onCancel}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default React.memo(CreateClient);
