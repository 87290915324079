import {FaFileMedical} from "react-icons/fa";
import apiClient from "../../../../utils/apiClient";
import React from 'react';
import {toast} from "react-toastify";

const AddPOItem = (row) => {
    apiClient
        .post('POItems',
            {
                'po_number' : row.PST_PO,
                'line' : row.PST_POItem,
                'vendor_id' : row.POHD_Vendor,
                'vendor_name' : row.Vendor_Name,
                'job_number': row.POIT_Job,
                'job_description' : row.Job_Description,
                'ordered_by' : row.POHD_OrderedBy,
                'order_date' : row.POHD_OrderDate,
                'description': row.POIT_Description,
                'price' : row.POIT_CurCost
        })
        .then(response => {
            toast.success(response.data.message);
        })
        .catch((error) => {
            console.error(error);
        });
}

export const JobCostPOTable = [
    {
        Header: 'PO #',
        accessor: 'PST_PO',
        width: 70,
    },
    {
        Header: 'Line',
        accessor: 'PST_POItem',
        width: 40,
    },
    {
        Header: 'Vendor ID',
        accessor: 'POHD_Vendor',
        width: 80,
    },
    {
        Header: 'Name',
        accessor: 'Vendor_Name',
    },
    {
        Header: 'Job',
        accessor: 'POIT_Job',
        width: 90,
    },
    {
        Header: 'Job Description',
        accessor: 'Job_Description',
    },
    {
        Header: 'Ordered By',
        accessor: 'POHD_OrderedBy',
        width: 90,
    },
    {
        Header: 'Order Date',
        accessor: 'POHD_OrderDate',
        width: 90,
    },
    {
        Header: 'Description',
        accessor: 'POIT_Description',
    },
    {
        Header: 'Cost',
        accessor: 'POIT_CurCost',
        width: 70,
    },
    {
        Header: 'Status',
        accessor: 'status',
        width: 50,
    },
    {
        Header: 'Actions',
        width: 100,
        Cell: (cell) => {
            const row = cell.row.original;
            return (
                <>
                    <FaFileMedical
                        title="Add to PO Items"
                        color='#A51E37'
                        size={20}
                        onClick={ () => { AddPOItem(row) }}
                    />
                </>
            );
        }
    }
];
