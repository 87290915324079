import React, {useState, useEffect} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import apiClient from "../../../utils/apiClient";
import {toast} from "react-toastify";
import {isEmpty} from "lodash"
import TableReport from "../../common/table/table";
import BottomHoleDescriptionsTable from "./BottomHoleDescriptionsTable";
import ModalConfirmation from "../../common/modal/ModalConfirmation";
import {removeObjFromArray} from "../../../utils/arrayHelpers";
import BottomHoleDescriptionsFormModal from "./BottomHoleDescriptionsFormModal";

const BottomHoleDescriptions = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [formModal, setFormModal] = useState({isOpen: false, action: 'create'});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    // delete function
    const deleteMobileAppSetting = () => {
        apiClient
            .delete('mobileBottomHoleDescriptions/' + selectedRow.id)
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    useEffect(() => {
        apiClient
            .get('mobileBottomHoleDescriptions')
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            });
    }, [setTableData]);

    return (
        <>
            <Breadcrumb title="Bottom Hole Descriptions" parent="Admin"/>
            <div className="container-fluid">
                <TableReport
                    columns={BottomHoleDescriptionsTable(setSelectedRow, setFormModal, setDeleteModal)}
                    data={tableData}
                    getExportFileName={() => 'mobile_app_settings'}
                />
                 <BottomHoleDescriptionsFormModal
                    title="Bottom Hole Description"
                    modal={formModal}
                    setModal={setFormModal}
                    tableData={tableData}
                    setTableData={setTableData}
                    selectedRow={selectedRow}
                />
                <ModalConfirmation
                    modal={deleteModal}
                    setModal={setDeleteModal}
                    title="Delete Bottom Hole Description"
                    onConfirm={deleteMobileAppSetting}
                />
            </div>
        </>
    );
}

export default BottomHoleDescriptions;
