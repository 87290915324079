import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useForm} from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {getCurrentPeriodFromArray} from "../../../utils/arrayHelpers";
import {updateStateValue} from "../../../utils/stateHelpers";
import {isEmpty, isUndefined} from "lodash";
import { useAuthenticate } from '../../../hooks/auth';

/**
 * Filter component for employees review report
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeesReviewFilters = (prop) => {
    const {register, handleSubmit, errors} = useForm();
    const {tableData, setTableData, reportFilters, setReportFilters} = prop;
    const [disciplines, setDisciplines] = useState([]);
    const [rigs, setRigs] = useState([]);
    const [payPeriods, setPayPeriods] = useState([]);
    const { user } = useAuthenticate();
    const TOOL_PUSHER = 11;
    const DRILLER_ASSISTANT = 9;
    const DRILLER = 7;
    const [endDate, setEndDate] = useState(0);

    // submit handler for report filter
    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient
            .post('getEmployeesReviewByPayPeriodAndRig', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    }

    // submit handler for approve
    const onSubmitApproveTime = () => {
        apiClient
            .post('approveDailyEmployeesByPayPeriod', reportFilters)
            .then(() => {
                handleSubmit(onSubmit)();
            });
    }

    // submit handler for unapprove
    const onSubmitUnapproveTime = () => {
        apiClient
            .post('unapproveDailyEmployeesByPayPeriod', reportFilters)
            .then(() => {
                handleSubmit(onSubmit)();
            });
    }

    // get list of disciplines and pay periods
    useEffect(() => {
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                let year = Number(moment(response.data.end_date, 'MM-DD-YY').format('YYYY'));
                setEndDate(year);
            });

        apiClient
            .post('getPayPeriodsByYear', {'year': endDate})
            .then(response => {
                setPayPeriods(
                    response.data.map(
                        ({id, start_date, end_date, is_current}) => ({
                            id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });

        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
        });
    }, [endDate]);

    // get list of rigs
    useEffect(() => {
        let discipline = (user?.group_id === TOOL_PUSHER || user?.group_id === DRILLER || user?.group_id === DRILLER_ASSISTANT)
            ?
                user?.discipline_id
            :
                reportFilters.discipline_id;

        if (discipline) {
            apiClient
                .post('getAllActiveRigsByDisciplineId',
                {'discipline_id': discipline})
                .then(response => {
                    setRigs(
                        response.data.rigs.map(
                            ({id, name}) => ({id: id, name: name})
                        )
                    );
                });
        }
    }, [reportFilters.discipline_id, setReportFilters, user]);

    const defaultPayPeriod = getCurrentPeriodFromArray(payPeriods);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="row s-gy">
                {!isUndefined(defaultPayPeriod) && <>
                    { (user?.group_id === TOOL_PUSHER || user?.group_id === DRILLER || user?.group_id === DRILLER_ASSISTANT)
                        ?
                            <FormSelect
                                id="discipline_id"
                                label="Discipline"
                                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                options={disciplines}
                                value={user?.discipline_id}
                                isControlled={true}
                                disabled
                                register={register({required: "required"})}
                                errors={errors}
                            />
                        :
                            <FormSelect
                                id="discipline_id"
                                label="Discipline"
                                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                options={disciplines}
                                register={register({required: "required"})}
                                errors={errors}
                                onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                            />

                    }
                    <FormSelect
                        id="rig_id"
                        label="Rigs"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        options={rigs}
                        register={register({required: "required"})}
                        errors={errors}
                        onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    />
                    <FormSelect
                        id="pay_period_id"
                        label="Pay Period"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                        value={defaultPayPeriod.id}
                        options={payPeriods}
                        register={register({required: "required"})}
                        errors={errors}
                        onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    />
                    <FormSubmitButton
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        label="Show"
                    />
                </>}
            </div>
            {(!isEmpty(tableData) && (tableData.tableLabel.type !== 'approved')) &&
                <div className="form-row marginTop">
                    <FormSubmitButton
                        type="button"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        label="Approve Time"
                        btnClassName="btn-success m-b-10"
                        onClick={() => handleSubmit(onSubmitApproveTime)()}
                    />
                </div>
            }
            {(!isEmpty(tableData) && (tableData.tableLabel.type === 'approved')) &&
                <div className="form-row">
                    <FormSubmitButton
                        type="button"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        label="Remove Time Approval"
                        btnClassName="btn-primary m-b-10"
                        onClick={() => handleSubmit(onSubmitUnapproveTime)()}
                    />
                </div>
            }
        </form>
    );
}

export default EmployeesReviewFilters;
