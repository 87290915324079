import React, { Fragment, useState, useLayoutEffect } from 'react';
import logo from '../../../assets/images/Background_Login/national_menu_logo.png';
import logo_compact from '../../../assets/images/Background_Login/national_menu_logo.png';
import { Link } from 'react-router-dom';
import Menu from "./menu";
import ConfigDB from "../../../data/customizer/config";

function useWindowSize(wrapper) {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    if (wrapper === "horizontal_sidebar") {
        if (size[0] > 100 && size[0] < 991) {
            document.querySelector(".page-wrapper").className = 'page-wrapper default';
            document.querySelector(".page-body-wrapper").className = 'page-body-wrapper default';
        } else {
            document.querySelector(".page-wrapper").className = 'page-wrapper horizontal_sidebar';
            document.querySelector(".page-body-wrapper").className = 'page-body-wrapper horizontal_sidebar';
        }
    }

    return size;
}

const Sidebar = ({ openCloseSidebar }) => {
    const [margin, setMargin] = useState(0);
    const [hideRightArrow, setHideRightArrow] = useState(false);
    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(false);
    const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
    const configDB = ConfigDB.data.sidebar_types;
    const layout = ConfigDB.data.settings.layout_type;
    const [width] = useWindowSize(ConfigDB.data.wrapper);

    const scrollToRight = () => {
        const element = document.getElementById("myDIV");
        const menuWidth = element.offsetWidth;
        const temp = menuWidth + margin
        // Checking condition for remain margin
        if (temp < menuWidth) {
            setMargin(-temp);
            setHideRightArrow(true);
        } else {
            setMargin(margin => margin += -width);
            setHideLeftArrow(false);
        }
    }

    const scrollToLeft = () => {
        // If Margin is reach between screen resolution
        if (margin >= -width) {
            setMargin(0);
            setHideLeftArrow(true);
        } else {
            setMargin(margin => margin += width);
            setHideRightArrow(false);
        }
    }

    const scrollToLeftRTL = () => {
        if (margin <= -width) {
            setMargin(margin => margin += -width);
            setHideLeftArrowRTL(true);
        } else {
            setMargin(margin => margin += -width);
            setHideRightArrowRTL(false);
        }
    }

    const scrollToRightRTL = () => {
        const temp = width + margin;
        // Checking condition for remain margin
        if (temp === 0) {
            setMargin(temp);
            setHideRightArrowRTL(true);
        } else {
            setMargin(margin => margin += width);
            setHideRightArrowRTL(false);
            setHideLeftArrowRTL(false);
        }
    }

    return (
        <Fragment>
            <div className="page-sidebar d-print-none">
                <div className="main-header-left d-none d-lg-block">
                    <div className="logo-wrapper compactLogo">
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/user_dashboard`}>
                            <img className="blur-up lazyloaded" src={logo_compact} alt="" />
                            <img className="blur-up lazyloaded whitelabel-logo" src={logo} alt="" />
                        </Link>
                    </div>
                </div>
                <div className="sidebar custom-scrollbar">
                    <ul
                        className="sidebar-menu"
                        id="myDIV"
                        style={configDB.wrapper === 'horizontal_sidebar' ? layout === 'rtl' ?
                            { 'marginRight': margin + 'px' } : { 'marginLeft': margin + 'px' } : { margin: '0px' }}
                    >
                        <li
                            className={
                                `left-arrow ${layout === 'rtl' 
                                    ? 
                                        hideLeftArrowRTL 
                                            ? 
                                                'd-none' 
                                            : 
                                                'hideLeftArrowRTL' 
                                    : 
                                        hideLeftArrow 
                                            ? 
                                                'd-none' 
                                            : 
                                                'hideLeftArrow'}`
                            }
                            onClick={
                                (configDB.wrapper === 'horizontal_sidebar' && layout === 'rtl')
                                    ?
                                        scrollToLeftRTL
                                    :
                                        scrollToLeft
                            }
                        >
                            <i className="fa fa-angle-left"/>
                        </li>
                        <Menu openCloseSidebar={openCloseSidebar} />
                        <li
                            className={
                                `right-arrow ${layout === 'rtl' 
                                    ? 
                                        hideRightArrowRTL 
                                            ? 
                                                'd-none' 
                                            : 
                                                'hideRightArrowRTL' 
                                    : 
                                        hideRightArrow 
                                            ? 
                                                'd-none' 
                                            : 
                                                'hideRightArrow'}`
                            }
                            onClick={
                                (configDB.wrapper === 'horizontal_sidebar' && layout === 'rtl')
                                    ?
                                        scrollToRightRTL
                                    :
                                        scrollToRight
                            }
                        >
                            <i className="fa fa-angle-right"/>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
}

export default Sidebar;

