import { httpClient, BASE_URL } from "./base";

const AUTH_BASE = `${BASE_URL}`;

export const AuthApis = {
    signin: async (email, password) => {
        return await httpClient.post(`${AUTH_BASE}login`, { email, password });
    },

    signout: async (token) => {
        return await httpClient.authPost(token, `${AUTH_BASE}logout`);
    },

    authGet: async (token) => {
        return await httpClient.authGet(token, `${AUTH_BASE}userInfo`);
    },
};
