import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import {useFieldArray} from "react-hook-form";

const Casing = (props) => {
    const {
        errors, register, control, collectedHoles, fieldDaily, action, disciplineId, fieldCasingsTemplate
    } = props;
    const kindOfCasing = [1, 2].includes(disciplineId) ? 'core_casing' : 'rotary_casing';
    const kindOfDepth = [1, 2].includes(disciplineId) ? 'footage' : 'depth';
    const {fields, append, remove} = useFieldArray({control, name: kindOfCasing});
    const [selectedCoreCasingHole, setSelectedCoreCasingHole] = useState('');

    useEffect(() => {
        const fieldArr = fields;
        if (action === 'edit') {
            append([1, 2].includes(disciplineId) ? fieldDaily.field_core_casing : fieldDaily.field_rotary_casing);
        }

        if (action === 'create' && fieldCasingsTemplate.length > 0) {
            // delete all field values and populate field array.
            remove();
            if (fieldArr.length>0) {
                append(fieldArr);
            } else {
                append(fieldCasingsTemplate)
            }
        }

        if (action === 'create' && fieldCasingsTemplate.length === 0) {
            remove();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        action, fieldDaily.field_core_casing, fieldDaily.field_rotary_casing,
        append, disciplineId, fieldCasingsTemplate, remove
    ]);

    return (
        <AccordionItem className="card-header bg-primary" title="Casing">
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        {[3, 4].includes(disciplineId) &&
                                            <th>Type</th>
                                        }
                                        <th>Size</th>
                                        <th>Footage</th>
                                        <th>Hole</th>
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            {[3, 4].includes(disciplineId) &&
                                                <InputInTable
                                                    id={`${kindOfCasing}[${index}].type`}
                                                    name={`${kindOfCasing}[${index}].type`}
                                                    register={register({required: "required"})}
                                                    value={(item.type) ? item.type : ''}
                                                    errors={errors}
                                                />
                                            }
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`${kindOfCasing}[${index}].size`}
                                                name={`${kindOfCasing}[${index}].size`}
                                                value={(item.size) ? item.size : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`${kindOfCasing}[${index}].${kindOfDepth}`}
                                                name={`${kindOfCasing}[${index}].${kindOfDepth}`}
                                                type="number"
                                                value={item.footage ? item.footage:((item.depth) ? item.depth : '')}                                    
                                                onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`${kindOfCasing}[${index}].hole_name`}
                                                    name={`${kindOfCasing}[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={
                                                        (collectedHoles.length === 1)
                                                            ?
                                                                collectedHoles[0].value
                                                            :
                                                                (item.hole_name)
                                                                    ?
                                                                        item.hole_name
                                                                    :
                                                                        ''
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedCoreCasingHole)
                                                    }
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`${kindOfCasing}[${index}].hole_name`}
                                                    name={`${kindOfCasing}[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={
                                                        selectedCoreCasingHole
                                                            ?
                                                                selectedCoreCasingHole
                                                            :
                                                                (collectedHoles.length === 1)
                                                                    ?
                                                                        collectedHoles[0].value
                                                                    :
                                                                        item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedCoreCasingHole)
                                                    }
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => remove(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default Casing;
