import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import DailyAccordion from "./DailyAccordion";
import {getHours} from "../../../../utils/listHelpers";
import {toast} from "react-toastify";
import LoadingIndicator from "../../../common/loadingIndicator";
import FormSelectDaily from "../../../forms/form-control/FormSelectDaily";
import CancelButton from "../../../forms/form-control/daily/CancelButton";
import SubmitButton from "../../../forms/form-control/daily/SubmitButton";
import ModalWarning from "../../../common/modal/ModalWarning";
import DailyTotalsTable from "./DailyTotalsTable";
import {isUndefined} from "lodash";
import { useAuthenticate } from '../../../../hooks/auth';

/**
 * Create Daily Component
 * @returns {JSX.Element}
 * @constructor
 */
const Daily = (props) => {
    const dailyId = (props.match.params.id > 0) ? props.match.params.id : 0;
    const action = (props.match.params.action) ? props.match.params.action : '';
    const [daily, setDaily] = useState([]);
    const [rigsAvailable, setRigsAvailable] = useState([]);
    const [fieldEmployeesAvailable, setFieldEmployeesAvailable] = useState([]);
    const [activityDescriptions, setActivityDescriptions] = useState([]);
    const [drilledDescriptions, setDrilledDescriptions] = useState([]);
    const [reamedDescriptions, setReamedDescriptions] = useState([]);
    const [generalDescriptions, setGeneralDescriptions] = useState([]);
    const [consumableDescriptions, setConsumableDescriptions] = useState([]);
    const [disciplineId, setDisciplineId] = useState(0);
    const [disciplines, setDisciplines] = useState([]);
    const [rigs, setRigs] = useState([]);
    const [totalActivities, setTotalActivities] = useState(0);
    const [totalDrilledPrice, setTotalDrilledPrice] = useState(0);
    const [totalReamedPrice, setTotalReamedPrice] = useState(0);
    const [totalDrilledVariance, setTotalDrilledVariance] = useState(0);
    const [totalReamedVariance, setTotalReamedVariance] = useState(0);
    const [totalConsumables, setTotalConsumables] = useState(0);
    const [totalGeneralCharges, setTotalGeneralCharges] = useState(0);
    // const [totalDrillingHours, setTotalDrillingHours] = useState([]);
    const [totalDrilled, setTotalDrilled] = useState(0);
    const [fieldEmployees, setFieldEmployees] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [warningModal, setWarningModal] = useState({isOpen: false, message: <p></p>});
    const [selectedRigName, setSelectedRigName] = useState('');
    const [selectedJobNumber, setSelectedJobNumber] = useState('');
    const [selectedLocationName, setSelectedLocationName] = useState('');
    const {register, control, handleSubmit, errors, reset, setValue, getValues, watch} = useForm();
    const [jobState, setJobState] = useState(0);
    const { user } = useAuthenticate();
    const TOOL_PUSHER = 11;
    const DRILLER_ASSISTANT = 9;
    const DRILLER = 7;

    // Discipline selection handler
    const disciplineSelectionHandler = (disciplineId) => {
        if (disciplineId !== null) {
            setDisciplineId(disciplineId);
        }

        // Employees
        apiClient
            .post('getActiveFieldEmployees', {'discipline_id': disciplineId})
            .then(response => {
                const fieldEmployees = response.data.activeFieldEmployees.map(
                    ({id, first_name, last_name}) => ({value: id, label: first_name + ' ' + last_name})
                );
                setFieldEmployees(fieldEmployees);
        });

        // Rigs
        apiClient
            .post('getAllActiveRigsByDisciplineId', {'discipline_id': disciplineId})
            .then(response => {
                const rigItems = response.data.rigs.map(
                    ({id, name}) => ({value: id, label: name})
                );
                setRigs(rigItems);
        });
    }

    // clean form
    const toggleCancel = () => {
        window.location.reload();
    }

    useEffect(() => {
        if (action === 'edit' && dailyId > 0) {
            /**
             * TODO: temporary fix to avoid the page to scroll down
             * The real problem is to figure out why general charge price is focus
             * once the focus problem is resolved this setTimeout can be removed
             **/
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 500);

            // get list of options getActiveFieldById
            apiClient
                .post('getActiveDailyById', {'daily_id': dailyId})
                .then(response => {
                    setDaily(response.data);
                    setDisciplineId(response.data.discipline_id);
                    // assign state id to later define if it is CA
                    setJobState(response.data.job?.state_id);

                    // Employees
                    apiClient
                        .post(
                        'getActiveFieldEmployees',
                        {'discipline_id': response.data.discipline_id}
                        )
                        .then(response => {
                            const fieldEmployees = response.data.activeFieldEmployees.map(
                                ({id, first_name, last_name}) => ({value: id, label: first_name + ' ' + last_name})
                            );

                            setFieldEmployeesAvailable(fieldEmployees);
                        }
                    );

                    // Rigs
                    apiClient
                        .post(
                        'getAllActiveRigsByDisciplineId',
                        {'discipline_id': response.data.discipline_id}
                        )
                        .then(response => {
                                const rigItems = response.data.rigs.map(
                                    ({id, name}) => ({value: id, label: name})
                                );
                                setRigsAvailable(rigItems);
                            }
                        );

                    // Activity Descriptions
                    apiClient
                        .post('getActivityDescriptionsByJobId', {'job_id': response.data.job_id})
                        .then(response => {
                            setActivityDescriptions(
                                response.data.map(
                                    ({code, description, revenue_group}) => ({value: code, label: description, revenue_group: revenue_group})
                                )
                            );
                        });

                    // General Descriptions
                    apiClient
                        .post('getGeneralDescriptionsByJobId', {'job_id': response.data.job_id})
                        .then(response => {
                            setGeneralDescriptions(
                                response.data.map(
                                    ({code, description}) => ({value: code, label: description})
                                )
                            );
                        });

                    // Consumable Descriptions
                    apiClient
                        .post('getConsumableDescriptionsByJobId', {'job_id': response.data.job_id})
                        .then(response => {
                            const consumableItems = response.data.map(
                                ({code, description}) => ({value: code, label: description})
                            );

                            setConsumableDescriptions(consumableItems);
                        });

                    // Drilled Descriptions
                    apiClient
                        .post('getDrilledDescriptionsByJobId', {'job_id': response.data.job_id})
                        .then(response => {
                            setDrilledDescriptions(
                                response.data.map(
                                    ({code, description}) => ({value: code, label: description})
                                )
                            );
                        });

                    // Reamed Descriptions
                    apiClient
                        .post('getReamedDescriptionsByJobId', {'job_id': response.data.job_id})
                        .then(response => {
                            setReamedDescriptions(response.data.map(
                                ({code, description}) => ({value: code, label: description})
                            ));
                        });
                });
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // saveDaily
    const saveDaily = (data) => {
        const url = (action === 'edit') ? 'editDaily' : 'createDaily';

        if (action === 'create') {
            if (user?.group_id === TOOL_PUSHER || user?.group_id === DRILLER || user?.group_id === DRILLER_ASSISTANT) {
                data['discipline_id'] = user?.discipline_id;
            }
        }

        // when editing field daily
        if (action === 'edit') {
            data['id'] = daily.id;
            data['discipline_id'] = disciplineId;
            // due to that those are disabled at general information card
            data['general_info']['job_id'] = daily.job_id;
            data['general_info']['client_id'] = daily.client_id;
            data['general_info']['rig_id'] = daily.rig_id;
            data['field_daily_id'] = daily.field_daily_id;
        }

        if (isUndefined(data.employee)) {
            const msg = 'Please Enter Employee Information';
            setWarningModal({
                message: msg,
                isOpen: true,
            });

            return null;
        }

        const isSetDriller = data.employee.some((emp) => emp.is_driller === 'true');

        if (!isSetDriller) {
            const msg = 'At least one driller must be selected';
                setWarningModal({
                    message: msg,
                    isOpen: true,
                });

                return null;
        }

        if ([3, 4].includes(Number(data.discipline_id))) {
            if (typeof data.hole_rotary === 'undefined') {
                const msg = 'Please Enter Hole Information';
                setWarningModal({
                    message: msg,
                    isOpen: true,
                });
                return null;
            }
        }

        if ([1, 2].includes(Number(data.discipline_id))) {
            if (typeof data.hole_core === 'undefined') {
                const msg = 'Please Enter Hole Information';
                setWarningModal({
                    message: msg,
                    isOpen: true,
                });
                return null;
            }
        }

        setIsLoading(true);
        apiClient
            .post(url, JSON.stringify(data))
            .then(response => {
                toast.success(response.data.message);
                // do not comment/remove
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                setIsLoading(false);
            });
    }

    // get list of options
    useEffect(() => {
        if (action === 'create') {
            if (user?.group_id === TOOL_PUSHER || user?.group_id === DRILLER || user?.group_id === DRILLER_ASSISTANT) {
                disciplineSelectionHandler(user?.discipline_id);
            }
        }

        //get disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                const disciplines = response.data.disciplines.map(
                    ({id, name}) => ({value: id, label: name})
                );
                setDisciplines(disciplines);
        });
    }, [action, user]);

    return (
        <>
            {action === 'create' &&
                <>
                    <Breadcrumb title="Create Daily" parent="Dailies"/>
                    <form
                        className="needs-validation"
                        onSubmit={handleSubmit(saveDaily)}
                    >
                        <DailyTotalsTable
                            user={user}
                            watch={watch}
                            daily={daily}
                            action={action}
                            totalActivities={totalActivities}
                            totalConsumables={totalConsumables}
                            totalGeneralCharges={totalGeneralCharges}
                            totalDrilled={totalDrilled}
                            totalDrilledPrice={totalDrilledPrice}
                            totalReamedPrice={totalReamedPrice}
                            totalDrilledVariance={totalDrilledVariance}
                            totalReamedVariance={totalReamedVariance}
                            selectedJobNumber={selectedJobNumber}
                            selectedRigName={selectedRigName}
                            selectedLocationName={selectedLocationName}
                        />
                        {
                            (user?.group_id !== TOOL_PUSHER && user?.group_id !== DRILLER && user?.group_id !== DRILLER_ASSISTANT) &&
                            <div className="form-row padding30">
                                <FormSelectDaily
                                    id='discipline_id'
                                    label='Discipline'
                                    name='discipline_id'
                                    className="mb-3"
                                    onChange={(e) => disciplineSelectionHandler(e.target.value)}
                                    options={disciplines}
                                    register={register({required: {value: true, message: "required"}})}
                                />
                            </div>
                        }
                        {disciplineId > 0 &&
                            <>
                                <div className="card" id="accordion_card">
                                    <div className="card-body">
                                        <div className="default-according panel-accordion" id="accordionclose">
                                            <DailyAccordion
                                                disciplineId={Number(disciplineId)}
                                                rigs={rigs}
                                                action={action}
                                                fieldEmployees={fieldEmployees}
                                                setActivityDescriptions={setActivityDescriptions}
                                                setDrilledDescriptions={setDrilledDescriptions}
                                                setReamedDescriptions={setReamedDescriptions}
                                                setGeneralDescriptions={setGeneralDescriptions}
                                                setConsumableDescriptions={setConsumableDescriptions}
                                                activityDescriptions={activityDescriptions}
                                                drilledDescriptions={drilledDescriptions}
                                                reamedDescriptions={reamedDescriptions}
                                                generalDescriptions={generalDescriptions}
                                                consumableDescriptions={consumableDescriptions}
                                                setTotalActivities={setTotalActivities}
                                                setTotalDrilledPrice={setTotalDrilledPrice}
                                                setTotalReamedPrice={setTotalReamedPrice}
                                                totalDrilledPrice={totalDrilledPrice}
                                                totalReamedPrice={totalReamedPrice}
                                                setTotalDrilledVariance={setTotalDrilledVariance}
                                                setTotalReamedVariance={setTotalReamedVariance}
                                                totalDrilledVariance={totalDrilledVariance}
                                                totalReamedVariance={totalReamedVariance}
                                                totalActivities={totalActivities}
                                                totalConsumables={totalConsumables}
                                                setTotalConsumables={setTotalConsumables}
                                                totalGeneralCharges={totalGeneralCharges}
                                                setTotalGeneralCharges={setTotalGeneralCharges}
                                                totalDrilled={totalDrilled}
                                                setTotalDrilled={setTotalDrilled}
                                                register={register}
                                                control={control}
                                                errors={errors}
                                                setValue={setValue}
                                                getValues={getValues}
                                                reset={reset}
                                                hours={getHours()}
                                                setWarningModal={setWarningModal}
                                                warningModal={warningModal}
                                                selectedJobNumber={selectedJobNumber}
                                                setSelectedRigName={setSelectedRigName}
                                                setSelectedJobNumber={setSelectedJobNumber}
                                                setSelectedLocationName={setSelectedLocationName}
                                                jobState={jobState}
                                                setJobState={setJobState}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {disciplineId > 0 ?
                                    <div className="form-row">
                                        <SubmitButton
                                            label="Create"
                                        />
                                        <CancelButton
                                            label="Cancel"
                                            onClick={toggleCancel}
                                        />
                                    </div>
                                    :
                                    <></>
                                }
                            </>
                        }
                    </form>
                    <ModalWarning
                        modal={warningModal}
                        setModal={setWarningModal}
                        title="Warning!!!"
                    />
                    <LoadingIndicator isLoading={isLoading}/>
                </>
            }
            {action === 'edit' && dailyId > 0 &&
                <>
                    <Breadcrumb title="Edit Daily" parent="Dailies"/>
                    <form
                        className="needs-validation"
                        onSubmit={handleSubmit(saveDaily)}
                    >
                        <DailyTotalsTable
                            watch={watch}
                            daily={daily}
                            action={action}
                            totalActivities={totalActivities}
                            totalDrilledPrice={totalDrilledPrice}
                            totalReamedPrice={totalReamedPrice}
                            totalConsumables={totalConsumables}
                            totalGeneralCharges={totalGeneralCharges}
                            totalDrilled={totalDrilled}
                            totalDrilledVariance={totalDrilledVariance}
                            totalReamedVariance={totalReamedVariance}
                            // totalDrillingHours={totalDrillingHours}
                            selectedJobNumber={selectedJobNumber}
                            selectedRigName={selectedRigName}
                            selectedLocationName={selectedLocationName}
                        />
                        <div className="card" id="accordion_card">
                            <div className="card-body">
                                <div className="default-according panel-accordion" id="accordionclose">
                                    <DailyAccordion
                                        disciplineId={disciplineId}
                                        rigsAvailable={rigsAvailable}
                                        daily={daily}
                                        action={action}
                                        fieldEmployeesAvailable={fieldEmployeesAvailable}
                                        setActivityDescriptions={setActivityDescriptions}
                                        setDrilledDescriptions={setDrilledDescriptions}
                                        setReamedDescriptions={setReamedDescriptions}
                                        setGeneralDescriptions={setGeneralDescriptions}
                                        setConsumableDescriptions={setConsumableDescriptions}
                                        activityDescriptions={activityDescriptions}
                                        drilledDescriptions={drilledDescriptions}
                                        reamedDescriptions={reamedDescriptions}
                                        generalDescriptions={generalDescriptions}
                                        consumableDescriptions={consumableDescriptions}
                                        setTotalActivities={setTotalActivities}
                                        setTotalDrilledVariance={setTotalDrilledVariance}
                                        setTotalReamedVariance={setTotalReamedVariance}
                                        totalDrilledVariance={totalDrilledVariance}
                                        setTotalReamedPrice={setTotalReamedPrice}
                                        totalReamedPrice={totalReamedPrice}
                                        totalReamedVariance={totalReamedVariance}
                                        setTotalDrilledPrice={setTotalDrilledPrice}
                                        totalDrilledPrice={totalDrilledPrice}
                                        totalActivities={totalActivities}
                                        totalConsumables={totalConsumables}
                                        setTotalConsumables={setTotalConsumables}
                                        totalGeneralCharges={totalGeneralCharges}
                                        setTotalGeneralCharges={setTotalGeneralCharges}
                                        totalDrilled={totalDrilled}
                                        setTotalDrilled={setTotalDrilled}
                                        register={register}
                                        control={control}
                                        errors={errors}
                                        setValue={setValue}
                                        getValues={getValues}
                                        reset={reset}
                                        hours={getHours()}
                                        setWarningModal={setWarningModal}
                                        warningModal={warningModal}
                                        selectedJobNumber={selectedJobNumber}
                                        setSelectedRigName={setSelectedRigName}
                                        setSelectedJobNumber={setSelectedJobNumber}
                                        setSelectedLocationName={setSelectedLocationName}
                                        jobState={jobState}
                                        setJobState={setJobState}
                                        // totalDrillingHours={totalDrillingHours}
                                        // setTotalDrillingHours={setTotalDrillingHours}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <SubmitButton
                                label="Save"
                            />
                            <CancelButton
                                label="Cancel"
                                onClick={toggleCancel}
                            />
                        </div>
                    </form>
                    <ModalWarning
                        modal={warningModal}
                        setModal={setWarningModal}
                        title="Warning!!!"
                    />
                    <LoadingIndicator isLoading={isLoading}/>
                </>
            }
        </>
    );
}

export default React.memo(Daily);
