import React, {useEffect, useState} from 'react';
import {isEmpty} from "lodash";
import {FaPencilAlt, FaTrash} from "react-icons/fa";
import FormInputText from "../../forms/form-control/FormInputText";
import {useFieldArray} from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import {EditInvoiceChargesModal} from './editInvoiceChargesModal';

/**
 * createInvoiceTableCharges component
 * @returns {JSX.Element}
 * @constructor
 */
const EditInvoiceTableCharges = (props) => {
    const {invoiceId, register, control, setTotalInvoiceCharges, jobId} = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "invoice_charges"
    });

    const [invoiceChargesModal, setInvoiceChargesModal] = useState({isOpen: false, invoiceCharges: []});

    useEffect(() => {
        apiClient
            .post('getInvoiceChargesByInvoice', {'id': invoiceId})
            .then(response => {
                remove();
                if (!isEmpty(response.data)) {
                    append(response.data);

                    let totalInvoiceCharges = 0;

                    response.data.forEach((row) => {
                        totalInvoiceCharges += Number(row.total);
                    });

                    setTotalInvoiceCharges(totalInvoiceCharges);
                }
            })
    }, [append, remove, invoiceId, setTotalInvoiceCharges]);

    const openInvoiceChargesModal = () => {
        setInvoiceChargesModal(modal => ({...modal, isOpen: true}))
    }

    const onInvoiceChargesEdit = (invoiceCharges) => {
        remove();
        append(invoiceCharges);
    }

    useEffect(() => {
        const totalInvoiceCharges = fields.reduce(
            (a, v) => a + Number(v.total), 0
        )
        setTotalInvoiceCharges(totalInvoiceCharges);
    }, [fields, setTotalInvoiceCharges])

    const RemoveChargeRow = (index) => {
        remove(index);
    }

    return (
        <table className="table table-hover table-striped col-md-11">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Units</th>
                    <th>Total</th>
                    <th>
                        <FaPencilAlt
                            color='A51E37'
                            size={20}
                            title="Add New Or Edit"
                            onClick={() => openInvoiceChargesModal({})}
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
                {fields.map((item, index) => (
                    <tr key={item.id}>
                        <td>{item.date}</td>
                        <td>{item.description}</td>
                        <td>{item.price}</td>
                        <td>{item.units}</td>
                        <td>{item.total}</td>
                        <td>
                            <FormInputText
                                id={`invoice_charges[${index}].id`}
                                type="hidden"
                                value={item.id}
                                register={register({required: "required"})}
                            />
                            <FaTrash
                                color='#A51E37'
                                title="Remove"
                                onClick={() => RemoveChargeRow(index)}
                            />
                        </td>
                    </tr>
                ))}
                {fields.length > 0 &&
                    <>
                        <tr>
                            <td colSpan={8}>
                                <span className="font-weight-bold">Total : </span>
                                <b className="red">{
                                    fields.reduce(
                                        (a, v) => Number(a) + Number(v.total), 0
                                    ).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
                                }</b>
                            </td>
                        </tr>
                    </>
                }
                {fields.length === 0 &&
                    <>
                        <tr>
                            <td colSpan={8}>
                                Invoice charges not found for selected invoice
                            </td>
                        </tr>
                    </>
                }
            </tbody>
            <EditInvoiceChargesModal
                modal={invoiceChargesModal}
                setModal={setInvoiceChargesModal}
                jobId={jobId}
                invoiceId={invoiceId}
                initialInvoiceCharges={fields}
                onSave={onInvoiceChargesEdit}
            />
        </table>
    );
}

export default EditInvoiceTableCharges;
