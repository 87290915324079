import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useRef} from "react";
import TextAreaInForm from "../../../../forms/form-control/daily/TextAreaInForm";
import SignatureInForm from "../../../../forms/form-control/daily/SignatureInForm";

const Notes = (props) => {
    const {register, setValue, fieldDaily, action} = props;
    const sigCanvasSupervisor = useRef({});
    const sigCanvasClient = useRef({});

    useEffect(() => {
        if (action === 'edit') {
            if (fieldDaily.signatures) {
                // get supervisor signature
                if (fieldDaily.signatures.supervisor_signature) {
                    setValue(
                        `notes.supervisor_signature`,
                        sigCanvasSupervisor.current.fromDataURL(fieldDaily.signatures.supervisor_signature)
                    );
                }

                // get client signature
                if (fieldDaily.signatures.client_signature) {
                    setValue(
                        `notes.client_signature`,
                        sigCanvasClient.current.fromDataURL(fieldDaily.signatures.client_signature)
                    );
                }
            }
        }
    }, [action, fieldDaily, setValue]);

    return (
        <AccordionItem className="card-header bg-primary" title="Notes">
            <div className="collapse show">
                <div className="form-row margin">
                    <TextAreaInForm
                        label="Notes"
                        className="col-md-5 mb-3"
                        id={`notes.notes`}
                        name={`notes.notes`}
                        rows="3"
                        value={(action === 'edit') ? fieldDaily.notes : ''}
                        cols="33"
                        register={register({required: false})}
                    />
                    <SignatureInForm
                        label="Tool Pusher's Signature"
                        id={`notes.supervisor_signature`}
                        name={`notes.supervisor_signature`}
                        className="col-md-3 mb-3"
                        height="85"
                        sigCanvas={sigCanvasSupervisor}
                        setValue={setValue}
                        register={register(`notes.supervisor_signature`)}
                    />
                    <SignatureInForm
                        label="Client's Signature"
                        id={`notes.client_signature`}
                        name={`notes.client_signature`}
                        className="col-md-3 mb-3"
                        height="85"
                        sigCanvas={sigCanvasClient}
                        setValue={setValue}
                        register={register(`notes.client_signature`)}
                    />
                </div>
            </div>
        </AccordionItem>
    );
}

export default Notes;
