import React from 'react';

/**
 * FormSubmitButton
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CancelButton = (props) => {
    const {
        label,
        className,
        onClick,
        type = "button",
        btnClassName = `btn btn-secondary cancel-button`
    } = props;

    return (
        <div className={className}>
            <button
                className={btnClassName}
                type={type}
                onClick={onClick}
            >
                {label}
            </button>
        </div>
    );
};

export default React.memo(CancelButton);
