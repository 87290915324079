import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {useForm} from 'react-hook-form'
import {toast} from "react-toastify";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormInputText from "../../forms/form-control/FormInputText";
import Select from "../../forms/form-control/daily/Select";
import FormInputPassword from "../../forms/form-control/FormInputPassword";
import FormInputEmail from "../../forms/form-control/FormInputEmail";
import FormInputNumber from "../../forms/form-control/FormInputNumber";
import FormInputDate from "../../forms/form-control/FormInputDate";
import moment from "moment/moment";
import { useAuthenticate } from '../../../hooks/auth';

const CreateUser = () => {
    const { register, handleSubmit, errors, reset } = useForm();
    const [timeApprovers, setTimeApprovers] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [showTimeApprovers, setShowTimeApprovers] = useState(0);
    const [showPasswords, setShowPasswords] = useState(0);
    const [requireDiscipline, setRequireDiscipline] = useState(0);
    const { user } = useAuthenticate();

    useEffect(() => {
        // User Types
        apiClient
            .get('getGroups')
            .then(response => {
                let optionItems = [];
                response.data.forEach(({id, group_name}) => {
                        if (group_name !== 'Client') {
                            // when system Admin
                            if (user?.group_id === 1) {
                                optionItems.push({id: id, name: group_name});
                            } else {
                                if (group_name !== 'Executive' && group_name !== 'System Administrator') {
                                    optionItems.push({id: id, name: group_name});
                                }
                            }
                        }
                });

                setUserTypes(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // Disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });

    }, [user]);

    const renderUserType = (event) => {
        // if user selects driller, driller assistant or toolpusher then discipline is required
        let groupsToValidate = ['7', '9', '11'];
        setRequireDiscipline((groupsToValidate.includes(event.target.value)) ? 1 : 0);

        // if user system admin and selected role executive or system admin provide ability to enter password
        let superiorRoles = ['1', '3'];
        setShowPasswords((superiorRoles.includes(event.target.value)) ? 1 : 0);
    }

    const renderTimeEntry = (event) => {
        if (event.target.value !== '1') {
            // populate time approvers
            let link = (event.target.value === '2') ? 'shopTimeApprovers' : 'officeTimeApprovers';
            apiClient
                .get(link)
                .then(response => {
                    let optionItems = [];
                    response.data.records.forEach(
                        ({user_id, name}) => {
                            optionItems.push({id: user_id, name: name});
                        }
                    );

                    setTimeApprovers(optionItems);
                })
                .catch((error) => {
                    console.error(error);
                });

            // if 1 or 2 show select
            setShowTimeApprovers((['2', '3'].includes(event.target.value)) ? 1 : 0);
        } else {
            setShowTimeApprovers(0);
        }
    }

    const buildEmail = (e, firstNameID, lastNameID, emailId) => {
        e.preventDefault();
        let firstNameValue = (document.getElementById(firstNameID).value) ? document.getElementById(firstNameID).value : '';
        let lastNameValue = (document.getElementById(lastNameID).value) ? document.getElementById(lastNameID).value : '';

        document.getElementById(emailId).value = firstNameValue.charAt(0).toLowerCase() +
            lastNameValue.toLowerCase() +
            '@nationalewp.com';
    }

    const onSubmit = (data) => {
        // TODO :: there is a bug  that supplies supervisor id to data even if
        //  driller or driller assistant or mechanic is not selected
        // this is dirty fix
        // if (data.group_id !== 6 || data.group_id !== 7 || data.group_id !== 9) {
        //     delete data.supervisor_id;
        // }

        if (data !== '') {
            let greenLight = 0;
            if (data.group_id === 1 || data.group_id === 3) {
                // if password confirmed
                if (data.password === data.confirmPassword) {
                    greenLight = 1;
                } else {
                    toast.error("The passwords don't match.");
                }
            } else {
                greenLight = 1;
            }

           if (greenLight === 1) {
               apiClient
                   .post('createUser', JSON.stringify(data))
                   .then(response => {
                       if (typeof response.data.success !== 'undefined') {
                           toast.success("User has been created.");
                           reset();
                       }
                   })
                   .catch((error) => {
                       console.error(error);
                   });
           }
        } else {
            errors.showMessages();
        }
    }

    return (
        <Fragment>
            <Breadcrumb title="Create User" parent="Admin" />
            <form
                className="needs-validation container-fluid s-flex s-flex-col s-flex-gy"
                noValidate=""
                id="create_employee_form"
                onSubmit={handleSubmit(onSubmit)}
            >

                <div className="form-row s-gy">
                    <FormInputText
                        id="first_name"
                        label="First Name"
                        type="text"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <FormInputText
                        id="last_name"
                        label="Last Name"
                        type="text"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        onInput={
                            (e) => buildEmail(
                                e,
                                'first_name',
                                'last_name',
                                'email'
                            )
                        }
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <Select
                        label="Suffix"
                        id="suffix"
                        name="suffix"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        options={[{value: "Jr", label: "Jr"}, {value: "Sr", label: "Sr"}]}
                        register={register({required: false})}
                        errors={errors}
                    />
                </div>
                
                <div className="form-row s-gy">
                    <FormInputNumber
                        id="erp_id"
                        label="VP ID"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        register={register({
                            min: {value: 1, message: "VP ID min value is 1"},
                            max: {value: 99999999, message: "VP ID max value is 99999999"},
                            required: "required"
                        })}
                        errors={errors}
                    />
                    <FormSelect
                        id="group_id"
                        label="User Type"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        options={userTypes}
                        register={register({required: "required"})}
                        onChange={(e) => renderUserType(e)}
                        errors={errors}
                    />
                    <Select
                        label="Discipline"
                        id="discipline_id"
                        name="discipline_id"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        options={disciplines}
                        register={register({required: (requireDiscipline > 0) ? "required" : false})}
                        errors={errors}
                    />
                </div>
                
                <div className="form-row s-gy">
                    <Select
                        label="Office Region"
                        id="office_region"
                        name="office_region"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        options={
                            [
                                {value: "Elko", label: "Elko"},
                                {value: "Phoenix", label: "Phoenix"}
                            ]
                        }
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <FormInputDate
                        id="hire_date"
                        label="Hire Date"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        min={moment(new Date('2000-01-01')).format('YYYY-MM-DD')}
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <Select
                        label="Time Entry User?"
                        id="time_entry"
                        name="time_entry"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        options={
                            [
                                {value: '1', label: 'No'},
                                {value: '2', label: 'Shop'},
                                {value: '3', label: 'Office'}
                            ]
                        }
                        onChange={(e) => renderTimeEntry(e)}
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    { showTimeApprovers > 0 && 
                        <FormSelect
                            id="time_approver_id"
                            label="Time Approver"
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            options={timeApprovers}
                            register={register({required: false})}
                            errors={errors}
                        />
                    }
                </div>
                
                <div className="form-row s-gy">
                    <FormInputEmail
                        id="email"
                        label="Email"
                        type="email"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                        register={register({required: "required"})}
                        errors={errors}
                    />
                </div>

                <div className="form-row s-gy">
                    { showPasswords > 0 && (
                        <>
                            <FormInputPassword
                                id="password"
                                label="Password"
                                type="password"
                                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                register={register({required: "required"})}
                                errors={errors}
                            />
                            <FormInputPassword
                                id="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                register={register({required: "Please confirm a password"})}
                                errors={errors}
                            />
                        </>
                    )}
                </div>
                
                <div>
                    <button className="btn btn-primary" type="submit">Create</button>
                </div>
            </form>
        </Fragment>
    );
}

export default CreateUser;
