import React, {useEffect, useState} from 'react';
import FormSelect from "../../../forms/form-control/FormSelect";
import apiClient from "../../../../utils/apiClient";
import {useForm} from "react-hook-form";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";

/**
 * Filter component for Daily Discipline Revenue
 * @param prop
 * @returns {JSX.Element}
 * @constructor
 */
const ReportFilters = (prop) => {
    const {register, handleSubmit, errors, setValue} = useForm();
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState({});
    const {
        setReportFilters,
        setTableData,
        setBarChartLabels,
        setBarChartDataDiscipline1,
        setBarChartDataDiscipline2,
        setBarChartDataDiscipline3,
        setBarChartDataDiscipline4,
        setPercentageChartDataDiscipline1,
        setPercentageChartDataDiscipline2,
        setPercentageChartDataDiscipline3,
        setPercentageChartDataDiscipline4,
        setPercentageChartDataTotal
    } = prop;

    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);
        apiClient.post('getDailyDisciplineRevenue', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    setBarChartLabels([])
                    setBarChartDataDiscipline1([]);
                    setBarChartDataDiscipline2([]);
                    setBarChartDataDiscipline3([]);
                    setBarChartDataDiscipline4([]);
                    setPercentageChartDataDiscipline1(0);
                    setPercentageChartDataDiscipline2(0);
                    setPercentageChartDataDiscipline3(0);
                    setPercentageChartDataDiscipline4(0);
                    setPercentageChartDataTotal(0);
                    return;
                }
                setTableData(response.data.tableRows);
                setBarChartLabels(response.data.dailyDataset.labels)
                setBarChartDataDiscipline1(response.data.dailyDataset.discipline_1);
                setBarChartDataDiscipline2(response.data.dailyDataset.discipline_2);
                setBarChartDataDiscipline3(response.data.dailyDataset.discipline_3);
                setBarChartDataDiscipline4(response.data.dailyDataset.discipline_4);
                setPercentageChartDataDiscipline1(response.data.percentageDataset.discipline_1);
                setPercentageChartDataDiscipline2(response.data.percentageDataset.discipline_2);
                setPercentageChartDataDiscipline3(response.data.percentageDataset.discipline_3);
                setPercentageChartDataDiscipline4(response.data.percentageDataset.discipline_4);
                setPercentageChartDataTotal(response.data.percentageDataset.total);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    };

    // get list of financial periods
    useEffect(() => {
        apiClient.get('getFinancialPeriods').then(response => {
            setFinancialPeriods(
                response.data.map(
                    ({id, name, is_current}) => {
                        if (is_current) {
                            setCurrentPeriod({id: id, name: name + ' (Current)'})
                        }
                        return {
                            id: id, name: name + (is_current ? ' (Current)' : '')
                        }
                    }
                )
            );
        });
    }, []);

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            setValue('financial_period_id', currentPeriod.id);
        }, 200);
    }, [currentPeriod, setValue]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    id="financial_period_id"
                    label="Financial Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={financialPeriods}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
};

export default ReportFilters;
