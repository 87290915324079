import React, {useEffect, useState} from 'react';
import FormInputDate from "../../forms/form-control/FormInputDate";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import {useForm} from "react-hook-form";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../utils/stateHelpers";
import {isEmpty} from "lodash";
import moment from "moment";

/**
 * Filter component
 * @returns {JSX.Element}
 * @constructor
 */
const InvoicesFilters = (prop) => {
    const {setFilters, setTableData} = prop;
    const { register, handleSubmit, errors } = useForm();
    const [invStatuses, setInvStatuses] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [financialPeriods, setFinancialPeriods] = useState([]);

    // get list of disciplines and invoice status
    useEffect(() => {
        apiClient
            .get('getInvoicesStatuses')
            .then(response => {
                setInvStatuses(response.data);
            });
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });

        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => {
                            return {
                                id: id, name: name + (is_current ? ' (Current)' : '')
                            }
                        }
                    )
                );
            });
    }, []);

    const setDate = (value) => {
        if (value) {
            apiClient
                .post('getFinancialPeriodById', {'id': value})
                .then(response => {
                    let startDate = response.data.start_date;
                    let endDate = response.data.end_date;
                    document.getElementById('date_from').value = startDate;
                    document.getElementById('date_to').value = endDate;
            });
        }
    }

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post('getInvoicesByDisciplineDateRangeStatus', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
                updateStateValue(setFilters,'isLoading', false);
            });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={disciplines}
                    register={register}
                    errors={errors}
                />
                <FormSelect
                    id="financial_period_id"
                    label="Financial Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={financialPeriods}
                    register={register({required: false})}
                    onChange={(e) => setDate(e.target.value)}
                    errors={errors}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={moment().subtract(7, "day").format("YYYY-MM-DD")}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={moment().format("YYYY-MM-DD")}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSelect
                    id="invoice_status"
                    label="Invoice Status"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    labelEmptyOption="All"
                    options={invStatuses}
                    register={register}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default InvoicesFilters;
