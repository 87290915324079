import { useState } from "react";
import Tippy from "@tippyjs/react";
import ShiftReportDetailTable from "./shiftReportDetailTable";
import apiClient from "../../../../utils/apiClient";

export const ShiftReportTooltip = ({ date, rig, shift, value }) => {
    const [content, setContent] = useState(null);
    const [status, setStatus] = useState("loading");
    const startCreating = (instance) => {
        instance._isFetching = false;
        instance._error = null;
        setContent("Loading...");
        setStatus("loading");
    }

    const hideDetail = (instance) => {
        setContent("Loading...");
        setStatus("loading");
        instance._error = null;
    }

    const fetchShiftReportDetail = (instance) => {
        if (instance._isFetching || instance._src || instance._error) {
            return;
        }

        instance._isFetching = true;
        setStatus("loading");
        apiClient
            .post("getShiftDetailReportData", {
                rig_id: rig,
                shift: shift,
                date: date,
            })
            .then((res) => {
                setStatus("resolve");
                const element = (
                    <ShiftReportDetailTable detailData={res.data} />
                );
                setContent(element);
            })
            .catch((error) => {
                instance._error = error;
                instance.setContent(`error`);
            })
            .finally(() => {
                instance._isFetching = false;
            });
    }

    return (
        <Tippy
            content={content}
            onCreate={(instance) => startCreating(instance)}
            onShow={(instance) => fetchShiftReportDetail(instance)}
            onHidden={(instance) => hideDetail(instance)}
            allowHTML={true}
            theme="light"
            maxWidth="none"
            delay={100}
            className={`shift_detail_tooltip ${
                status === "loading" ? "loading" : "resolve"
            }`}
        >
            <span>{value}</span>
        </Tippy>
    );
}
