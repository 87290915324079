import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import PayPeriodDateRangeFilter from "../../../common/form-filters/PayPeriodDateRangeFilter";
import ReportBody from "./reportBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import {isEqual} from 'lodash';

/**
 * Employee payable approved/unapproved hours component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ApprovedUnapprovedHours = (props) => {
    const pathFilter = props.location.pathname.split("/").pop();
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    // set report title
    let subTitle = "Approved Hours";
    if (isEqual(pathFilter, 'unapproved_hours')) {
        subTitle = "Unapproved Hours";
    }

    return (
        <>
            <Breadcrumb title={"Employee Payable Hours - " + subTitle} parent="Payroll"/>
            <PayPeriodDateRangeFilter {...{setFilters, setTableData, pathFilter, OnSubmitUrl:"getEmployeePayableApprovedFilterHours"}}/>
            <ReportBody {...{pathFilter, filters, tableData}} />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
};

export default ApprovedUnapprovedHours;
