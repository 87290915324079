import React, {useState} from 'react';
import Accordion from "react-light-accordion";
import GeneralInformation from "./accordion_cards/GeneralInformation";
import Employee from "./accordion_cards/Employee";
import Safety from "./accordion_cards/Safety";
import Hole from "./accordion_cards/Hole";
import Bit from "./accordion_cards/Bit";
import FootageDrilled from "./accordion_cards/FootageDrilled";
import FootageReamed from "./accordion_cards/FootageReamed";
import GeneralActivities from "./accordion_cards/GeneralActivities";
import Consumables from "./accordion_cards/Consumables";
import Surveys from "./accordion_cards/Surveys";
import Casing from "./accordion_cards/Casing";
import GeneralCharges from "./accordion_cards/GeneralCharges";
import Fuel from "./accordion_cards/Fuel";
import WaterHaul from "./accordion_cards/WaterHaul";
import Notes from "./accordion_cards/Notes";
import MudTest from "./accordion_cards/MudTest";
import BottomHole from "./accordion_cards/BottomHole";
import BottomHoleAssembly from "./accordion_cards/BottomHoleAssembly";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * FieldDaily Accordion Component
 * @returns {JSX.Element}
 * @constructor
 */
const FieldDailyAccordion = (props) => {
    const {
        disciplineId, fieldEmployees, register, errors, rigs, control, setValue, getValues, setWarningModal,
        fieldDaily, fieldEmployeesAvailable, rigsAvailable, activityDescriptions, drilledDescriptions,
        reamedDescriptions, generalDescriptions, consumableDescriptions, setActivityDescriptions, action,
        setDrilledDescriptions, setReamedDescriptions, setGeneralDescriptions, setConsumableDescriptions,
        selectedJobId, setSelectedJobId, setFieldEmployeesTemplate, fieldEmployeesTemplate, setFieldHolesTemplate,
        fieldHolesTemplate, fieldCasingsTemplate, setFieldCasingsTemplate, fieldBHATemplate, setFieldBHATemplate,
        setSelectedRigName, setSelectedJobNumber, setSelectedClientName, setSelectedLocationName,
        fieldBottomHoleTemplate, setFieldBottomHoleTemplate, fieldDrillBitsTemplate, setFieldDrillBitsTemplate,
        fieldBitsTemplate, setFieldBitsTemplate, mobileBottomHoleDescriptions, jobState, setJobState,
        totalHours, setTotalHours,
    } = props;
    const [collectedHoles, setCollectedHoles] = useState([]);
    const [holes, setHoles] = useState('');

    // check of discipline is selected
    if (disciplineId === 0) {
        return null;
    }

    // display loading spinner until getting mobileBottomHoleDescriptions
    if (!mobileBottomHoleDescriptions.length) {
        return <LoadingIndicator isLoading={!mobileBottomHoleDescriptions.length}/>;
    }

    // display loading spinner until getting activityDescriptions
    if (!activityDescriptions.length && action === 'edit') {
        return <LoadingIndicator isLoading={!activityDescriptions.length}/>;
    }

    /**
     *  this prevents submit form on click of Accordion
     *  react-light accordion has no property provided to control button type
    */
    const handleClick = (target) => {
        if (target.type === 'submit') {
            target.setAttribute("type", "button");
        }
    }

    // return accordion cards
    return (
        <>
            <Accordion atomic="false">
                <div onClick={(e) => handleClick(e.target)}>
                    <GeneralInformation
                        rigs={rigs}
                        errors={errors}
                        control={control}
                        register={register}
                        disciplineId={disciplineId}
                        setActivityDescriptions={setActivityDescriptions}
                        setDrilledDescriptions={setDrilledDescriptions}
                        setReamedDescriptions={setReamedDescriptions}
                        setGeneralDescriptions={setGeneralDescriptions}
                        setSelectedJobId={setSelectedJobId}
                        setConsumableDescriptions={setConsumableDescriptions}
                        setValue={setValue}
                        setHoles={setHoles}
                        rigsAvailable={rigsAvailable}
                        fieldDaily={fieldDaily}
                        action={action}
                        selectedJobId={selectedJobId}
                        setFieldEmployeesTemplate={setFieldEmployeesTemplate}
                        setFieldHolesTemplate={setFieldHolesTemplate}
                        setFieldCasingsTemplate={setFieldCasingsTemplate}
                        setSelectedRigName={setSelectedRigName}
                        setSelectedJobNumber={setSelectedJobNumber}
                        setSelectedClientName={setSelectedClientName}
                        setSelectedLocationName={setSelectedLocationName}
                        setFieldBitsTemplate={setFieldBitsTemplate}
                        setJobState={setJobState}
                        getValues={getValues}
                    />
                    <Employee
                        fieldEmployees={fieldEmployees}
                        errors={errors}
                        register={register}
                        control={control}
                        fieldEmployeesAvailable={fieldEmployeesAvailable}
                        setValue={setValue}
                        fieldDaily={fieldDaily}
                        action={action}
                        fieldEmployeesTemplate={fieldEmployeesTemplate}
                        setWarningModal={setWarningModal}
                        jobState={jobState}
                    />
                    <Safety
                        register={register}
                        errors={errors}
                        fieldDaily={fieldDaily}
                        setValue={setValue}
                        action={action}
                    />
                    <Hole
                        errors={errors}
                        disciplineId={disciplineId}
                        register={register}
                        control={control}
                        holes={holes}
                        collectedHoles={collectedHoles}
                        setValue={setValue}
                        getValues={getValues}
                        setCollectedHoles={setCollectedHoles}
                        fieldDaily={fieldDaily}
                        action={action}
                        setFieldBHATemplate={setFieldBHATemplate}
                        setFieldBottomHoleTemplate={setFieldBottomHoleTemplate}
                        fieldHolesTemplate={fieldHolesTemplate}
                        setFieldDrillBitsTemplate={setFieldDrillBitsTemplate}
                        setFieldBitsTemplate={setFieldBitsTemplate}
                    />
                    <Casing
                        errors={errors}
                        register={register}
                        control={control}
                        collectedHoles={collectedHoles}
                        fieldDaily={fieldDaily}
                        action={action}
                        disciplineId={disciplineId}
                        fieldCasingsTemplate={fieldCasingsTemplate}
                    />
                    <Bit
                        errors={errors}
                        register={register}
                        control={control}
                        collectedHoles={collectedHoles}
                        fieldDaily={fieldDaily}
                        action={action}
                        disciplineId={disciplineId}
                        fieldDrillBitsTemplate={fieldDrillBitsTemplate}
                        fieldBitsTemplate={fieldBitsTemplate}
                    />
                    <FootageDrilled
                        errors={errors}
                        register={register}
                        control={control}
                        collectedHoles={collectedHoles}
                        drilledDescriptions={drilledDescriptions}
                        setWarningModal={setWarningModal}
                        setValue={setValue}
                        getValues={getValues}
                        fieldDaily={fieldDaily}
                        action={action}
                        selectedJobId={selectedJobId}
                    />
                    <FootageReamed
                        errors={errors}
                        register={register}
                        control={control}
                        collectedHoles={collectedHoles}
                        reamedDescriptions={reamedDescriptions}
                        setWarningModal={setWarningModal}
                        setValue={setValue}
                        fieldDaily={fieldDaily}
                        action={action}
                        selectedJobId={selectedJobId}
                    />
                    <GeneralActivities
                        errors={errors}
                        register={register}
                        control={control}
                        collectedHoles={collectedHoles}
                        activityDescriptions={activityDescriptions}
                        setWarningModal={setWarningModal}
                        selectedJobId={selectedJobId}
                        setValue={setValue}
                        fieldDaily={fieldDaily}
                        action={action}
                        disciplineId={disciplineId}
                        totalHours={totalHours}
                        setTotalHours={setTotalHours}
                    />
                    <Consumables
                        errors={errors}
                        register={register}
                        control={control}
                        collectedHoles={collectedHoles}
                        consumableDescriptions={consumableDescriptions}
                        setValue={setValue}
                        fieldDaily={fieldDaily}
                        action={action}
                    />
                    {[1, 2].includes(disciplineId) ?
                        <Surveys
                            errors={errors}
                            register={register}
                            control={control}
                            collectedHoles={collectedHoles}
                            fieldDaily={fieldDaily}
                            action={action}
                        />
                        :
                        <></>
                    }
                    <GeneralCharges
                        errors={errors}
                        register={register}
                        control={control}
                        collectedHoles={collectedHoles}
                        generalDescriptions={generalDescriptions}
                        setValue={setValue}
                        fieldDaily={fieldDaily}
                        action={action}
                    />
                    <Fuel
                        errors={errors}
                        register={register}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        collectedHoles={collectedHoles}
                        fieldDaily={fieldDaily}
                        action={action}
                    />
                    <WaterHaul
                        errors={errors}
                        register={register}
                        control={control}
                        collectedHoles={collectedHoles}
                        fieldDaily={fieldDaily}
                        action={action}
                    />
                    <Notes
                        register={register}
                        setValue={setValue}
                        fieldDaily={fieldDaily}
                        action={action}
                    />
                    {[1, 2].includes(disciplineId) ?
                        <></>
                        :
                        <>
                            <BottomHole
                                errors={errors}
                                register={register}
                                control={control}
                                collectedHoles={collectedHoles}
                                fieldDaily={fieldDaily}
                                action={action}
                                fieldBottomHoleTemplate={fieldBottomHoleTemplate}
                                setValue={setValue}
                                mobileBottomHoleDescriptions={mobileBottomHoleDescriptions}
                            />
                            <BottomHoleAssembly
                                register={register}
                                fieldDaily={fieldDaily}
                                action={action}
                                fieldBHATemplate={fieldBHATemplate}
                            />
                        </>
                    }
                    <MudTest
                        register={register}
                        fieldDaily={fieldDaily}
                        action={action}
                    />
                </div>
            </Accordion>
        </>
    );
}

export default FieldDailyAccordion;
