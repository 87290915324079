import React, {useEffect, useState} from 'react';
import FormInputDate from "../../forms/form-control/FormInputDate";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import {useForm} from "react-hook-form";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../utils/stateHelpers";

/**
 * Filter component for consumable register
 * @returns {JSX.Element}
 * @constructor
 */
const ConsumableRegisterFilters = (prop) => {
    const {filters, setTableData, setFilters, rigs, setRigs} = prop;
    const {register, handleSubmit, errors} = useForm();
    const [disciplines, setDisciplines] = useState([]);

    // get list of disciplines
    useEffect(() => {
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
        });
    }, []);

    // get list of rigs with consumable allocated type
    useEffect(() => {
        if (!filters.discipline_id) {
            setRigs([]);
            updateStateValue(setFilters, 'rig_id', null);
            return;
        }

        apiClient
            .post('getRigsByDisciplineConsumableType',
            {
                    'discipline_id' : filters.discipline_id,
                    'date_from' : filters.date_from,
                    'date_to' : filters.date_to,
                    'consumable_type' : filters.consumable_type
                }
            )
            .then(response => {
                setRigs(
                    response.data.map(
                        ({id, label,name, count}) => ({value: id, label: label, rig_name: name, count: count})
                    )
                );
            });
    }, [setFilters, setRigs, filters.discipline_id, filters.consumable_type, filters.date_from, filters.date_to]);

    const onSubmit = (data) => {
        updateStateValue(setFilters, 'isLoading', true);
        updateStateValue(setFilters, 'isSubmitted', true);

        apiClient
            .post('getConsumables', data)
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
            updateStateValue(setFilters, 'isLoading', false);
        });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row">
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.date_from}
                    onChange={(e) => {updateStateValue(setFilters, e.target.id, e.target.value)}}
                    register={register({required: "Required"})}
                    errors={errors}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.date_to}
                    onChange={(e) => {updateStateValue(setFilters, e.target.id, e.target.value)}}
                    register={register({required: "Required"})}
                    errors={errors}
                />
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.discipline_id}
                    onChange={(e) => {updateStateValue(setFilters, e.target.id, e.target.value)}}
                    options={disciplines}
                    register={register({required: "Required"})}
                    errors={errors}
                />
                <FormSelect
                    id="consumable_type"
                    label="Type"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.consumable_type}
                    onChange={(e) => {updateStateValue(setFilters, e.target.id, e.target.value)}}
                    options={
                        [
                            {value: "allocated", label: "Allocated"},
                            {value: "unallocated", label: "Unallocated"}
                        ]
                    }
                    register={register({required: "Required"})}
                    errors={errors}
                />
                <FormSelect
                    id="rig_id"
                    label="Rig"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.rig_id}
                    onChange={(e) => {updateStateValue(setFilters, e.target.id, e.target.value)}}
                    options={rigs}
                    register={register({required: "Required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default ConsumableRegisterFilters;
