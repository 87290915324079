import React from 'react';
import TableReport from "../../../common/table/table";
import {employeeRegisterTableColumns} from "./employeeRegisterTableColumns.js";
import MessageEmptyData from "../../../common/messageEmptyData";

/**
 * Body report component for for EmployeeRegister Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const EmployeeRegisterBody = (prop) => {
    const {reportFilters, tableData} = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={employeeRegisterTableColumns}
                data={tableData}
                exportOptions={{pdf: true, xlsx: true, csv: false}}
                getExportFileName={() => 'employee_register'}
                withGlobalSearch={true}
            />
        </div>
    );
};

export default EmployeeRegisterBody;
