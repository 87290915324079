import React from 'react';

/**
 * Form input for password
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormInputPassword = (props) => {
    const {
        id,
        label,
        value,
        type = "password",
        className,
        onChange,
        errors,
        register,
        isControlled = false,
        ...rest
    } = props;

    return (
        <div className={className}>
            {label && <label htmlFor={id}>{label}</label>}
            <input
                id={id}
                name={id}
                className="form-control"
                type={type}
                {...(isControlled ? {value: value} : {defaultValue: value})}
                onChange={onChange}
                ref={register}
                {...rest}
            />
            {errors && errors[id] && <span>{errors[id].message}</span>}
        </div>
    );
}

export default React.memo(FormInputPassword);