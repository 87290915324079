import { initialState, UserContext, reducer, saveState } from "../auth";
import { useReducerWithSubscriber } from "../context";

export const WithAuth = ({ children }) => {
    const { state, dispatch } = useReducerWithSubscriber(
        reducer,
        initialState.state,
        [saveState]
    );
    return (
        <UserContext.Provider value={{ state, dispatch }}>
            {children}
        </UserContext.Provider>
    );
};
