import React, {useState} from 'react';
import Table from "../../../common/table/table";
import RigsTable from "./rigsTable.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import EditRigModal from "./editRigModal";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";

/**
 * RigsBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const RigsBody = (prop) => {
    const {filters, setFilters, tableData, setTableData} = prop;
    const [editRigModal, setEditRigModal] = useState({isOpen: false});
    const [deactivateModal, setDeactivateModal] = useState({isOpen: false, message: <p></p>});
    const [reactivateModal, setReactivateModal] = useState({isOpen: false, message: <p></p>});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});
    const [selectedRow, setSelectedRow] = useState({});

    const ReactivateHandler = () => {
        apiClient
            .post('reactivateRig', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .then(function () {
                //close reactivateModal
                setReactivateModal({...reactivateModal, isOpen: false})
            });
    }

    const DeactivateHandler = () => {
        apiClient
            .post('deactivateRig', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .then(function () {
                //close deactivateModal
                setDeactivateModal({...deactivateModal, isOpen: false})
            });
    }

    const DeleteHandler = () => {
        apiClient
            .post('deleteRig', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .then(function () {
                //close deactivateModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    RigsTable(
                        setFilters, setSelectedRow, setDeactivateModal, setReactivateModal,
                        setEditRigModal, setDeleteModal
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'rigs'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={deactivateModal}
                setModal={setDeactivateModal}
                title="Deactivate Rig"
                onConfirm={DeactivateHandler}
            />
            <ModalConfirmation
                modal={reactivateModal}
                setModal={setReactivateModal}
                title="Reactivate Rig"
                onConfirm={ReactivateHandler}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Delete Rig"
                onConfirm={DeleteHandler}
            />
            <EditRigModal
                modal={editRigModal}
                setModal={setEditRigModal}
                selectedRow={selectedRow}
                tableData={tableData}
                setTableData={setTableData}
            />
        </div>
    );
}

export default RigsBody;
