import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import moment from "moment";

const CUSTOM_FILTER_TYPE_OPTIONS = [
    {value: "client_id", label: "Client"},
    {value: "state_id", label: "State"},
    {value: "shift", label: "Shift"},
    {value: "rig_type_id", label: "RigType"},
    {value: "rig_id", label: "Rig"},
];

/**
 * Filter component for driller production
 * @returns {JSX.Element}
 * @constructor
 */
const DrillerProductionFilters = (prop) => {
    const {register, handleSubmit, errors, setValue, getValues} = useForm();
    const [disciplines, setDisciplines] = useState([]);
    const [customFilterType, setCustomFilterType] = useState(null);
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [selectedCustomFilterOptions, setSelectedCustomFilterOptions] = useState([]);
    const [selectedCustomFilterValue, setSelectedCustomFilterValue] = useState(null);
    const {tableData, setTableData, reportFilters, setReportFilters, setBarChartData, setBarChartLabels} = prop;
    const [endDate, setEndDate] = useState(0);

    // submit handler for report filter
    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        const filters = customFilterType !== null ?
            { ...data, [customFilterType.value]: selectedCustomFilterValue } :
            data; 

        apiClient
            .post('getDrillersProductivity', filters)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    setBarChartData([]);
                    setBarChartLabels([]);
                    return;
                }
                setTableData(response.data);
                const records = response.data.slice(0, -1);
                setBarChartLabels(records.map(driller => driller.driller_full_name));
                setBarChartData(records.map(driller => driller.footage_per_shift_hour));
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    }

    // get list of daily dates
    useEffect(() => {
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                let year = Number(moment(response.data.end_date).format('YYYY'));
                setEndDate(year);
            });

        // get Disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(
                    response.data.disciplines.map(
                        ({id, name}) => ({id: id, name: name})
                    )
                );
        });

        // get FinancialPeriods
        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => ({
                            id: id, name: name + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });

        // set yesterday date for date range components
        setValue('date_from', moment().subtract(1, "day").format("YYYY-MM-DD"));
        setValue('date_to', moment().subtract(1, "day").format("YYYY-MM-DD"));

    }, [setValue, endDate]);

    const getRigsByDiscipline = (disciplineId) => {
        apiClient
        .post('getAllActiveRigsByDisciplineId', {'discipline_id': disciplineId})
        .then(response => {
            const rigItems = response.data.rigs.map(
                ({id, name}) => ({id: id, name: name})
            );
            setSelectedCustomFilterOptions(rigItems);
        });
    }

    const getClientsByDiscipline = (disciplineId) => {
        apiClient
        .post('getActiveClientsByDiscipline', {'discipline_id': disciplineId})
        .then(response => {
            setSelectedCustomFilterOptions(
                response.data.map(
                    ({id, client_name}) => ({id: id, name: client_name})
                )
            );
        });
    }

    const updateFilters = (id, value) => {
      setReportFilters(prevState => ({
          ...prevState,
          [id]: value
      }));
  }

  const handleRigTypeFilterSelect = () => {
        // get list of rig types
        apiClient
            .get('getRigTypes')
            .then(response => {
                setSelectedCustomFilterOptions(response.data.map(rigType => ({ value: rigType.id, label: rigType.name})))
        });
  }

  const handleShiftFilterSelect = () => {
    setSelectedCustomFilterOptions([{value: "Day", label: "Day"}, {value: "Night", label: "Night"}])
  }

  const handleStateFilterSelect = () => {
    // States
    apiClient
    .get('getUSAStates')
    .then(response => {
        const stateItems = response.data.states.map(
            ({id, abbreviation}) => ({value: id, label: abbreviation})
        );

        setSelectedCustomFilterOptions(stateItems);
    })
    .catch((error) => {
        console.error(error);
    });
  }


  const setDate = (value) => {
    if (value) {
        apiClient
            .post('getFinancialPeriodById', {'id': value})
            .then(response => {
                let startDate = response.data.start_date;
                let endDate = response.data.end_date;
                document.getElementById('date_from').value = startDate;
                document.getElementById('date_to').value = endDate;
        });
    }
}

    const filterSelectionHandler = (e) => {
      const {id, value} = e.target;
      updateFilters(id, value);
    }

    const customFilterSelectionHandler = (e) => {
        const {value} = e.target;
        setSelectedCustomFilterValue(value);
    }

    const customFilterTypeSelectionHandler = (e) => {
      const {value} = e.target;
      if (!value) {
        setCustomFilterType(null);
        return;
      }
      const label = CUSTOM_FILTER_TYPE_OPTIONS.filter(option => option.value === value)[0].label;

      setCustomFilterType({ label, value });
    }

    useEffect(() => {
        if (customFilterType == null) {
            return;
        }

        setSelectedCustomFilterOptions([]);
        setSelectedCustomFilterValue(null);
        switch (customFilterType.value) {
            case 'state_id':
                handleStateFilterSelect();
                break;
            case 'shift':
                handleShiftFilterSelect()
                break;
            case 'rig_type_id':
                handleRigTypeFilterSelect()
                break;
            case 'rig_id':
                if (reportFilters.discipline_id) {
                    getRigsByDiscipline(reportFilters.discipline_id)
                }
                break;
            case 'client_id':
                if (reportFilters.discipline_id) {
                    getClientsByDiscipline(reportFilters.discipline_id)
                }
                break;
            default:
                return;
        }
    }, [customFilterType, reportFilters.discipline_id])

    useEffect(() => {
        if (!reportFilters.discipline_id) {
            if (customFilterType?.value === 'rig_id' || customFilterType?.value === 'client_id') {
                setSelectedCustomFilterOptions([]);
                setSelectedCustomFilterValue(null);
            }
            return;
        }

        if (customFilterType?.value === 'rig_id') {
            getRigsByDiscipline(reportFilters.discipline_id);
        }

        if (customFilterType?.value === 'client_id') {
            getClientsByDiscipline(reportFilters.discipline_id);
        }
    }, [reportFilters.discipline_id, customFilterType?.value])

    useEffect(() => {
        if (selectedCustomFilterOptions.length > 0) {
            setSelectedCustomFilterValue(selectedCustomFilterOptions[0].value);
        }
    }, [selectedCustomFilterOptions])

    return (
        <form
            className="needs-validation s-filters-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="s-filters">
                <FormSelect
                  id="status"
                  label="Status"
                  className="s-filter"
                  onChange={filterSelectionHandler}
                  options={[{value: 1, label: "Active"}, {value: 0, label: "Inactive"}]}
                  register={register({required: {value: true, message: "required"}})}
                  errors={errors}
                />
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="s-filter"
                    onChange={filterSelectionHandler}
                    options={disciplines}
                    register={register}
                    errors={errors}
                />
                <FormSelect
                    id="financial_period_id"
                    label="Monthly Data Period"
                    className="s-filter"
                    options={financialPeriods}
                    register={register}
                    onChange={(e) => setDate(e.target.value)}
                    errors={errors}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="s-filter"
                    onChange={moment().subtract(7, "day").format("YYYY-MM-DD")}
                    register={register}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="s-filter"
                    onChange={moment().format("YYYY-MM-DD")}
                    register={register}
                />
                <FormSelect
                    label="Filter Type"
                    className="s-filter"
                    onChange={customFilterTypeSelectionHandler}
                    options={CUSTOM_FILTER_TYPE_OPTIONS}
                    register={register}
                    errors={errors}
                />
                {customFilterType && <FormSelect
                    label={customFilterType.label}
                    className="s-filter"
                    onChange={customFilterSelectionHandler}
                    options={selectedCustomFilterOptions}
                    register={register}
                    errors={errors}
                />}
                
                <FormSubmitButton
                    label="Show"
                    className="s-button s-button-xl"
                />
                
                {/* For mobile view */}
                {!isEmpty(tableData) ? (
                    <div className="s-buttons s-justify-between">
                        <FormSubmitButton
                            label="Show"
                            className="s-button"
                        />
                    </div>
                ) : (
                    <div className="s-buttons s-justify-end">
                        <FormSubmitButton
                            label="Show"
                            className="s-button"
                        />
                    </div>
                )}
            </div>
            {/* do not delete, blocked for now */}
            {/*{!isEmpty(tableData) && (*/}
            {/*    <FormSubmitButton*/}
            {/*        type="button"*/}
            {/*        label="Send Email"*/}
            {/*        btnClassName="btn-success s-button-xl"*/}
            {/*        onClick={() => {*/}
            {/*            setSendReportModal({*/}
            {/*                isOpen: true*/}
            {/*            });*/}
            {/*        }}*/}
            {/*    />*/}
            {/*)}*/}
        </form>
    );
}

export default DrillerProductionFilters;
