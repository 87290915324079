
const RigStatusTable = () => {
    return [
        {
            Header: 'Rig',
            accessor: 'rig',
        },
        {
            Header: 'Description',
            accessor: 'rig_description',
        },
        {
            Header: 'Shifts',
            accessor: 'shifts',
        },
        {
            Header: 'Shift Hrs',
            accessor: 'shift_hrs',
        },
        {
            Header: 'Billable Hrs',
            accessor: 'billable_hrs',
        },
        {
            Header: 'Billed/Unbilled',
            accessor: 'billed_vs_unbilled',
        },
        {
            Header: 'Drill Hrs',
            accessor: 'drill_hrs',
        },
        {
            Header: 'Revenue',
            accessor: 'revenue_total',
        },
        {
            Header: 'Shift Avg',
            accessor: 'shift_avg',
        },
        {
            Header: 'Rev/FT',
            accessor: 'rev_ft',
        },
        {
            Header: 'Rev/Shift Hr',
            accessor: 'rev_shift_hrs',
        },
        {
            Header: 'Rev/Drill Hr',
            accessor: 'rev_drill_hrs',
        },
        {
            Header: 'Depth',
            accessor: 'depth_total',
        },
        {
            Header: '% Drilling',
            accessor: 'drill_percentage',
        },
        {
            Header: 'FT/drill Hr',
            accessor: 'ft_drill_hrs',
        },
        {
            Header: 'FT/shift Hr',
            accessor: 'ft_shift_hrs',
        },
        {
            Header: 'FT/shift',
            accessor: 'ft_shift',
        },
    ];
}

export default RigStatusTable;

