import React from 'react';
import {FaSearch} from "react-icons/fa";

/**
 * Global search filter
 * @param prop
 * @returns {JSX.Element}
 * @constructor
 */
const TableGlobalFilter = (prop) => {

    const {
        filter,
        setFilter
    } = prop;

    return (
        <form className="s-table-standard-global-filter m-t-10">
            <FaSearch size='1.2em'/>
            <input
                className="form-control"
                size={35}
                type="text"
                placeholder="search"
                value={filter || ''}
                onChange={(e) => setFilter(e.target.value)}
            />
        </form>
    );
};

export default React.memo(TableGlobalFilter);
