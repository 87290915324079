import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import {useFieldArray} from "react-hook-form";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import apiClient from "../../../../../utils/apiClient";

const DailyFootageReamed = (props) => {
    const {
        errors, register, control, collectedHoles, reamedDescriptions, daily, action, setValue,
        setTotalReamedPrice, totalReamedPrice, setTotalReamedVariance, totalReamedVariance, selectedJobId
    } = props;
    const {fields, append, remove} = useFieldArray({control, name: 'reaming'});
    const [totalReamed, setTotalReamed] = useState(0);
    const [selectedReamingHole, setSelectedReamingHole] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (reamedDescriptions.length) {
            setIsLoading(false);
        }
    }, [reamedDescriptions]);

    setTimeout(() => {
        if (!reamedDescriptions.length) {
            setIsLoading(false);
        }
    }, 2000);

    useEffect(() => {
        if (action === 'edit') {
            append(daily.daily_reaming);

            setTimeout(() => {
                daily.daily_reaming.forEach((item, index) => {
                    // populate name select
                    setValue(
                        `reaming[${index}].cost_code`,
                        {value: item.cost_code, label: item.description}
                    );
                });
            }, 500);

            setTimeout(() => {
                Shared.calculateTotals('total_reamed', setTotalReamed);
                Shared.calculateTotals('total_reamed_price', setTotalReamedPrice);
                Shared.calculateTotalVariance(
                    'total_reamed',
                    'total_reamed_billable',
                    'total_reamed_price',
                    setTotalReamedVariance
                );
            }, 200);
        }
    }, [append, daily, action, setTotalReamed, setTotalReamedPrice, setTotalReamedVariance, setValue]);

    const calculateTotalReamedInRow = (e, from, to, totalId, reamedId, priceId, totalPriceId) => {
        e.preventDefault();
        let fromFootage = (document.getElementById(from).value) ? document.getElementById(from).value : 0;
        let toFootage = (document.getElementById(to).value) ? document.getElementById(to).value : 0;
        let price = (document.getElementById(priceId).value) ? document.getElementById(priceId).value : 0;
        let totalRaw = Number(toFootage) - Number(fromFootage);
        let total = Number(totalRaw).toFixed(2);
        document.getElementById(totalId).value = total;
        // if price is set make reamed billable the same as total reamed
        document.getElementById(reamedId).value = (price > 0) ? total : 0;
        document.getElementById(totalPriceId).value =
            Number(price * document.getElementById(reamedId).value).toFixed(2);

        Shared.calculateTotals('total_reamed', setTotalReamed);
        Shared.calculateTotals('total_reamed_price', setTotalReamedPrice);
    }

    const removeFootageReamedRow = (index) => {
        remove(index);
        // calculate again
        setTimeout(() => {
            Shared.calculateTotals('total_reamed', setTotalReamed);
            Shared.calculateTotals('total_reamed_price', setTotalReamedPrice);
            Shared.calculateTotalVariance(
                'total_reamed',
                'total_reamed_billable',
                'total_reamed_price',
                setTotalReamedVariance
            );
        }, 500);
    }

    const selectCode = (index, value) => {
        let jobId = (action === 'edit') ? daily.job_id : selectedJobId;
        if (value && jobId) {
            apiClient
                .post('getJobChargesPriceByCodeAndJobId', {'job_id': jobId, 'code': value})
                .then(response => {
                    const realPrice = parseFloat(response.data[0]).toFixed(2);
                    document.getElementById(`reaming[${index}].price`).value = realPrice;
                });
        }
    }

    const calculateTotalPriceInRow = (e, price, reamingBillable, reamingTotal, totalId) => {
        e.preventDefault();
        let priceValue = (document.getElementById(price) !== null) ? document.getElementById(price).value : 0;
        let reamingBillableValue = (document.getElementById(reamingBillable) !== null)
            ?
                document.getElementById(reamingBillable).value
            :
                0;

        if (reamingBillableValue !== 0) {
            let totalRaw = Number(reamingBillableValue) * Number(priceValue);
            let total = Number(totalRaw).toFixed(2);
            document.getElementById(totalId).value = total;
            Shared.calculateTotals('total_reamed_price', setTotalReamedPrice);
        }

        // calculate variance if price > 0
        if (priceValue > 0) {
            Shared.calculateTotalVariance(
                'total_reamed',
                'total_reamed_billable',
                'total_reamed_price',
                setTotalReamedVariance
            );
        }
    }

    useEffect(() => {
        if (!fields.length) {
            setTotalReamed(0);
            setTotalReamedPrice(0);
            setTotalReamedVariance(0);
        }
    }, [fields.length, setTotalReamed, setTotalReamedPrice, setTotalReamedVariance])

    return (
            <AccordionItem className="card-header bg-primary" title="Footage Reamed">
                {isLoading ? null : (
                    <div className="card">
                        <div className="form-row" id="reamed_totals">
                            <div className="col-md-3 mb-3 total_block">
                                <p className="form-control background_total_block_color">
                                    Total Reamed: {totalReamed}
                                </p>
                            </div>
                            <div className="col-md-3 mb-3 total_block">
                                <p className="form-control background_total_block_color">
                                    Total Reamed Price: {
                                        Number(totalReamedPrice)
                                        .toLocaleString('en-US', {style: 'currency', currency: 'USD'})
                                    }
                                </p>
                            </div>
                            <div className="col-md-3 mb-3 total_block">
                                <p className="form-control background_total_block_color">
                                    Total Reamed Variance: {totalReamedVariance}
                                </p>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Price</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Total Reamed</th>
                                                <th>Reamed Billable</th>
                                                <th>Total Price</th>
                                                <th>Hole</th>
                                                <th className="action-cell">
                                                    <FaPlus
                                                        color='#22af47'
                                                        size={20}
                                                        title="Add New"
                                                        onClick={() => append({})}
                                                    />
                                                    <FaEraser
                                                        color='#A51E37'
                                                        className="marginLeft"
                                                        size={20}
                                                        title="Remove All"
                                                        onClick={() => remove()}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fields.map((item, index) => (
                                                <tr key={item.id}>
                                                    <SearchableSelectInTable
                                                        id={`reaming[${index}].cost_code`}
                                                        options={reamedDescriptions}
                                                        rules={{ required: "required" }}
                                                        control={control}
                                                        errors={errors}
                                                        onChange={(value) => selectCode(index, value)}
                                                        value={(action === 'edit') ? item.cost_code?.value : ''}
                                                        customStyles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                width: 500,
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                width: 500,
                                                                position: 'sticky',
                                                            })
                                                        }}
                                                    />
                                                    <InputInTable
                                                        className="short-select-width"
                                                        id={`reaming[${index}].price`}
                                                        name={`reaming[${index}].price`}
                                                        value={
                                                            (action === 'edit')
                                                                ?
                                                                (item.price)
                                                                    ?
                                                                    Number(item.price).toFixed(2)
                                                                    :
                                                                    Number(0).toFixed(2)
                                                                :
                                                                ''
                                                        }
                                                        register={register({required: false})}
                                                        disabled={'true'}
                                                    />
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`reaming[${index}].reaming_from`}
                                                        name={`reaming[${index}].reaming_from`}
                                                        onKeyUp={(e) => calculateTotalReamedInRow(
                                                            e,
                                                            `reaming[${index}].reaming_from`,
                                                            `reaming[${index}].reaming_to`,
                                                            `reaming[${index}].reaming_total`,
                                                            `reaming[${index}].reamed_billable`,
                                                            `reaming[${index}].price`,
                                                            `reaming[${index}].total_price`
                                                        )}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                        value={(action === 'edit') ? item.reaming_from : ''}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`reaming[${index}].reaming_to`}
                                                        name={`reaming[${index}].reaming_to`}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                        onKeyUp={(e) => calculateTotalReamedInRow(
                                                            e,
                                                            `reaming[${index}].reaming_from`,
                                                            `reaming[${index}].reaming_to`,
                                                            `reaming[${index}].reaming_total`,
                                                            `reaming[${index}].reamed_billable`,
                                                            `reaming[${index}].price`,
                                                            `reaming[${index}].total_price`
                                                        )}
                                                        value={(action === 'edit') ? item.reaming_to : ''}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                    <InputInTable
                                                        className="total_reamed hours-column-width"
                                                        id={`reaming[${index}].reaming_total`}
                                                        name={`reaming[${index}].reaming_total`}
                                                        register={register({required: false})}
                                                        value={(action === 'edit') ? item.reaming_total : ''}
                                                        disabled={'true'}
                                                    />
                                                    <InputInTable
                                                        className="total_reamed_billable short-select-width"
                                                        id={`reaming[${index}].reamed_billable`}
                                                        name={`reaming[${index}].reamed_billable`}
                                                        onChange={
                                                            (e) => calculateTotalPriceInRow(
                                                                e,
                                                                `reaming[${index}].price`,
                                                                `reaming[${index}].reamed_billable`,
                                                                `reaming[${index}].drilling_total`,
                                                                `reaming[${index}].total_price`
                                                            )
                                                        }
                                                        value={(action === 'edit') ? item.reamed_billable : ''}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 9)}
                                                        register={register({required: true})}
                                                        errors={errors}
                                                    />
                                                    <InputInTable
                                                        className="total_reamed_price hours-column-width"
                                                        id={`reaming[${index}].total_price`}
                                                        name={`reaming[${index}].total_price`}
                                                        disabled={'true'}
                                                        value={
                                                            (action === 'edit')
                                                                ?
                                                                (item.total_price > 0)
                                                                    ?
                                                                    Number(item.total_price).toFixed(2)
                                                                    :
                                                                    Number(0).toFixed(2)
                                                                :
                                                                ''
                                                        }
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                    {action === 'create' &&
                                                        <SelectInTable
                                                            id={`reaming[${index}].hole_name`}
                                                            name={`reaming[${index}].hole_name`}
                                                            options={collectedHoles}
                                                            value={(collectedHoles.length === 1) ? collectedHoles[0].value : '' }
                                                            register={register({required: "required"})}
                                                            errors={errors}
                                                        />
                                                    }
                                                    {action === 'edit' &&
                                                        <SelectInTable
                                                            id={`reaming[${index}].hole_name`}
                                                            name={`reaming[${index}].hole_name`}
                                                            value={
                                                                selectedReamingHole
                                                                    ?
                                                                    selectedReamingHole
                                                                    :
                                                                    (collectedHoles.length === 1)
                                                                        ?
                                                                        collectedHoles[0].value
                                                                        :
                                                                        item.hole_name
                                                            }
                                                            onChange={
                                                                (e) => Shared.setSelectedValue(e.target.value, setSelectedReamingHole)
                                                            }
                                                            options={collectedHoles}
                                                            register={register({required: "required"})}
                                                            errors={errors}
                                                        />
                                                    }
                                                    <td>
                                                        <FaTrash
                                                            color='#A51E37'
                                                            size={20}
                                                            title="Remove"
                                                            onClick={() => removeFootageReamedRow(index)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </AccordionItem>
    );
}

export default React.memo(DailyFootageReamed);
