import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Table from "../../../common/table/table";
import {FaPlus} from "react-icons/fa";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";

/**
 * POItemsModal Modal
 * @returns {JSX.Element}
 * @constructor
 */
const POItemsModal = (props) => {
    const {append, modal, setModal, poItemsData, setPoItemsData} = props;

    const deactivatePOItem = (row) => {
        apiClient
            .post('deactivatePOItem', {'id': row.id})
            .then(response => {
                setPoItemsData(removeObjFromArray(poItemsData, "id", response.data.id));
                toast.success(response.data.message);
            });
    }

    const ModalTableColumns = [
        {
            Header: 'PO #',
            accessor: 'po_number',
            width: 90
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Actions',
            width: 90,
            Cell: (cell) => {
                const row = cell.row.original;

                return (
                    <>
                        <FaPlus
                            title="Add to Consumables"
                            color='#22af47'
                            size={20}
                            onClick={() => {
                                append({
                                    'cost_code': 2700,
                                    'description': row.description,
                                    'units': '0.000',
                                    'charged': '0.000',
                                    'price': '0.000',
                                    'markup': '0',
                                    'markup_price': '0.000',
                                    'total': '0.000',
                                    'line': row.line,
                                    'po_number': row.po_number
                                });

                                // deactivate po item
                                deactivatePOItem(row);
                            }}
                        />
                    </>
                );
            }
        }
    ];

    // toggle for open/close the modal form
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    return (
            <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-lg modal-body" centered={true} >
                <ModalHeader toggle={toggle}>Add PO Item</ModalHeader>
                <ModalBody>
                    <div className="container-fluid">
                        <Table
                            columns={ModalTableColumns}
                            data={(typeof poItemsData !== 'undefined' && poItemsData.length > 0) ? poItemsData : []}
                            exportOptions={{pdf: false, xlsx: true, csv: true}}
                            getExportFileName={() => 'POItems'}
                            withGlobalSearch={true}
                            scrollable={true}
                        />
                    </div>
                </ModalBody>
            </Modal>
    );
}

export default POItemsModal;
