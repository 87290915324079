const FullWorkHistoryTable = () => {
    return [
        {
            Header: 'Date',
            accessor: 'date',
        },
        {
            Header: 'Employee',
            accessor: 'employee',
        },
        {
            Header: 'Code',
            accessor: 'code',
        },
        {
            Header: 'Description',
            accessor: 'notes',
        },
        {
            Header: 'Total Hours',
            accessor: 'hours',
        }
    ];
}

export default FullWorkHistoryTable;

