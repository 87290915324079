import React, {useState} from 'react';
import TableReport from "../../common/table/table";
import ModalConfirmation from "../../common/modal/ModalConfirmation.js";
import MessageEmptyData from "../../common/messageEmptyData";
import {FaCheck} from "react-icons/fa";
import apiClient from "../../../utils/apiClient";
import {toast} from "react-toastify";

/**
 * Body report component for for Rec Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const SubmitInvoicesBody = (prop) => {
    const {reportFilters, tableData, setTableData} = prop;
    const [modal, setModal] = useState({isOpen: false, message: <p></p>});
    const [selectedRow, setSelectedRow] = useState({});

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    const removeObjFromArray = (array, key, value) => {
        const index = array.findIndex(obj => obj[key] === value);
        return index >= 0 ? [
            ...array.slice(0, index),
            ...array.slice(index + 1)
        ] : array;
    }

    const InvoiceFinalizeHandler = () => {
        apiClient
            .get('finalizeInvoice/' + selectedRow.invoice_id)
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "invoice_id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close modal
                setModal({...modal, isOpen: false})
            });
    }

    const openModal = (row) => {
        const msg = <p>Are you sure that you want to finalize invoice number: <b>{row.invoice_number}</b></p>;
        setSelectedRow(row);
        setModal({
            message: msg,
            isOpen: true,
        })
    }

    const tableColumns = [
        {
            Header: 'Invoice Number',
            accessor: 'invoice_number',
        },
        {
            Header: 'Date',
            accessor: 'date',
        },
        {
            Header: 'Client',
            accessor: 'client',
        },
        {
            Header: 'Job Number',
            accessor: 'job_number',
        },
        {
            Header: 'Holes',
            accessor: 'holes',
        },
        {
            Header: 'PO Number',
            accessor: 'po_number',
        },
        {
            Header: 'Revenue',
            accessor: 'revenue',
        },
        {
            Header: 'Invoice Status',
            accessor: 'invoice_status',
        },
        {
            Header: 'Created By',
            accessor: 'created_by',
        },
        {
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;
                return <FaCheck color='#A51E37' title="Finalize" onClick={() => {
                    openModal(row)
                }}/>
            }
        },
    ];

    return (
        <div className="container-fluid">
            <TableReport
                columns={tableColumns}
                data={tableData}
                getExportFileName={() => 'submit_invoices'}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={modal}
                setModal={setModal}
                title="Finalize Invoice"
                onConfirm={InvoiceFinalizeHandler}
            />
        </div>
    );
}

export default SubmitInvoicesBody;
