import React from 'react';
import TableReport from "../../../common/table/table";
import tableColumns from "./tableColumns.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import {TableStyle} from "./tableStyle";
import {isEqual} from "lodash";

/**
 * Body report component for Employee Payable approved/unapproved
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ReportBody = (prop) => {
    const {pathFilter, filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    // set export file name
    let filename = "employee_payable_approved_hours";
    if (isEqual(pathFilter, 'unapproved_hours')) {
        filename = "employee_payable_unapproved_hours";
    }

    return (
        <div className="container-fluid">
            <TableStyle>
                <TableReport
                    columns={tableColumns}
                    data={tableData}
                    exportOptions={{pdf: false, xlsx: true, csv: false}}
                    getExportFileName={() => filename}
                    withGlobalSearch={true}
                    scrollable={true}
                />
            </TableStyle>
        </div>
    );
};

export default ReportBody;
