import {AccordionItem} from "react-light-accordion";
import React, {useEffect} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import {useFieldArray} from "react-hook-form";
import CreatableSelectInTable from "../../../../forms/form-control/daily/CreatableSelectInTable";

const DailyHole = (props) => {
    const {
        errors, register, control, disciplineId, setCollectedHoles,
        holes, setValue, getValues, collectedHoles, daily, action
    } = props;
    const kindOfHole = [1, 2].includes(disciplineId) ? "hole_core" : "hole_rotary";
    const {fields, append, remove} = useFieldArray({control, name: kindOfHole});

    useEffect(() => {
        if (action === 'edit') {
            append(daily.daily_holes);

            let options = daily.daily_holes.map(
                (value) => (
                    {value: value.name, label: value.name}
                )
            );

            setCollectedHoles(options);

            setTimeout(() => {
                daily.daily_holes.forEach((item, index) => {
                    setValue(`${kindOfHole}[${index}].name`, {value: item.name, label: item.name});
                });
            }, 200);
        }
    }, [append, daily, setCollectedHoles, action, kindOfHole, setValue]);

    const removeHoleRow = (index) => {
        remove(index);
        collectedHoles.splice(index, 1);
        setCollectedHoles(collectedHoles);
    }

    return (
        <AccordionItem className="card-header bg-primary" title="Hole">
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Angle</th>
                                        {[1, 2].includes(disciplineId) &&
                                            <>
                                                <th>Runs</th>
                                                <th>Core Retrieved</th>
                                            </>
                                        }
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <CreatableSelectInTable
                                                id={`${kindOfHole}[${index}].name`}
                                                className={kindOfHole}
                                                options={holes}
                                                rules={{required: "required"}}
                                                control={control}
                                                customStyles={Shared.customStylesWide}
                                                errors={errors}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                            (item.name)
                                                                ?
                                                                    {value: item.name, label: item.name}
                                                                :
                                                                    ''
                                                        :
                                                            ''
                                                }
                                                onChange={(v) => Shared.buildFieldDailyHoles(
                                                    v,
                                                    setValue,
                                                    `${kindOfHole}[${index}].name`,
                                                    getValues,
                                                    disciplineId,
                                                    setCollectedHoles
                                                )}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`${kindOfHole}[${index}].angle`}
                                                name={`${kindOfHole}[${index}].angle`}
                                                value={(action === 'edit') ? item.angle : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                                register={register({required: false})}
                                            />
                                            {[1, 2].includes(disciplineId) &&
                                                <>
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`${kindOfHole}[${index}].runs`}
                                                        name={`${kindOfHole}[${index}].runs`}
                                                        value={(action === 'edit')
                                                            ?
                                                                item.runs > 0
                                                                    ?
                                                                        item.runs
                                                                    :
                                                                        '0'
                                                            :
                                                                ''
                                                    }
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                                        register={register({required: false})}
                                                    />
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`${kindOfHole}[${index}].core_retrieved`}
                                                        value={
                                                            (action === 'edit')
                                                                ?
                                                                    item.core_retrieved > 0
                                                                        ?
                                                                            item.core_retrieved
                                                                        :
                                                                            '0'
                                                                :
                                                                    ''
                                                        }
                                                        name={`${kindOfHole}[${index}].core_retrieved`}
                                                        register={register({required: false})}
                                                    />
                                                </>
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => removeHoleRow(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default React.memo(DailyHole);
