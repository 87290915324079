import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import LoadingIndicator from "../../common/loadingIndicator";
import InvoicesFilters from "./InvoicesFilters";
import InvoicesBody from "./InvoicesBody";

const Invoices = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Invoices" parent="Invoicing"/>
            <InvoicesFilters {...{setFilters, setTableData}} />
            <InvoicesBody {...{filters, tableData, setTableData}} />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default Invoices;