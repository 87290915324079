import React, {useEffect, useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import apiClient from "../../../../utils/apiClient";
import {useForm} from "react-hook-form";
import {addObjToArray} from "../../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormSearchableSelect from "../../../forms/form-control/FormSearchableSelect";

/**
 * Modal form to create a record
 * @returns {JSX.Element}
 * @constructor
 */
const ShopTimeApproversCreateModal = (props) => {
    const {modal, setModal, title, tableData, setTableData} = props;
    const {control, handleSubmit, errors} = useForm();
    const [users, setUsers] = useState([]);
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    useEffect(() => {
        // get period status
        apiClient
            .get('getPotentialShopTimeApprovers')
            .then(response => {
                setUsers(response.data.map(
                    ({id, first_name, last_name, suffix}) => (
                        {value: id, label: first_name + ' ' + last_name + ' ' + suffix}
                    )
                ));
            });
    }, []);

    const onSubmit = (data) => {
        apiClient
            .post('shopTimeApprovers', {'user_id': data.user_id.value})
            .then(response => {
                let objectString = {
                    'id': response.data.record.id,
                    'name': response.data.record.user.first_name + ' ' + response.data.record.user.last_name + ' ' +
                        response.data.record.user.suffix
                };
                setTableData(addObjToArray(tableData, objectString));
                toast.success(response.data.message);
                toggle();
            });
    }

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} size={'md'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{`${title}`}</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormSearchableSelect
                            id="user_id"
                            options={users}
                            className="col-md-8 m-b-8"
                            rules={{required: "required"}}
                            control={control}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default React.memo(ShopTimeApproversCreateModal);
