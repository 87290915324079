import React from "react";
import {FaLock, FaUnlock} from "react-icons/fa";

const PayPeriodsTable = (setFilters, setSelectedRow, setLockModal, setUnlockModal) => {
    return [
        {
            Header: 'Start Date',
            accessor: 'start_date',
        },
        {
            Header: 'End Date',
            accessor: 'end_date',
        },
        {
            Header: 'Locked',
            accessor: 'is_locked',
            Cell: (cell) => {
                const row = cell.row.original;

                if (typeof row.is_locked !== 'undefined') {
                    switch (Number(row.is_locked)) {
                        case 1:
                            return 'YES';
                        case 0:
                            return 'NO';
                        default:
                            return null;
                    }
                } else {
                    return null;
                }
            }
        },
        {
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;
                let locked = (
                    <>
                        <FaLock
                            className="actions"
                            title="Unlock Pay Period"
                            onClick={() => {
                                const details = row.start_date + ' - ' + row.end_date;
                                const msg = 'Are you sure that you want to unlock pay period: ' + details;
                                setSelectedRow(row);
                                setLockModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                    </>
                );

                let unlocked = (
                    <>
                        <FaUnlock
                            className="actions"
                            title="Lock Pay Period"
                            onClick={() => {
                                const details = row.start_date + ' - ' + row.end_date;
                                const msg = 'Are you sure that you want to lock pay period: ' + details;
                                setSelectedRow(row);
                                setUnlockModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                    </>
                );

                return (row.is_locked === 1) ? locked : unlocked;
            }
        }
    ];
}

export default PayPeriodsTable;
