import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";

/**
 * RigsFilters component
 * @returns {JSX.Element}
 * @constructor
 */
const RigsFilters = (prop) => {
    const {filters, setFilters, setTableData} = prop;
    const {register, handleSubmit, errors} = useForm();
    const [disciplines, setDisciplines] = useState([]);

    // get list of disciplines
    useEffect(() => {
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });
    }, []);

    const filterSelectionHandler = (e) => {
        const {id, value} = e.target;
        updateStateValue(setFilters, id, value);
    }

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post('getRigsByDisciplineStatus', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
            updateStateValue(setFilters,'isLoading', false);
        });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="status"
                    label="Status"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.status}
                    onChange={filterSelectionHandler}
                    options={[{value: 1, label: "Active"}, {value: 0, label: "Inactive"}]}
                    register={register({required: {value: true, message: "required"}})}
                    errors={errors}
                />
                <FormSelect
                    label="Discipline"
                    id="discipline_id"
                    name="discipline_id"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={disciplines}
                    onChange={filterSelectionHandler}
                    value={filters.discipline_id}
                    register={register}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default RigsFilters;
