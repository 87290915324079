import React from 'react';
import {Button} from 'reactstrap';

/**
 * FormSubmitButton
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormCancelButton = (props) => {
    const {
        label,
        className,
        color = "secondary",
        onClick,
        type = "button",
        disabled = false,
        btnClassName = `btn-primary m-t-30`
    } = props;

    return (
        <div className={className}>
            <Button
                color={color}
                className={`btn ${btnClassName}`}
                type={type}
                onClick={onClick}
                disabled={(disabled) ? 'disabled' : ''}
            >
                {label}
            </Button>
        </div>
    );
}

export default FormCancelButton;
