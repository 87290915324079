import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {isCurrentYear} from "../../../../utils/dateHelpers";
import { useCallback } from 'react';

/**
 * PayPeriodsFilters component
 * @returns {JSX.Element}
 * @constructor
 */
const PayPeriodsFilters = (prop) => {
    const {register, handleSubmit, errors, setValue} = useForm();
    const {filters, setFilters, setTableData} = prop;
    const [years, setYears] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState({});

    useEffect(() => {
        // Existing Years
        apiClient
            .get('getExistingYears')
            .then(response => {
                const list = response.data.map((year) => {
                    if (isCurrentYear(year.year)) {
                        setCurrentPeriod({id: year.year, name: year.year + ' (Current)'})
                    }
                    return {
                        id: year.year, name: year.year + (isCurrentYear(year.year) ? " (Current)" : "")
                    }
                });
                setYears(list);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const updateFilters = useCallback((id, value) => {
        setFilters(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // filter selection handler
    const filterSelectionHandler = (e) => {
        const {id, value} = e.target;
        updateFilters(id, value);
    }

    const onSubmit = () => {
        updateFilters('isLoading', true);
        updateFilters('isSubmitted', true);

        apiClient
            .post('getPayPeriodsByYear', filters)
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
            updateFilters('isLoading', false);
        });
    }

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            setValue('year', currentPeriod.id);
            updateFilters("year", currentPeriod.id);
        }, 200);
    }, [currentPeriod, setValue, updateFilters]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="row">
                <FormSelect
                    id="year"
                    label="Year"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.year}
                    onChange={filterSelectionHandler}
                    options={years}
                    register={register({required: {value: true, message: "required"}})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default PayPeriodsFilters;
