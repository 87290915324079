import React, {useEffect, useState} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import TableToRender from "./tableToRender";
import {toast} from "react-toastify";
import {replacePartialObjFromArray} from "../../../../utils/arrayHelpers";
import {useAuthenticate} from "../../../../hooks/auth";

/**
 * EditUserModal Modal
 * @returns {JSX.Element}
 * @constructor
 */
const ShopTimeEntryToApproveModal = (props) => {
    const {modal, setModal, selectedRow, setHoursTableRows, hoursTableRows} = props;
    const {handleSubmit} = useForm();
    const [tableData0, setTableData0] = useState([]);
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [tableData3, setTableData3] = useState([]);
    const [tableData4, setTableData4] = useState([]);
    const [tableData5, setTableData5] = useState([]);
    const [tableData6, setTableData6] = useState([]);
    const [tableData7, setTableData7] = useState([]);
    const [tableData8, setTableData8] = useState([]);
    const [tableData9, setTableData9] = useState([]);
    const [tableData10, setTableData10] = useState([]);
    const [tableData11, setTableData11] = useState([]);
    const [tableData12, setTableData12] = useState([]);
    const [tableData13, setTableData13] = useState([]);
    const [showApprovalButton, setShowApprovalButton] = useState(0);
    const { user } = useAuthenticate();

    useEffect(() => {
        if (selectedRow.user_id) {
            apiClient
                .post('getShopTimeEntriesByUserIdAndPayPeriodID',
                    {
                        'pay_period_id': selectedRow.pay_period_id,
                        'user_id': selectedRow.user_id,
                        'date_range': selectedRow.date_range
                    }
                )
                .then(response => {
                    // set defined states
                    response.data.forEach((data, i) => {
                        if (i === 0) {
                            setTableData0(data);
                        }

                        if (i === 1) {
                            setTableData1(data);
                        }

                        if (i === 2) {
                            setTableData2(data);
                        }

                        if (i === 3) {
                            setTableData3(data);
                        }

                        if (i === 4) {
                            setTableData4(data);
                        }

                        if (i === 5) {
                            setTableData5(data);
                        }

                        if (i === 6) {
                            setTableData6(data);
                        }

                        if (i === 7) {
                            setTableData7(data);
                        }

                        if (i === 8) {
                            setTableData8(data);
                        }

                        if (i === 9) {
                            setTableData9(data);
                        }

                        if (i === 10) {
                            setTableData10(data);
                        }

                        if (i === 11) {
                            setTableData11(data);
                        }

                        if (i === 12) {
                            setTableData12(data);
                        }

                        if (i === 13) {
                            setTableData13(data);
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        // define if logged user is in officeTiemApprovers
        apiClient
            .get('shopTimeApprovers')
            .then(response => {
                response.data.records.forEach(
                    ({user_id}) => {
                        if (typeof user !== 'undefined') {
                            if (user.id === user_id) {
                                setShowApprovalButton(1);
                            }
                        }
                    }
                );
            })
            .catch((error) => {
                console.error(error);
            });

    }, [selectedRow, setShowApprovalButton, user]);

    const onApprove = () => {
        if (selectedRow.user_id > 0) {
            apiClient
                .post('approveTimeEntryByPayPeriodAndUserId',
                    {user_id: selectedRow.user_id, pay_period_id: selectedRow.pay_period_id, name: selectedRow.name}
                )
                .then(response => {
                    const newTableData = replacePartialObjFromArray(
                        hoursTableRows, "user_id", response.data.user_id, {'approver': response.data.approver}
                    );

                    // first reset data in table to be updated later
                    setHoursTableRows([]);
                    // set new data in table
                    setHoursTableRows(newTableData);
                    toast.success(response.data.message);
                    toggle();
                });
        }
    }

    // toggle for open/close the modal form
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-lg modal-body" centered={true} size={'lg'}>
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onApprove)}
            >
                <ModalHeader toggle={toggle}>Review Employee</ModalHeader>
                <ModalBody>
                    <div className="text-center bold-red"><h3>{selectedRow ? selectedRow.name : ''}</h3></div>
                    <div className="form-row">
                        <TableToRender tableData={tableData0} tableTotal={tableData7}/>
                        <TableToRender tableData={tableData1} tableTotal={tableData8}/>
                        <TableToRender tableData={tableData2} tableTotal={tableData9}/>
                        <TableToRender tableData={tableData3} tableTotal={tableData10}/>
                        <TableToRender tableData={tableData4} tableTotal={tableData11}/>
                        <TableToRender tableData={tableData5} tableTotal={tableData12}/>
                        <TableToRender tableData={tableData6} tableTotal={tableData13}/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    { showApprovalButton === 1 &&
                        <Button color="primary" type="submit">Approve</Button>
                    }
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default ShopTimeEntryToApproveModal;
