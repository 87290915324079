import { Switch, Route } from "react-router-dom";
import { PrivatePages } from "./private";
import { PublicPages } from "./public";
import { useAuthenticate } from "../hooks/auth";

export const AppRouter = () => {
    const { token } = useAuthenticate();

    return (
        <Switch>
            {token ? (
                <PrivatePages />
            ) : (
                <Route path="/">
                    <PublicPages />
                </Route>
            )}
        </Switch>
    );
};
