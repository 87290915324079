import React from 'react';
import SignatureCanvas from 'react-sigpad';
import CancelButton from "../../../forms/form-control/daily/CancelButton";

/**
 * Signature for Form
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SignatureInForm = (props) => {
    const {
        id,
        name,
        label,
        className,
        width,
        setValue,
        height,
        sigCanvas
    } = props;

    const saveToPNG = (v, sigCanvas, name, setValue) => {
        if (sigCanvas.current.isEmpty() === false) {
            // console.log(sigCanvas.current);
            setValue(name, sigCanvas.current.toDataURL("image/png"));
        }
    }

    const handleClear = (v, sigCanvas, name, setValue) => {
        // if signature pad is not clear then you can clear it
        if (sigCanvas.current.isEmpty() === false) {
            // remove signature
            sigCanvas.current.clear();
            // unset value
            setValue(name, '');
        }
    }

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <SignatureCanvas
                penColor="black"
                backgroundColor="white"
                canvasProps={
                    {
                        width: width,
                        height: height,
                        id: id,
                        name: name
                    }
                }
                onEnd={(v) => saveToPNG(v, sigCanvas, name, setValue)}
                ref={sigCanvas}
            />
            <CancelButton
                label="Clear"
                className="padding10"
                onClick={(v) => {handleClear(v, sigCanvas, name, setValue)}}
            />
        </div>
    );
}

export default React.memo(SignatureInForm);
