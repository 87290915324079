import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormInputDate from "../../forms/form-control/FormInputDate";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../utils/stateHelpers";

/**
 * DisciplineDateRangeFilter component
 * @returns {JSX.Element}
 * @constructor
 */
const DisciplineDateRangeFilter = (prop) => {
    const {register, handleSubmit, errors} = useForm();
    const {filters, setFilters, setTableData, OnSubmitUrl} = prop;
    const [disciplines, setDisciplines] = useState([]);

    // get list of disciplines
    useEffect(() => {
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });
    }, []);

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post(OnSubmitUrl, data)
            .then(response => {
                setTableData(response.data);
            })
            .catch(function () {})
            .then(function () {
                updateStateValue(setFilters,'isLoading', false);
            });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.discipline_id}
                    options={disciplines}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.date_from}
                    register={register}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.date_to}
                    register={register}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default DisciplineDateRangeFilter;
