import React, {useEffect, useState} from 'react';
import {useForm, useFieldArray} from 'react-hook-form';
import {toast} from 'react-toastify';
import apiClient from '../../../../utils/apiClient';
import Breadcrumb from '../../../common/breadcrumb';
import FormInputDate from "../../../forms/form-control/FormInputDate";
import FormSelect from '../../../forms/form-control/FormSelect';
import InvoiceChargesTable from './InvoiceChargesTable';
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import LoadingIndicator from "../../../common/loadingIndicator";

const CreateInvoiceCharges = () => {
    const {register, control, handleSubmit, setValue, getValues, errors, reset} = useForm({
        defaultValues: {
            charges: [{}], // Set first row empty by default
        }
    });
    const {fields, append, remove} = useFieldArray({
        control,
        keyName: 'fieldArrayId',
        name: 'charges'
    });
    const [disciplines, setDisciplines] = useState([]);
    const [rigs, setRigs] = useState([]);
    const [clients, setClients] = useState([]);
    const [locations, setLocations] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });
    }, []);

    const onDisciplineSelect = async (event) => {
        const disciplineId = event.target.value;

        if (disciplineId) {
            setValue('rig_id', null);
            setValue('job_id', null);
            setValue('client_id', null);
            setValue('location_id', null);

            // Get active rigs
            apiClient
                .post('getAllActiveRigsByDisciplineId', {discipline_id: disciplineId})
                .then((response) => {
                    setRigs(response.data.rigs);
                });
        }
    }

    const onRigSelect = (event) => {
        const rigId = event.target.value;

        if (rigId) {
            setValue('job_id', null);
            setValue('client_id', null);
            setValue('location_id', null);

            // Get active jobs based on selected rig
            apiClient
                .post('getAllActiveJobsByRigIdList', {rig_id: rigId})
                .then(response => {
                    setJobs(
                        response.data.jobs.map(
                            ({id, job_number}) => ({id: id, name: job_number})
                        )
                    );
                });
        }
    }

    const onJobSelect = (event) => {

        const jobId = event.target.value;
        
        if (jobId) {
            setValue('client_id', null);
            setValue('location_id', null);

            // Populate locations available from selected job
            apiClient
            .post('getLocationsByJobId', {'job_id': jobId})
            .then(response => {
                setLocations(
                    response.data.locations.map(
                        ({id, name}) => ({value: id, label: name})
                    )
                );
            });
            
            // Get active clients from selected job
            apiClient
                .post('getActiveClientsByJobIdList', {'job_id': jobId})
                .then(response => {
                    setClients(
                        response.data.activeClientsList.map(
                            ({id, client_name}) => ({id: id, name: client_name})
                        )
                    );
                });
        }
    }

    const onSave = (data) => {
        setIsLoading(true);
        apiClient
            .post('createInvoiceCharges', data)
            .then((response) => {
                reset();
                toast.success(response.data?.message, {autoClose: 2000});
            })
            .catch(function () {
            })
            .then(function () {
                setIsLoading(false);
            });
    }

    return (
        <>
            <Breadcrumb title="Create Invoice Charges" parent="Data Entry"/>
            <form
                className="needs-validation container-fluid"
                onSubmit={handleSubmit(onSave)}>
                <div className="form-row row s-gy">
                    <FormInputDate
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        id="date"
                        label="Date"
                        register={register({required: "required"})}
                    />
                    <FormSelect
                        id="discipline_id"
                        label="Discipline"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        register={register({required: "required"})}
                        onChange={onDisciplineSelect}
                        options={disciplines}
                        errors={errors}
                    />
                    <FormSelect
                        id="rig_id"
                        label="Rig"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        register={register({required: "required"})}
                        onChange={onRigSelect}
                        options={rigs}
                        errors={errors}
                    />
                    <FormSelect
                        id="job_id"
                        label="Job"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        register={register({required: "required"})}
                        onChange={onJobSelect}
                        options={jobs}
                        errors={errors}
                    />
                    <FormSelect
                        id="client_id"
                        label="Client"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        register={register({required: "required"})}
                        options={clients}
                        errors={errors}
                    />
                </div>
                <div className="form-row row m-t-10 s-gy">
                    <FormSelect
                        id="location_id"
                        label="Location"
                        className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        register={register({required: "required"})}
                        options={locations}
                        errors={errors}
                    />
                </div>
                <div className="form-row m-t-10">
                    <InvoiceChargesTable
                        register={register}
                        control={control}
                        errors={errors}
                        fields={fields}
                        append={append}
                        remove={remove}
                        setValue={setValue}
                        getValues={getValues}
                    />
                </div>
                <div className="form-row m-t-10">
                    <FormSubmitButton
                        className="col-md-1"
                        btnClassName="btn-primary"
                        label="Save"
                    />
                </div>
            </form>
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default CreateInvoiceCharges;
