import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import ShiftReportFilters from "./shiftReportFilters";
import ShiftReportBody from "./shiftReportBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import apiClient from "../../../../utils/apiClient";
import { isEmpty } from "lodash";

/**
 * ShiftReport Component
 * @returns {JSX.Element}
 * @constructor
 */
export default function ShiftReport(props) {
    const {id, type, date_from, date_to} = props.match.params;
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        financial_period_id: "",
        pay_period_id: "",
        isLoading: false,
        isSubmitted: false,
    });

    const reportFilterProps = {
        setFilters,
        setTableData,
    };

    const reportBodyProps = {
        filters,
        tableData,
    };

    useEffect(()=> {
        if (id) {
            apiClient
                .post("getShiftReportData", {financial_period_id: type === 'financial_period' ? id : '', pay_period_id: type === 'pay_period' ? id : ''})
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setTableData([]);
                        return;
                    }
                    setTableData(response.data);
                })
                .then(setFilters(prev=>({
                    ...prev,
                    financial_period_id: type === 'financial_period' ? id : '',
                    pay_period_id: type === 'pay_period' ? id : '',
                    isSubmitted: true
                })))
        }
            
    }, [id, type])

    return (
        <>
            <Breadcrumb title="Shift Report" parent="Reports" />
            {id ? <h3>{type === 'financial_period' ? 'Monthly Data Period' : 'Weekly Data Period'} ({date_from} - {date_to})</h3> : <ShiftReportFilters {...reportFilterProps} />}
            <ShiftReportBody {...reportBodyProps} />
            <LoadingIndicator isLoading={filters.isLoading} />
        </>
    );
}
