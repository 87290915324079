import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import ClientUsersBody from "./clientUsersBody";
import ClientFormFilter from "../../common/form-filters/ClientFormFilter";
import LoadingIndicator from "../../common/loadingIndicator";

/**
 * Client Users Component
 * @returns {JSX.Element}
 * @constructor
 */
const ClientUsers = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Users" parent="Client Portal" />
            <ClientFormFilter {...{setFilters, setTableData, onSubmitUrl:"getClientUsers"}}/>
            <ClientUsersBody {...{filters, tableData}} />
            <LoadingIndicator isLoading={filters.isLoading} />
        </>
    );
}

export default ClientUsers;