import Axios from "axios";
import {toast} from "react-toastify";

class HttpClient {
    _accessToken = "";
    instance = null;

    _refreshToken = "";

    get refreshToken() {
        return this._refreshToken;
    }

    set accessToken(token) {
        this._accessToken = token;
    }

    get accessToken() {
        return this._accessToken;
    }

    set refreshToken(token) {
        this._refreshToken = token;
    }

    static getInstance() {
        if (!HttpClient.instance) {
            HttpClient.instance = new HttpClient();
        }

        return HttpClient.instance;
    }

    get = async (url, params = {}, headers = {}) => {
        try {
            const { data } = await Axios.get(url, { params, headers });
            return data;
        } catch (error) {
            handleError(error)
            throw error
        }
    };

    post = async (url, body, params = {}, headers = {}) => {
        try {
            const { data } = await Axios.post(url, body, { params, headers });
            return data;
        } catch (error) {
            handleError(error)
            throw error
        }
    };

    put = async (url, body, params = {}, headers = {}) => {
        try {
            const { data } = await Axios.put(url, body, { params, headers });
            return data;
        } catch (error) {
            handleError(error)
            throw error
        }
    };

    delete = async (url, params = {}, headers = {}) => {
        try {
            const { data } = await Axios.delete(url, { params, headers });
            return data;
        } catch (error) {
            handleError(error)
            throw error
        }
    };

    authGet = async (token, url, params = {}) => {
        return await this.get(url, params, {
            Authorization: `Bearer ${token}`,
        });
    };

    authPost = async (token, url, body = {}, params = {}) => {
        return await this.post(url, body, params, {
            Authorization: `Bearer ${token}`,
        });
    };

    authPut = async (token, url, body = {}, params = {}) => {
        return await this.put(url, body, params, {
            Authorization: `Bearer ${token}`,
        });
    };

    authDelete = async (token, url, params = {}) => {
        return await this.delete(url, params, {
            Authorization: `Bearer ${token}`,
        });
    };
}

const handleError = (error) => {
    if (error.response) {
        notifyError(error.response.data)
    } else if (error.request) {
        console.log(error.request)
    } else {
        console.log('Error', error.message)
        toast.error(error.message, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        })
    }
}

const notifyError = (data) => {
    toast.warn(getErrorMessageContent(data), {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};

/**
 * parse and build error message content
 * @param errorData
 * @returns {JSX.Element}
 */
const getErrorMessageContent = (errorData) => {
    let errorList = [];
    //Check if error response include list of errors
    if (errorData.error) {
        errorList = Object.values(errorData.error).flat();
    }

    return (
        <div>
            <span className="font-weight-bold">{errorData.message}</span>
            <ul>
                {errorList.map((item, index) =>
                    <li key={index} >- {item}</li>
                )}
            </ul>
        </div>
    );
}

export const httpClient = HttpClient.getInstance();

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;