import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import apiClient from "../../../../utils/apiClient";
import TableReport from "../../../common/table/table";

/**
 * downtime
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const Downtime = (props) => {
    const dateFrom = props.match.params.date_from;
    const dateTo = props.match.params.date_to;
    const jobId = props.match.params.job_id;
    const [tableData, setTableData] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);

    // get report detail data
    useEffect(() => {
        //get revenue by rig job downtime
        apiClient
            .post('getDownTime', {
                job_id: jobId,
                date_from: dateFrom,
                date_to: dateTo
            })
            .then(response => {
                setTableFilter(response.data.tableFilter)
                setTableData(response.data.tableRows)
            });
    }, [jobId, dateFrom, dateTo]);

    return (
        <>
            <Breadcrumb title="Downtime and Maintenance" parent="Reports"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-2">
                        <span className="font-weight-bold"> Report Date : </span>
                        {tableFilter.date}
                    </div>
                    <div className="col-sm-3">
                        <span className="font-weight-bold"> Rig: </span>
                        {tableFilter.rig_name}
                    </div>
                    <div className="col-sm-2">
                        <span className="font-weight-bold"> Job: </span>
                        {tableFilter.job_number}
                    </div>
                    <div className="col-sm-5">
                        <span className="font-weight-bold"> Period : </span>
                        {tableFilter.financial_period}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TableReport
                            columns={tableColumns}
                            data={tableData}
                            exportOptions={{pdf: false, xlsx: true, csv: true}}
                            getExportFileName={() => 'downtime'}
                            withGlobalSearch={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Downtime;

const tableColumns = [
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'Shift',
        accessor: 'shift',
    },
    {
        Header: 'Description',
        accessor: 'description',
    },
    {
        Header: 'Hours',
        accessor: 'units',
    },
    {
        Header: 'Category',
        accessor: 'revenue_group',
    },
];
