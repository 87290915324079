import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import ShopTimeEntryToApproveFilter from "./shopTimeEntryToApproveFilter";
import ShopTimeEntryToApproveBody from "./shopTimeEntryToApproveBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Shop Time Entry To Approve
 * @returns {JSX.Element}
 * @constructor
 */
const ShopTimeEntryToApprove = () => {
    const [hoursTableHeader, setHoursTableHeader] = useState([]);
    const [hoursTableRows, setHoursTableRows] = useState([]);
    const [perDiemTableHeader, setPerDiemTableHeader] = useState([]);
    const [perDiemTableRows, setPerDiemTableRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [approveModal, setApproveModal] = useState({isOpen: false});
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Shop Time Approve" parent="Time Entry"/>
            <ShopTimeEntryToApproveFilter {...{
                setFilters, setHoursTableRows, setHoursTableHeader, setPerDiemTableHeader,
                setPerDiemTableRows, setSelectedRow, setApproveModal, OnSubmitUrl:"getShopTimeEntriesToReviewByPayPeriodId"
            }}/>
            <ShopTimeEntryToApproveBody {...{
                filters, hoursTableHeader, hoursTableRows, perDiemTableHeader, perDiemTableRows, selectedRow,
                setApproveModal, approveModal, setHoursTableRows
            }}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default ShopTimeEntryToApprove;
