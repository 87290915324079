import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import {useFieldArray} from "react-hook-form";

const Surveys = (props) => {
    const { errors, register, control, collectedHoles, fieldDaily, action } = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "surveys"
    });
    const [selectedSurveysHole, setSelectedSurveysHole] = useState('');

    useEffect(() => {
        if (action === 'edit') {
            append(fieldDaily.field_surveys);
        }
    }, [action, append, fieldDaily]);

    return (
        <AccordionItem className="card-header bg-primary" title="Surveys">
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Depth</th>
                                        <th>Azi</th>
                                        <th>Inc</th>
                                        <th>Roll</th>
                                        <th>Temp</th>
                                        <th>Mag</th>
                                        <th>Hole</th>
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`surveys[${index}].depth`}
                                                name={`surveys[${index}].depth`}
                                                value={(action === 'edit') ? item.depth : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`surveys[${index}].azi`}
                                                name={`surveys[${index}].azi`}
                                                value={(action === 'edit') ? item.azi : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`surveys[${index}].inc`}
                                                name={`surveys[${index}].inc`}
                                                value={(action === 'edit') ? item.inc : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`surveys[${index}].roll`}
                                                name={`surveys[${index}].roll`}
                                                value={(action === 'edit') ? item.roll : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`surveys[${index}].temp`}
                                                name={`surveys[${index}].temp`}
                                                value={(action === 'edit') ? item.temp : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`surveys[${index}].mag`}
                                                name={`surveys[${index}].mag`}
                                                value={(action === 'edit') ? item.mag : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`surveys[${index}].hole_name`}
                                                    name={`surveys[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`surveys[${index}].hole_name`}
                                                    name={`surveys[${index}].hole_name`}
                                                    value={
                                                        selectedSurveysHole
                                                            ?
                                                            selectedSurveysHole
                                                            :
                                                            (collectedHoles.length === 1)
                                                                ?
                                                                collectedHoles[0].value
                                                                :
                                                                item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedSurveysHole)
                                                    }
                                                    options={collectedHoles}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => remove(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default Surveys;
