import React, {useState} from 'react';
import TableReport from "../../common/table/table";
import ModalConfirmation from "../../common/modal/ModalConfirmation.js";
import MessageEmptyData from "../../common/messageEmptyData";
import {processInvoicesTableColumns} from "./processInvoicesTableColumns.js";
import apiClient from "../../../utils/apiClient";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {isEmpty} from "lodash";

/**
 * Body report component for report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ProcessInvoicesBody = (prop) => {
    const {reportFilters, tableData} = prop;
    const [modal, setModal] = useState({isOpen: false, message: <p></p>});

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    // process and download handler
    const onProcess = () => {
        //get pdf export file and open it in a new window
        apiClient('invoicesProcessAndDownload', {
            method: 'POST',
            responseType: 'blob', //Force to receive data in a Blob Format
            data: reportFilters
        })
            .then(response => {
                const blob = new Blob([response.data], {type: response.data.type});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'processedInvoices.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(function () {
            })
            .then(function () {
                //close modal
                setModal({...modal, isOpen: false})
            });
    }

    const openModal = () => {
        const msg = <p>Are you sure that you want to finalize the invoices</p>;
        setModal({
            message: msg,
            isOpen: true,
        })
    };

    return (
        <div className="container-fluid">
            <TableReport
                columns={processInvoicesTableColumns}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                scrollable={true}
                getExportFileName={() => 'process_invoices'}
            />
            {!isEmpty(tableData) &&
                <FormSubmitButton
                    className="col-md-1"
                    type="button"
                    label="Process & Download"
                    onClick={openModal}
                />
            }
            <ModalConfirmation
                modal={modal}
                setModal={setModal}
                title="Finalize Invoices"
                onConfirm={onProcess}
            />
        </div>
    );
}

export default ProcessInvoicesBody;
