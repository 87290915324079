import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import FormSelect from "../../../forms/form-control/FormSelect";

/**
 * Filter component
 * @returns {JSX.Element}
 * @constructor
 */
const AllocateJobsFilters = (prop) => {
    const {register, handleSubmit, errors} = useForm();
    const {setFilters, setTableData} = prop;
    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);

    // get list of active clients
    useEffect(() => {
        apiClient
            .get('getActiveClientsList')
            .then(response => {
                setClients(
                    response.data.activeClientsList.map(
                        ({id, client_name}) => ({id: id, name: client_name})
                    )
                );
            });
    }, []);

    const getClientUsers = (id, value) => {
        updateStateValue(setFilters, id, value);
        if (value) {
            apiClient
                .post('getClientById', {id: value})
                .then(response => {
                    setUsers(
                        response.data.client.employees.map(
                            ({id, first_name, last_name}) => (
                                {value: id, label: first_name + ' ' + last_name}
                            )
                        )
                    );
                })
                .then(function () {
                    updateStateValue(setFilters, 'isLoading', false);
                });
        }
    }

    const onSubmit = (data) => {
        updateStateValue(setFilters, 'isLoading', true);
        updateStateValue(setFilters, 'isSubmitted', true);

        apiClient
            .post('getUserJobsByClientIdAndUserId', data)
            .then(response => {
                console.log(response.data)
                if (Object.keys(response.data.records).length === 0) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data.records);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
            updateStateValue(setFilters, 'isLoading', false);
        });
    }

    return (
        <form
            className="needs-validation container-fluid mb-4"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="client_id"
                    label="Client"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={clients}
                    register={register({required: "required"})}
                    onChange={(e) => getClientUsers(e.target.id, e.target.value)}
                    errors={errors}
                />
                <FormSelect
                    id="user_id"
                    label="User"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    onChange={(e) => {updateStateValue(setFilters, e.target.id, e.target.value)}}
                    options={users}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default AllocateJobsFilters;
