import React, {useEffect, useState} from 'react';
import Header from './common/header-component/header';
import EnvNotification from './common/header-component/envNotification';
import Sidebar from './common/sidebar-component/sidebar';
import Footer from './common/footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './common/loader';

const AppLayout = ({children}) => {
    const [sidebar, setSidebar] = useState(false);

    // calculate scroll height when scrolled.
    const calcScroll = () => {
        let headerHeight = document?.getElementById("header-menu").scrollHeight;
        // subtract a fixed value from the header height.
        return  headerHeight - 117;
    }

    const openCloseSidebar = () => {
        if (sidebar) {
            setSidebar(!sidebar)
            document.querySelector(".page-main-header").classList.remove('open');
            document.querySelector(".page-sidebar").classList.remove('open');
        } else {
            setSidebar(!sidebar)
            document.querySelector(".page-main-header").classList.add('open');
            document.querySelector(".page-sidebar").classList.add('open');
        }
    }

    useEffect(() => {
        // register onscroll event to Event Queue
        window.onscroll = () => {
            // decide visibility level according to mouse scroll height
            if (document.documentElement.scrollTop < calcScroll()) {
                document.getElementById("page-body").className = "page-body";
            } else {
                document.getElementById("page-body").className = "page-body top-page-body";
            }
        }
    }, []);

    return (
        <div>
            <Loader />
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Header
                        openCloseSidebar={openCloseSidebar}
                        setSidebar={setSidebar}
                    />
                    <Sidebar openCloseSidebar={openCloseSidebar} />
                    <div className="page-body" id="page-body">
                        <EnvNotification />
                        {children}
                    </div>
                    <Footer />
                </div>
            </div>
            <ToastContainer theme="colored"/>
        </div>
    );
}

export default AppLayout;
