import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import { updateStateValue } from "../../../utils/stateHelpers";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import { isEmpty } from "lodash";
import {useAuthenticate} from "../../../hooks/auth";

/**
 * Client Form Filter Component
 * @returns {JSX.Element}
 * @constructor
 */
const ClientFormFilter = (prop) => {
    const { setFilters, setTableData, onSubmitUrl} = prop;
    const [clients, setClients] = useState([]);
    const { user } = useAuthenticate();
    const CLIENT_GROUP_ID = 5;
    const {register, handleSubmit} = useForm();

    // get list of active clients
    useEffect(()=> {
        if (user?.group_id !== CLIENT_GROUP_ID) {
            apiClient
                .get('getActiveClientsList')
                .then(response => {
                    setClients(
                        response.data.activeClientsList.map(
                            ({id, client_name}) => ({id: id, name: client_name})
                        )
                    )
                })
        } else {
            apiClient
                .post(onSubmitUrl,[])
                .then(response => {
                    if (isEmpty(response.data)) {
                        setTableData([]);
                        return;
                    }
                    setTableData(response.data);
                })
                .catch(function () {
                    setTableData([]);
                }).then(function () {
                    updateStateValue(setFilters, 'isLoading', false);
                    updateStateValue(setFilters, 'isSubmitted', true);
                })
        }
    }, [setFilters, setTableData, user?.group_id, onSubmitUrl])

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post(onSubmitUrl, data)
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
                updateStateValue(setFilters,'isLoading', false);
            });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                {
                    (user?.group_id !== CLIENT_GROUP_ID) &&
                    <>
                        <FormSelect
                            id="client_id"
                            label="Client"
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                            options={clients}
                            register={register}
                        />
                        <FormSubmitButton
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                            label="Show"
                        />
                    </>
                }
            </div>
        </form>
    );
}

export default ClientFormFilter;