export const JobCostTable = [
    {
        Header: 'Rig',
        accessor: 'rig',
        width: 70
    },
    {
        Header: 'Job',
        accessor: 'job',
        width: 90,
    },
    {
        Header: 'Customer',
        accessor: 'customer',
        width: 50,
    },
    {
        Header: 'Labor',
        accessor: 'labor',
        width: 90,
    },
    {
        Header: 'Material',
        accessor: 'material',
    },
    {
        Header: 'Subs',
        accessor: 'job_description',
    },
    {
        Header: 'External Equipment',
        accessor: 'ordered_by',
        width: 90,
    },
    {
        Header: 'Internal Equipment',
        accessor: 'order_date',
        width: 90,
    },
    {
        Header: 'Fuel',
        accessor: 'description',
    },
    {
        Header: 'Bits & Tooling',
        accessor: 'price',
        width: 100,
    },
    {
        Header: 'Travel',
        accessor: 'is_charged',
        width: 90
    },
    {
        Header: 'Other',
        // accessor: 'daily_id',
        width: 100,
    },
    {
        Header: 'Total Cost',
        // accessor: 'daily_id',
        width: 100,
    },
    {
        Header: 'Billed Amount',
        // accessor: 'daily_id',
        width: 100,
    },
    {
        Header: 'Profit',
        // accessor: 'daily_id',
        width: 100,
    },
    {
        Header: 'CM JTDP%',
        // accessor: 'daily_id',
        width: 100,
    }
];