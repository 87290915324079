import React from 'react';
import {Link} from 'react-router-dom';
import {Settings, LogOut} from 'react-feather';
import { withRouter } from "react-router";
import {toast} from "react-toastify";
import { useAuthenticate } from '../../../hooks/auth';

const UserMenu = () => {
    const {token, user, signout} = useAuthenticate();

    const logOut = async () => {
        try {
            await signout(token);
        } catch (error) {
            console.error(error);
            toast.error("Oops.. Something went wrong.");
        }
    }

    return (
        <>
            <li className="onhover-dropdown">
                <div className="media align-items-center s-header-icon">
                    <i className="usermenu-icon fa fa-user fa-lg s-icon-lg mt-2" />
                    <i className="usermenu-icon fa fa-user fa-lg s-icon-sm s-header-icon mt-1" style={{ fontSize: 24 }} />
                    {/*<User />*/}
                    {/*<img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={profile} alt="header-user" />*/}
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li>{`${user ? user.first_name + ' ' + user.last_name : ''}`}</li>
                    {/* <li><Link to="/settings/profile" ><i data-feather="user"></i>Edit Profile</Link></li> */}
                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/user/settings/change_password`}>
                            <Settings size={18} className="s-icon-lg s-header-icon" />
                            <Settings
                                size={26}
                                className="s-icon-sm s-header-icon"
                            />
                            User Settings
                        </Link>
                    </li>
                    <li>
                        <div
                            onClick={logOut}
                            className="s-logout-menu-item"
                        >
                            <LogOut size={18} className="s-icon-lg s-header-icon" />
                            <LogOut
                                size={26}
                                className="s-icon-sm s-header-icon"
                            />Log out
                        </div>
                    </li>
                </ul>
            </li>
        </>
    );
}

export default withRouter(UserMenu);
