import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {Controller, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import apiClient from "../../../utils/apiClient";
import Creatable from 'react-select/creatable';
import FormInputFile from "../../forms/form-control/FormInputFile";

const CreateJob = () => {
    const { register, handleSubmit, errors, control, reset } = useForm(); // initialise the hook
    const [clientsInfo, setClientsInfo] = useState('');
    const [statesInfo, setStatesInfo] = useState('');
    const [allStatesInfo, setAllStatesInfo] = useState('');
    const [managers, setManagers] = useState('');
    const [workTypes, setWorkTypes] = useState('');
    const [endUses, setEndUses] = useState('');
    const [jobMethods, setJobMethods] = useState('');
    const [disciplines, setDisciplines] = useState('');
    const [billingPeriods, setBillingPeriods] = useState('');
    const [rigs, setRigs] = useState('');
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        // job methods
        apiClient
            .get('getJobMethods')
            .then(response => {
                const optionItems = response.data.jobMethods.map((jobMethod) =>
                    <option key={jobMethod.id} value={jobMethod.id}>{jobMethod.name}</option>
                );

                setJobMethods(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // Billing Periods
        apiClient
            .get('billingPeriods')
            .then(response => {
                const optionItems = response.data.periods.map((period) =>
                    <option key={period.id} value={period.id}>{period.period_name}</option>
                );

                setBillingPeriods(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // end uses
        apiClient
            .get('getJobEndUses')
            .then(response => {
                const optionItems = response.data.jobEndUses.map((endUses) =>
                    <option key={endUses.id} value={endUses.id}>{endUses.name}</option>
                );

                setEndUses(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // work types
        apiClient
            .get('getJobWorkTypes')
            .then(response => {
                const optionItems = response.data.jobWorkTypes.map((workTypes) =>
                    <option key={workTypes.id} value={workTypes.id}>{workTypes.name}</option>
                );

                setWorkTypes(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // Managers in DB  it is operations managers but we are deactivating this group
        apiClient
            .post('getUsersByGroupId', {'id' : 2})
            .then(response => {
                const optionItems = response.data.map((user) =>
                    <option key={user.id} value={user.id}>{user.first_name + ' ' + user.last_name}</option>
                );

                setManagers(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // USA States
        apiClient
            .get('getUSAStates')
            .then(response => {
                const optionItems = response.data.states.map((state) =>
                    <option key={state.id} value={state.id}>{state.abbreviation}</option>
                );

                setStatesInfo(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // Clients
        apiClient
            .get('getActiveClientsList')
            .then(response => {
                const clientItems = response.data.activeClientsList.map((client) =>
                    <option key={client.id} value={client.id}>{client.client_name + '(' + client.erp_id + ')'}</option>
                );

                setClientsInfo(clientItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // get Disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                const disciplineItems = response.data.disciplines.map((item) =>
                    <option key={item.id} value={item.id}>{item.name}</option>
                );
                setDisciplines(disciplineItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // get all countries
        apiClient
            .get('getALLStates')
            .then(response => {
                const optionItems = response.data.states.map((state, index) =>
                    (index === 0)
                        ?
                        (<optgroup key={index} label='United States :'/>)
                        :
                        (index > 0 && index <= 49)
                            ?
                            (<option key={index} value={state.id}>{state.abbreviation + ' - ' + state.name}</option>)
                            :
                            (index === 50)
                                ?
                                (<optgroup key={index} label='Canada Provinces :'/>)
                                :
                                (<option key={index} value={state.id}>{state.abbreviation + ' - ' + state.name}</option>)
                );

                setAllStatesInfo(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // Locations
        apiClient
            .get('getLocations')
            .then(response => {
                const locationItems = response.data.locations.map(
                    ({id, name}) => ({value: id, label: name})
                );
                setLocations(locationItems);
            });

    }, []);

    const handleDisciplineSelect = (disciplineId) => {
        // rigs
        if (disciplineId) {
            apiClient
                .post('getAllActiveRigsByDisciplineId', {'discipline_id': disciplineId},)
                .then(response => {
                    const rigItems = response.data.rigs.map((rig) =>
                        <option key={rig.id} value={rig.id}>{rig.name}</option>
                    );

                    setRigs(rigItems);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    const onSubmit = (data) => {
        const formData = new FormData();
        for (const key in data) {
            switch (key) {
                case 'job_file':
                    for (const file of data[key]) {
                        formData.append('job_file[]', file, file.name);
                    }
                    break;
                case 'locations':
                    for (const obj of data[key]) {
                        formData.append('locations[]', JSON.stringify(obj));
                    }
                    break;
                default:
                    formData.append(key, data[key]);
            }
        }

        apiClient
            .post('createJob', formData)
            .then(response => {
                toast.success("Job has been created.");
                reset();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <Fragment>
            <Breadcrumb title="Create Job" parent="Commercial" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <form
                            className="needs-validation"
                            noValidate=""
                            id="create_job_form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="discipline_id">Discipline</label>
                                            <select
                                                className="form-control"
                                                name="discipline_id"
                                                id="discipline_id"
                                                ref={register({ required: true })}
                                                onChange={e => handleDisciplineSelect(e.target.value)}
                                            >
                                                <option value=""/>
                                                {disciplines}
                                            </select>
                                            <span>{errors.discipline_id && 'Select Discipline'}</span>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="job_number">Job #</label>
                                            <input
                                                className="form-control"
                                                name="job_number"
                                                type="text"
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.job_number && 'Job number is required'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="contract_date">Contract Date</label>
                                            <input
                                                className="form-control"
                                                name="contract_date"
                                                id="contract_date"
                                                type="date"
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.contract_date && 'Contract Date is required'}</span>
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="client_id">Client</label>
                                            <select
                                                className="form-control"
                                                name="client_id"
                                                id="client_id"
                                                ref={register({ required: true })}
                                            >
                                                <option value=""/>
                                                {clientsInfo}
                                            </select>
                                            <span>{errors.client_id && 'Please select Client'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="job_start_date">Start Date</label>
                                            <input
                                                className="form-control"
                                                name="job_start_date"
                                                id="job_start_date"
                                                type="date"
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.job_start_date && 'Job Start Date is required'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="projected_close_date">Close Date</label>
                                            <input
                                                className="form-control"
                                                name="projected_close_date"
                                                id="projected_close_date"
                                                type="date"
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.projected_close_date && 'Projected Close Date is required'}</span>
                                        </div>
                                    </div>
                                    <div className="form-row s-gy">
                                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <label htmlFor="job_name">Job Name</label>
                                            <input
                                                className="form-control"
                                                id="job_name"
                                                name="job_name"
                                                type="text"
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.job_name && 'Please provide a valid Job Name.'}</span>
                                            <div className="invalid-feedback">Please provide a valid Job Name.</div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <label htmlFor="billing_period_id">Payment terms</label>
                                            <select
                                                className="form-control"
                                                name="billing_period_id"
                                                id="billing_period_id"
                                                ref={register({ required: true })}
                                            >
                                                <option value=""/>
                                                {billingPeriods}
                                            </select>
                                            <span>{errors.billing_period_id && 'Please Select Payment Term'}</span>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <label htmlFor="retainage">Retainage %</label>
                                            <input
                                                className="form-control"
                                                name="retainage"
                                                id="retainage"
                                                type="number"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <label htmlFor="job_city">Job City</label>
                                            <input
                                                className="form-control"
                                                id="job_city"
                                                name="job_city"
                                                type="text"
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.job_city && 'Please enter Job City.'}</span>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <label htmlFor="state_id">State</label>
                                            <select
                                                className="form-control"
                                                name="state_id"
                                                id="state_id"
                                                ref={register({ required: true })}
                                            >
                                                <option value=""/>
                                                {statesInfo}
                                            </select>
                                            <span>{errors.state_id && 'Please Select State.'}</span>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="service_agreement">Service Agreement</label>
                                            <input
                                                className="form-control"
                                                id="service_agreement"
                                                name="service_agreement"
                                                type="text"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-1 mb-3">
                                            <label htmlFor="bid_number">BID #</label>
                                            <input
                                                className="form-control"
                                                id="bid_number"
                                                name="bid_number"
                                                type="number"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-1 mb-3">
                                            <label htmlFor="rig_id">Rig</label>
                                            <select
                                                className="form-control"
                                                name="rig_id"
                                                id="rig_id"
                                                ref={register({ required: true })}
                                            >
                                                <option value=""/>
                                                {rigs}
                                            </select>
                                            <span>{errors.rig_id && 'Select Rig'}</span>
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="operations_manager_id">Manager</label>
                                            <select
                                                className="form-control"
                                                name="operations_manager_id"
                                                id="operations_manager_id"
                                                ref={register({ required: false })}
                                            >
                                                <option value=""/>
                                                {managers}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-5 mb-3">
                                            <label htmlFor="scope_of_work">Scope Of Work</label>
                                            <input
                                                className="form-control"
                                                id="scope_of_work"
                                                name="scope_of_work"
                                                type="text"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="type_of_work_id">Type Of Work</label>
                                            <select
                                                className="form-control"
                                                name="type_of_work_id"
                                                id="type_of_work_id"
                                                ref={register({ required: true })}
                                            >
                                                <option value=""/>
                                                {workTypes}
                                            </select>
                                            <span>{errors.type_of_work_id && 'Please Select Type Of Work.'}</span>
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="job_method_id">Job Method</label>
                                            <select
                                                className="form-control"
                                                name="job_method_id"
                                                id="job_method_id"
                                                ref={register({ required: true })}
                                            >
                                                <option value=""/>
                                                {jobMethods}
                                            </select>
                                            <span>{errors.job_method_id && 'Please Select Job Method.'}</span>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-1 mb-3">
                                            <label htmlFor="bonded">Bonded</label>
                                            <select
                                                className="form-control"
                                                name="bonded"
                                                id="bonded"
                                                ref={register({ required: true })}
                                            >
                                                <option value=""/>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                            <span>{errors.bonded && 'Please Select Bonded'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="prevailing_wage">Prevailing Wage</label>
                                            <select
                                                className="form-control"
                                                name="prevailing_wage"
                                                id="prevailing_wage"
                                                ref={register({ required: true })}
                                            >
                                                <option value=""/>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                            <span>{errors.prevailing_wage && 'Please Select Prevailing Wage'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="end_use_id">End Use</label>
                                            <select
                                                className="form-control"
                                                name="end_use_id"
                                                id="end_use_id"
                                                ref={register({ required: true })}
                                            >
                                                <option value=""/>
                                                {endUses}
                                            </select>
                                            <span>{errors.end_use_id && 'Please Select End Use'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="deposit">Client's Deposit</label>
                                            <input
                                                className="form-control"
                                                id="deposit"
                                                name="deposit"
                                                type="number"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-7 mb-3">
                                            <label htmlFor="notes">Notes</label>
                                            <textarea
                                                className="form-control"
                                                type="text"
                                                name="notes"
                                                id="notes"
                                                rows="3"
                                                cols="33"
                                                ref={register({required: false})}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="fuel_markup_percent">Fuel Markup %</label>
                                            <input
                                                className="form-control"
                                                name="fuel_markup_percent"
                                                id="fuel_markup_percent"
                                                type="number"
                                                ref={register({required: true})}
                                            />
                                            <span>{errors.fuel_markup_percent && 'required'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h4>Billing Details</h4>
                                    <div className="form-row">
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="ap_contact_name">AP Contact Name</label>
                                            <input
                                                className="form-control"
                                                id="ap_contact_name"
                                                name="ap_contact_name"
                                                type="text"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="ap_email">Email</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    name="ap_email"
                                                    type="email"
                                                    id="ap_email"
                                                    aria-describedby="inputGroupPrepend"
                                                    ref={register({ required: false })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="ap_contact_number">Contact #</label>
                                            <input
                                                className="form-control"
                                                id="ap_contact_number"
                                                name="ap_contact_number"
                                                type="number"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-5 mb-3">
                                            <label htmlFor="billing_address">Address</label>
                                            <input
                                                className="form-control"
                                                id="billing_address"
                                                name="billing_address"
                                                type="text"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="billing_city">City</label>
                                            <input
                                                className="form-control"
                                                id="billing_city"
                                                name="billing_city"
                                                type="text"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-1 mb-3">
                                            <label htmlFor="billing_state">State</label>
                                            <select
                                                className="form-control"
                                                name="billing_state"
                                                id="billing_state"
                                                ref={register({ required: false })}
                                            >
                                                <option value=""/>
                                                {allStatesInfo}
                                            </select>
                                        </div>
                                        <div className="col-md-1 mb-3">
                                            <label htmlFor="billing_zip">Zip</label>
                                            <input
                                                className="form-control"
                                                id="billing_zip"
                                                name="billing_zip"
                                                type="text"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h4>Productivity Expectations Section</h4>
                                    <div className="form-row">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="expected_shift_average">Expected Shift Average</label>
                                            <input
                                                className="form-control"
                                                id="expected_shift_average"
                                                name="expected_shift_average"
                                                type="text" ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="expected_ft_per_shift">Expected FT./Shift</label>
                                            <input
                                                className="form-control"
                                                id="expected_ft_per_shift"
                                                name="expected_ft_per_shift"
                                                type="text" ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="expected_billed_percentage">Expected Billed %</label>
                                            <input
                                                className="form-control"
                                                id="expected_billed_percentage"
                                                name="expected_billed_percentage"
                                                type="number"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="expected_drilling_percentage">Expected Drilling %</label>
                                            <input
                                                className="form-control"
                                                id="expected_drilling_percentage"
                                                name="expected_drilling_percentage"
                                                type="number"
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h4>Locations</h4>
                                    <div className="form-row">
                                        <div className="col-md-8">
                                            <Controller
                                                as={Creatable}
                                                options={locations}
                                                name="locations"
                                                defaultValue={[]}
                                                isMulti
                                                control={control}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <h4>Files</h4>
                                    <div className="form-row">
                                        <div className="col-md-8">
                                            <FormInputFile
                                                id="job_file"
                                                className="col-md-12"
                                                // do not delete, accepting all at this point for now
                                                // accept="image/png,image/jpg,image/jpeg,.pdf"
                                                register={register}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button className="btn btn-primary" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CreateJob;
