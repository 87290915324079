import styled from "styled-components";

export const WeeklyProductionTableStyle = styled.div`
  table {
    thead {
      tr {
        th {
          :nth-child(1) {
            width: 20%;
          }
        }
        th:not(:first-child) {
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        td:not(:first-child) {
          text-align: center;
        }
      }
    }
  }
`;