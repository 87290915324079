import FormNoteAlert from "../../forms/form-control/FormNoteAlert";
import React, {Fragment} from "react";

const EnvNotification = () => {
    const hostName = window.location.hostname;

    return (
        <Fragment>
            {hostName !== 'drillsite.nationalewp.com'
                ?
                    <div className="container-fluid">
                        <div className="col-16">
                            <FormNoteAlert
                                className="alert-success"
                                label={(hostName === 'localhost') ? 'LOCALHOST' : (hostName === '10.0.3.11' || hostName === '3.101.133.62') ? 'STAGING' : ''}
                            />
                        </div>
                    </div>
                :
                    <></>
            }
        </Fragment>
    );
}

export default EnvNotification;