/**
 * Add an object to array of objects
 * @param array
 * @param obj
 * @returns {*[]|*}
 */
export const addObjToArray = (array, obj) => {
    return [
        ...array,
        obj
    ];
}

/**
 * Remove an object from array of objects by object parameter
 * @param array
 * @param key
 * @param value
 * @returns {*[]|*}
 */
export const removeObjFromArray = (array, key, value) => {
    const index = array.findIndex(obj => obj[key] === value);
    return index >= 0 ? [
        ...array.slice(0, index),
        ...array.slice(index + 1)
    ] : array;
}

/**
 * Replace an object from array of objects by object parameter
 * @param array
 * @param key
 * @param value
 * @param record
 * @returns {*}
 */
export const replaceObjFromArray = (array, key, value, record) => {
    const index = array.findIndex(obj => obj[key] === value);
    array[index] = record;
    return array;
}

/**
 * Replace an object partially from array of objects by object parameter
 * @param array
 * @param key
 * @param value
 * @param partialRecord
 * @returns {*}
 */
export const replacePartialObjFromArray = (array, key, value, partialRecord) => {
    const index = array.findIndex(obj => obj[key] === value);
    const currentObj = array[index];
    const newObj = {...currentObj, ...partialRecord}
    array[index] = newObj;
    return array;
}

/**
 * Replace multiple objects partially from array of objects by object parameter
 * @param array
 * @param key
 * @param value
 * @param partialRecord
 * @returns {*}
 */
export const multiReplacePartialObjFromArray = (array, key, value, partialRecord) => {
    const indexes = array.reduce(
        (previousValue, currentValue, i) => currentValue[key] === value ? previousValue.concat(i) : previousValue, []
    );
    indexes.forEach((index) => {
        const currentObj = array[index];
        const newObj = {...currentObj, ...partialRecord}
        array[index] = newObj;
    });
    return array;
}

/**
 * This function is specific only to active and inactive dailies
 * @param array
 * @param key
 * @param value
 * @param record
 * @returns {*}
 */
export const replaceDailyObjectsFromArray = (array, key, value, record) => {
    const recordLength = record.length;

    if (recordLength === 1) {
        const index = array.findIndex(obj => obj[key] === value);
        array[index] = record[0];
    } else {
        let i;
        let j;
        for (i = 0; i < array.length; i++) {
            if (array[i]['daily_id'] === value) {
                for (j = 0; j < recordLength; j++) {
                    if (array[i]['hole'] === record[j]['hole']) {
                        array[i] = record[j];
                    }

                }
            }
        }
    }

    return array;
}

/**
 * his function is specific only to active and inactive dailies
 * @param array
 * @param key
 * @param value
 * @returns {*[]|*}
 */
export const removeDailyObjFromArray = (array, key, value) => {
    let i, j;
    let count = 0;
    for (i = 0; i < array.length; i++) {
        if (array[i]['daily_id'] === value) {
            count++;
        }
    }

    // when only one occurrence of value in the array
    if (count === 1) {
        const index = array.findIndex(obj => obj[key] === value);
        return index >= 0 ? [
            ...array.slice(0, index),
            ...array.slice(index + 1)
        ] : array;
    } else {
        for (j = 0; j < array.length; j++) {
            if (array[j]['daily_id'] === value) {
                array.splice(array[j], count);
            }
        }

        return array;
    }
}

/**
 * this function get the period that includes the current date from period array
 * @param array
 * @returns {{}}
 */

export const getCurrentPeriodFromArray = (array) => {
    return array.find(period => {
        return (period.name.includes("(Current)")) ? period : null;
    })
}