/**
 * This function check if the inputed string expresses the current year.
 * @param string
 * @returns boolean
 */

export const isCurrentYear = (year) => {
    const currentYear = new Date().getFullYear();
    if (year.toString() === currentYear.toString()) {
        return true;
    }
    return false;
}