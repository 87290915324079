import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import { WithApp, WithAuth } from "./contexts/wrappers";
import { AppRouter } from "./router";
import "./App.scss";

function App() {
    return (
        <div className="App">
            <BrowserRouter basename="/">
                <ScrollContext>
                    <WithApp>
                        <WithAuth>
                            <AppRouter />
                        </WithAuth>
                    </WithApp>
                </ScrollContext>
            </BrowserRouter>
        </div>
    );
}

export default App;
