import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import {useFieldArray} from "react-hook-form";

const DailyBottomHole = (props) => {
    const { errors, register, control, collectedHoles, daily, action } = props;
    const {fields, append, remove} = useFieldArray({control, name: 'bottom_hole'});
    const [totalLength, setTotalLength] = useState(0);
    const [selectedBottomHoleHole, setSelectedBottomHoleHole] = useState('');

    useEffect(() => {
        if (action === 'edit') {
            append(daily.daily_bottom_holes);

            setTimeout(() => {
                if (daily.daily_bottom_holes.length > 0) {
                    // make visible
                    let totals = document.getElementById("bh_totals");

                    if (totals) {
                        totals.classList.remove('hidden');
                    }

                    Shared.calculateTotals('total_length', setTotalLength);
                }
            }, 200);
        }
    }, [action, daily, append]);

    const calculateTotalLengthInRow = (e, length) => {
        e.preventDefault();
        let lengthValue = (document.getElementById(length).value) ? document.getElementById(length).value : 0;

        if (lengthValue > 0) {
            // make visible
            let totals = document.getElementById("bh_totals");

            if (totals) {
                totals.classList.remove('hidden');
            }

            Shared.calculateTotals('total_length', setTotalLength);
        }
    }

    const removeBottomHoleRow = (index) => {
        remove(index);
        // calculate again
        setTimeout(() => {
            Shared.calculateTotals('total_length', setTotalLength);
        }, 500)
    }

    useEffect(() => {
        if (!fields.length) {
            setTotalLength(0);
            let totals = document.getElementById("bh_totals");

            if (totals) {
                totals.classList.add('hidden');
            }
        }
    }, [fields.length, setTotalLength])

    return (
        <AccordionItem className="card-header bg-primary" title="Bottom Hole">
            <div className="card">
                <div className="form-row hidden" id="bh_totals">
                    <div className="col-md-3 mb-3 total_block">
                        <p className="form-control background_total_block_color"> Total Length : {totalLength}</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Size</th>
                                        <th>Length</th>
                                        <th>Fish Neck Size</th>
                                        <th>Hole</th>
                                        <th>
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <InputInTable
                                                id={`bottom_hole[${index}].description`}
                                                name={`bottom_hole[${index}].description`}
                                                register={register({required: "required"})}
                                                errors={errors}
                                                value={(action === 'edit') ? item.description : ''}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`bottom_hole[${index}].size`}
                                                name={`bottom_hole[${index}].size`}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                                value={(action === 'edit') ? item.size : ''}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="total_length hours-column-width"
                                                id={`bottom_hole[${index}].length`}
                                                name={`bottom_hole[${index}].length`}
                                                onKeyUp={(e) => calculateTotalLengthInRow(
                                                    e,
                                                    `bottom_hole[${index}].length`
                                                )}
                                                value={(action === 'edit') ? item.length : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`bottom_hole[${index}].fish_neck_size`}
                                                name={`bottom_hole[${index}].fish_neck_size`}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                value={(action === 'edit') ? item.fish_neck_size : ''}
                                                register={register({required: false})}
                                                errors={errors}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`bottom_hole[${index}].hole_name`}
                                                    name={`bottom_hole[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : '' }
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`bottom_hole[${index}].hole_name`}
                                                    name={`bottom_hole[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={
                                                        selectedBottomHoleHole
                                                            ?
                                                                selectedBottomHoleHole
                                                            :
                                                                (collectedHoles.length === 1)
                                                                    ?
                                                                        collectedHoles[0].value
                                                                    :
                                                                        item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedBottomHoleHole)
                                                    }
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => removeBottomHoleRow(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default React.memo(DailyBottomHole);
