import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import EmailList from "../../common/data-tables/email-list/EmailList";
import LoadingIndicator from "../../common/loadingIndicator";
import EmailRigListFilters from "./EmailRigListFilters";

/**
 * Email rig list component
 * @returns {JSX.Element}
 * @constructor
 */

const EmailRigList = () => {
    const [tableData, setTableData] = useState([]);

    const [reportFilters, setReportFilters] = useState({
        discipline_id: '',
        rig_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Rig Emails" parent="Admin" />
            <EmailRigListFilters {...{
                reportFilters,
                setReportFilters,
                setTableData,
            }} />
            <EmailList
                type='mobile'
                title='Distribution List'
                reportFilters={reportFilters}
                tableData={tableData}
                setTableData={setTableData}
            />
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default EmailRigList;
