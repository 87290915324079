export const tableColumns = [
    {
        Header: 'Discipline',
        accessor: 'discipline',
        width: 130,
    },
    {
        Header: 'Date',
        accessor: 'daily_date',
        width: 70,
    },
    {
        Header: 'Shift',
        accessor: 'daily_shift',
        width: 60,
    },
    {
        Header: 'Rig',
        accessor: 'rig_name',
        width: 60,
    },
    {
        Header: 'Client',
        accessor: 'client_name',
        width: 200,
    },
    {
        Header: 'Job',
        accessor: 'job_number',
        width: 90,
    },
    {
        Header: 'Footage',
        accessor: 'depth_total',
        width: 60,
    },
    {
        Header: 'Driller Notes',
        accessor: 'notes',
    },
];
