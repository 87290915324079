import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormInputText from "../../../forms/form-control/FormInputText";
import {useForm} from "react-hook-form";
import FormInputNumber from "../../../forms/form-control/FormInputNumber";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import * as ArrayHelpers from "../../../../utils/arrayHelpers";

/**
 * EditParentModal Modal
 * @returns {JSX.Element}
 * @constructor
 */
const EditParentModal = (props) => {
    const {
        modal, setModal, editParentData, parentId, tableData, setTableData,
        setEditParentModal, editParentModal,
    } = props;
    const {register, handleSubmit, errors} = useForm();

    const onSave = (data) => {
        if (data !== '') {
            data['id'] = parentId;

            apiClient
                .post('editParent', JSON.stringify(data))
                .then(response => {
                    if (response.status === 200) {
                        if (typeof response.data.error !== 'undefined') {
                            if (typeof response.data.error.parent_name !== 'undefined') {
                                toast.error("This Parent Name is already taken.");
                            }
                        } else {
                            const newTableData = ArrayHelpers.replaceObjFromArray(
                                tableData,
                                "id",
                                response.data.id,
                                response.data.row
                            );
                            setTableData(newTableData);
                            toast.success(response.data.message);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(function () {
                    //close deactivateModal
                    setEditParentModal({...editParentModal, isOpen: false})
                });
        } else {
            errors.showMessages();
        }
    }

    // toggle for open/close the modal form
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-lg modal-body" centered={true} >
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSave)}
            >
                <ModalHeader toggle={toggle}>Edit Parent</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputText
                            id="parent_name"
                            label="Parent Name"
                            className="col-md-8 mb-3"
                            value={editParentData ? editParentData.parent_name : ''}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputNumber
                            id="erp_id"
                            label="VP ID"
                            disabled={'true'}
                            value={editParentData ? editParentData.erp_id : ''}
                            className="col-md-3 mb-3"
                            register={register({required: false})}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default EditParentModal;
