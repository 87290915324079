import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import ReportFilters from "./reportFilters";
import ReportBody from "./reportBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Daily Discipline Revenue Report
 * @returns {JSX.Element}
 * @constructor
 */
const DailyDisciplineRevenue = () => {
    const [tableData, setTableData] = useState([]);
    const [barChartLabels, setBarChartLabels] = useState([]);
    const [barChartDataDiscipline1, setBarChartDataDiscipline1] = useState([]);
    const [barChartDataDiscipline2, setBarChartDataDiscipline2] = useState([]);
    const [barChartDataDiscipline3, setBarChartDataDiscipline3] = useState([]);
    const [barChartDataDiscipline4, setBarChartDataDiscipline4] = useState([]);
    const [PercentageChartDataDiscipline1, setPercentageChartDataDiscipline1] = useState(0);
    const [PercentageChartDataDiscipline2, setPercentageChartDataDiscipline2] = useState(0);
    const [PercentageChartDataDiscipline3, setPercentageChartDataDiscipline3] = useState(0);
    const [PercentageChartDataDiscipline4, setPercentageChartDataDiscipline4] = useState(0);
    const [PercentageChartDataTotal, setPercentageChartDataTotal] = useState(0);
    const [reportFilters, setReportFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    const reportFilterProps = {
        setReportFilters,
        setTableData,
        setBarChartLabels,
        setBarChartDataDiscipline1,
        setBarChartDataDiscipline2,
        setBarChartDataDiscipline3,
        setBarChartDataDiscipline4,
        setPercentageChartDataDiscipline1,
        setPercentageChartDataDiscipline2,
        setPercentageChartDataDiscipline3,
        setPercentageChartDataDiscipline4,
        setPercentageChartDataTotal
    };

    const reportBodyProps = {
        tableData,
        barChartLabels,
        barChartDataDiscipline1,
        barChartDataDiscipline2,
        barChartDataDiscipline3,
        barChartDataDiscipline4,
        PercentageChartDataDiscipline1,
        PercentageChartDataDiscipline2,
        PercentageChartDataDiscipline3,
        PercentageChartDataDiscipline4,
        PercentageChartDataTotal,
        reportFilters
    };

    return (
        <>
            <Breadcrumb title="Daily Discipline Revenue" parent="Reports"/>
            <ReportFilters {...reportFilterProps}/>
            <ReportBody {...reportBodyProps}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
};

export default DailyDisciplineRevenue;
