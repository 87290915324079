import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import moment from "moment";
import ConsumableRegisterFilters from "./consumableRegisterFilters";
import ConsumableRegisterBody from "./consumableRegisterBody";
import FormModal from "./consumableRegisterFormModal";
import LoadingIndicator from "../../common/loadingIndicator";
import FileManager from "../../common/file_manager/fileManager";

/**
 * Consumable Register Component
 * @returns {JSX.Element}
 * @constructor
 */
const ConsumableRegister = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedFilesRow, setSelectedFilesRow] = useState({});
    const [formModal, setFormModal] = useState({isOpen: false, action: 'create'});
    const [formModalFiles, setFormModalFiles] = useState({isOpen: false});
    const [rigs, setRigs] = useState([]);
    const [filters, setFilters] = useState({
        discipline_id: '',
        consumable_type: 'unallocated',
        rig_id: '',
        date_from: moment().startOf('month').format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Consumable Register" parent="Invoicing"/>
            <ConsumableRegisterFilters {...{filters, setTableData, setFilters, rigs, setRigs}} />
            <ConsumableRegisterBody {...{filters, tableData, setSelectedRow, setSelectedFilesRow, setFormModal, setFormModalFiles}} />
            <FormModal
                title="Edit Consumable Register"
                modal={formModal}
                setModal={setFormModal}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                filters={filters}
                setFilters={setFilters}
                rigs={rigs}
                setRigs={setRigs}
            />
            <FileManager
                fileableId={selectedFilesRow.id}
                fileableType ="App\Models\DailyConsumables"
                setFileableId={setSelectedFilesRow}
                modalTitle="Consumable Files"
                modal={formModalFiles}
                setModal={setFormModalFiles}
                allowUpload={false}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default ConsumableRegister;
