import styled from "styled-components";

export const ShopTimeEntryReportHoursStyle = styled.div`
  overflow-x: auto;

  table {
    tbody {
      tr {
        :nth-last-child(3) {
          border-top: 5px double black;
          font-weight: bold;
        }
        :nth-last-child(2) {
          border-top: 5px double black;
          font-weight: bold;
        }
        :last-child {
          td {
            border-top: 5px double black;
            font-weight: bold;
          }
        }
      }
    }
  }
`;
