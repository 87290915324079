import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import DisciplineTargetsFilters from "./disciplineTargetFilters";
import DisciplineTargetsBody from "./dicsiplineTargetsBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * DisciplineTargets Component
 * @returns {JSX.Element}
 * @constructor
 */
const DisciplineTargets = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Discipline Targets" parent="Admin"/>
            <DisciplineTargetsFilters
                filters={filters}
                setTableData={setTableData}
                setFilters={setFilters}
            />
            <DisciplineTargetsBody
                filters={filters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default DisciplineTargets;
