import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";

/**
 * Filter component for DirectEntry Report
 * @returns {JSX.Element}
 * @constructor
 */
const DailyHolesFilters = (prop) => {
    const {register, handleSubmit, errors} = useForm();
    const {filters, setFilters, setTableData} = prop;
    const [disciplines, setDisciplines] = useState([]);
    const [jobs, setJobs] = useState([]);

    const updateReportFilters = (id, value) => {
        setFilters(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    // filter selection handler
    const filterSelectionHandler = e => {
        const {id, value} = e.target;
        updateReportFilters(id, value);
    }

    // discipline selection handler
    const disciplineSelectionHandler = e => {
        const {id, value} = e.target;
        updateReportFilters(id, value);

        // get jobs by Discipline Id
        apiClient
            .post('getAllActiveJobsByDisciplineIdList', {'discipline_id' : value})
            .then(response => {
                const jobItems = response.data.jobs.map(
                    ({id, job_number}) => ({id: id, name: job_number})
                );
                setJobs(jobItems);
        });
    }

    const onSubmit = () => {
        updateReportFilters('isLoading', true);
        updateReportFilters('isSubmitted', true);

        apiClient
            .post('getDailyHolesByJobId', {'job_id': filters.job_id})
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                    return;
                }
                console.log(response.data);
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
            updateReportFilters('isLoading', false);
        });
    }

    // get filters data
    useEffect(() => {
        //get Disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                const disciplineItems = response.data.disciplines.map(
                    ({id, name}) => ({id: id, name: name})
                );
                setDisciplines(disciplineItems);
        });
    }, []);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.discipline_id}
                    onChange={disciplineSelectionHandler}
                    options={disciplines}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSelect
                    id="job_id"
                    label="Job"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.job_id}
                    onChange={filterSelectionHandler}
                    options={jobs}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default DailyHolesFilters;
