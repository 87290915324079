import { FaPlus, FaTrash } from "react-icons/fa";
import FormInputDate from "../../forms/form-control/FormInputDate";
import FormInputNumber from "../../forms/form-control/FormInputNumber";
import FormInputText from "../../forms/form-control/FormInputText";
import FormSelect from "../../forms/form-control/FormSelect";
import React from "react";

const MobileEntryItem = ({
    register,
    errors,
    append,
    remove,
    ecOptions,
    jobs,
    states,
    i,
    payrollDates,
}) => {
    return (
        <div className="form-row s-gy">
            <FormSelect
                id={`direct_entries[${i}].pay_sequence`}
                label="Pay Sequence"
                options={[{id: 1, name: 'Current'}, {id: 2, name: 'Previous'}]}
                register={register({required: "required"})}
                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                errors={errors}
            />
            <FormSelect
                id={`direct_entries[${i}].ec_id`}
                label="EC"
                options={ecOptions}
                register={register({required: "required"})}
                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                errors={errors}
            />
            <FormInputDate
                id={`direct_entries[${i}].work_date`}
                label="Work Date"
                register={register({required: "required"})}
                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                errors={errors}
            />
            <FormSelect
                label="Payroll Date"
                id={`direct_entries[${i}].payroll_date`}
                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                options={payrollDates}
                register={register({required: "required"})}
                errors={errors}
            />
            <FormSelect
                id={`direct_entries[${i}].job_id`}
                label="Job"
                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                options={jobs}
                register={register}
                errors={errors}
            />
            <FormSelect
                id={`direct_entries[${i}].state_id`}
                label="State"
                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                options={states}
                register={register({required: "required"})}
                errors={errors}
            />
            <FormInputNumber
                id={`direct_entries[${i}].hours`}
                label="Hours"
                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                register={register({
                    min: {value: 0, message: "min value is 0"},
                    max: {value: 24.01, message: "max value is 24.00"}
                })}
                errors={errors}
            />
            <FormInputNumber
                id={`direct_entries[${i}].per_diem`}
                label="Per Diem"
                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                register={register({
                    min: {value: 0, message: "Per Diem min value is 0"},
                    max: {value: 99999999.99, message: "Per Diem max value is 99999999.99"}
                })}
                errors={errors}
            />
            <FormInputText
                id={`direct_entries[${i}].equipment_number`}
                label="Equipment #"
                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                register={register}
                errors={errors}
            />
            <FormInputText
                id={`direct_entries[${i}].equipment_code`}
                label="Equipment Code"
                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                register={register}
                errors={errors}
            />
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2 s-flex s-justify-between">
                <FaPlus
                    color='#22af47'
                    size={20}
                    title="Add Time"
                    onClick={() => append({})}
                />
                <FaTrash
                    color='#A51E37'
                    title="Remove"
                    onClick={() => remove(i)}
                />
            </div>
        </div>
    )
}

export default MobileEntryItem;