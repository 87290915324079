import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import ReportFilters from "./reportFilters";
import ReportBody from "./reportBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * DailyEmployee payable summary hours component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SummaryHours = () => {
    const [tableSummaryData, setTableSummaryData] = useState([]);
    const [tableRigSummaryData, setTableRigSummaryData] = useState([]);
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Employee Payable Hours - Summary Hours" parent="Payroll"/>
            <ReportFilters {...{setTableSummaryData, setTableRigSummaryData, setFilters}} />
            <ReportBody {...{filters, tableSummaryData, tableRigSummaryData}} />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
};

export default SummaryHours;