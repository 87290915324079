import React, {useState, useRef} from 'react';
import {useForm} from "react-hook-form";
import Breadcrumb from '../../common/breadcrumb';
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import apiClient from "../../../utils/apiClient";
import {toast} from "react-toastify";
import LoadingIndicator from "../../common/loadingIndicator";
import FormInputText from "../../forms/form-control/FormInputText";
import FormInputPassword from "../../forms/form-control/FormInputPassword";

/**
 * UserChangePassword Component
 * @returns {JSX.Element}
 * @constructor
 */
const UserChangePassword = () => {
    const {register, handleSubmit, errors, reset, watch} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        shouldFocusError: true,
    });
    const [isLoading, setIsLoading] = useState(false);
    const password = useRef({});
    password.current = watch("new_password", "");
    // save form handler
    const onSubmit = (data) => {
        setIsLoading(true);
        apiClient
            .post("changePasswordUser", data)
            .then(response => {
                toast.success(response.data.message);
                reset();
            })
            .catch(function () {
            })
            .then(function () {
                setIsLoading(false);
            });
    }

    return (
        <>
            <Breadcrumb title="Change Password" parent="User"/>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-row">
                    <FormInputText
                        id="current_password"
                        label="Current Password"
                        type="password"
                        className="col-md-4"
                        register={register({required: "required"})}
                        errors={errors}
                    />
                </div>
                <div className="form-row form-row m-t-10">
                    <FormInputPassword
                        id="new_password"
                        label="New Password"
                        type="password"
                        className="col-md-4"
                        register={register({
                            required: {value: true, message: 'required'},
                            pattern: {value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, message: 'Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.'}
                        })}
                        errors={errors}
                    />
                </div>
                <div className="form-row m-t-10">
                    <FormInputPassword
                        id="confirmation_password"
                        label="Confirm New Password"
                        type="password"
                        className="col-md-4"
                        register={register({
                            validate: value => value === password.current || 'Please confirm the password'
                        })}
                        errors={errors}
                    />
                </div>
                <div className="form-row m-t-15">
                    <FormSubmitButton
                        className="col-md-1"
                        btnClassName="btn-primary"
                        label="Change"
                    />
                </div>
            </form>
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default React.memo(UserChangePassword);