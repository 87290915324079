import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import {useFieldArray} from "react-hook-form";

const WaterHaul = (props) => {
    const { errors, register, control, collectedHoles, fieldDaily, action } = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "water_haul"
    });
    const [selectedWaterHaulHole, setSelectedWaterHaulHole] = useState('');

    useEffect(() => {
        if (action === 'edit') {
            append(fieldDaily.field_water_hauls);
        }
    }, [action, append, fieldDaily]);

    return (
        <AccordionItem className="card-header bg-primary" title="Water Haul">
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Miles</th>
                                        <th>Gallons</th>
                                        <th>Loads</th>
                                        <th>Hole</th>
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`water_haul[${index}].miles`}
                                                name={`water_haul[${index}].miles`}
                                                type="number"
                                                value={(action === 'edit') ? item.miles : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`water_haul[${index}].gallons`}
                                                name={`water_haul[${index}].gallons`}
                                                type="number"
                                                value={(action === 'edit') ? item.gallons : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`water_haul[${index}].loads`}
                                                name={`water_haul[${index}].loads`}
                                                type="number"
                                                value={(action === 'edit') ? item.loads : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: false})}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`water_haul[${index}].hole_name`}
                                                    name={`water_haul[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`water_haul[${index}].hole_name`}
                                                    name={`water_haul[${index}].hole_name`}
                                                    value={
                                                        selectedWaterHaulHole
                                                            ?
                                                            selectedWaterHaulHole
                                                            :
                                                            (collectedHoles.length === 1)
                                                                ?
                                                                collectedHoles[0].value
                                                                :
                                                                item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedWaterHaulHole)
                                                    }
                                                    options={collectedHoles}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => remove(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default WaterHaul;
