import React, {useEffect, useState} from 'react';
import {min, max} from "lodash";

import moment from 'moment';

import {FaPencilAlt, FaTrash} from "react-icons/fa";
import FormInputText from "../../forms/form-control/FormInputText";
import {useFieldArray} from "react-hook-form";
import { EditInvoiceDailiesModal } from './editInvoiceDailiesModal';

/**
 * Display invoice dailies form table component
 * @returns {JSX.Element}
 * @constructor
 */
const EditInvoiceTableDailies = (props) => {
    const {
        invoiceId, register, control, jobId, setTotalDailies, totalDailies, totalInvoiceCharges, dailies,
    } = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "dailies"
    });

    const [dailiesModal, setDailiesModal] = useState({isOpen: false, invoiceCharges: []});

    const openDailiesModal = () => {
        setDailiesModal(modal => ({...modal, isOpen: true}))
    }

    const RemoveDailyRow = (index) => {
        remove(index);
    }

    useEffect(() => {
        remove();
        append(dailies);
    }, [append, dailies, remove]);

    useEffect(() => {
        const totalDailies = fields.reduce(
            (a, v) => a + Number(v.revenue), 0
        );

        setTotalDailies(totalDailies);
    }, [fields, setTotalDailies])

    const onDailiesEdit = (invoiceCharges) => {
        remove();
        append(invoiceCharges);
    }

    return (
        <table className="table table-hover table-striped col-md-11">
            <thead>
                <tr>
                    <th>Daily</th>
                    <th>Shift</th>
                    <th>Rig</th>
                    <th>Revenue</th>
                    <th>Hole</th>
                    <th>Modified By</th>
                    <th>Modified Date</th>
                    <th>
                        <FaPencilAlt
                            color='#A51E37'
                            size={20}
                            title="Add New or Edit"
                            onClick={() => openDailiesModal({})}
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
            {fields.map((item, index) => (
                <tr key={index} style={{ backgroundColor: item.is_zero_consumable_price ? '#A51E3799' : '' }}>
                    <td>{item.date}</td>
                    <td>{item.shift}</td>
                    <td>{item.rig_name}</td>
                    <td>{item.revenue}</td>
                    <td>{item.hole}</td>
                    <td>{item.modified_by}</td>
                    <td>{item.modified_at}</td>
                    <td>
                        <FormInputText
                            id={`dailies[${index}].id`}
                            type="hidden"
                            value={item.id}
                            register={register({required: "required"})}
                        />
                        <FormInputText
                            id={`revenue[${index}].revenue`}
                            type="hidden"
                            value={totalDailies + totalInvoiceCharges}
                            register={register({required: "required"})}
                        />
                        <FaTrash
                            color='#A51E37'
                            title="Remove"
                            onClick={() => RemoveDailyRow(index)}
                        />
                    </td>
                </tr>
            ))}
            {fields.length > 0 &&
                <>
                    <tr>
                        <td colSpan={8}>
                            <span className="font-weight-bold">Total Dailies : </span>
                            <b className="red">{
                                Number(
                                    fields.reduce(
                                        (a, v) => a + Number(v.revenue), 0
                                    )).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
                            }</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <span className="font-weight-bold"> Work Period : </span>
                            {moment(min(fields.map(a => new Date(a.date)))).format('MM/DD/YYYY')}
                            <span> - </span>
                            {moment(max(fields.map(a => new Date(a.date)))).format('MM/DD/YYYY')}
                        </td>
                    </tr>
                </>
            }
            </tbody>
            <EditInvoiceDailiesModal
                modal={dailiesModal}
                setModal={setDailiesModal}
                jobId={jobId}
                invoiceId={invoiceId}
                initialDailies={fields}
                onSave={onDailiesEdit}
            />
        </table>
    );
}

export default EditInvoiceTableDailies;
