import React from 'react';
import TableReport from "../../../common/table/table";
import {transactionRegisterPlusTable} from "./TransactionRegisterPlusTable.js";
import {TableStyle} from "./tableStyle.js";
import MessageEmptyData from "../../../common/messageEmptyData";

/**
 * Body report component for Daily Discipline Revenue Detail
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const TransactionRegisterPlusBody = (prop) => {
    const {reportFilters, tableData} = prop;
    const tableExportOptions = {pdf: false, xlsx: true, csv: true};

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableStyle>
                <TableReport
                    columns={transactionRegisterPlusTable}
                    data={tableData}
                    exportOptions={tableExportOptions}
                    getExportFileName={() => 'transaction_register_detail'}
                    withGlobalSearch={true}
                    scrollable={true}
                />
            </TableStyle>
        </div>
    );
}

export default TransactionRegisterPlusBody;
