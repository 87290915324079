import React from 'react';
import TableReport from "../../../common/table/table";
import {tableSummaryColumns, tableRigSummaryColumns} from "./tableColumns.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import {TableSummaryStyle, TableRigSummaryStyle} from "./tableStyle";
import {isEmpty} from 'lodash';

/**
 * Body report component for Employee Payable Summary Hours
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ReportBody = (prop) => {
    const {filters, tableSummaryData, tableRigSummaryData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableSummaryData)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <h5>Summary Totals</h5>
                </div>
            </div>
            <TableSummaryStyle>
                <TableReport
                    columns={tableSummaryColumns}
                    data={tableSummaryData}
                    exportOptions={{pdf: false, xlsx: true, csv: false}}
                    getExportFileName={() => 'employee_payable_hours_summary_totals'}
                    withGlobalSearch={false}
                />
            </TableSummaryStyle>
            <div className="row">
                <div className="col">
                    <h5>Summary By Rig</h5>
                </div>
            </div>
            <TableRigSummaryStyle>
                <TableReport
                    columns={tableRigSummaryColumns}
                    data={tableRigSummaryData}
                    exportOptions={{pdf: false, xlsx: true, csv: false}}
                    getExportFileName={() => 'employee_payable_hours_summary_by_rig'}
                    withGlobalSearch={false}
                />
            </TableRigSummaryStyle>
        </div>
    );
};

export default ReportBody;
