import React, {useEffect, useState} from 'react';
import TableReport from "../../../common/table/table";
import {POItemsTable} from "./poItemsTable";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from "lodash";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";

/**
 * Body component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const POItemsBody = (props) => {
    const {poItemsData, setPOItemsData,} = props;
    const [selectedRow, setSelectedRow] = useState({});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    // get po Items
    useEffect(() => {
        apiClient
            .get('POItems')
            .then(response => {
                setPOItemsData(response.data);
            });
    }, [setPOItemsData]);

    if (isEmpty(poItemsData)) {
        return <MessageEmptyData/>;
    }

    const DeleteHandler = () => {
        apiClient
            .delete('POItems/' + selectedRow.id)
            .then(response => {
                const newData = removeObjFromArray(poItemsData, "id", response.data.id)
                setPOItemsData(newData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close Modal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={POItemsTable(setDeleteModal, setSelectedRow)}
                data={poItemsData}
                scrollable={true}
                withGlobalSearch={true}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'po_items'}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Delete PO Item"
                onConfirm={DeleteHandler}
            />
        </div>
    );
}

export default POItemsBody;
