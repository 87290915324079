import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import {useFieldArray} from "react-hook-form";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import apiClient from "../../../../../utils/apiClient";
import {isEmpty} from "lodash";

const FootageDrilled = (props) => {
    const {
        errors, register, control, setValue, getValues, collectedHoles, drilledDescriptions, fieldDaily, action,
        selectedJobId
    } = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "drilling"
    });
    const [totalDrilled, setTotalDrilled] = useState(0);
    const [selectedDrillingHole, setSelectedDrillingHole] = useState('');

    useEffect(() => {
        if (!fields.length) {
            setTotalDrilled(0);
        }
    }, [fields.length])

    useEffect(() => {
        if (action === 'edit') {
            append(fieldDaily.field_drilling);

            setTimeout(() => {
                fieldDaily.field_drilling.forEach((item, index) => {
                    // populate name select
                    setValue(
                        `drilling[${index}].cost_code`,
                        {value: item.cost_code, label: item.description}
                    );
                });
            }, 500);

            setTimeout(() => {
                if (fieldDaily.field_drilling.length > 0) {
                    Shared.calculateTotals('total_drilled', setTotalDrilled);
                }
            }, 200);
        }
    }, [append, action, fieldDaily, setTotalDrilled, setValue]);

    const selectCode = (index, value) => {
        let jobId = (action === 'edit') ? fieldDaily.job_id : selectedJobId;
        if (value && jobId) {
            apiClient
                .post('getJobChargesPriceByCodeAndJobId', {'job_id': jobId, 'code': value})
                .then(response => {
                    const realPrice = parseFloat(response.data[0]).toFixed(2);
                    setValue(`drilling[${index}].price`, realPrice);
                });
        }
    }

    const calculateTotalDrilledInRow = (e, fromId, toId, totalId, billableId) => {
        e.preventDefault();
        let fromFootage = (document.getElementById(fromId).value) ? document.getElementById(fromId).value : 0;
        let toFootage = (document.getElementById(toId).value) ? document.getElementById(toId).value : 0;
        let totalRaw = Number(toFootage) - Number(fromFootage);
        let total = Number(totalRaw).toFixed(2);

        if (toFootage > 0) {
            setValue(totalId, total);
            // register(billableId);
            setValue(billableId, total);
            Shared.calculateTotals('total_drilled', setTotalDrilled);
        }
    }

    const removeFootageDrilledRow = (index) => {
        remove(index);
        // recalculate total length when removing one Bottom Hole row.
        setTimeout(() => {
            Shared.calculateTotals('total_drilled', setTotalDrilled);
        }, 500);
    }

    return (
        <AccordionItem className="card-header bg-primary" title="Footage Drilled">
            <div className="card">
                { totalDrilled > 0 && fields.length > 0 &&
                    <div className="form-row" id="drilled_totals">
                        <div className="col-md-3 mb-3 total_block">
                            <p className="form-control background_total_block_color">
                                Total Drilled : {totalDrilled}
                            </p>
                        </div>
                    </div>
                }
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Total</th>
                                        <th>Billable</th>
                                        <th>Hole</th>
                                        <th/>
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {fields.map((item, index) => {
                                    const lastDrillingToValue = (index === 0) ? null : fields[index-1].drilling_to;
                                    return (
                                        <tr key={item.id}>
                                            <SearchableSelectInTable
                                                id={`drilling[${index}].cost_code`}
                                                options={drilledDescriptions}
                                                rules={{ required: "required" }}
                                                control={control}
                                                value={(action === 'edit') ? item.cost_code?.value : ''}
                                                onChange={(value) => selectCode(index, value)}
                                                errors={errors}
                                                customStyles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: 500,
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 500,
                                                        position: 'sticky',
                                                    })
                                                }}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`drilling[${index}].drilling_from`}
                                                name={`drilling[${index}].drilling_from`}
                                                onKeyUp={(e) => calculateTotalDrilledInRow(
                                                    e,
                                                    `drilling[${index}].drilling_from`,
                                                    `drilling[${index}].drilling_to`,
                                                    `drilling[${index}].drilling_total`,
                                                    `drilling[${index}].billable`
                                                )}
                                                value={(!isEmpty(item.drilling_from)) ? item.drilling_from : lastDrillingToValue}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 8)}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`drilling[${index}].drilling_to`}
                                                name={`drilling[${index}].drilling_to`}
                                                value={(action === 'edit') ? item.drilling_to : ''}
                                                onKeyUp={(e) => calculateTotalDrilledInRow(
                                                    e,
                                                    `drilling[${index}].drilling_from`,
                                                    `drilling[${index}].drilling_to`,
                                                    `drilling[${index}].drilling_total`,
                                                    `drilling[${index}].billable`
                                                )}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 8)}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="total_drilled hours-column-width"
                                                id={`drilling[${index}].drilling_total`}
                                                name={`drilling[${index}].drilling_total`}
                                                value={(action === 'edit') ? item.drilling_total : ''}
                                                disabled={'true'}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="total_billable short-select-width"
                                                id={`drilling[${index}].billable`}
                                                name={`drilling[${index}].billable`}
                                                value={(action === 'edit') ? item.billable : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 9)}
                                                register={
                                                    register({
                                                        required: true,
                                                        validate: value => {
                                                            const totalValue = getValues(`drilling[${index}].drilling_total`);
                                                            return Number(value) <= Number(totalValue) || 'Billable must be less than or equal to total'
                                                    },})
                                                }
                                                errors={errors}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`drilling[${index}].hole_name`}
                                                    name={`drilling[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`drilling[${index}].hole_name`}
                                                    name={`drilling[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={
                                                        selectedDrillingHole
                                                            ?
                                                            selectedDrillingHole
                                                            :
                                                            (collectedHoles.length === 1)
                                                                ?
                                                                collectedHoles[0].value
                                                                :
                                                                item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedDrillingHole)
                                                    }
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {/*Intentionally hidden */}
                                            <InputInTable
                                                id={`drilling[${index}].price`}
                                                register={register({required: false})}
                                                errors={errors}
                                                type="hidden"
                                            />
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => removeFootageDrilledRow(index)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default FootageDrilled;