import React, { useState } from 'react';
import TableReport from "../../../common/table/table";
import {getTransactionRegisterColumns} from "./transactionRegisterColumns.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import {replaceObjFromArray} from "../../../../utils/arrayHelpers";

/**
 * Body report component for Daily Discipline Revenue Detail
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const TransactionRegisterBody = (prop) => {
    const {reportFilters, tableData, setTableData} = prop;

    const [selectedRow, setSelectedRow] = useState(null);
    const [approveModal, setApproveModal] = useState({isOpen: false, message: <p></p>});
    const [disapproveModal, setDisapproveModal] = useState({isOpen: false, message: <p></p>});

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    const ApproveHandler = () => {
        apiClient
            .post('approveTransactionRegister/' + selectedRow.invoice_id)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "invoice_id", response.data.id, response.data.row
                    )
                );
                toast.success(response.data.message);
            })
            .then(function () {
                //close deactivateModal
                setApproveModal({...approveModal, isOpen: false})
            });
    }

    const DisapproveHandler = () => {
        apiClient
            .post('disapproveTransactionRegister/' + selectedRow.invoice_id)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "invoice_id", response.data.id, response.data.row
                    )
                );
                toast.success(response.data.message);
            })
            .then(function () {
                //close deactivateModal
                setDisapproveModal({...disapproveModal, isOpen: false})
            });
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={getTransactionRegisterColumns(setSelectedRow, setApproveModal, setDisapproveModal)}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'transaction_register'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={approveModal}
                setModal={setApproveModal}
                title="Approve Invoice"
                onConfirm={ApproveHandler}
            />
            <ModalConfirmation
                modal={disapproveModal}
                setModal={setDisapproveModal}
                title="Disapprove Invoice"
                onConfirm={DisapproveHandler}
            />
        </div>
    );
}

export default TransactionRegisterBody;
