import React from 'react';
import Table from '../../../common/table/table';
import MessageEmptyData from '../../../common/messageEmptyData';
import {RevenueWeeklyTrackingStyle} from './RevenueWeeklyTrackingStyle';
import revenueWeeklyTrackingColumns from './RevenueWeeklyTrackingColumns';

/**
 * Body component for RevenueByRigWeekly Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const RevenueWeeklyTrackingBody = (prop) => {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData?.weeks?.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className='container-fluid'>
            {tableData?.weeks?.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className='row'>
                            <h4>Week {index + 1}</h4>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <RevenueWeeklyTrackingStyle>
                                    <Table
                                        columns={revenueWeeklyTrackingColumns(item)}
                                        data={item.tableRows}
                                        exportOptions={{
                                            pdf: false,
                                            xlsx: true,
                                            csv: false,
                                        }}
                                        getExportFileName={() => 'shift_entry_time'}
                                    />
                                </RevenueWeeklyTrackingStyle>
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
}

export default RevenueWeeklyTrackingBody;
