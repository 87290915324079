import React from "react";
import {Link} from "react-router-dom";
import {FaPencilAlt, FaEyeSlash, FaEye, FaTrash} from "react-icons/fa";

const RigsTable = (
    setFilters, setSelectedRow, setDeactivateModal, setReactivateModal,
    setEditRigModal, setDeleteModal
) => {

    const onClickName = (e, row) => {
        e.preventDefault();
        setSelectedRow(row);
        setEditRigModal({
            isOpen: true
        });
    }

    return [
        {
            Header: 'Name',
            accessor: 'name',
            width: 110,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <Link
                        className={'font-weight-bold'}
                        onClick={(e) => onClickName(e, row)}
                    >
                        {cell.value}
                    </Link>
                );
            }
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Make',
            accessor: 'make',
        },
        {
            Header: 'Model',
            accessor: 'model',
        },
        {
            Header: 'Year',
            accessor: 'year',
            width: 60,
        },
        {
            Header: 'Type',
            accessor: 'type.name',
            width: 110,
        },
        {
            Header: 'Discipline',
            accessor: 'discipline_id',
            width: 110,
            Cell: (cell) => {
                const row = cell.row.original;

                if (typeof row.discipline_id !== 'undefined') {
                    switch (Number(row.discipline_id)) {
                        case 1:
                            return 'Surface Core';
                        case 2:
                            return 'Underground Core';
                        case 3:
                            return 'RCX';
                        case 4:
                            return 'Rotary';
                        default:
                            return null;
                    }
                } else {
                    return null;
                }
            }
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                let active = (
                    <>
                        <FaPencilAlt
                            className="actions"
                            title="Edit Rig"
                            onClick={() => {
                                setSelectedRow(row);
                                setEditRigModal({
                                    isOpen: true
                                });
                            }}
                        />
                        {' '}
                        <FaEyeSlash
                            className="actions"
                            title="Deactivate Rig"
                            onClick={() => {
                                const msg = 'Are you sure that you want to deactivate rig: ' + row.name;
                                setSelectedRow(row);
                                setDeactivateModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                    </>
                );

                let inactive = (
                    <>
                        <FaEye
                            className="actions"
                            title="Reactivate Rig"
                            onClick={() => {
                                const msg = 'Are you sure that you want to reactivate rig: ' + row.name;
                                setSelectedRow(row);
                                setReactivateModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                        {' '}
                        <FaTrash
                            className="actions"
                            title="Delete Rig"
                            onClick={() => {
                                const msg = 'Are you sure that you want to delete rig: ' + row.name;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                );

                return (row.status === 1) ? active : inactive;
            }
        }
    ];
}

export default RigsTable;
