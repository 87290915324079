import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import apiClient from "../../../utils/apiClient";
import {useForm} from "react-hook-form";
import {addObjToArray, replaceObjFromArray} from "../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormInputText from "../../forms/form-control/FormInputText";
import {upperFirst} from "lodash";
import FormInputDate from "../../forms/form-control/FormInputDate";
import FormInputNumber from "../../forms/form-control/FormInputNumber";
import FormSelect from "../../forms/form-control/FormSelect";
import moment from "moment";

/**
 * Modal form to create and edit a record
 * @returns {JSX.Element}
 * @constructor
 */
const FinancialPeriodsFormModal = (props) => {
    const {modal, setModal, title, selectedRow, tableData, setTableData, filters} = props;
    const {register, handleSubmit, errors} = useForm();
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    const onSubmit = (data) => {
        if (modal.action === 'create') {
            onCreate(data);
        }

        if (modal.action === 'edit') {
            onEdit(data);
        }
    }

    // create
    const onCreate = (data) => {
        apiClient
            .post('financialPeriods', data)
            .then(response => {
                if (isStartDateOnFilterYear(response.data.record)) {
                    setTableData(
                        addObjToArray(
                            tableData, response.data.record
                        )
                    );
                }
                toast.success(response.data.message);
                toggle();
            });
    }

    const isStartDateOnFilterYear = (record) => {
        return moment(record.start_date, "MM/DD/YYYY").isBetween(
            moment(filters.year, "YYYY").startOf('year'),
            moment(filters.year, "YYYY").endOf('year'),
        )
    }

    // edit
    const onEdit = (data) => {
        apiClient
            .put('financialPeriods/' + selectedRow.id, data)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} size={'md'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{`${upperFirst(modal.action)} ${title}`}</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputText
                            id="name"
                            label="Name"
                            className="col-md-12"
                            value={selectedRow.name}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputDate
                            id="start_date"
                            label="Start Date"
                            className="col-md-12 m-t-10"
                            value={(selectedRow.start_date) &&
                                moment(selectedRow.start_date, "MM/DD/YYYY").format("YYYY-MM-DD")
                            }
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputDate
                            id="end_date"
                            label="End Date"
                            className="col-md-12 m-t-10"
                            value={(selectedRow.end_date) &&
                                moment(selectedRow.end_date, "MM/DD/YYYY").format("YYYY-MM-DD")
                            }
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelect
                            id="number_of_weeks"
                            label="Number Of Weeks"
                            className="col-md-12 m-t-10"
                            value={selectedRow.number_of_weeks}
                            options={[{value: 4, label: "4"}, {value: 5, label: "5"}]}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputNumber
                            id="year"
                            label="Year"
                            className="col-md-12 m-t-10"
                            value={selectedRow.year}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default React.memo(FinancialPeriodsFormModal);
