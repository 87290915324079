import React, {useState} from 'react';
import Accordion from "react-light-accordion";
import DailyGeneralInformation from "./daily_accordion_cards/DailyGeneralInformation";
import DailyEmployee from "./daily_accordion_cards/DailyEmployee";
import DailyHole from "./daily_accordion_cards/DailyHole";
import DailyBit from "./daily_accordion_cards/DailyBit";
import DailyFootageDrilled from "./daily_accordion_cards/DailyFootageDrilled";
import DailyFootageReamed from "./daily_accordion_cards/DailyFootageReamed";
import DailyGeneralActivities from "./daily_accordion_cards/DailyGeneralActivities";
import DailyConsumables from "./daily_accordion_cards/DailyConsumables";
import DailyGeneralCharges from "./daily_accordion_cards/DailyGeneralCharges";
import DailyFuel from "./daily_accordion_cards/DailyFuel";
import DailyNotes from "./daily_accordion_cards/DailyNotes";
import DailyBottomHole from "./daily_accordion_cards/DailyBottomHole";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Daily Accordion Component
 * @returns {JSX.Element}
 * @constructor
 */
const DailyAccordion = (props) => {
    const {
        disciplineId, fieldEmployees, register, errors, rigs, control, setValue, getValues, setWarningModal,
        action, rigsAvailable, daily, fieldEmployeesAvailable, setActivityDescriptions, setDrilledDescriptions,
        setReamedDescriptions, setGeneralDescriptions, setConsumableDescriptions, activityDescriptions,
        drilledDescriptions, reamedDescriptions, generalDescriptions, consumableDescriptions, setTotalActivities,
        totalActivities, totalConsumables, setTotalConsumables, totalGeneralCharges, setTotalGeneralCharges,
        totalDrilled, setTotalDrilled, setTotalDrilledPrice, totalDrilledPrice, setTotalDrilledVariance,
        totalDrilledVariance, setTotalReamedPrice, totalReamedPrice, setTotalReamedVariance, totalReamedVariance,
        selectedJobNumber, setSelectedRigName, setSelectedJobNumber, setSelectedLocationName, jobState, setJobState
        // totalDrillingHours, setTotalDrillingHours
    } = props;
    const [collectedHoles, setCollectedHoles] = useState([]);
    const [holes, setHoles] = useState('');
    const [selectedJobId, setSelectedJobId] = useState('');

    // check of user is selected
    if (disciplineId === 0) {
        return null;
    }

    if (!activityDescriptions.length && action === 'edit') {
        return <LoadingIndicator isLoading={!activityDescriptions.length}/>;
    }
    /**
     *  this prevents submit form on click of Accordion
     *  react-light accordion has no property provided to control button type
    */
    const handleClick = (target) => {
        if (target.type === 'submit') {
            target.setAttribute("type", "button");
        }
    }

    // return accordion cards
    return (
        <>
            <Accordion atomic="false">
                <div onClick={(e) => handleClick(e.target)}>
                    <DailyGeneralInformation
                        rigs={rigs}
                        daily={daily}
                        rigsAvailable={rigsAvailable}
                        action={action}
                        errors={errors}
                        control={control}
                        disciplineId={disciplineId}
                        register={register}
                        setActivityDescriptions={setActivityDescriptions}
                        setDrilledDescriptions={setDrilledDescriptions}
                        setReamedDescriptions={setReamedDescriptions}
                        setGeneralDescriptions={setGeneralDescriptions}
                        setConsumableDescriptions={setConsumableDescriptions}
                        setHoles={setHoles}
                        setValue={setValue}
                        setSelectedJobId={setSelectedJobId}
                        setSelectedJobNumber={setSelectedJobNumber}
                        setSelectedRigName={setSelectedRigName}
                        setSelectedLocationName={setSelectedLocationName}
                        setJobState={setJobState}
                    />
                    <DailyEmployee
                        action={action}
                        daily={daily}
                        fieldEmployees={fieldEmployees}
                        fieldEmployeesAvailable={fieldEmployeesAvailable}
                        errors={errors}
                        register={register}
                        control={control}
                        setValue={setValue}
                        jobState={jobState}
                    />
                    <DailyHole
                        action={action}
                        daily={daily}
                        errors={errors}
                        disciplineId={disciplineId}
                        register={register}
                        control={control}
                        setCollectedHoles={setCollectedHoles}
                        collectedHoles={collectedHoles}
                        holes={holes}
                        setValue={setValue}
                        getValues={getValues}
                    />
                    <DailyBit
                        action={action}
                        daily={daily}
                        errors={errors}
                        register={register}
                        control={control}
                        collectedHoles={collectedHoles}
                        disciplineId={disciplineId}
                    />
                    <DailyFootageDrilled
                        action={action}
                        daily={daily}
                        errors={errors}
                        register={register}
                        control={control}
                        setValue={setValue}
                        collectedHoles={collectedHoles}
                        drilledDescriptions={drilledDescriptions}
                        setWarningModal={setWarningModal}
                        totalDrilled={totalDrilled}
                        selectedJobId={selectedJobId}
                        setTotalDrilled={setTotalDrilled}
                        setTotalDrilledPrice={setTotalDrilledPrice}
                        totalDrilledPrice={totalDrilledPrice}
                        setTotalDrilledVariance={setTotalDrilledVariance}
                        totalDrilledVariance={totalDrilledVariance}
                    />
                    <DailyFootageReamed
                        action={action}
                        daily={daily}
                        setValue={setValue}
                        errors={errors}
                        register={register}
                        control={control}
                        selectedJobId={selectedJobId}
                        collectedHoles={collectedHoles}
                        reamedDescriptions={reamedDescriptions}
                        setWarningModal={setWarningModal}
                        setTotalReamedPrice={setTotalReamedPrice}
                        totalReamedPrice={totalReamedPrice}
                        setTotalReamedVariance={setTotalReamedVariance}
                        totalReamedVariance={totalReamedVariance}
                    />
                    <DailyGeneralActivities
                        action={action}
                        daily={daily}
                        errors={errors}
                        register={register}
                        control={control}
                        setValue={setValue}
                        collectedHoles={collectedHoles}
                        activityDescriptions={activityDescriptions}
                        setWarningModal={setWarningModal}
                        selectedJobId={selectedJobId}
                        setTotalActivities={setTotalActivities}
                        totalActivities={totalActivities}
                        // totalDrillingHours={totalDrillingHours}
                        // setTotalDrillingHours={setTotalDrillingHours}
                        // getValues={getValues}
                    />
                    <DailyConsumables
                        action={action}
                        daily={daily}
                        errors={errors}
                        setValue={setValue}
                        register={register}
                        control={control}
                        collectedHoles={collectedHoles}
                        consumableDescriptions={consumableDescriptions}
                        selectedJobId={selectedJobId}
                        totalConsumables={totalConsumables}
                        setTotalConsumables={setTotalConsumables}
                        selectedJobNumber={selectedJobNumber}
                    />
                    <DailyGeneralCharges
                        action={action}
                        daily={daily}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        control={control}
                        collectedHoles={collectedHoles}
                        generalDescriptions={generalDescriptions}
                        selectedJobId={selectedJobId}
                        totalGeneralCharges={totalGeneralCharges}
                        setTotalGeneralCharges={setTotalGeneralCharges}
                    />
                    <DailyFuel
                        action={action}
                        daily={daily}
                        errors={errors}
                        register={register}
                        control={control}
                        setValue={setValue}
                        collectedHoles={collectedHoles}
                    />
                    <DailyNotes
                        action={action}
                        daily={daily}
                        register={register}
                    />
                    {[3, 4].includes(disciplineId) ?
                        <DailyBottomHole
                            action={action}
                            daily={daily}
                            errors={errors}
                            register={register}
                            control={control}
                            collectedHoles={collectedHoles}
                        />
                        :
                        <></>
                    }
                </div>
            </Accordion>
        </>
    );
}

export default React.memo(DailyAccordion);
