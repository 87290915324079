import React from 'react';
import Table from "../../../common/table/table";
import MessageEmptyData from "../../../common/messageEmptyData";
import {ShopTimeEntryReportHoursStyle} from "./shopTimeEntryReportHoursStyle";
import {ShopTimeEntryReportPeerDiemStyle} from "./shopTimeEntryReportPeerDiemStyle";
import {isEmpty} from "lodash";

/**
 * ShopTimeEntryReportBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function ShopTimeEntryReportBody(prop) {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableData)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <h4>Time</h4>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <ShopTimeEntryReportHoursStyle>
                        <Table
                            columns={tableData.tableHours.header}
                            data={tableData.tableHours.body}
                            exportOptions={{pdf: false, xlsx: true, csv: false}}
                            getExportFileName={() => 'shop_entry_time'}
                        />
                    </ShopTimeEntryReportHoursStyle>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <hr/>
                </div>
            </div>
            <div className="row">
                <h4>Per Diem</h4>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <ShopTimeEntryReportPeerDiemStyle>
                        <Table
                            columns={tableData.tablePerDiem.header}
                            data={tableData.tablePerDiem.body}
                            exportOptions={{pdf: false, xlsx: true, csv: false}}
                            getExportFileName={() => 'shop_entry_per_diem'}
                        />
                    </ShopTimeEntryReportPeerDiemStyle>
                </div>
            </div>
        </div>
    );
}