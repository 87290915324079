import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import JobCostFilters from "./jobCostFilters";
import JobCostBody from "./jobCostBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * Job Cost ViewPoint Report
 * @returns {JSX.Element}
 * @constructor
 */
const JobCost = () => {
    const [tableData, setTableData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    const jobCostFilterProps = {
        setReportFilters,
        setTableData,
    }

    const jobCostBodyProps = {
        tableData,
        reportFilters
    }

    return (
        <>
            <Breadcrumb title="Job Cost" parent="ViewPoint"/>
            <JobCostFilters {...jobCostFilterProps}/>
            <JobCostBody {...jobCostBodyProps}/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default JobCost;
