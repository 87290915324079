import React, {useState} from 'react';
import Breadcrumb from "../../../common/breadcrumb";
import LoadingIndicator from "../../../common/loadingIndicator";
import FullWorkHistoryBody from "./fullWorkHistoryBody";
import DateRangeEquipmentFilter from "../../../common/form-filters/DateRangeEquipmentFilter";
import moment from "moment";

/**
 * FullWorkHistory report Component
 * @returns {JSX.Element}
 * @constructor
 */
const FullWorkHistory = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        date_from: moment().subtract(1, "month").format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Full Work History" parent="Equipment" />
            <DateRangeEquipmentFilter {...{filters, setFilters, setTableData, OnSubmitUrl:"getFullWorkHistory"}}/>
            <FullWorkHistoryBody {...{filters, tableData}} />
            <LoadingIndicator isLoading={filters.isLoading} />
        </>
    );
}

export default FullWorkHistory;
