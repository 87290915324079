import React from 'react';
import TableReport from "../../common/table/table";
import {isEmpty} from "lodash";

/**
 * Rig Table for Employees Review
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const EmployeesReviewBodyRigTable = (prop) => {
    let {title, header, rows, fileName, isApproved, checkMaxCellValue = false} = prop;

    // if there is no row don't display the table
    if (isEmpty(rows)) {
        return null;
    }

    // change cell colors for approved record
    if (isApproved) {
        header = setApprovedCellColor(header);
    }

    // change cell colors for pass max value
    if (!isApproved && checkMaxCellValue) {
        header = setMaxCellColor(header);
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <h5>{title}</h5>
                </div>
            </div>
            <TableReport
                columns={header}
                data={rows}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => fileName}
            />
        </>
    );
}

// set cell text color for pass max value
const setMaxCellColor = (array) => {
    array.forEach(function (obj, index, arr) {
        // ignore first and last indexes
        if (index === 0 || arr.length - 1 === index) {
            return;
        }
        return obj['Cell'] = (cell) => {
            if (cell.value <= 12) {
                return cell.value;
            }
            return <div className="bg-primary">{cell.value}</div>
        };
    });
    return array;
}

// set cell text color for approved records
const setApprovedCellColor = (array) => {
    array.forEach(function (obj, index, arr) {
        // ignore first and last indexes
        if (index === 0 || arr.length - 1 === index) {
            return;
        }

        return obj['Cell'] = (cell) => {
            return <div className="bg-success">{cell.value}</div>
        }
    });
    return array;
}

export default EmployeesReviewBodyRigTable;
