import apiClient from "./apiClient";

/**
 * This function open a text/html resource that is render in the serverside in the browser
 * @param endpoint API url
 * @param id id resource
 */
const exportToHTML = (endpoint, id) => {

    //get html export file and open it in a new window
    apiClient(`/${endpoint}/${id}`, {
        method: 'GET',
        responseType: 'blob' //Force to receive data in a Blob Format
    })
        .then(response => {
            //Create a Blob from the HTML Document
            const file = new Blob(
                [response.data],
                {type: 'text/html'});
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        })
        .catch(error => {
            console.log(error);
        });
};

export default exportToHTML;
