import React, {useState} from 'react';
import ModalConfirmation from "../../../common/modal/ModalConfirmation.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import Table from "../../../common/table/table";
import invoiceChargesTableColumns from "./invoiceChargesTableColumns.js";
import apiClient from "../../../../utils/apiClient";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import EditFormModal from "./editFormModal";

/**
 * Body component for Invoice Charges
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const InvoiceChargesBody = (prop) => {
    const {filters, tableData, setTableData} = prop;
    const [selectedRow, setSelectedRow] = useState({});
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    const onDelete = () => {
        apiClient
            .post(`deleteInvoiceCharge/${selectedRow.id}`)
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deleteModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    return (
        <div className="container-fluid">
            <Table
                columns={invoiceChargesTableColumns(setSelectedRow, setEditModal, setDeleteModal)}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'invoice_charges'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <EditFormModal
                title="Edit Invoice Charge"
                modal={editModal}
                setModal={setEditModal}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Remove Invoice Charge"
                onConfirm={onDelete}
            />
        </div>
    );
}

export default InvoiceChargesBody;
