import React from "react";
import apiClient from "../../../../utils/apiClient";
import {FaPencilAlt, FaEyeSlash, FaEye, FaKey} from "react-icons/fa";

function sortDateColumn(row1, row2, columnId) {
    const value1 = row1.values[columnId];
    const value2 = row2.values[columnId];

    if (value1 === '') {
        return -1;
    }

    if (value2 === '') {
        return 1;
    }

    const date1 = new Date(value1);
    const date2 = new Date(value2);

    return date1 > date2 ? 1 : -1;
}

const UsersTable = (
    setFilters, setSelectedRow, setDeactivateModal, setReactivateModal,
    setEditUserModal, setEditUserEditData, setUserId, setResetPasswordModal
) => {
    const getUserData = (id) => {
        apiClient
            .post('getActiveUserById', {'id': id})
            .then(response => {
                setEditUserEditData(response.data);
                setUserId(response.data.id);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return [
        {
            Header: 'Last Name',
            accessor: 'last_name',
            width: 150,
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
            width: 150,
        },
        {
            Header: 'Suffix',
            accessor: 'suffix',
            width: 50,
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'VP ID',
            accessor: 'erp_id',
            width: 60,
        },
        {
            Header: 'Type',
            accessor: 'type',
            width: 150,
        },
        {
            Header: 'Discipline',
            accessor: 'discipline_id',
            width: 140,
        },
        {
            Header: 'Office',
            accessor: 'office_region',
            width: 60,
        },
        {
            Header: 'Created On',
            accessor: 'created_at',
            width: 80,
            sortType: sortDateColumn
        },
        {
            Header: 'Hire Date',
            accessor: 'hire_date',
            width: 80,
            sortType: sortDateColumn
        },
        {
            Header: 'Modified By',
            accessor: 'modified_by',
            width: 150,
            // Cell: (cell) => {
            //     const row = cell.row.original;
            //     console.log(row);
            //     let name = '';
            //     if (row.modified_by != null) {
            //         name = row.modified_by.substring(0,1);
            //     }
            //     return name;
            // }
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                let active = (
                    <>
                        <FaPencilAlt
                            className="actions"
                            title="Edit User"
                            onClick={() => {
                                setEditUserEditData([]);
                                getUserData(row.user_id);
                                setEditUserModal({
                                    isOpen: true
                                });
                            }}
                        />
                        {' '}
                        <FaEyeSlash
                            className="actions"
                            title="Deactivate User"
                            onClick={() => {
                                const details = row.first_name + ' ' + row.last_name;
                                const msg = 'Are you sure that you want to deactivate user: ' + details;
                                setSelectedRow(row);
                                setDeactivateModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                        {' '}
                        <FaKey
                            className="actions"
                            title="Reset Password"
                            onClick={() => {
                                const details = row.first_name + ' ' + row.last_name;
                                const msg = 'Are you sure that you want to reset password for: ' + details;
                                setSelectedRow(row);
                                setResetPasswordModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                    </>
                );

                let inactive = (
                    <>
                        <FaEye
                            className="actions"
                            title="Reactivate User"
                            onClick={() => {
                                const details = row.first_name + ' ' + row.last_name;
                                const msg = 'Are you sure that you want to reactivate user: ' + details;
                                setSelectedRow(row);
                                setReactivateModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                    </>
                );

                if (row.status === 1) {
                    if (row.type !== 'Client') {
                        return active;
                    } else {
                        return '';
                    }
                } else {
                    return inactive;
                }
            }
        }
    ];
}

export default UsersTable;
