import React from "react";
import {isEmpty, isUndefined} from "lodash";
import {TotalTableContainer} from './tableStyle'
import moment from "moment";

const FieldDailyTotalsTable = (props) => {
    const {
        watch, fieldDaily, action, selectedJobNumber, selectedRigName, selectedClientName, selectedLocationName, user,
        totalHours
    } = props;
    const TOOL_PUSHER = 11;
    const DRILLER_ASSISTANT = 9;
    const DRILLER = 7;
    let columnTitle = <></>;
    let columnValue = <></>;

    // if we create FD and user is in groups down below
    if (action === 'create') {
        if (user?.group_id === TOOL_PUSHER || user?.group_id === DRILLER || user?.group_id === DRILLER_ASSISTANT) {
            columnTitle = <th>Discipline</th>;
            columnValue = <td className="bold-red">{user?.discipline_name}</td>;
        }
    }

    if (action === 'edit') {
        columnTitle = <th>Discipline</th>;
        columnValue = <td className="bold-red">{(typeof fieldDaily.discipline !== 'undefined') ? fieldDaily.discipline.name : ''}</td>;
    }

    let generalInfo = {
        rig_name: '',
        job_number: '',
        client_name: '',
        location_name: ''
    };

    const watchForm = watch([
        "general_info.date",
        "general_info.daily_shift",
    ]);

    if (action === 'create') {
        generalInfo.rig_name = selectedRigName
        generalInfo.job_number = selectedJobNumber;
        generalInfo.client_name = selectedClientName;
        generalInfo.location_name = selectedLocationName;
    }

    if (action === 'edit' && !isEmpty(fieldDaily)) {
        generalInfo.rig_name = fieldDaily.rig.name
        generalInfo.job_number = fieldDaily.job.job_number
        generalInfo.client_name = fieldDaily.client.client_name;
        generalInfo.location_name = selectedLocationName !== '' ? selectedLocationName : fieldDaily.location.name;
    }

    return (
        <TotalTableContainer className="sticky-top card">
            <div className="card-body">
                <table className="table">
                    <thead>
                        <tr>
                            {columnTitle}
                            <th>Date</th>
                            <th>Shift</th>
                            <th>Rig</th>
                            <th>Job</th>
                            <th>Client</th>
                            <th>Location</th>
                            <th>Total Hours</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {columnValue}
                            <td>{!isUndefined(watchForm['general_info.date']) && moment(watchForm['general_info.date']).format("MM/DD/YYYY")}</td>
                            <td>{!isUndefined(watchForm['general_info.daily_shift']) && watchForm['general_info.daily_shift']}</td>
                            <td>{generalInfo.rig_name}</td>
                            <td>{generalInfo.job_number}</td>
                            <td>{generalInfo.client_name}</td>
                            <td>{generalInfo.location_name}</td>
                            <td>{totalHours}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </TotalTableContainer>
    );
}
export default FieldDailyTotalsTable;
