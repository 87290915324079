import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {useForm} from 'react-hook-form'
import {Button} from "reactstrap";
import FormInputText from "../../forms/form-control/FormInputText";
import FormInputNumber from "../../forms/form-control/FormInputNumber";
import FormSelect from "../../forms/form-control/FormSelect";
import apiClient from "../../../utils/apiClient";
import {toast} from "react-toastify";


const CreateRig = () => {
    const {register, handleSubmit, errors, reset} = useForm();
    const [disciplines, setDisciplines] = useState([]);
    const [types, setTypes] = useState([]);
    const [supervisors, setSupervisors] = useState([]);

    useEffect(() => {
        // get list of disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
        });

        // get list of rig types
        apiClient
            .get('getRigTypes')
            .then(response => {
                setTypes(response.data);
        });

        // get list of supervisor users
        apiClient
            .get('getSupervisors')
            .then(response => {
                setSupervisors(
                    response.data.map(
                        ({id, first_name, last_name}) => (
                            {value: id, label: first_name + ' ' + last_name}
                        )
                    )
                );
        });
    }, []);

    const onSave = (data) => {
        apiClient
            .post('createRig', data)
            .then(response => {
                toast.success(response.data.message);
                reset();
            });
    }

    return (
        <>
            <Breadcrumb title="Create Rig" parent="Admin"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    className="needs-validation"
                                    noValidate=""
                                    onSubmit={handleSubmit(onSave)}
                                >
                                    <div className="form-row s-gy">
                                        <FormInputText
                                            id="name"
                                            label="Name"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-4"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="make"
                                            label="Make"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-4"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="model"
                                            label="Model"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-4"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />

                                        <FormInputNumber
                                            id="year"
                                            label="Year"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-4"
                                            register={register({
                                                min: {value: 1, message: "min value is 1"},
                                                max: {value: 3000, message: "max value is 3000"},
                                                required: "required"
                                            })}
                                            errors={errors}
                                        />
                                        <FormSelect
                                            id="discipline_id"
                                            label="Discipline"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-4"
                                            options={disciplines}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormSelect
                                            id="rig_type_id"
                                            label="Type"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-4"
                                            options={types}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />

                                        <FormSelect
                                            id="supervisor_id"
                                            label="Tool Pusher"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-4"
                                            options={supervisors}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="description"
                                            label="Description"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-8 col-lg-8"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                    </div>

                                    <Button color="primary" type="submit" className="mt-4">Save</Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateRig;
