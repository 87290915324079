import React, {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import FormInputDate from "../../../forms/form-control/FormInputDate";

/**
 * Filter component for EmployeeRegister Report
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeeRegisterFilters = (prop) => {
    const {register, handleSubmit, errors, setValue} = useForm();
    const {reportFilters, setReportFilters, setTableData} = prop;
    const [disciplines, setDisciplines] = useState([]);
    const [payPeriods, setPayPeriods] = useState([]);
    const [payPeriod, setPayPeriod] = useState({});
    const [endDate, setEndDate] = useState(0);

    const updateReportFilters = useCallback((id, value) => {
        setReportFilters(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // filter selection handler
    const filterSelectionHandler = (e) => {
        const {id, value} = e.target;
        updateReportFilters(id, value);
        setValue('date_from', '');
        setValue('date_to', '');
    }

    const onSubmit = (data) => {
        const pay_period_id = data.date_from && data.date_to ? "" : reportFilters.pay_period_id;
        const registerData = {discipline_id:reportFilters.discipline_id, pay_period_id, date_from:data.date_from, date_to:data.date_to};
        updateReportFilters('isLoading', true);
        updateReportFilters('isSubmitted', true);

        apiClient
            .post('getEmployeeRegister', registerData)
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
                updateReportFilters('isLoading', false);
            });
    }

    // get list of disciplines and pay periods
    useEffect(() => {
        // get current pay period
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                setPayPeriod(response.data);
                let year = Number(moment(response.data.end_date).format('YYYY'));
                setEndDate(year);
            });
            
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
        });

        apiClient
            .post('getPayPeriodsByYear', {'year': endDate})
            .then(response => {
                setPayPeriods(
                    response.data.map(
                        ({id, start_date, end_date, is_current}) => ({
                            id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')
                        })
                    )
                );
        });
    }, [endDate]);

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            setValue('pay_period_id', payPeriod.id);
            updateReportFilters("pay_period_id", payPeriod.id);
        }, 200);
    }, [payPeriod, setValue, updateReportFilters]);

    return (
        <form
            className="needs-validation"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row">
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-md-q mb-3"
                    value={reportFilters.discipline_id}
                    onChange={filterSelectionHandler}
                    options={disciplines}
                    register={register({required: {value: true, message: "Discipline is required"}})}
                    errors={errors}
                />
                <FormSelect
                    id="pay_period_id"
                    label="Pay Period"
                    className="col-md-q mb-3"
                    value={reportFilters.pay_period_id}
                    onChange={filterSelectionHandler}
                    options={payPeriods}
                    register={register}
                    errors={errors}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-md-q"
                    onChange={() => {
                        setValue('pay_period_id', '');
                    }}
                    register={register}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-md-q"
                    onChange={() => {
                        setValue('pay_period_id', '');
                    }}
                    register={register}
                />
                <FormSubmitButton
                    className="col-md-q"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default EmployeeRegisterFilters;
