const employeePayableHoursAllHoursColumns = [
    {
        Header: 'Emp #',
        accessor: 'erp_id',
        width: 60,
    },
    {
        Header: 'First Name',
        accessor: 'first_name',
    },
    {
        Header: 'Last Name',
        accessor: 'last_name',
    },
    {
        Header: 'Rig',
        accessor: 'rig_name',
    },
    {
        Header: 'Type',
        accessor: 'type',
        width: 40,
    },
    {
        Header: 'Pay Seq',
        accessor: 'pay_seq',
        width: 50,
    },
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'Job',
        accessor: 'job_number',
    },
    {
        Header: 'Phase',
        accessor: 'phase',
    },
    {
        Header: 'Craft',
        accessor: 'craft',
        width: 40,
    },
    {
        Header: 'Class',
        accessor: 'class',
        width: 45,
    },
    {
        Header: 'Crew',
        accessor: 'crew',
        width: 50,
    },
    {
        Header: 'Hours',
        accessor: 'hours',
        width: 60,
    },
    {
        Header: 'EC',
        accessor: 'ec',
        width: 40,
    },
    {
        Header: 'Per Diem',
        accessor: 'per_diem',
    },
    {
        Header: 'Equip #',
        accessor: 'equipment_number',
    },
    {
        Header: 'Equip CC',
        accessor: 'equipment_cost_code',
        width: 50,
    },
    {
        Header: 'Certified',
        accessor: 'certified',
        width: 70,
    },
    {
        Header: 'State',
        accessor: 'state',
        width: 50,
    },
    {
        Header: 'Shift',
        accessor: 'shift',
        width: 40,
    }
];

export default employeePayableHoursAllHoursColumns;

