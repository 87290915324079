import React from 'react';
import {FaPlus, FaTrash} from 'react-icons/fa';
import FormInputText from '../../../forms/form-control/FormInputText';
import FormInputNumber from '../../../forms/form-control/FormInputNumber';
import {clone} from "lodash";

const invoiceChargeStructure = {
    client_id: '',
    job_id: '',
    location_id: '',
    description: '',
    price: 0,
    units: 0,
    total: 0,
}

const InvoiceChargesTable = (props) => {
    const {register, errors, fields, append, remove, setValue, getValues} = props;

    const addCharge = () => {
        append(clone(invoiceChargeStructure));
    }

    const removeCharge = (index) => {
        remove(index);
    }

    const calculateTotals = (index) => {
        const units = Number(getValues(`charges[${index}].units`));
        const price = Number(getValues(`charges[${index}].price`));
        const total = Number(units * price).toFixed(2);
        setValue(`charges[${index}].total`, total);
    }

    return (
        <>
            <table className="table table-hover table-striped col-md-11">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th>Units</th>
                        <th>Price</th>
                        <th>Total</th>
                        <th className="text-right">
                            <FaPlus
                                color="#22af47"
                                size={20}
                                title="Add Charge"
                                onClick={addCharge}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                {fields.map((field, indexKey) => (
                    <tr key={field.fieldArrayId}>
                        <td>
                            <FormInputText
                                id={`charges[${indexKey}].description`}
                                register={register({required: "required"})}
                                errors={errors}
                                value={field.description}/>
                        </td>
                        <td>
                            <FormInputNumber
                                id={`charges[${indexKey}].units`}
                                register={register({required: "required"})}
                                errors={errors}
                                value={field.units}
                                onChange={() => calculateTotals(indexKey)}
                            />
                        </td>
                        <td>
                            <FormInputNumber
                                id={`charges[${indexKey}].price`}
                                register={register({required: "required"})}
                                errors={errors}
                                value={field.price}
                                onChange={() => calculateTotals(indexKey)}
                            />
                        </td>
                        <td>
                            <FormInputNumber
                                id={`charges[${indexKey}].total`}
                                register={register}
                                errors={errors}
                                disabled='true'
                                value={field.total}
                            />
                        </td>
                        <td>
                            <FaTrash
                                color='#A51E37'
                                size={20}
                                title="Remove"
                                onClick={() => removeCharge(indexKey)}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
}

export default InvoiceChargesTable;
