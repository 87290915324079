export const tableColumns = [
    {
        Header: '',
        accessor: 'month',
    },
    {
        Header: 'Surface Core',
        accessor: 'discipline_1',
    },
    {
        Header: 'Underground Core',
        accessor: 'discipline_2',
    },
    {
        Header: 'RCX',
        accessor: 'discipline_3',
    },
    {
        Header: 'Rotary',
        accessor: 'discipline_4',
    },
    {
        Header: 'Total',
        accessor: 'total',
    },
];
