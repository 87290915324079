import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import RevenueWeeklyTrackingBody from './RevenueWeeklyTrackingBody';
import LoadingIndicator from '../../../common/loadingIndicator';
import apiClient from '../../../../utils/apiClient';
import {isEmpty} from 'lodash';
import {updateStateValue} from "../../../../utils/stateHelpers";

/**
 * RevenueWeeklyTracking Component
 * @returns {JSX.Element}
 * @constructor
 */

const getParam = (type, jobId, id) => {
    if (type === 'financial_period') {
        return {
            financial_period_id: id,
            job_id: jobId,
        };
    } else if (type === 'pay_period') {
        return {
            pay_period_id: id,
            job_id: jobId,
        };
    } else {
        return {
            pay_period_id: '',
            job_id: null,
        };
    }
}

export default function RevenueWeeklyTracking(props) {
    const {jobId, id, type} = props.match.params;
    const [tableData, setTableData] = useState([]);

    const [filters, setFilters] = useState({
        financial_period_id: '',
        pay_period_id: '',
        isLoading: true,
        isSubmitted: false,
    });

    const reportBodyProps = {
        filters,
        tableData,
    };

    useEffect(() => {
        if (id) {
            apiClient
                .post('getRevenueWeeklyTracking', getParam(type, jobId, id))
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setTableData([]);
                        return;
                    }
                    setTableData(response.data);
                })
                .then(
                    setFilters((prev) => ({
                        ...prev,
                        financial_period_id: type === 'financial_period' ? id : '',
                        pay_period_id: type === 'pay_period' ? id : '',
                        isSubmitted: true,
                    }))
                ).then(function () {
                updateStateValue(setFilters, 'isLoading', false);
            });
        }
    }, [id, type, jobId]);

    return (
        <>
            <Breadcrumb title='Revenue Weekly Tracking' parent='Reports'/>
            <h3>
                {tableData?.financial_period?.replace('-', '')}{' - '}{tableData?.job_name}
            </h3>
            <RevenueWeeklyTrackingBody {...reportBodyProps} />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}
