import React from 'react';
import TableReport from "../../common/table/table";
import MessageEmptyData from "../../common/messageEmptyData";
import {TableStyle} from "./tableStyle";
import EmployeesReviewBodyRigTable from "./employeesReviewBodyRigTable"
import ReportBodyLabelTable from "./employeesReviewBodyLabelTable"
import {isEmpty} from "lodash"

/**
 * Body report component for Employees Review report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const EmployeesReviewBody = (prop) => {
    const {reportFilters, tableData} = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableData)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <ReportBodyLabelTable
                reportLabel={tableData.tableLabel}
            />
            <div className="row">
                <div className="col">
                    <h5>Summary</h5>
                </div>
            </div>
            <TableStyle>
                <TableReport
                    columns={tableData.tableTotal.header}
                    data={tableData.tableTotal.body}
                    exportOptions={{pdf: false, xlsx: true, csv: false}}
                    getExportFileName={() => 'employee_review_total_table'}
                />
            </TableStyle>
            {/* Day Hours */}
            <EmployeesReviewBodyRigTable
                title={tableData.tableRigDayHours.title}
                header={tableData.tableRigDayHours.header}
                rows={tableData.tableRigDayHours.body}
                fileName={tableData.tableRigDayHours.fileName}
                isApproved={(tableData.tableLabel.type === 'approved')}
                checkMaxCellValue={true}
            />
            {/* Day PerDiem */}
            <EmployeesReviewBodyRigTable
                title={tableData.tableRigDayPerDiem.title}
                header={tableData.tableRigDayPerDiem.header}
                rows={tableData.tableRigDayPerDiem.body}
                fileName={tableData.tableRigDayPerDiem.fileName}
                isApproved={(tableData.tableLabel.type === 'approved')}
            />
            {/* Night Hours */}
            <EmployeesReviewBodyRigTable
                title={tableData.tableRigNightHours.title}
                header={tableData.tableRigNightHours.header}
                rows={tableData.tableRigNightHours.body}
                fileName={tableData.tableRigNightHours.fileName}
                isApproved={(tableData.tableLabel.type === 'approved')}
                checkMaxCellValue={true}
            />
            {/* Night PerDiem */}
            <EmployeesReviewBodyRigTable
                title={tableData.tableRigNightPerDiem.title}
                header={tableData.tableRigNightPerDiem.header}
                rows={tableData.tableRigNightPerDiem.body}
                fileName={tableData.tableRigNightPerDiem.fileName}
                isApproved={(tableData.tableLabel.type === 'approved')}
            />
        </div>
    );
}

export default EmployeesReviewBody;
