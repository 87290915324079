import React from 'react';
import TableReport from "../../../common/table/table";
import {JobCostPOTable} from "./jobCostPOTable.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from "lodash";

/**
 * Body report component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const JobCostPOBody = (prop) => {
    const {
        reportFilters,
        tableData,
    } = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableData)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
                <TableReport
                    columns={JobCostPOTable}
                    data={tableData}
                    scrollable={true}
                    withGlobalSearch={true}
                    exportOptions={{pdf: false, xlsx: true, csv: true}}
                    getExportFileName={() => 'job_cost_po'}
                />
        </div>
    );
}

export default JobCostPOBody;
