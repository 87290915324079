import React, {useEffect, useState} from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormInputText from "../../../forms/form-control/FormInputText";
import {useForm} from "react-hook-form";
import FormSelect from "../../../forms/form-control/FormSelect";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import {replacePartialObjFromArray} from "../../../../utils/arrayHelpers";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import FormInputTextArea from "../../../forms/form-control/FormInputTextArea";
import moment from "moment";
import FormNoteAlert from "../../../forms/form-control/FormNoteAlert";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import FormCancelButton from "../../../forms/form-control/FormCancelButton";
import * as Shared from "../../../common/shared";
import FormInputNumber from "../../../forms/form-control/FormInputNumber";

/**
 * Edit TimeEntry Modal
 * @returns {JSX.Element}
 * @constructor
 */
const EditTimeEntryModal = (props) => {
    const {modal, setModal, selectedRow, tableData, setTableData} = props;
    const {register, handleSubmit, errors} = useForm();
    const [payPeriod, setPayPeriod] = useState({});
    const [ecOptions, setEcOptions] = useState([]);
    const [periodLockedStatus, setPeriodLockedStatus] = useState(false);

    useEffect(() => {
        // get period status
        apiClient
            .get('isLocked')
            .then(response => {
                setPeriodLockedStatus(response.data);
            });

        // get current pay period
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                setPayPeriod(response.data)
            });

        //get EC Options
        apiClient
            .get('getEcOptionsForOffice')
            .then(response => {
                setEcOptions(response.data);
            });
    }, []);

    const onSave = (data) => {
        apiClient
            .post('editOfficeTimeEntry', data)
            .then(response => {
                setTableData(
                    replacePartialObjFromArray(
                        tableData, "id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    // toggle for open/close the modal form
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-lg modal-body" centered={true} >
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSave)}
            >
                <ModalHeader toggle={toggle}>Edit Office Time Entry</ModalHeader>
                <ModalBody>
                    <FormNoteAlert
                        className={(periodLockedStatus) ? "alert-danger" : "alert-success"}
                        label={
                            (periodLockedStatus)
                                ?
                                    'Pay Period is Locked'
                                :
                                    'Current Pay Period: ' +
                                    moment(new Date(payPeriod.start_date)).format('MM/DD/YYYY') + ' - '
                                    +    moment(new Date(payPeriod.end_date)).format('MM/DD/YYYY')
                        }
                    />
                    <div className="form-row">
                        <FormInputText
                            id="id"
                            type="hidden"
                            value={selectedRow.id}
                            register={register}
                        />
                        <FormInputDate
                            id="date"
                            label="Date"
                            className="col-md-4"
                            min={moment(new Date(payPeriod.start_date)).subtract(7,'d').format('YYYY-MM-DD')}
                            max={moment(new Date(payPeriod.end_date)).format('YYYY-MM-DD')}
                            value={selectedRow.date}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputNumber
                            label="Hours"
                            id="hours"
                            className="col-md-4"
                            register={register({
                                required: {value: true, message: 'required'},
                                min: {value: 0, message: "min value is 0"},
                                max: {value: 24.01, message: "max value is 24.00"}
                            })}
                            onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                            value={selectedRow?.hours}
                            errors={errors}
                        />
                        <FormSelect
                            id="ec_id"
                            label="Reason"
                            className="col-md-4 mb-3"
                            options={ecOptions}
                            value={selectedRow?.ec_id}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row">
                        <FormInputTextArea
                            id="notes"
                            label="Notes"
                            className="col-md-12 mb-3"
                            rows="3"
                            cols="45"
                            value={selectedRow.notes}
                            register={register}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="form-row">
                        <FormSubmitButton
                            disabled={(periodLockedStatus)}
                            className="col-md-6"
                            label="Save"
                        />
                        <FormCancelButton
                            className="col-md-5"
                            label="Cancel"
                            onClick={toggle}
                        />
                    </div>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default EditTimeEntryModal;
