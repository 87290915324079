import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import {useFieldArray} from "react-hook-form";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import * as Shared from "../../../../common/shared";
import apiClient from "../../../../../utils/apiClient";
// import {filter, find} from "lodash/collection";
// import {isEmpty, isNil, isUndefined} from "lodash";
// import {replaceObjFromArray} from "../../../../../utils/arrayHelpers";

const DailyGeneralActivities = (props) => {
    const {
        errors, register, control, collectedHoles, activityDescriptions, selectedJobId,
        action, daily, setTotalActivities, totalActivities, setValue
        //getValues, totalDrillingHours, setTotalDrillingHours
    } = props;
    const [selectedActivityHole, setSelectedActivityHole] = useState('');
    const {fields, append, remove} = useFieldArray({control, name: "activities"});

    useEffect(() => {
        if (action === 'edit') {
            append(daily.daily_activities);

            setTimeout(() => {
                daily.daily_activities.forEach((item, index) => {
                    // populate select
                    setValue(
                        `activities[${index}].cost_code`,
                        {value: item.cost_code, label: item.description}
                    );
                });
            }, 500);

            // // set total drilling hours
            // setTotalDrillingHours(
            //     filter(daily.daily_activities, {'revenue_group': 'DR'})
            // )

            setTimeout(() => {
                Shared.calculateTotals('total_activities', setTotalActivities);
            }, 500);
        }
    }, [append, action, daily, setTotalActivities, setValue]);

    const calculateTotalChargedInRow = (e, price, charged, totalId) => {
        e?.preventDefault();

        let priceValue = (document.getElementById(price) !== null) ? document.getElementById(price).value : 0;
        let chargedValue = (document.getElementById(charged) !== null) ? document.getElementById(charged).value : 0;

        if (chargedValue !== 0) {
            let totalRaw = Number(chargedValue) * Number(priceValue);
            let total = Number(totalRaw).toFixed(2);
            document.getElementById(totalId).value = total;

            Shared.calculateTotals('total_activities', setTotalActivities);
        }
    }

    const selectActivityRow = (index, value) => {
        //get activity record from list
        const activityDescription = activityDescriptions.find(obj => obj.value === value);

        // //set revenue_group to hidden text input
        // setValue(`activities[${index}].revenue_group`, activityDescription.revenue_group);

        //check if activity has revenue group mechanical loss
        if (activityDescription.revenue_group === 'ML') {
            setValue(`activities[${index}].price`, "0.00");
            calculateTotalChargedInRow(
                undefined,
                `activities[${index}].price`,
                `activities[${index}].charged`,
                `activities[${index}].total`
            )
            return;
        }

        // //check if activity has revenue group ream & re-drill
        // if (activityDescription.revenue_group === 'DR') {
        //     const $activityDrilling = filter(daily.daily_activities, {'cost_code': activityDescription.value});
        //     if ($activityDrilling.length === 0 ){
        //         setTotalDrillingHours(
        //             [...totalDrillingHours,
        //             {'revenue_group': 'DR', 'units': activityDescription.units, 'hole_name': ''}
        //             ]
        //         );
        //     }
        // }
        //calculate the units for hole

        let jobId = (action === 'edit') ? daily.job_id : selectedJobId;

        if (value && jobId) {
            apiClient
                .post('getJobChargesPriceByCodeAndJobId', {'job_id': jobId, 'code': value})
                .then(response => {
                    const realPrice = parseFloat(response.data[0]).toFixed(2);
                    setValue(`activities[${index}].price`, realPrice);
                    calculateTotalChargedInRow(
                        undefined,
                        `activities[${index}].price`,
                        `activities[${index}].charged`,
                        `activities[${index}].total`
                    )
                });
        }
    }

    const removeGeneralActivitiesRow = (index) => {
        remove(index);
        // calculate again
        setTimeout(() => {
            Shared.calculateTotals('total_activities', setTotalActivities);
        }, 500)
    }

    // const checkForHoleDrillingHours = (index) => {
    //
    //
    //     const activity_row = getValues(['activities']);
    //
    //     console.log(activity_row);
    //
    //     let holeDrillingHours = [];
    //
    //     fields.forEach((item, index) => {
    //
    //
    //
    //         if(item.revenue_group === 'DR'){
    //             // console.log(item);
    //             if(isEmpty(holeDrillingHours)){
    //                 holeDrillingHours = [
    //                     {
    //                         'units': item.units,
    //                         'hole_name': item.hole_name
    //                     }
    //                 ];
    //             }
    //         }
    //     });
    //
    //     setTotalDrillingHours(holeDrillingHours);


        // const activity_row = getValues([
        //     `activities[${index}].cost_code`,
        //     `activities[${index}].units`,
        //     `activities[${index}].hole_name`
        //     `activities[${index}].revenue_group`
        // ]).activities[index];
        //
        // if (isNil(activity_row.cost_code) ||
        //     isNil(activity_row.units) ||
        //     isNil(activity_row.hole_name) ||
        //     isNil(activity_row.revenue_group)){
        //     return null;
        // }

        // console.log(activity_row);

        // if (activity_row.revenue_group === 'DR') {

            // const $activityHoleDrilling = find(totalDrillingHours, {'cost_code': activity_row.cost_code.value});
            //
            // if (isUndefined($activityHoleDrilling)) {
            //
            //     setTotalDrillingHours(
            //         [...totalDrillingHours,
            //             {
            //                 'description': activity_row.cost_code.label,
            //                 'cost_code': activity_row.cost_code.value,
            //                 'revenue_group': activity_row.revenue_group,
            //                 'units': activity_row.units,
            //                 'hole_name': activity_row.hole_name
            //             }
            //         ]
            //     );
            //
            // } else {
            //     setTotalDrillingHours(
            //         replaceObjFromArray(
            //             totalDrillingHours, "cost_code", activity_row.cost_code.value,
            //             {
            //                 'description': activity_row.cost_code.label,
            //                 'cost_code': activity_row.cost_code.value,
            //                 'revenue_group': activity_row.revenue_group,
            //                 'units': activity_row.units,
            //                 'hole_name': activity_row.hole_name
            //             }
            //         )
            //     );
            // }

        // }

    // }

    useEffect(() => {
        if (!fields.length) {
            setTotalActivities(0);
        }
    }, [fields.length, setTotalActivities])

    return (
        <AccordionItem className="card-header bg-primary" title="General Activities">
            <div className="card">
                {totalActivities >= 0 &&
                    <div className="form-row" id="totals">
                        <div className="col-md-3 mb-3 total_block">
                            <p className="form-control background_total_block_color" id="total">
                                Total: {
                                    Number(totalActivities)
                                    .toLocaleString('en-US', {style: 'currency', currency: 'USD'})
                                }
                            </p>
                        </div>
                    </div>
                }
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Price</th>
                                        <th>Units</th>
                                        <th>Charged</th>
                                        <th>Total</th>
                                        <th>Hole</th>
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <SearchableSelectInTable
                                                id={`activities[${index}].cost_code`}
                                                options={activityDescriptions}
                                                rules={{required: "required"}}
                                                control={control}
                                                errors={errors}
                                                value={(action === 'edit') ? item.cost_code?.value : ''}
                                                onChange={ (value) => {
                                                    selectActivityRow(index, value);
                                                    // checkForHoleDrillingHours(index);
                                                }}
                                                customStyles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: 650,
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 650,
                                                        position: 'sticky',
                                                    })
                                                }}
                                            />
                                            <InputInTable
                                                className="short-select-width"
                                                id={`activities[${index}].price`}
                                                name={`activities[${index}].price`}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                            (item.price)
                                                                ?
                                                                    Number(item.price).toFixed(2)
                                                                :
                                                                    Number(0).toFixed(2)
                                                        :
                                                            ''
                                                }
                                                register={register({required: false})}
                                                disabled="true"
                                            />
                                            <InputInTable
                                                className="units short-select-width"
                                                id={`activities[${index}].units`}
                                                name={`activities[${index}].units`}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                            (item.units)
                                                                ?
                                                                    Number(item.units).toFixed(2)
                                                                :
                                                                    Number(0).toFixed(2)
                                                        :
                                                            ''
                                                }
                                                onInput={(e) => {
                                                    Shared.limitInputDaily(e.target, 9);
                                                    // checkForHoleDrillingHours(index);

                                                    // const activity_code = getValues([
                                                    //     `activities[${index}].cost_code`,
                                                    //     `activities[${index}].units`,
                                                    //     `activities[${index}].hole_name`
                                                    // ]).activities[index];
                                                    //
                                                    //
                                                    // // console.log(activity_code);
                                                    //
                                                    // if (activity_code.revenue_group === 'DR') {
                                                    //
                                                    // }

                                                }}
                                                register={register({required: true})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="short-select-width"
                                                id={`activities[${index}].charged`}
                                                name={`activities[${index}].charged`}
                                                // type="number"
                                                onChange={(e) => calculateTotalChargedInRow(
                                                    e,
                                                    `activities[${index}].price`,
                                                    `activities[${index}].charged`,
                                                    `activities[${index}].total`
                                                )}
                                                value={(action === 'edit') ? item.charged : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 9)}
                                                register={register({required: true})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="total_activities short-select-width"
                                                id={`activities[${index}].total`}
                                                name={`activities[${index}].total`}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                            item.total
                                                                ?
                                                                    Number(item.total).toFixed(2)
                                                                :
                                                                    Number(0).toFixed(2)
                                                        :
                                                            ''
                                                }
                                                register={register({required: false})}
                                                disabled={'true'}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`activities[${index}].hole_name`}
                                                    name={`activities[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : '' }
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`activities[${index}].hole_name`}
                                                    name={`activities[${index}].hole_name`}
                                                    value={
                                                        selectedActivityHole
                                                            ?
                                                                selectedActivityHole
                                                            :
                                                                (collectedHoles.length === 1)
                                                                    ?
                                                                    collectedHoles[0].value
                                                                    :
                                                                    item.hole_name
                                                    }
                                                    onChange={(e) => {
                                                        Shared.setSelectedValue(e.target.value, setSelectedActivityHole);
                                                        // checkForHoleDrillingHours(index);
                                                    }}
                                                    options={collectedHoles}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => removeGeneralActivitiesRow(index)}
                                                />
                                            </td>
                                            {/*<InputInTable*/}
                                            {/*    id={`activities[${index}].revenue_group`}*/}
                                            {/*    value={(action === 'edit') ? item.revenue_group : ''}*/}
                                            {/*    register={register}*/}
                                            {/*    type="hidden"*/}
                                            {/*/>*/}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default React.memo(DailyGeneralActivities);
