import React, {useEffect, useState} from 'react';
import Table from "../../../common/table/table";
import OfficeTimeEntryReviewTable from "./officeTimeEntryReviewTable.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import EditTimeEntryModal from "./editTimeEntryModal";
import {removeObjFromArray, replacePartialObjFromArray} from "../../../../utils/arrayHelpers";
import { useAuthenticate } from '../../../../hooks/auth';

/**
 * OfficeTimeEntryReviewBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const OfficeTimeEntryReviewBody = (prop) => {
    const {filters, setFilters, tableData, setTableData} = prop;
    const [editTimeEntryModal, setTimeEntryModal] = useState({isOpen: false});
    const [disapproveModal, setDisapproveModal] = useState({isOpen: false, message: <p></p>});
    const [approveModal, setApproveModal] = useState({isOpen: false, message: <p></p>});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});
    const [showApprovalCheckMark, setShowApprovalCheckMark] = useState(0);
    const [selectedRow, setSelectedRow] = useState({});
    const { user } = useAuthenticate();

    useEffect(() => {
        // define if logged user is in officeTiemApprovers
        apiClient
            .get('officeTimeApprovers')
            .then(response => {
                response.data.records.forEach(
                    ({user_id}) => {
                        if (typeof user !== 'undefined') {
                            if (user.id === user_id) {
                                setShowApprovalCheckMark(1);
                            }
                        }
                    }
                );
            })
            .catch((error) => {
                console.error(error);
            });
    }, [user, setShowApprovalCheckMark]);

    const ApproveHandler = () => {
        apiClient
            .post('approveTimeEntry', {'id': selectedRow.id})
            .then(response => {
                const newTableData = replacePartialObjFromArray(tableData, "id", response.data.id, response.data.record);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .then(function () {
                //close modal
                setApproveModal({...approveModal, isOpen: false})
            });
    }

    const DisapproveHandler = () => {
        apiClient
            .post('disapproveTimeEntry', {'id': selectedRow.id})
            .then(response => {
                const newTableData = replacePartialObjFromArray(tableData, "id", response.data.id, response.data.record);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .then(function () {
                //close mModal
                setDisapproveModal({...disapproveModal, isOpen: false})
            });
    }

    const DeleteHandler = () => {
        apiClient
            .post('deleteTimeEntry', {'id': selectedRow.id})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id);
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .then(function () {
                //close modal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    OfficeTimeEntryReviewTable(
                        setFilters, setSelectedRow, setApproveModal, setDisapproveModal,
                        setTimeEntryModal, setDeleteModal, showApprovalCheckMark
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'office_time_entry_review'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={approveModal}
                setModal={setApproveModal}
                title="Approve Time Entry"
                onConfirm={ApproveHandler}
            />
            <ModalConfirmation
                modal={disapproveModal}
                setModal={setDisapproveModal}
                title="Disapprove Time Entry"
                onConfirm={DisapproveHandler}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Delete Time Entry"
                onConfirm={DeleteHandler}
            />
            <EditTimeEntryModal
                modal={editTimeEntryModal}
                setModal={setTimeEntryModal}
                selectedRow={selectedRow}
                tableData={tableData}
                setTableData={setTableData}
            />
        </div>
    );
}

export default OfficeTimeEntryReviewBody;
