import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import {useForm} from 'react-hook-form'
import {toast} from "react-toastify";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {isCurrentYear} from "../../../../utils/dateHelpers";

const CreatePayPeriod = () => {
    const {register, handleSubmit, errors, reset, setValue} = useForm();
    const [periodYears, setPeriodYears] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState({});

    // Years
    useEffect(() => {
        apiClient
            .get('getNonExistingYears')
            .then(response => {
                let list = response.data.map((year) => {
                    if (isCurrentYear(year)) {
                        setCurrentPeriod({value: year, label: year + ' (Current)'})
                    }
                    return {
                        value: year, label: year + (isCurrentYear(year) ? " (Current)" : "")
                    }
                });
                setPeriodYears(list);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const onSubmit = (data) => {
        if (data !== '') {
            apiClient
                .post('createPayPeriod', JSON.stringify(data))
                .then(response => {
                    if (typeof response.data.message !== 'undefined') {
                        toast.success(response.data.message);
                        reset();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            setValue('year', currentPeriod.value);
        }, 200);
    }, [currentPeriod, setValue]);

    return (
        <Fragment>
            <Breadcrumb title="Create Pay Period" parent="Payroll"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    className="needs-validation"
                                    noValidate=""
                                    id="create_pay_period_form"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div className="row">
                                        <FormSelect
                                            id="year"
                                            label="Year"
                                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                            options={periodYears}
                                            register={register({required: {value: true, message: "required"}})}
                                            errors={errors}
                                        />
                                        <FormSubmitButton
                                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                            label="Create"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CreatePayPeriod;
