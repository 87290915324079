import React, {useEffect, useState} from 'react';
import FormSelect from "../../../forms/form-control/FormSelect";
import FormInputNumber from "../../../forms/form-control/FormInputNumber";
import FormInputText from "../../../forms/form-control/FormInputText";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import FormInputTextArea from "../../../forms/form-control/FormInputTextArea";
import FormInputFile from "../../../forms/form-control/FormInputFile";
import Breadcrumb from '../../../common/breadcrumb';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import SubmitButton from "../../../forms/form-control/daily/SubmitButton";
import CancelButton from "../../../forms/form-control/daily/CancelButton";

const AssetForm = (props) => {
    const {assetEditData, action,  title, assetId} = props;
    const {register, handleSubmit, reset, errors} = useForm();
    const [states, setStates] = useState([]);
    const [categories, setCategories] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [locations, setLocations] = useState([]);
    const [ownerships, setOwnerships] = useState([]);
    const [apcoNames, setApcoNames] = useState([]);
    const [fuels, setFuels] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedDiscipline, setSelectedDiscipline] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedJob, setSelectedJob] = useState('');
    const [selectedOwnership, setSelectedOwnership] = useState('');
    const [selectedApcoName, setSelectedApcoName] = useState('');
    const [selectedFuelType, setSelectedFuelType] = useState('');

    useEffect(() => {
        // get States
        apiClient
            .get('getUSAStates')
            .then(response => {
                setStates(
                    response.data.states.map(
                        ({id, abbreviation}) => ({id: id, name: abbreviation})
                    )
                );
            });

        // get Equipment Categories
        apiClient
            .get('getEquipmentCategories')
            .then(response => {
                setCategories(response.data);
            });

        // get Equipment Disciplines
        apiClient
            .get('getEquipmentDisciplines')
            .then(response => {
                setDisciplines(response.data);
            });

        // get Locations
        apiClient
            .get('getEquipmentLocations')
            .then(response => {
                setLocations(response.data);
            });

        // get Ownerships
        apiClient
            .get('getEquipmentOwnerships')
            .then(response => {
                setOwnerships(response.data);
            });

        // get Apconames
        apiClient
            .get('getEquipmentApconames')
            .then(response => {
                setApcoNames(response.data);
            });

        // get Fuels
        apiClient
            .get('getEquipmentFuels')
            .then(response => {
                setFuels(response.data);
            });

        // get Jobs
        apiClient
            .get('getAllActiveJobs')
            .then(response => {
                setJobs(
                    response.data.jobs.map(
                        ({id, job_number}) => ({id: id, name: job_number})
                    )
                );
            });
    }, []);

    const toggleCancel = () => {
        if (action === 'create') {
            reset();
        } else {
            window.close();
        }
    }

    const onSubmit = (data) => {
        const url = (action === 'edit') ? 'editAsset' : 'createAsset';
        const fdata = new FormData();

        if (action === 'create') {
            for (const key in data) {
                if (key === 'equipment_file') {
                    for (const file of data[key]) {
                        fdata.append('equipment_file[]', file, file.name);
                    }
                } else {
                    fdata.append(key, data[key]);
                }
            }
        } else {
            data['id'] = assetId;
        }

        apiClient
            .post(url, (action === 'create') ? fdata : JSON.stringify(data))
            .then(response => {
                toast.success(response.data.message);
                if (action === 'create') {
                    reset();
                }
                // TODO :: haven't checked it yet if we really need it
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            });
    }

    return (
        <>
            <Breadcrumb title={title + " Asset"} parent="Equipment"/>
            <form
                className="needs-validation container-fluid"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-row">
                    <FormInputText
                        id="equipment_number"
                        label="Equipment #"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.equipment_number : ''}
                        register={
                            register({
                                min: {value: 1, message: "min value is 1"},
                                max: {value: 999999999999999999999, message: "max value is 999999999999999999999"},
                                required: "required"
                            })
                        }
                        errors={errors}
                    />
                    <FormSelect
                        id="category_id"
                        label="Category"
                        className="col-md-3 mb-3"
                        options={categories}
                        isControlled={true}
                        value={
                            selectedCategory
                            ?
                                selectedCategory
                            :
                                assetEditData
                                    ?
                                        assetEditData.category_id
                                    :
                                        ''
                        }
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <FormSelect
                        id="discipline_id"
                        label="Discipline"
                        className="col-1/2 mb-3"
                        options={disciplines}
                        value={
                            selectedDiscipline
                                ?
                                selectedDiscipline
                                :
                                assetEditData
                                    ?
                                        assetEditData.discipline_id
                                    :
                                        ''
                        }
                        isControlled={true}
                        onChange={(e) => setSelectedDiscipline(e.target.value)}
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <FormSelect
                        id="equipment_location_id"
                        label="Location"
                        className="col-md-1 mb-3"
                        options={locations}
                        value={
                            selectedLocation
                                ?
                                    selectedLocation
                                :
                                    assetEditData
                                        ?
                                            assetEditData.equipment_location_id
                                        :
                                            ''
                        }
                        isControlled={true}
                        onChange={(e) => setSelectedLocation(e.target.value)}
                        register={register({required: "required"})}
                        errors={errors}
                    />
                </div>
                <div className="form-row">
                    <FormInputText
                        id="make"
                        label="Make"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.make : ''}
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <FormInputText
                        id="model"
                        label="Model"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.model : ''}
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <FormInputNumber
                        id="year"
                        label="Year"
                        value={(action === 'edit') ? assetEditData.year : ''}
                        className="col-md-1 mb-3"
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <FormInputText
                        id="unique_number"
                        label="VIN/Serial #"
                        className="col-md-3 mb-3"
                        value={(action === 'edit') ? assetEditData.unique_number : ''}
                        register={register({required: "required"})}
                        errors={errors}
                    />
                </div>
                <div className="form-row">
                    <FormInputDate
                        id="in_service_date"
                        value={(action === 'edit') ? assetEditData.in_service_date : ''}
                        label="In Service Date"
                        className="col-md-2 mb-3"
                        register={register({required: "required"})}
                        errors={errors}
                    />
                    <FormInputText
                        id="license_number"
                        label="License Plate #"
                        className="col-md-3 mb-3"
                        value={(action === 'edit') ? assetEditData.license_number : ''}
                        register={register}
                    />
                    <FormSelect
                        id="license_state_id"
                        label="State"
                        className="col-md-1 mb-3"
                        options={states}
                        value={
                            selectedState
                                ?
                                    selectedState
                                :
                                    assetEditData
                                        ?
                                            assetEditData.license_state_id
                                        :
                                            ''
                        }
                        isControlled={true}
                        onChange={(e) => setSelectedState(e.target.value)}
                        register={register}
                    />
                </div>
                <div className="form-row">
                    <FormInputDate
                        id="license_expiration_date"
                        label="Expiration Date"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.license_expiration_date : ''}
                        register={register}
                    />
                    <FormSelect
                        id="job_id"
                        label="Job"
                        className="col-md-2 mb-3"
                        options={jobs}
                        value={
                            selectedJob
                                ?
                                    selectedJob
                                :
                                    assetEditData
                                        ?
                                            assetEditData.job_id
                                        :
                                            ''
                        }
                        isControlled={true}
                        onChange={(e) => setSelectedJob(e.target.value)}
                        register={register}
                    />
                    <FormInputText
                        id="description"
                        label="Description"
                        value={(action === 'edit') ? assetEditData.description : ''}
                        className="col-md-4 mb-3"
                        register={register({required: "required"})}
                        errors={errors}
                    />
                </div>
                <div className="form-row">
                    <FormInputNumber
                        id="hour_reading"
                        label="Hour Reading"
                        value={(action === 'edit') ? assetEditData.hour_reading : ''}
                        className="col-md-2 mb-3"
                        register={register}
                    />
                    <FormInputDate
                        id="hour_date"
                        label="Hour Date"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.hour_date : ''}
                        register={register}
                    />
                    <FormInputText
                        id="replaced_reading"
                        label="Replaced Reading"
                        value={(action === 'edit') ? assetEditData.replaced_reading : ''}
                        className="col-md-2 mb-3"
                        register={register}
                    />
                    <FormInputDate
                        id="replaced_date"
                        label="Replaced Date"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.replaced_date : ''}
                        register={register}
                    />
                </div>
                <div className="form-row">
                    <FormInputNumber
                        id="odometer_reading"
                        label="Odometer Reading"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.odometer_reading : ''}
                        register={register}
                    />
                    <FormInputDate
                        id="odometer_date"
                        label="Odometer Date"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.odometer_date : ''}
                        register={register}
                    />
                    <FormInputText
                        id="odometer_replaced_reading"
                        label="Replaced Reading"
                        value={(action === 'edit') ? assetEditData.odometer_replaced_reading : ''}
                        className="col-md-2 mb-3"
                        register={register}
                    />
                    <FormInputDate
                        id="odometer_replaced_date"
                        label="Replaced Date"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.odometer_replaced_date : ''}
                        register={register}
                    />
                </div>
                <div className="form-row">
                    <FormInputTextArea
                        id="service_notes"
                        label="Service Notes"
                        className="col-md-8 mb-3"
                        rows="3"
                        cols="45"
                        value={(action === 'edit') ? assetEditData.service_notes : ''}
                        register={register}
                    />
                </div>
                <div className="form-row">
                    <FormInputFile
                        id="equipment_file"
                        label="Equipment files"
                        className="col-md-8 mb-3"
                        register={register}
                    />
                </div>
                <div className="form-row">
                    <FormSelect
                        id="ownership_id"
                        label="Ownership"
                        className="col-md-1 mb-3"
                        options={ownerships}
                        value={
                            selectedOwnership
                                ?
                                    selectedOwnership
                                :
                                    assetEditData
                                        ?
                                            assetEditData.ownership_id
                                        :
                                            ''
                        }
                        isControlled={true}
                        onChange={(e) => setSelectedOwnership(e.target.value)}
                        register={register}
                    />
                    <FormSelect
                        id="ap_co_name"
                        label="AP Co. Name"
                        className="col-md-2 mb-3"
                        options={apcoNames}
                        value={
                            selectedApcoName
                                ?
                                    selectedApcoName
                                :
                                    assetEditData
                                        ?
                                            assetEditData.ap_co_name
                                        :
                                            ''
                        }
                        isControlled={true}
                        onChange={(e) => setSelectedApcoName(e.target.value)}
                        register={register}
                    />
                    <FormInputText
                        id="leased_from"
                        label="Leased From"
                        value={(action === 'edit') ? assetEditData.leased_from : ''}
                        className="col-md-1 mb-3"
                        register={register}
                    />
                    <FormInputDate
                        id="lease_start_date"
                        label="Lease Start Date"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.lease_start_date : ''}
                        register={register}
                    />
                    <FormInputDate
                        id="lease_end_date"
                        label="Lease End Date"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.lease_end_date : ''}
                        register={register}
                    />
                </div>
                <div className="form-row">
                    <FormInputText
                        id="lease_payment"
                        label="Lease Payment"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.lease_payment : ''}
                        register={register}
                    />
                    <FormInputText
                        id="vendor"
                        label="Vendor"
                        className="col-md-2 mb-3"
                        value={(action === 'edit') ? assetEditData.vendor : ''}
                        register={register}
                    />
                    <FormInputNumber
                        id="estimated_average_mpg"
                        label="Average MPG"
                        value={(action === 'edit') ? assetEditData.estimated_average_mpg : ''}
                        className="col-md-2 mb-3"
                        register={register}
                    />
                    <FormSelect
                        id="fuel_type_id"
                        label="Fuel Type"
                        className="col-md-2 mb-3"
                        options={fuels}
                        value={
                            selectedFuelType
                                ?
                                    selectedFuelType
                                :
                                    assetEditData
                                        ?
                                            assetEditData.fuel_type_id
                                        :
                                            ''
                        }
                        isControlled={true}
                        onChange={(e) => setSelectedFuelType(e.target.value)}
                        register={register}
                    />
                </div>
                <div className="form-row">
                    <SubmitButton
                        label={(action === 'edit') ? "Save" : "Create"}
                    />
                    <CancelButton
                        label="Cancel"
                        onClick={toggleCancel}
                    />
                </div>
            </form>
        </>
    );
}

export default AssetForm;
