import React from 'react';
import { get } from 'lodash';
/**
 * FormSelectDaily
 * options accept array of object with below parameters
 *  ex:
 *   - [{id: "", name: "" },...],
 *   - [{value: "", label:""},...],
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormSelectDaily = (props) => {

    const {id, label, name, value, options, className, onChange, errors, register} = props;

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <select
                id={id}
                name={name}
                className="form-control"
                defaultValue={value || ''}
                onChange={onChange}
                ref={register}
            >
                <option value=''>- Select -</option>
                {
                    options.length > 0
                        ?
                            options.map((item, i) =>
                                <option
                                    key={i}
                                    value={item.id || item.value}
                                >
                                    {item.name || item.label}
                                </option>
                            )
                        :
                            ''
                }
            </select>
            {errors && <span>{ get(errors, id)?.message }</span>}
        </div>
    );
}

export default React.memo(FormSelectDaily);
