import React, {useState, useCallback} from 'react';
import moment from 'moment';
import Breadcrumb from '../../../common/breadcrumb';
import FieldDailiesFilters from "./fieldDailiesFilters";
import FieldDailiesBody from "./fieldDailiesBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import apiClient from "../../../../utils/apiClient";

/**
 * DirectEntry Report Component
 * @returns {JSX.Element}
 * @constructor
 */
const FieldDailies = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [filters, setFilters] = useState({
        start_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        isLoading: false,
        isSubmitted: false,
    });
    const [tableDataDisciplineId, setTableDataDisciplineId] = useState(null);
    const updateReportFilters = useCallback((id, value) => {
        setFilters(prevState => ({
            ...prevState,
            [id]: value
        }));
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const getFieldDailiesData = () => {
        updateReportFilters('isLoading', true);
        updateReportFilters('isSubmitted', true);

        apiClient
            .post('getFieldDailies', filters)
            .then(response => {
                setTableDataDisciplineId(filters.discipline_id);
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                } else {
                    setTableData(response.data);
                }
            })
            .catch(function () {
                setTableDataDisciplineId(filters.discipline_id);
                setTableData([]);
            }).then(function () {
            updateReportFilters('isLoading', false);
        });
    }

    return (
        <>
            <Breadcrumb title="Field Dailies" parent="Data Entry"/>
            <FieldDailiesFilters
                filters={filters}
                updateReportFilters={updateReportFilters}
                getFieldDailiesData={getFieldDailiesData}
            />
            <FieldDailiesBody
                filters={filters}
                setFilters={setFilters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                tableDataDisciplineId={tableDataDisciplineId}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default FieldDailies;
