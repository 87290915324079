import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from 'lodash';
import moment from "moment";
import FormInputDate from "../../../forms/form-control/FormInputDate";

/**
 * Filter component for revenue by hour Report
 * @returns {JSX.Element}
 * @constructor
 */
const RevenueByHourFilters = (prop) => {
    const {
        setReportFilters,
        setBarChartLabels,
        setBarChartDataDiscipline1,
        setBarChartDataDiscipline2,
        setBarChartDataDiscipline3,
        setBarChartDataDiscipline4,
        setStartDate,
        setEndDate,
        setPeriodId
    } = prop;
    const {register, handleSubmit, setValue, errors, watch} = useForm();
    const [endDateYear, setEndDateYear] = useState(0);
    const payPeriodId = watch('pay_period_id');
    const financialPeriodId = watch('financial_period_id');
    useEffect(()=> {
        setPeriodId(payPeriodId ? {
            id: payPeriodId,
            type: 'pay_period'
        } : {
            id: financialPeriodId,
            type: 'financial_period'
        })
    }, [setPeriodId, payPeriodId, financialPeriodId])
    
    const [payPeriod, setPayPeriod] = useState({});
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [payPeriods, setPayPeriods] = useState([]);

    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient
            .post('getRevenueByHour', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setBarChartLabels([])
                    setBarChartDataDiscipline1([]);
                    setBarChartDataDiscipline2([]);
                    setBarChartDataDiscipline3([]);
                    setBarChartDataDiscipline4([]);
                    setStartDate([]);
                    setEndDate([]);
                    return;
                }
                setBarChartLabels(response.data.dailyDataset.labels)
                setBarChartDataDiscipline1(response.data.dailyDataset.discipline_1);
                setBarChartDataDiscipline2(response.data.dailyDataset.discipline_2);
                setBarChartDataDiscipline3(response.data.dailyDataset.discipline_3);
                setBarChartDataDiscipline4(response.data.dailyDataset.discipline_4);
                setStartDate(response.data.start_date);
                setEndDate(response.data.end_date);
            })
            .catch(function () {
                setBarChartDataDiscipline1([]);
                setBarChartDataDiscipline2([]);
                setBarChartDataDiscipline3([]);
                setBarChartDataDiscipline4([]);
                setStartDate([]);
                setEndDate([]);
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    }

    // get list of financial periods and pay peridos
    useEffect(() => {
        // get current pay period
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                setPayPeriod(response.data);
                let year = Number(moment(response.data.end_date).format('YYYY'));
                setEndDateYear(year);
            });

        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => ({
                            id: id, name: name + (is_current ? ' (Current)' : '')
                        })
                    )
                );
        });

        apiClient.post('getPayPeriodsByYear', {'year': endDateYear})
            .then(response => {
                setPayPeriods(
                    response.data.map(
                        ({id, start_date, end_date, is_current}) => ({id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')})
                    )
                );
            });

    }, [setFinancialPeriods, setPayPeriods, endDateYear]);

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            updateStateValue(setReportFilters, 'pay_period_id', payPeriod.id);
            setValue('pay_period_id', payPeriod.id);
        }, 200);
    }, [payPeriod, setReportFilters, setValue]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="pay_period_id"
                    label="Weekly Data Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                    options={payPeriods}
                    register={register}
                    errors={errors}
                    onChange={() => {
                        setValue('financial_period_id', '');
                        setValue('date_from', '');
                        setValue('date_to', '');
                    }}
                />
                <FormSelect
                    id="financial_period_id"
                    label="Monthly Data Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={financialPeriods}
                    register={register}
                    onChange={() => {
                        setValue('pay_period_id', '');
                        setValue('date_from', '');
                        setValue('date_to', '');
                    }}
                    errors={errors}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    onChange={() => {
                        setValue('pay_period_id', '');
                        setValue('financial_period_id', '');
                    }}
                    register={register}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    onChange={() => {
                        setValue('pay_period_id', '');
                        setValue('financial_period_id', '');
                    }}
                    register={register}
                />
                <FormSubmitButton
                    className=""
                    label="Show"
                />
            </div>
        </form>
    );
}

export default RevenueByHourFilters;