import React, {useEffect, useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import apiClient from "../../../utils/apiClient";
import {useForm} from "react-hook-form";
import {addObjToArray, replaceObjFromArray} from "../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormInputText from "../../forms/form-control/FormInputText";
import {upperFirst} from "lodash";
import FormSelect from "../../forms/form-control/FormSelect";

/**
 * Modal form to create and edit a record
 * @returns {JSX.Element}
 * @constructor
 */
const CostCodesFormModal = (props) => {
    const {modal, setModal, title, selectedRow, tableData, setTableData} = props;
    const {register, handleSubmit, errors} = useForm();
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});
    const [revenueGroups, setRevenueGroups] = useState([]);
    const [lostTimeGroups, setLostTimeGroups] = useState([]);

    useEffect(() => {
        // get list of revenue groups
        apiClient
            .get('getRevenueGroups')
            .then(response => {
                setRevenueGroups(
                    response.data.map(
                        ({id, name, description}) => ({value: id, label: name + ' - ' + description})
                    )
                );
            });

        // get list of lost time groups
        apiClient
            .get('getLostTimeGroups')
            .then(response => {
                setLostTimeGroups(
                    response.data.map(
                        ({id, name, description}) => ({value: id, label: name + ' - ' + description})
                    )
                );
            });

    }, []);

    const onSubmit = (data) => {
        if (modal.action === 'create') {
            onCreate(data);
        }

        if (modal.action === 'edit') {
            onEdit(data);
        }
    }

    // create
    const onCreate = (data) => {
        apiClient
            .post('costCodes', data)
            .then(response => {
                setTableData(
                    addObjToArray(
                        tableData, response.data.record
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    // edit
    const onEdit = (data) => {
        apiClient
            .put('costCodes/' + selectedRow.id, data)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} size={'lg'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{`${upperFirst(modal.action)} ${title}`}</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputText
                            id="code"
                            label="Code"
                            type="number"
                            className="col-md-6"
                            value={selectedRow.code}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputText
                            id="description"
                            label="Description"
                            className="col-md-6"
                            value={selectedRow.description}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row m-t-15">
                        <FormSelect
                            id="revenue_group_id"
                            label="Revenue Group"
                            className="col-md-6"
                            value={selectedRow.revenue_group_id}
                            options={revenueGroups}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelect
                            id="lost_time_group_id"
                            label="Lost Time Group"
                            className="col-md-6"
                            value={selectedRow.lost_time_group_id}
                            options={lostTimeGroups}
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row m-t-15">
                        <FormSelect
                            id="shift_hours"
                            label="Shift Hours"
                            className="col-md-6"
                            value={selectedRow.shift_hours}
                            options={[{value: 1, label: "Yes"}, {value: 0, label: "No"}]}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelect
                            id="mobile_entry"
                            label="Mobile Entry"
                            className="col-md-6"
                            value={selectedRow.mobile_entry}
                            options={[{value: 1, label: "Yes"}, {value: 0, label: "No"}]}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default React.memo(CostCodesFormModal);
