import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from 'lodash';

/**
 * Filter component for Revenue By Rig Report
 * @returns {JSX.Element}
 * @constructor
 */
const ReportFilters = (prop) => {
    const {
        setReportFilters,
        setTableHeaderDiscipline1,
        setTableHeaderDiscipline2,
        setTableHeaderDiscipline3,
        setTableHeaderDiscipline4,
        setTableRowsDiscipline1,
        setTableRowsDiscipline2,
        setTableRowsDiscipline3,
        setTableRowsDiscipline4,
        setTotalTableHeader,
        setTotalTableRows
    } = prop;
    const {register, handleSubmit, errors, setValue} = useForm();
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState({});

    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient.post('getDailyRevenueByRigWeekly', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableHeaderDiscipline1([]);
                    setTableHeaderDiscipline2([]);
                    setTableHeaderDiscipline3([]);
                    setTableHeaderDiscipline4([]);
                    setTableRowsDiscipline1([]);
                    setTableRowsDiscipline2([]);
                    setTableRowsDiscipline3([]);
                    setTableRowsDiscipline4([]);
                    setTotalTableHeader([]);
                    setTotalTableRows([]);
                    return;
                }
                setTableHeaderDiscipline1(response.data.core_surface.tableHeader);
                setTableHeaderDiscipline2(response.data.core_underground.tableHeader);
                setTableHeaderDiscipline3(response.data.rcx.tableHeader);
                setTableHeaderDiscipline4(response.data.rotary.tableHeader);
                setTableRowsDiscipline1(response.data.core_surface.tableRows);
                setTableRowsDiscipline2(response.data.core_underground.tableRows);
                setTableRowsDiscipline3(response.data.rcx.tableRows);
                setTableRowsDiscipline4(response.data.rotary.tableRows);
                setTotalTableHeader(response.data.total.tableHeader);
                setTotalTableRows(response.data.total.tableRows);
            }).catch(function () {
            //In case of error do nothing!!
            //let apiClient to handle the error
        }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    };

    // get list of financial periods
    useEffect(() => {
        apiClient.get('getFinancialPeriods').then(response => {
            setFinancialPeriods(
                response.data.map(
                    ({id, name, is_current}) => {
                        if (is_current) {
                            setCurrentPeriod({id: id, name: name + ' (Current)'})
                        }
                        return {
                            id: id, name: name + (is_current ? ' (Current)' : '')
                        }
                    }
                )
            );
        });
    }, []);

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            setValue('financial_period_id', currentPeriod.id);
        }, 200);
    }, [currentPeriod, setValue]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    id="financial_period_id"
                    label="Financial Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={financialPeriods}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
};

export default ReportFilters;
