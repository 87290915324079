import {AccordionItem} from "react-light-accordion";
import React, {useState} from "react";
import Select from "../../../../forms/form-control/daily/Select";
import FormInputText from "../../../../forms/form-control/FormInputText";
import * as Shared from "../../../../common/shared";
import {isEmpty} from "lodash";
import EditSelect from "../../../../forms/form-control/daily/EditSelect";

const Safety = (props) => {
    const { register, errors, fieldDaily, setValue, action } = props;
    const [selectedSafety, setSelectedSafety] = useState('');

    if (action === 'edit') {
        setValue(`safety.injuries`, fieldDaily.safety?.injuries);
    }

    return (
        <AccordionItem className="card-header bg-primary" title="Safety">
            <div className="collapse show">
                <div className="form-row margin">
                    {action === 'edit' &&
                        <EditSelect
                            label="Injuries"
                            id="safety.injuries"
                            name="safety.injuries"
                            value={
                                selectedSafety
                                    ?
                                        selectedSafety
                                    :
                                        isEmpty(fieldDaily.safety)
                                            ?
                                                ''
                                            :
                                                fieldDaily.safety.injuries
                            }
                            onChange={(e) => Shared.setSelectedValue(e.target.value, setSelectedSafety)}
                            className="col-md-1 mb-3"
                            options={[{value: "0", label: "No"}, {value: "1", label: "Yes"}]}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    }
                    {action === 'create' &&
                        <Select
                            label="Injuries Today"
                            id="safety.injuries"
                            name="safety.injuries"
                            className="col-md-1 mb-3"
                            options={[{value: "0", label: "No"}, {value: "1", label: "Yes"}]}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    }
                    <FormInputText
                        id="safety.per_shift_completed"
                        label="Preshift Completed By"
                        value={(action === 'edit') ? fieldDaily.safety?.per_shift_completed : ''}
                        className="col-md-2 mb-3"
                        register={register({required: false})}
                    />
                </div>
            </div>
        </AccordionItem>
    );
}

export default Safety;
