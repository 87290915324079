import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {useForm} from 'react-hook-form'
import {toast} from "react-toastify";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import FormInputNumber from "../../forms/form-control/FormInputNumber";

const CreateDisciplineTargetByMonth = () => {
    // initialise the hook
    const {register, handleSubmit, errors, reset, setValue} = useForm();
    const [disciplines, setDisciplines] = useState([]);
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState({});

    // get Disciplines and Financial Periods
    useEffect(() => {
        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => {
                            if (is_current) {
                                setCurrentPeriod({id: id, name: name + ' (Current)'})
                            }
                            return {
                                id: id, name: name + (is_current ? ' (Current)' : '')
                            }
                        }
                    )
                );
            });

        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });
    }, [setFinancialPeriods, setDisciplines]);

    const onSubmit = (data) => {
        apiClient
            .post('createDisciplineTarget', data)
            .then(response => {
                if (response instanceof Error) {
                    toast.error(response.response.data.message);
                }
                if (response.status === 200) {
                    toast.success(response.data.message);
                    reset(register);
                }
            });
    };

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            setValue('financial_period_id', currentPeriod.id);
        }, 1000);
    }, [currentPeriod, setValue]);

    return (
        <>
            <Breadcrumb title="Discipline Target By Financial Period" parent="Admin"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    className="needs-validation"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div className="form-row s-gy">
                                        <FormSelect
                                            id="discipline_id"
                                            label="Discipline"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-2"
                                            options={disciplines}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormSelect
                                            id="financial_period_id"
                                            label="Financial Period"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-2"
                                            options={financialPeriods}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputNumber
                                            id="amount"
                                            label="Amount"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-2"
                                            register={register({
                                                    required: "required",
                                                    min: {value: 0, message: "Amount min value is 0"},
                                                    max: {value: 999999999.99, message: "Amount max value is 999999999.99"}
                                                }
                                            )}
                                            errors={errors}
                                        />
                                    </div>
                                    <FormSubmitButton
                                        label="Create"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateDisciplineTargetByMonth;
