import React from 'react';
import TableReport from "../../../common/table/table";
import {getTableColumns} from "./revenueByRigColumns.js";
import {getTotalTableColumns} from "./revenueByRigTotalColumns";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from 'lodash';
import {TableStyle} from "./tableStyle";

/**
 * Body component for Revenue By Rig Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const RevenueByRigBody = (prop) => {
    const {
        reportFilters, tableDataDiscipline1, tableDataDiscipline2, tableDataDiscipline3,
        tableDataDiscipline4, totalTableData, reportStartDate, reportEndDate, periodId, combined
    } = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableDataDiscipline1) &&
        isEmpty(tableDataDiscipline2) &&
        isEmpty(tableDataDiscipline3) &&
        isEmpty(tableDataDiscipline4)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid marginTop">
            {tableDataDiscipline1.map((coreTable, index) => (
                <TableStyle key={index}>
                    <TableReport
                        columns={getTableColumns('Surface Core', reportStartDate, reportEndDate, periodId)}
                        data={coreTable.table}
                        withGlobalSearch={false}
                    />
                </TableStyle>
            ))}
            {tableDataDiscipline2.map((coreTable, index) => (
                <TableStyle key={index}>
                    <TableReport
                        columns={getTableColumns('Underground Core', reportStartDate, reportEndDate, periodId)}
                        data={coreTable.table}
                        withGlobalSearch={false}
                    />
                </TableStyle>
            ))}
            {tableDataDiscipline3.map((rotaryTable, index) => (
                <TableStyle key={index}>
                    <TableReport
                        columns={getTableColumns('RCX', reportStartDate, reportEndDate, periodId)}
                        data={rotaryTable.table}
                        withGlobalSearch={false}
                    />
                </TableStyle>
            ))}
            {tableDataDiscipline4.map((rotaryTable, index) => (
                <TableStyle key={index}>
                    <TableReport
                        columns={getTableColumns('Rotary', reportStartDate, reportEndDate, periodId)}
                        data={rotaryTable.table}
                        withGlobalSearch={false}
                    />
                </TableStyle>
            ))}
            <div className="text-center bold padding30">Grand Total</div>
            <TableStyle>
                <TableReport
                    columns={getTotalTableColumns()}
                    data={isEmpty(combined) ? [] : combined}
                    withGlobalSearch={false}
                />
            </TableStyle>

            <table className="table table-hover table-striped mt-5">
                <thead>
                    <tr>
                        <th colSpan={2}>Totals Per Discipline</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Surface Core</td>
                        <td>{totalTableData.core_surface}</td>
                    </tr>
                    <tr>
                        <td>Underground Core</td>
                        <td>{totalTableData.core_underground}</td>
                    </tr>
                    <tr>
                        <td>RCX</td>
                        <td>{totalTableData.rcx}</td>
                    </tr>
                    <tr>
                        <td>Rotary</td>
                        <td>{totalTableData.rotary}</td>
                    </tr>
                    <tr>
                        <td>Grand Total</td>
                        <td>{totalTableData.grant_total}</td>
                    </tr>
                </tbody>
            </table>

        </div>
    );
}

export default RevenueByRigBody;
