import styled from "styled-components";

export const TableStyle = styled.div`
  table {
    tbody {
        tr {
            :last-child {
                td {
                    border-top: 5px double black;
                    font-weight: bold;
                }                
            }
        }
    }
  }
`;
