import React from 'react';
import BarChart from "../../../charts/BarChart";

/**
 * Body report component for revenue by hour
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const RevenueByHourBody = (prop) => {
    const {
        reportFilters,
        barChartLabels,
        barChartDataDiscipline1,
        barChartDataDiscipline2,
        barChartDataDiscipline3,
        barChartDataDiscipline4,
    } = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <BarChart title='Surface Core' labels={barChartLabels} dataset={barChartDataDiscipline1}/>
                        <BarChart title='Underground Core' labels={barChartLabels} dataset={barChartDataDiscipline2}/>
                        <BarChart title='RCX' labels={barChartLabels} dataset={barChartDataDiscipline3}/>
                        <BarChart title='Rotary' labels={barChartLabels} dataset={barChartDataDiscipline4}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RevenueByHourBody;
