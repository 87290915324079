import {AccordionItem} from "react-light-accordion";
import React from "react";
import * as Shared from "../../../../common/shared";
import InputInDiv from "../../../../forms/form-control/daily/InputInDiv";

const BottomHoleAssembly = (props) => {
    const {register, fieldDaily, action, fieldBHATemplate} = props;

    return (
        <AccordionItem className="card-header bg-primary" title="Bottom Hole Assembly Weights">
            <div className="collapse show">
                <div className="form-row margin">
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.bha_h_weight"
                        label="BHA Hyd Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].bha_h_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.bha_h_weight)
                                        ?
                                            fieldBHATemplate.bha_h_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.bha_multiplier"
                        label="BHA Multiplier"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].bha_multiplier
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.bha_multiplier)
                                        ?
                                            fieldBHATemplate.bha_multiplier
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.bha_weight"
                        label="BHA Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].bha_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.bha_weight)
                                        ?
                                            fieldBHATemplate.bha_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.total_string_h_weight"
                        label="Total String Hyd Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].total_string_h_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.total_string_h_weight)
                                        ?
                                            fieldBHATemplate.total_string_h_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.total_string_multiplier"
                        label="Total String Multiplier"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].total_string_multiplier
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.total_string_multiplier)
                                        ?
                                            fieldBHATemplate.total_string_multiplier
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.total_string_weight"
                        label="Total String Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].total_string_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.total_string_weight)
                                        ?
                                            fieldBHATemplate.total_string_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.runnable_weight_h_weight"
                        label="Runnable Weight Hyd Weight"
                        value={
                            (action === 'edit')
                                    ?
                                        (fieldDaily.field_b_h_as.length > 0)
                                            ?
                                                fieldDaily.field_b_h_as[0].runnable_weight_h_weight
                                            :
                                                ''
                                    :
                                        (action === 'create' && fieldBHATemplate.runnable_weight_h_weight)
                                            ?
                                                fieldBHATemplate.runnable_weight_h_weight
                                            :
                                                ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.runnable_weight_multiplier"
                        label="Runnable Weight Multiplier"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].runnable_weight_multiplier
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.runnable_weight_multiplier)
                                        ?
                                            fieldBHATemplate.runnable_weight_multiplier
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.runnable_weight_weight"
                        label="Runnable Weight Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].runnable_weight_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.runnable_weight_weight)
                                        ?
                                            fieldBHATemplate.runnable_weight_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.actual_weight_on_bit_h_weight"
                        label="Actual Weight on Bit Hyd Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].actual_weight_on_bit_h_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.actual_weight_on_bit_h_weight)
                                        ?
                                            fieldBHATemplate.actual_weight_on_bit_h_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.actual_weight_on_bit_multiplier"
                        label="Actual Weight On Bit Multiplier"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].actual_weight_on_bit_multiplier
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.actual_weight_on_bit_multiplier)
                                        ?
                                            fieldBHATemplate.actual_weight_on_bit_multiplier
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.actual_weight_on_bit_weight"
                        label="Actual Weight On Bit Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].actual_weight_on_bit_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.actual_weight_on_bit_weight)
                                        ?
                                            fieldBHATemplate.actual_weight_on_bit_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.rotating_pressure_low_h_weight"
                        label="Rotating Pressure Low Hyd Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].rotating_pressure_low_h_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.rotating_pressure_low_h_weight)
                                        ?
                                            fieldBHATemplate.rotating_pressure_low_h_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.rotating_pressure_low_multiplier"
                        label="Rotating Pressure Low Multiplier"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].rotating_pressure_low_multiplier
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.rotating_pressure_low_multiplier)
                                        ?
                                            fieldBHATemplate.rotating_pressure_low_multiplier
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.rotating_pressure_low_weight"
                        label="Rotating Pressure Low Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].rotating_pressure_low_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.rotating_pressure_low_weight)
                                        ?
                                            fieldBHATemplate.rotating_pressure_low_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.rotating_pressure_high_h_weight"
                        label="Rotating Pressure High Hyd Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].rotating_pressure_high_h_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.rotating_pressure_high_h_weight)
                                        ?
                                            fieldBHATemplate.rotating_pressure_high_h_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.rotating_pressure_high_multiplier"
                        label="Rotating Pressure High Multiplier"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].rotating_pressure_high_multiplier
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.rotating_pressure_high_multiplier)
                                        ?
                                            fieldBHATemplate.rotating_pressure_high_multiplier
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.rotating_pressure_high_weight"
                        label="Rotating Pressure High Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].rotating_pressure_high_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.rotating_pressure_high_weight)
                                        ?
                                            fieldBHATemplate.rotating_pressure_high_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
                <div className="form-row">
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.rpm_h_weight"
                        label="RPM Hyd Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].rpm_h_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.rpm_h_weight)
                                        ?
                                            fieldBHATemplate.rpm_h_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.rpm_multiplier"
                        label="RPM Multiplier"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].rpm_multiplier
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.rpm_multiplier)
                                        ?
                                            fieldBHATemplate.rpm_multiplier
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                    <InputInDiv
                        className="col-md-3 mb-3"
                        id="bha.rpm_weight"
                        label="RPM Weight"
                        value={
                            (action === 'edit')
                                ?
                                    (fieldDaily.field_b_h_as.length > 0)
                                        ?
                                            fieldDaily.field_b_h_as[0].rpm_weight
                                        :
                                            ''
                                :
                                    (action === 'create' && fieldBHATemplate.rpm_weight)
                                        ?
                                            fieldBHATemplate.rpm_weight
                                        :
                                            ''
                        }
                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                        register={register({required: false})}
                    />
                </div>
            </div>
        </AccordionItem>
    );
}

export default BottomHoleAssembly;
