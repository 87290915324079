export default function ShopTimeEntryWeeklySummaryEquipmentTable() {
    return [
        {
            Header: 'Equipment #',
            accessor: 'equipment_number',
        },
        {
            Header: 'Description',
            accessor: 'equipment_description',
        },
        {
            Header: 'Hours',
            accessor: 'hours',
        },
        {
            Header: '% Time',
            accessor: 'percentage',
        }
    ];
}