import React from 'react';
import TableReport from "../../common/table/table";
import consumableRegisterColumns from "./consumableRegisterColumns";
import MessageEmptyData from "../../common/messageEmptyData";

/**
 * table for consumable register
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ConsumableRegisterBody = (prop) => {
    const {filters, tableData, setSelectedRow, setSelectedFilesRow, setFormModal, setFormModalFiles} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={consumableRegisterColumns(setSelectedRow, setSelectedFilesRow, setFormModal, setFormModalFiles)}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'consumable_register'}
                withGlobalSearch={true}
                scrollable={true}
            />
        </div>
    );
}

export default ConsumableRegisterBody;
