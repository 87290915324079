import React ,{Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { DollarSign, MapPin, TrendingDown, ArrowUp, ShoppingCart} from 'react-feather';
import six from "../../../assets/images/user/6.jpg";
import two from "../../../assets/images/user/2.png";
import three from "../../../assets/images/user/3.jpg";
import four from "../../../assets/images/user/4.jpg";
import five from "../../../assets/images/user/5.jpg";
import fifteen from "../../../assets/images/user/15.png"
import { saleData, saleOptions, incomeData, incomeOptions, profitData, profitOptions, staticData, staticOptions} from '../../../charts-data/default';
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import CountUp from 'react-countup';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ManagementCore = () => {
  return (
      <Fragment>
          <Breadcrumb parent="Toolbox" title="Dashboard"/>
          <div className="container-fluid">
              <div className="row">
                  <div className="col-xl-7 xl-100">
                      <div className="row">
                          <div className="col-md-12 ecommerce-slider">
                              <div className="item">
                                  <div className="card">
                                      <div className="card-body ecommerce-icons text-center">
                                          <DollarSign />
                                          <div>
                                              <span>Revenue YTD</span>
                                          </div>
                                          <h4 className="font-primary mb-0">2,052,045</h4>
                                      </div>
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="card">
                                      <div className="card-body ecommerce-icons text-center">
                                          <MapPin />
                                          <div>
                                              <span>% to Annual Target</span>
                                          </div>
                                          <h4 className="font-primary mb-0">
                                              <CountUp className="counter" end={22} />
                                          </h4>
                                      </div>
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="card">
                                      <div className="card-body ecommerce-icons text-center">
                                          <ShoppingCart />
                                          <div>
                                              <span>R&M</span>
                                          </div>
                                          <h4 className="font-primary mb-0">
                                              <CountUp className="counter" end={120} />
                                          </h4>
                                      </div>
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="card">
                                      <div className="card-body ecommerce-icons text-center">
                                          <TrendingDown />
                                          <div>
                                              <span>Downtime %</span>
                                          </div>
                                          <h4 className="font-primary mb-0">
                                              <CountUp className="counter" end={10} />
                                          </h4>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="card">
                                  <div className="card-header">
                                      <h5>Total Combined Cost Spend</h5>
                                  </div>
                                  <div className="card-body charts-box">
                                      <div className="flot-chart-container">
                                          <div className="flot-chart-placeholder" id="graph123">
                                              <Line data={saleData} options={saleOptions} />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-xl-5 xl-100">
                      <div className="card">
                          <div className="card-body">
                              <div className="table-responsive sellers">
                                  <table className="table table-bordernone">
                                      <thead>
                                          <tr>
                                              <th scope="col">Driller</th>
                                              <th scope="col">Footage</th>
                                              <th scope="col">Shifts</th>
                                              <th scope="col">Job Number</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <td>
                                                  <div className="d-inline-block align-middle">
                                                      <img className="img-radius img-30 align-top m-r-15 rounded-circle" src={six} alt="" />
                                                      <div className="d-inline-block">
                                                          <p>Jesse Shutts</p>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>
                                                  <p>400</p>
                                              </td>
                                              <td>
                                                  <p>10</p>
                                              </td>
                                              <td>
                                                  <p>40001.0306</p>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className="d-inline-block align-middle">
                                                      <img className="img-radius img-30 align-top m-r-15 rounded-circle" src={two} alt="" />
                                                      <div className="d-inline-block">
                                                          <p>Kurt Sparks</p>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>
                                                  <p>50</p>
                                              </td>
                                              <td>
                                                  <p>10</p>
                                              </td>
                                              <td>
                                                  <p>40001.0295</p>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className="d-inline-block align-middle">
                                                      <img className="img-radius img-30 align-top m-r-15 rounded-circle" src={three} alt="" />
                                                      <div className="d-inline-block">
                                                        <p>Ed Burrow</p>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>
                                                  <p>360</p>
                                              </td>
                                              <td>
                                                  <p>12</p>
                                              </td>
                                              <td>
                                                  <p>40001.0301</p>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className="d-inline-block align-middle">
                                                      <img className="img-radius img-30 align-top m-r-15 rounded-circle" src={four} alt="" />
                                                      <div className="d-inline-block">
                                                          <p>Richard Goldie</p>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>
                                                  <p>1260</p>
                                              </td>
                                              <td>
                                                  <p>18</p>
                                              </td>
                                              <td>
                                                  <p>40001.0300</p>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className="d-inline-block align-middle">
                                                      <img className="img-radius img-30 align-top m-r-15 rounded-circle" src={five} alt="" />
                                                      <div className="d-inline-block">
                                                          <p>Steven Greene</p>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>
                                                  <p>1140</p>
                                              </td>
                                              <td>
                                                  <p>19</p>
                                              </td>
                                              <td>
                                                  <p>40001.0307</p>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className="d-inline-block align-middle">
                                                      <img className="img-radius img-30 align-top m-r-15 rounded-circle" src={fifteen} alt="" />
                                                      <div className="d-inline-block">
                                                          <p>Dylan Thompson</p>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>
                                                  <p>910</p>
                                              </td>
                                              <td>
                                                  <p>13</p>
                                              </td>
                                              <td>
                                                  <p>40001.0309</p>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className="d-inline-block align-middle">
                                                      <img className="img-radius img-30 align-top m-r-15 rounded-circle" src={four} alt="" />
                                                      <div className="d-inline-block">
                                                          <p>Tim Johnson</p>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>
                                                  <p>700</p>
                                              </td>
                                              <td>
                                                  <p>14</p>
                                              </td>
                                              <td>
                                                  <p>40001.0315</p>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-xl-3 xl-50 col-sm-6">
                      <div className="card">
                          <div className="card-body">
                              <div className="number-widgets">
                                  <div className="media">
                                      <div className="media-body align-self-center">
                                          <h6 className="mb-0">% To Monthly Target</h6>
                                      </div>
                                      <div className="radial-bar radial-bar-95 radial-bar-primary" data-label="95%"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-xl-3 xl-50 col-sm-6">
                      <div className="card">
                          <div className="card-body">
                              <div className="number-widgets">
                                  <div className="media">
                                      <div className="media-body align-self-center">
                                          <h6 className="mb-0">% Drilling</h6>
                                      </div>
                                      <div className="radial-bar radial-bar-75 radial-bar-primary" data-label="75%"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-xl-3 xl-50 col-sm-6">
                      <div className="card">
                          <div className="card-body">
                              <div className="number-widgets">
                                  <div className="media">
                                      <div className="media-body align-self-center">
                                          <h6 className="mb-0">Uptime YTD</h6>
                                      </div>
                                      <div className="radial-bar radial-bar-90 radial-bar-primary" data-label="90%"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-xl-3 xl-50 col-sm-6">
                      <div className="card">
                          <div className="card-body">
                              <div className="number-widgets">
                                  <div className="media">
                                      <div className="media-body align-self-center">
                                        <h6 className="mb-0">Billable Vs Non-Billable</h6>
                                      </div>
                                      <div className="radial-bar radial-bar-80 radial-bar-primary" data-label="80%"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="card">
                          <div className="card-header">
                              <h5>Total Job Revenue</h5>
                          </div>
                          <div className="card-body chart-block ecommerce-income">
                              <Line data={incomeData} options={incomeOptions}  />
                          </div>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="card">
                          <div className="card-header">
                              <h5>Total Profit</h5>
                          </div>
                          <div className="card-body chart-block ecommerce-income">
                              <Line data={profitData} options={profitOptions}  />
                          </div>
                      </div>
                  </div>
                  <div className="col-xl-4 xl-50 col-md-6">
                      <div className="card">
                          <div className="card-header">
                              <h5>Billable Activities</h5>
                          </div>
                          <div className="card-body height-equal">
                              <div className="progress-block">
                                  <div className="progress-title">
                                      <span>Core Drilling</span>
                                      <span className="pull-right">15/18</span>
                                  </div>
                                  <div className="progress" style={{ height: "3px" }}>
                                      <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{ width: "90%" }}
                                          aria-valuenow="90"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                      />
                                  </div>
                              </div>
                              <div className="progress-block">
                                  <div className="progress-title">
                                      <span>Conditioning</span>
                                      <span className="pull-right">5/6</span>
                                  </div>
                                  <div className="progress" style={{ height: "3px" }}>
                                      <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{ width: "85%" }}
                                          aria-valuenow="85"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                      />
                                  </div>
                              </div>
                              <div className="progress-block">
                                  <div className="progress-title">
                                      <span>Reaming</span>
                                      <span className="pull-right">12/20</span>
                                  </div>
                                  <div className="progress" style={{ height: "3px" }}>
                                      <div
                                          className="progress-bar bg-primary"
                                          role="progressbar" style={{ width: "80%" }}
                                          aria-valuenow="80"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                      />
                                  </div>
                              </div>
                              <div className="progress-block">
                                  <div className="progress-title">
                                      <span>Tripping</span>
                                      <span className="pull-right">5/10</span>
                                  </div>
                                  <div className="progress" style={{ height: "3px" }}>
                                      <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{ width: "95%" }}
                                          aria-valuenow="95"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                      />
                                  </div>
                              </div>
                              <div className="progress-block">
                                  <div className="progress-title">
                                      <span>Moves</span>
                                      <span className="pull-right">15/17</span>
                                  </div>
                                  <div className="progress" style={{ height: "3px" }}>
                                      <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{ width: "75%" }}
                                          aria-valuenow="75"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                      />
                                  </div>
                              </div>
                              <div className="progress-block mb-0">
                                  <div className="progress-title">
                                      <span>Abandonment</span>
                                      <span className="pull-right">2/5</span>
                                  </div>
                                  <div className="progress" style={{ height: "3px" }}>
                                      <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{ width: "95%" }}
                                          aria-valuenow="95"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-xl-4 xl-50 col-md-6">
                      <div className="card">
                          <div className="card-header">
                              <h5>Logs</h5>
                          </div>
                          <div className="card-body height-equal log-content">
                              <div className="logs-element">
                                  <div className="circle-double-odd"/>
                                  <span>New User Registration</span>
                                  <span className="pull-right">14:12</span>
                              </div>
                              <div className="logs-element">
                                  <div className="circle-double-even"/>
                                  <span>New sale: souffle.</span>
                                  <span className="pull-right">19:00</span>
                              </div>
                              <div className="logs-element">
                                  <div className="circle-double-odd"/>
                                  <span>14 products added.</span>
                                <span className="pull-right">05:22</span>
                              </div>
                              <div className="logs-element">
                                  <div className="circle-double-even"/>
                                  <span>New sale: Napole.</span>
                                  <span className="pull-right">08:45</span>
                              </div>
                              <div className="logs-element">
                                  <div className="circle-double-odd"/>
                                  <span>New User Registration</span>
                                  <span className="pull-right">06:51</span>
                              </div>
                              <div className="logs-element">
                                  <div className="circle-double-even"/>
                                  <span>New User Registration</span>
                                  <span className="pull-right">09:42</span>
                              </div>
                              <div className="logs-element">
                                  <div className="circle-double-odd"/>
                                  <span>New User Registration</span>
                                  <span className="pull-right">10:45</span>
                              </div>
                              <div className="logs-element mb-0">
                                  <div className="circle-double-even"/>
                                  <span>New User Registration</span>
                                <span className="pull-right">02:05</span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-xl-4 xl-100">
                      <div className="card">
                          <div className="card-header">
                              <h5>Revenue Per Shift</h5>
                          </div>
                          <div className="card-body updating-chart height-equal">
                              <div className="upadates text-center">
                                  <h2 className="font-primary">
                                      <DollarSign />
                                      <span>
                                          <CountUp className="counter" end={89.65} />89.68
                                      </span>
                                      <ArrowUp />
                                  </h2>
                                  <p>Week2 +
                                      <span>
                                          <CountUp className="counter" end={15.44} />15.44
                                      </span>
                                  </p>
                              </div>
                              <div className="flot-chart-container">
                                  <div className="flot-chart-placeholder" id="updating-data-morris-chart">
                                      <Line data={staticData} options={staticOptions}  />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </Fragment>
    );
}

export default ManagementCore;