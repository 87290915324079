import React from 'react';
import { FaSearch } from 'react-icons/fa';

/**
 * Global search filter
 * @param prop
 * @returns {JSX.Element}
 * @constructor
 */
const TableGlobalFilter = (prop) => {
    const {filter, setFilter, useAsyncDebounce} = prop;
    const [value, setValue] = React.useState(filter)
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined);
    }, 200);

    return (
        <form>
            <div className="s-table-scrollable-global-filter m-t-10">
                <div className="s-icon-container">
                    <FaSearch />
                </div>
                
                <div className="s-divider"></div>

                <input
                    className="form-control-plaintext"
                    size={35}
                    type="text"
                    placeholder="Search in Table"
                    value={value || ""}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                />
            </div>
        </form>
    );
}

export default React.memo(TableGlobalFilter);
