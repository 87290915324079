import React from "react";
import {FaPencilAlt} from "react-icons/fa";

const DisciplineTargetsTable = (setSelectedRow, setEditDisciplineTargetModal) => {
    return [
        {
            Header: 'Financial Period',
            accessor: 'name'
        },
        {
            Header: 'Amount',
            accessor: 'amount'
        },
        {
            Header: 'Discipline',
            accessor: 'discipline_id',
            Cell: (cell) => {
                const row = cell.row.original;

                if (typeof row.discipline_id !== 'undefined') {
                    switch (Number(row.discipline_id)) {
                        case 1:
                            return 'Core Surface';
                        case 2:
                            return 'Core Underground';
                        case 3:
                            return 'RCX';
                        case 4:
                            return 'Rotary';
                        default:
                            return null;
                    }
                } else {
                    return null;
                }
            }
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <FaPencilAlt
                        className="actions"
                        title="Edit Discipline Target"
                        onClick={() => {
                            setSelectedRow(row);
                            setEditDisciplineTargetModal({
                                isOpen: true
                            });
                        }}
                    />
                );
            }
        }
    ];
}

export default DisciplineTargetsTable;
