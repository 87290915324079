import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import InvoiceChargesFilters from "./invoiceChargesFilters";
import InvoiceChargesBody from "./invoiceChargesBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * InvoiceCharges Component
 * @returns {JSX.Element}
 * @constructor
 */
const Invoice_charges = () => {

    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Invoice Charges" parent="Data Entry"/>
            <InvoiceChargesFilters {...{filters, setTableData, setFilters}}/>
            <InvoiceChargesBody {...{filters, tableData, setTableData}} />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default Invoice_charges;
