const primary = "#4466f2";

// ecommerce page
export const saleData = {
    labels: ["2/1", "2/2", "2/3", "2/4", "2/5", "2/6", "2/7"],
    datasets: [
        {
            data: [0, 2.25, 1.25, 3, 1.25, 2.25, 0],
            borderColor: primary,
            pointBackgroundColor: primary,
            backgroundColor: "transparent",
            fill: 'origin',
            tension: 0.4
        }
    ]
};
export const saleOptions = {
    maintainAspectRatio: false,
    height: 45,
    width: 500,
    scales: {
        x: {
            grid: {
                color: "rgba(0, 0, 0, 0)",
            },
            display: true
        }
    },
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            display: false,
        }
    }
}

export const incomeData = {
    labels: ["", "2009", "2010", "2011", "2012", "2013", "2014"],
    datasets: [
        {
            data: [20, 33, 20, 50, 20, 33, 20, 0],
            lineTension: 0.05,
            borderColor: primary,
            backgroundColor: "transparent",
            pointBackgroundColor: primary,
            borderWidth: '2',
            fill: 'origin',
        }
    ]
}

export const incomeOptions = {
    maintainAspectRatio: false,

    scales: {
        x: {
            grid: {
                color: "rgba(0, 0, 0, 0)",
            },
            display: false
        }
    },
    plugins: {
        datalabels: {
            display: false,
        },
        legend: {
            display: false,
        },
    }
}

export const profitData = {
    labels: ["", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016"],
    datasets: [
        {
            data: [5, 0, 5, 0, 15, 0, 5, 0, 5],
            lineTension: 0.05,
            borderColor: primary,
            pointBackgroundColor: primary,
            borderWidth: '2',
            backgroundColor: "transparent",
            fill: 'origin',
        }
    ]
}

export const profitOptions = {
    maintainAspectRatio: false,
    height: 45,
    width: 500,
    scales: {
        x: {
            grid: {
                color: "rgba(0, 0, 0, 0)",
            },
            display: false
        }
    },
    plugins: {
        datalabels: {
            display: false,
        },
        legend: {
            display: false,
        },
    }
}

export const staticData = {
    labels: ["0", "50", "100", "150", "200", "250", "300", "350"],
    datasets: [
        {
            data: [1.000000000, 0.642787610, -0.173648178, -0.866025404, -0.939692621, -0.342020143, 0.500000000, 0.984807753],
            borderColor: primary,
            pointBackgroundColor: primary,
            borderWidth: '2',
            backgroundColor: "transparent",
            fill: 'origin',
        }
    ]
}

export const staticOptions = {
    maintainAspectRatio: false,
    height: 45,
    width: 500,
    scales: {
        x: {
            grid: {
                color: "rgba(0, 0, 0, 0)",
            },
            display: true
        }
    },
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            display: false,
        }
    }
}
