import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import apiClient from "../../../../utils/apiClient";
import {useForm} from "react-hook-form";
import FormInputNumber from "../../../forms/form-control/FormInputNumber";
import FormReadonlyPlainText from "../../../forms/form-control/FormReadonlyPlainText";
import {replaceObjFromArray} from "../../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormInputText from "../../../forms/form-control/FormInputText";

/**
 * Modal for edit record
 * @returns {JSX.Element}
 * @constructor
 */
const EditFormModal = (props) => {
    const {modal, setModal, title, selectedRow, tableData, setTableData} = props;
    const {register, handleSubmit, errors, setValue, getValues} = useForm();
    const toggle = () => setModal(!modal);

    const onSubmit = (data) => {
        apiClient
            .post(`editInvoiceCharge/${selectedRow.id}`, data)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    const calculateTotals = () => {
        const units = Number(getValues(`units`));
        const price = Number(getValues(`price`));
        const total = Number(units * price).toFixed(2);
        setValue(`total`, total);
    }

    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-body" centered={true} size={'lg'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormReadonlyPlainText
                            className="col-md-4"
                            label="Date"
                            value={selectedRow.date}
                        />
                        <FormReadonlyPlainText
                            className="col-md-4"
                            label="Job"
                            value={selectedRow.job_number}
                        />
                        <FormReadonlyPlainText
                            className="col-md-4"
                            label="Created By"
                            value={selectedRow.created_by}
                        />

                    </div>
                    <div className="form-row">
                        <FormReadonlyPlainText
                            className="col-md-12"
                            label="Client"
                            value={selectedRow.client_name}
                        />
                    </div>
                    <div className="form-row m-t-10">
                        <FormInputText
                            id="id"
                            type="hidden"
                            value={selectedRow.id}
                            register={register}
                        />
                        <FormInputText
                            id="description"
                            label="Description"
                            className="col-md-5"
                            register={register({required: "required"})}
                            errors={errors}
                            value={selectedRow.description}
                        />
                        <FormInputNumber
                            id="units"
                            label="Units"
                            className="col-md-3"
                            register={register({required: "required"})}
                            errors={errors}
                            value={selectedRow.units}
                            onChange={() => calculateTotals()}
                        />
                        <FormInputNumber
                            id="price"
                            label="Price"
                            className="col-md-3"
                            register={register({required: "required"})}
                            errors={errors}
                            value={selectedRow.price}
                            onChange={() => calculateTotals()}
                        />
                    </div>
                    <div className="form-row m-t-10">
                        <FormInputNumber
                            id="total"
                            label="Total"
                            className="col-md-3"
                            register={register}
                            errors={errors}
                            disabled='true'
                            value={selectedRow.total}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default React.memo(EditFormModal);
