import {AccordionItem} from "react-light-accordion";
import InputDate from "../../../../forms/form-control/daily/InputDate";
import Select from "../../../../forms/form-control/daily/Select";
import React, {useEffect, useState} from "react";
import apiClient from "../../../../../utils/apiClient";
import Creatable from 'react-select/creatable';
import * as Shared from "../../../../common/shared";
import {get} from "lodash";
import EditSelect from "../../../../forms/form-control/daily/EditSelect";

const DailyGeneralInformation = (props) => {
    const {
        rigs, errors, register, setActivityDescriptions, setDrilledDescriptions, setGeneralDescriptions,
        setConsumableDescriptions, setReamedDescriptions, disciplineId, setHoles, setSelectedJobId, setValue, action,
        daily, rigsAvailable, setSelectedRigName, setSelectedJobNumber, setSelectedLocationName, setJobState
    } = props;
    const [jobs, setJobs] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedShift, setSelectedShift] = useState('');

    useEffect(() => {
        // open all cards
        Shared.toggleAllAccordionItems();

        if (action === 'create') {
            // set default date to today
            document.getElementById('general_info.date').valueAsDate = new Date();
        }

        // when editing daily
        if (action === 'edit') {
            // build holes for creatable select
            if (daily.job_id > 0 && disciplineId > 0) {
                // get holes by job and discipline
                apiClient
                    .post('getHolesByJobAndDisciplineId',
                        {
                            'job_id': daily.job_id,
                            'discipline_id': disciplineId
                        })
                    .then(response => {
                        let holes = response.data.map(({name}) => ({value: name, label: name}));
                        setHoles(holes);
                    });
            }

            if (daily.rig_id > 0) {
                apiClient
                    .post('getActiveJobsByRigId', {'rig_id': daily.rig_id})
                    .then(response => {
                        const jobItems = response.data.jobs.map(
                            ({id, job_number}) => ({value: id, label: job_number})
                        );

                        setJobs(jobItems);
                    });
            }

            if (daily.job_id > 0) {
                apiClient
                    .post('getLocationsByJobId', {'job_id': daily.job_id})
                    .then(response => {
                        const locationItems = response.data.locations.map(
                            ({id, name}) => ({value: id, label: name})
                        );

                        setLocations(locationItems);
                    });
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // while editing field daily
    if (action === 'edit') {
        setValue(`general_info.location_id`, {value: daily.location_id, label: daily.location.name});
    }

    const handleRigSelect = (rigId, rigName) => {
        setSelectedRigName(rigName);
        apiClient
            .post('getActiveJobsByRigId', {'rig_id': rigId})
            .then(response => {
                // assign state id to later define if it is CA
                setJobState(response.data.jobs[0]?.state_id);
                const jobItems = response.data.jobs.map(
                    ({id, job_number}) => ({value: id, label: job_number})
                );

                setJobs(jobItems);
            });
    }

    const handleJobSelect = (jobId, jobName) => {
        if (jobId > 0) {
            setSelectedJobId(jobId);
            setSelectedJobNumber(jobName);

            // Locations
            apiClient
                .post('getLocationsByJobId', {'job_id': jobId})
                .then(response => {
                    const locationItems = response.data.locations.map(
                        ({id, name}) => ({value: id, label: name})
                    );

                    setLocations(locationItems);
                });

            // Activity Descriptions
            apiClient
                .post('getActivityDescriptionsByJobId', {'job_id': jobId})
                .then(response => {
                    const activityItems = response.data.map(
                        ({code, description, revenue_group}) => ({
                                value: code,
                                label: description,
                                revenue_group: revenue_group
                        })
                    );
                    setActivityDescriptions(activityItems);
                });

            // General Descriptions
            apiClient
                .post('getGeneralDescriptionsByJobId', {'job_id': jobId})
                .then(response => {
                    const generalItems = response.data.map(
                        ({code, description}) => ({value: code, label: description})
                    );
                    setGeneralDescriptions(generalItems);
                });

            // Consumable Descriptions
            apiClient
                .post('getConsumableDescriptionsByJobId', {'job_id': jobId})
                .then(response => {
                    const consumableItems = response.data.map(
                        ({code, description}) => ({value: code, label: description})
                    );

                    setConsumableDescriptions(consumableItems);
                });

            // Drilled Descriptions
            apiClient
                .post('getDrilledDescriptionsByJobId', {'job_id': jobId})
                .then(response => {
                    const drilledItems = response.data.map(
                        ({code, description}) => ({value: code, label: description})
                    );
                    setDrilledDescriptions(drilledItems);
                });

            // Reamed Descriptions
            apiClient
                .post('getReamedDescriptionsByJobId', {'job_id': jobId})
                .then(response => {
                    const reamedItems = response.data.map(
                        ({code, description}) => ({value: code, label: description})
                    );
                    setReamedDescriptions(reamedItems);
                });

            if (disciplineId) {
                // get holes by job and discipline
                apiClient
                    .post('getHolesByJobAndDisciplineId',
                    {
                        'job_id': jobId,
                        'discipline_id': disciplineId
                    })
                    .then(response => {
                        let holes = response.data.map(({name}) => ({value: name, label: name}));
                        setHoles(holes);
                    });
            }
        }
    }

    const handleLocationSelect = (v) => {
        setValue(`general_info.location_id`, {value: v.value, label: v.label});
        setSelectedLocationName(v.label);
    }

    return (
            <AccordionItem className="card-header bg-primary" title="General Information">
                <div className="collapse show">
                    <div className="form-row margin">
                        <InputDate
                            label="Date"
                            className="col-md-q mb-3"
                            id="general_info.date"
                            name="general_info.date"
                            value={action === 'edit' ? daily.date : ''}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        {action === 'create' &&
                            <Select
                                label="Shift"
                                id="general_info.daily_shift"
                                name="general_info.daily_shift"
                                className="col-md-q mb-3"
                                options={[{value: "Day", label: "Day"}, {value: "Night", label: "Night"}]}
                                register={register({required: "required"})}
                                errors={errors}
                            />
                        }
                        {action === 'edit' &&
                            <EditSelect
                                label="Shift"
                                id="general_info.daily_shift"
                                name="general_info.daily_shift"
                                className="col-md-q mb-3"
                                value={selectedShift ? selectedShift : daily.shift}
                                onChange={(e) => Shared.setSelectedValue(e.target.value, setSelectedShift)}
                                options={[{value: "Day", label: "Day"}, {value: "Night", label: "Night"}]}
                                register={register({required: "required"})}
                                errors={errors}
                            />
                        }
                        {action === 'create' &&
                            <Select
                                label="Rig"
                                id="general_info.rig_id"
                                name="general_info.rig_id"
                                className="col-md-q mb-3"
                                onChange={
                                    (e) => handleRigSelect(
                                        e.target.value,
                                        e.target.options[e.target.selectedIndex].text
                                    )
                                }
                                options={rigs}
                                register={register({required: "required"})}
                                errors={errors}
                            />
                        }
                        {action === 'edit' &&
                            <EditSelect
                                label="Rig"
                                id="general_info.rig_id"
                                name="general_info.rig_id"
                                className="col-md-q mb-3"
                                value={daily.rig_id}
                                onChange={
                                    (e) => handleRigSelect(
                                        e.target.value,
                                        e.target.options[e.target.selectedIndex].text
                                    )
                                }
                                options={rigsAvailable}
                                register={register({required: "required"})}
                                errors={errors}
                                disabled={true}
                            />
                        }
                        {action === 'create' &&
                            <Select
                                label="Job"
                                id="general_info.job_id"
                                name="general_info.job_id"
                                className="col-md-q mb-3"
                                onChange={
                                    (e) => handleJobSelect(
                                        e.target.value,
                                        e.target.options[e.target.selectedIndex].text
                                    )
                                }
                                options={jobs}
                                register={register({required: "required"})}
                                errors={errors}
                            />
                        }
                        {action === 'edit' &&
                            <EditSelect
                                label="Job"
                                id="general_info.job_id"
                                name="general_info.job_id"
                                className="col-md-q mb-3"
                                value={daily.job_id}
                                onChange={
                                    (e) => handleJobSelect(
                                        e.target.value,
                                        e.target.options[e.target.selectedIndex].text
                                    )
                                }
                                options={jobs}
                                register={register({required: "required"})}
                                errors={errors}
                                disabled={true}
                            />
                        }
                        <div className="col-md-q mb-3">
                            <label htmlFor={`general_info.location_id`}>Location</label>
                            <Creatable
                                id={`general_info.location_id`}
                                name={`general_info.location_id`}
                                styles={Shared.customStylesSmall}
                                onChange={(v) => handleLocationSelect(v)}
                                defaultValue={
                                    (action === 'edit')
                                        ?
                                            {value: daily.location_id, label: daily.location.name}
                                        :
                                            ''
                                }
                                options={locations}
                                register={register(`general_info.location_id`, { required: "required" })}
                                errors={errors}
                            />
                            {errors && <span className="red">{ get(errors, `general_info.location_id`)?.message }</span>}
                        </div>
                    </div>
                </div>
            </AccordionItem>
    );
}

export default React.memo(DailyGeneralInformation);
