import React from "react";
import {FaPencilAlt, FaTrash, FaCheck, FaCheckSquare} from "react-icons/fa";
import {isNull} from "lodash";


const OfficeTimeEntryReviewTable = (
    setFilters, setSelectedRow, setApproveModal, setDisapproveModal, setTimeEntryModal, setDeleteModal,
    showApprovalCheckMark
) => {
    return [
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Date',
            accessor: 'date',
        },
        {
            Header: 'Hours Worked',
            accessor: 'hours',
        },
        {
            Header: 'Reason',
            accessor: 'name',
        },
        {
            Header: 'Status',
            Cell: (cell) => {
                const row = cell.row.original;
                return (row.approved_by !== null) ? 'Approved' : 'Pending';
            }
        },
        {
            Header: 'Approved By',
            Cell: (cell) => {
                const row = cell.row.original;
                let suffix = (typeof row.approver_suffix !== 'undefined') ? row.approver_suffix : '';
                let name = '';
                if (row.approved_by !== null) {
                    name = (row.approver_first_name != null)
                        ?
                            row.approver_first_name + ' ' + row.approver_last_name + ' ' + suffix
                        :
                            '';
                }
                return name;
            }
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                         <FaPencilAlt
                             className="actions"
                             title="Edit Time Entry"
                             size={18}
                             onClick={() => {
                                 setSelectedRow(row);
                                 setTimeEntryModal({
                                     isOpen: true
                                 });
                             }}
                         />
                        {' '}
                        { (isNull(row.approved_by) && showApprovalCheckMark === 1) &&
                            <FaCheck
                                className="actions"
                                size={18}
                                title="Approve Time Entry"
                                onClick={() => {
                                    const name = row.first_name + ' ' + row.last_name;
                                    const msg = <p>Approve time entry for  <b>{name}</b>?</p>;
                                    setSelectedRow(row);
                                    setApproveModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        { (!isNull(row.approved_by) && showApprovalCheckMark === 1) &&
                            <FaCheckSquare
                                className="actions"
                                color='#22af47'
                                size={18}
                                title="Disapprove Time Entry"
                                onClick={() => {
                                    const name = row.first_name + ' ' + row.last_name;
                                    const msg = <p>Disapprove time entry for <b>{name}</b>?</p>;
                                    setSelectedRow(row);
                                    setDisapproveModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        {' '}
                        <FaTrash
                            className="actions"
                            size={18}
                            title="Delete Time Entry"
                            onClick={() => {
                                const name = row.first_name + ' ' + row.last_name;
                                const msg = <p>Are you sure you want to delete Time Entry for <b>{name}</b>?</p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                );
            }
        }
    ];
}

export default OfficeTimeEntryReviewTable;
