import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import TableReport from "../../../common/table/table";
import Table from "./allocateJobsTable";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";
import AllocateJobsCreateModal from "./allocateJobsCreateModal";
import AllocateJobsFilters from "./allocateJobsFilters";
import LoadingIndicator from "../../../common/loadingIndicator";

const AllocateJobs = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });
    const [formModal, setFormModal] = useState({isOpen: false, action: 'create'});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    // delete function
    const deleteJob = () => {
        apiClient
            .post('deleteClientsAllocatedJob', {id: selectedRow.id} )
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    return (
        <>
            <Breadcrumb title="Allocate Jobs" parent="Client Portal Settings"/>
            <AllocateJobsFilters setTableData={setTableData} setFilters={setFilters}/>
            <div className="container-fluid">
                {/*{ tableData.length !==  0 &&*/}
                    <TableReport
                        columns={Table(filters, setSelectedRow, setFormModal, setDeleteModal)}
                        data={tableData}
                        getExportFileName={() => 'client_jobs'}
                        exportOptions={{pdf: false, xlsx: true, csv: true}}
                        withGlobalSearch={true}
                        scrollable={true}
                    />
                {/*}*/}
                <AllocateJobsCreateModal
                    title="Add Job"
                    modal={formModal}
                    filters={filters}
                    setModal={setFormModal}
                    tableData={tableData}
                    setTableData={setTableData}
                />
                <ModalConfirmation
                    modal={deleteModal}
                    setModal={setDeleteModal}
                    title="Delete Job"
                    onConfirm={deleteJob}
                />
                <LoadingIndicator isLoading={filters.isLoading}/>
            </div>
        </>
    );
}

export default AllocateJobs;
