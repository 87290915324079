import React from "react";
import exportToHTML from "../../../utils/exportToHtml";
import {FaCheck, FaCheckSquare, FaFileInvoiceDollar, FaFileInvoice} from "react-icons/fa";
import {isNull} from "lodash";

const ClientInvoicesTable = (setFilters, setSelectedRow, setApproveModal, setDisapproveModal, user) => {
    const CLIENT_GROUP_ID = 5;
    return [
        {
            Header: 'Invoice #',
            accessor: 'invoice_number',
            width: 80,
        },
        {
            Header: 'PO #',
            accessor: 'po_number',
            width: 100,
        },
        {
            Header: 'Status',
            accessor: 'invoice_status',
            width: 55,
        },
        {
            Header: 'Date',
            accessor: 'date',
            width: 85,
        },
        {
            Header: 'Invoice Total',
            accessor: 'revenue',
        },
        {
            Header: 'Job',
            accessor: 'job_number',
            width: 90,
        },
        {
            Header: 'Holes',
            accessor: 'holes',
        },
        {
            Header: 'Rigs',
            accessor: 'rigs',
            width: 80,
        },
        {
            Header: 'Work Period',
            accessor: 'work_period',
        },
        {
            Header: 'Approved By',
            accessor: 'approved',
        },
        {
            Header: 'Approved On',
            accessor: 'client_approved_at',
            width: 100,
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaFileInvoiceDollar
                            title="View Invoice"
                            size='1.1em'
                            color='#A51E37'
                            onClick={
                                () => {
                                    exportToHTML('exportInvoiceById',row.id)
                                }
                            }
                        />
                        {' '}
                        <FaFileInvoice
                            title="View Invoice Details Report"
                            size='1.1em'
                            color='#A51E37'
                            onClick={
                                () => {
                                    exportToHTML('exportInvoiceDetailsById',row.id)
                                }
                            }
                        />
                        {' '}
                        {!isNull(row.client_approved_by) && user?.group_id !== CLIENT_GROUP_ID &&
                            <FaCheckSquare
                                className="actions"
                                title="Disapprove Invoice"
                                color='#22af47'
                                onClick={() => {
                                    const details = row.invoice_number;
                                    const msg = 'Are you sure that you want to disapprove invoice : ' + details;
                                    setSelectedRow(row);
                                    setDisapproveModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        {isNull(row.client_approved_by) &&
                                <FaCheck
                                    className="actions"
                                    title="Approve Invoice"
                                    onClick={() => {
                                        const details = row.invoice_number;
                                        const msg = 'Are you sure that you want to approve invoice : ' + details;
                                        setSelectedRow(row);
                                        setApproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                        }
                    </>
                );
            }
        }
    ];
}

export default ClientInvoicesTable;

