import React from 'react';
import TableReport from "../../../common/table/table";
import MessageEmptyData from "../../../common/messageEmptyData";
import {adpUploadPerDiemTableColumns} from "./adpUploadPerDiemTableColumns";

/**
 * Body report component for report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const AdpUploadPerDiemBody = (prop) => {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={adpUploadPerDiemTableColumns}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                withGlobalSearch={true}
                scrollable={true}
                getExportFileName={() => 'adp_upload_per_diem'}
            />
        </div>
    );
}

export default AdpUploadPerDiemBody;
