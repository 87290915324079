import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormInputText from "../../../forms/form-control/FormInputText";
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import * as ArrayHelpers from "../../../../utils/arrayHelpers";

/**
 * EditDisciplineTargetModal Modal
 * @returns {JSX.Element}
 * @constructor
 */
const EditDisciplineTargetModal = (props) => {
    const {
        modal, setModal, tableData, setTableData, setEditDisciplineTargetModal,
        editDisciplineTargetModal, selectedRow
    } = props;
    const {register, handleSubmit, errors, reset} = useForm();

    const onSave = (data) => {
        if (data !== '') {
            data['id'] = selectedRow.id;
            data['name'] = selectedRow.name;

            apiClient
                .post('editDisciplineTarget', JSON.stringify(data))
                .then(response => {
                    reset();
                    const newTableData = ArrayHelpers.replaceObjFromArray(
                        tableData,
                        "id",
                        response.data.id,
                        response.data.row
                    );
                    setTableData(newTableData);
                    toast.success(response.data.message);
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(function () {
                    //close deactivateModal
                    setEditDisciplineTargetModal({...editDisciplineTargetModal, isOpen: false})
                });
        } else {
            errors.showMessages();
        }
    }

    // toggle for open/close the modal form
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} >
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSave)}
            >
                <ModalHeader toggle={toggle}>Edit Discipline Target</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputText
                            id="amount"
                            label="Amount"
                            type="text"
                            className="col-md-4"
                            value={selectedRow ? selectedRow.amount : ''}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default EditDisciplineTargetModal;
