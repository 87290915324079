import React from "react";
import {FaFileDownload, FaTrash, FaArrowUp, FaArrowDown} from "react-icons/fa";
// import {TbShare, TbShareOff} from "react-icons/tb";

const FileManagerTableColumns = (setSelectedRow, setDeleteModal, setShareModal, onDownload, allowShareFiles, user) => {

    let tableColumns = [];
    const CLIENT_GROUP_ID = 5;

    tableColumns.push(
        {
            Header: 'File Name',
            accessor: 'original_name',
        },
    );

    if(allowShareFiles){

        tableColumns.push(
            {
                Header: 'Shared With Client Portal',
                Cell: (cell) => {
                    const row = cell.row.original;

                    if(row.is_shared){
                        return <div>Yes</div>
                    }
                    return <div>No</div>;
                }
            }
        );

    }

    tableColumns.push(
        {
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;

                return (
                    <>
                        <FaFileDownload color='#A51E37' title="Download" onClick={() => {
                            onDownload(row);
                        }}/>

                        {' '}

                        {
                            (user?.group_id !== CLIENT_GROUP_ID) &&
                                <FaTrash color='#A51E37' title="Remove" onClick={() => {
                                    const msg = <p>Are you sure that you want to remove the file <b>{row.original_name}</b></p>;
                                    setSelectedRow(row);
                                    setDeleteModal({
                                        message: msg,
                                        isOpen: true,
                                    })
                                }}/>
                        }

                        {' '}

                        { allowShareFiles && <>

                            { !row.is_shared

                                ?

                                <FaArrowUp color='#A51E37' title="Share file with client" onClick={() => {
                                    const msg = <p>Are you sure that you want to share the file with client portal <b>{row.original_name}</b></p>;
                                    setSelectedRow(row);
                                    setShareModal({
                                        message: msg,
                                        isOpen: true,
                                    })
                                }}/>

                                :

                                <FaArrowDown color='#A51E37' title="Unshare file with client" onClick={() => {
                                    const msg = <p>Are you sure that you want to unshare the file with client portal <b>{row.original_name}</b></p>;
                                    setSelectedRow(row);
                                    setShareModal({
                                        message: msg,
                                        isOpen: true,
                                    })
                                }}/>

                            }
                        </>}
                    </>
                )
            }
        }
    );

    return tableColumns;

}

export default FileManagerTableColumns;

