import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import NewVendorsBody from "./newVendorsBody";
import FinancialPeriodFilter from "../../../common/form-filters/FinancialPeriodFilter";

/**
 * NewVendors Component
 * @returns {JSX.Element}
 * @constructor
 */
export default function NewVendors () {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        financial_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="New Vendors" parent="Reports"/>
            <FinancialPeriodFilter {...{setFilters, setTableData, OnSubmitUrl:"getNewVendors"}}/>
            <NewVendorsBody {...{filters, tableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}