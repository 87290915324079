import React from "react";
import {FaPencilAlt, FaTrash} from "react-icons/fa";

const ShopTimeEntryReviewTable = (
    setFilters, setSelectedRow, setApproveModal, setDisapproveModal, setTimeEntryModal, setDeleteModal,
    // showApprovalCheckMark
) => {

    return [
        {
            Header: 'Last Name',
            accessor: 'last_name',
            width: 110,
            Cell: (cell) => {
                const row = cell.row.original;
                let result = '';
                result =
                    (
                        <a
                            className="actions"
                            rel="noopener noreferrer"
                            target="_blank"
                            href="#/"
                            onClick={() => {
                                setSelectedRow(row);
                                setTimeEntryModal({
                                    isOpen: true
                                });
                            }}
                        >
                            <b>{cell.value}</b>
                        </a>
                    );
                return result;
            }
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
            width: 100,
        },
        {
            Header: 'Date',
            accessor: 'date',
            width: 90,
        },
        {
            Header: 'Hours',
            accessor: 'hours',
            width: 60,
        },
        {
            Header: 'Per Diem',
            accessor: 'per_diem',
            width: 80,
        },
        {
            Header: 'Reason',
            accessor: 'ec_name',
            width: 110,
        },
        {
            Header: 'Cost Code',
            accessor: 'cost_codes_description',
        },
        {
            Header: 'Job',
            accessor: 'job_number',
            width: 100,
        },
        {
            Header: 'Equipment',
            Cell: (cell) => {
                const row = cell.row.original;
                if (row.equipment_id) {
                    return row.equipment_number + ': ' + row.equipment_make + ' - ' + row.equipment_model +
                        ' - ' + row.equipment_description
                }
                return '';
            }
        },
        {
            Header: 'Status',
            width: 80,
            Cell: (cell) => {
                const row = cell.row.original;
                return (row.approved_by != null) ? 'Approved' :  'Pending';
            }
        },
        {
            Header: 'Approved By',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                let name = '';
                if (row.approved_by != null) {
                    name = row.approver_first_name.substring(0,1) + ' ' + row.approver_last_name + ' ' + row.approver_suffix;
                }
                return name;
            }
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                         <FaPencilAlt
                             className="actions"
                             size={18}
                             title="Edit Time Entry"
                             onClick={() => {
                                 setSelectedRow(row);
                                 setTimeEntryModal({
                                     isOpen: true
                                 });
                             }}
                         />
                        {' '}
                        {/*Disabled per manager's request do not delete */}
                        {/*{ isNull(row.approved_by) && showApprovalCheckMark === 1 &&*/}
                        {/*    <FaCheck*/}
                        {/*        className="actions"*/}
                        {/*        size={18}*/}
                        {/*        title="Approve Time Entry"*/}
                        {/*        onClick={() => {*/}
                        {/*            const name = row.first_name + ' ' + row.last_name;*/}
                        {/*            const msg = <p>Approve time entry for  <b>{name}</b>?</p>;*/}
                        {/*            setSelectedRow(row);*/}
                        {/*            setApproveModal({*/}
                        {/*                message: msg,*/}
                        {/*                isOpen: true,*/}
                        {/*            });*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*}*/}
                        {/*{ !isNull(row.approved_by) && showApprovalCheckMark === 1 &&*/}
                        {/*    <FaCheckSquare*/}
                        {/*        className="actions"*/}
                        {/*        size={18}*/}
                        {/*        color='#22af47'*/}
                        {/*        title="Disapprove Time Entry"*/}
                        {/*        onClick={() => {*/}
                        {/*            const name = row.first_name + ' ' + row.last_name;*/}
                        {/*            const msg = <p>Disapprove time entry for <b>{name}</b>?</p>;*/}
                        {/*            setSelectedRow(row);*/}
                        {/*            setDisapproveModal({*/}
                        {/*                message: msg,*/}
                        {/*                isOpen: true,*/}
                        {/*            });*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*}*/}
                        {' '}
                        <FaTrash
                            className="actions"
                            title="Delete Time Entry"
                            size={18}
                            onClick={() => {
                                const name = row.first_name + ' ' + row.last_name;
                                const msg = <p>Are you sure you want to delete Time Entry for <b>{name}</b>?</p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                );
            }
        }
    ];
}

export default ShopTimeEntryReviewTable;
