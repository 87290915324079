import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import {useFieldArray} from "react-hook-form";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import * as Shared from "../../../../common/shared";

const Consumables = (props) => {
    const { errors, register, control, collectedHoles, consumableDescriptions, fieldDaily, action, setValue } = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "consumables"
    });

    const [selectedConsumableHole, setSelectedConsumableHole] = useState('');

    useEffect(() => {
        if (action === 'edit') {
            append(fieldDaily.field_consumables);

            setTimeout(() => {
                fieldDaily.field_consumables.forEach((item, index) => {
                    // populate select
                    setValue(
                        `consumables[${index}].cost_code`,
                        {value: item.cost_code, label: item.description}
                    );
                });
            }, 500);
        }
    }, [fieldDaily, append, action, setValue]);

    return (
            <AccordionItem className="card-header bg-primary" title="Consumables">
                <div className="card">
                    <div className="form-row">
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Notes</th>
                                            <th>Qty</th>
                                            <th>Billable</th>
                                            <th>Supplied By</th>
                                            <th>Hole</th>
                                            <th className="action-cell">
                                                <FaPlus
                                                    color='#22af47'
                                                    size={20}
                                                    title="Add New"
                                                    onClick={() => append({})}
                                                />
                                                <FaEraser
                                                    color='#A51E37'
                                                    className="marginLeft"
                                                    size={20}
                                                    title="Remove All"
                                                    onClick={() => remove()}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map((item, index) => (
                                            <tr key={item.id}>
                                                <SearchableSelectInTable
                                                    id={`consumables[${index}].cost_code`}
                                                    options={consumableDescriptions}
                                                    rules={{ required: "required" }}
                                                    control={control}
                                                    errors={errors}
                                                    value={(action === 'edit') ? item.cost_code : ''}
                                                    customStyles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            width: 500,
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            width: 500,
                                                            position: 'sticky',
                                                        })
                                                    }}
                                                />
                                                <InputInTable
                                                    className="notes-column-width"
                                                    id={`consumables[${index}].notes`}
                                                    name={`consumables[${index}].notes`}
                                                    value={(action === 'edit') ? item.notes : ''}
                                                    register={register({required: false})}
                                                    errors={errors}
                                                />
                                                <InputInTable
                                                    className="hours-column-width"
                                                    id={`consumables[${index}].qty`}
                                                    name={`consumables[${index}].qty`}
                                                    type="number"
                                                    value={(action === 'edit') ? item.qty : ''}
                                                    onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                <SelectInTable
                                                    id={`consumables[${index}].billable`}
                                                    name={`consumables[${index}].billable`}
                                                    value={(action === 'edit') ? item.billable : ''}
                                                    options={[{value: "1", label: "Yes"}, {value: "0", label: "No"}]}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                <SelectInTable
                                                    id={`consumables[${index}].supplied_by`}
                                                    name={`consumables[${index}].supplied_by`}
                                                    value={(action === 'edit') ? item.supplied_by : ''}
                                                    options={[{value: "1", label: "Client"}, {value: "2", label: "NEWP"}]}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                {action === 'create' &&
                                                    <SelectInTable
                                                        id={`consumables[${index}].hole_name`}
                                                        name={`consumables[${index}].hole_name`}
                                                        options={collectedHoles}
                                                        value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                }
                                                {action === 'edit' &&
                                                    <SelectInTable
                                                        id={`consumables[${index}].hole_name`}
                                                        name={`consumables[${index}].hole_name`}
                                                        value={
                                                            selectedConsumableHole
                                                                ?
                                                                selectedConsumableHole
                                                                :
                                                                (collectedHoles.length === 1)
                                                                    ?
                                                                    collectedHoles[0].value
                                                                    :
                                                                    item.hole_name
                                                        }
                                                        onChange={
                                                            (e) => Shared.setSelectedValue(e.target.value, setSelectedConsumableHole)
                                                        }
                                                        options={collectedHoles}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                }
                                                <td>
                                                    <FaTrash
                                                        color='#A51E37'
                                                        size={20}
                                                        title="Remove"
                                                        onClick={() => remove(index)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </AccordionItem>
    );
}

export default Consumables;
