import React from 'react';
import TableReport from "../../../common/table/table";
import {tableColumns} from "./tableColumns.js";
import MessageEmptyData from "../../../common/messageEmptyData";

/**
 * Body report component for for Rec Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ReportBody = (prop) => {
    const {reportFilters, tableData} = prop;
    const tableExportOptions = {pdf: true, xlsx: true, csv: false};

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={tableColumns}
                data={tableData}
                exportOptions={tableExportOptions}
                getExportFileName={() => 'rec_report'}
            />
        </div>
    );
};

export default ReportBody;
