import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import { useAuthenticate } from '../../../../hooks/auth';

function validateDateField(value, getValues) {
    const financialPeriodId = getValues('financial_period_id');

    if (
        (financialPeriodId == null || financialPeriodId === '') &&
        (value == null || value === '')
    ) {
        return 'required';
    }

    return true;
}

/**
 * Filter component for DirectEntry Report
 * @returns {JSX.Element}
 * @constructor
 */
const FieldDailiesFilters = (prop) => {
    const {register, handleSubmit, errors, getValues, trigger, setValue} = useForm();
    const {filters, updateReportFilters, getFieldDailiesData} = prop;
    const [disciplines, setDisciplines] = useState([]);
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [rigs, setRigs] = useState([]);
    const { user } = useAuthenticate();
    const TOOL_PUSHER = 11;
    const DRILLER_ASSISTANT = 9;
    const DRILLER = 7;

    // filter selection handler
    const filterSelectionHandler = (e) => {
        const {id, value} = e.target;
        updateReportFilters(id, value);
    }

    // discipline selection handler
    const disciplineSelectionHandler = e => {
        const {id, value} = e.target;
        updateReportFilters(id, value);

        // get Rigs by Discipline Id
        apiClient
            .post('getAllActiveRigsByDisciplineId', {'discipline_id': value})
            .then(response => {
                const rigItems = response.data.rigs.map(
                    ({id, name}) => ({id: id, name: name})
                );
                setRigs(rigItems);
            });
    }

    const financialPeriodSelectionHandler = (e) => {
        setValue('start_date', null);
        updateReportFilters('start_date', null);
        setValue('end_date', null);
        updateReportFilters('end_date', null);
        trigger(["start_date", 'end_date']);
        filterSelectionHandler(e);
    }

    const dateFieldSelectionHandler = (e) => {
        setValue('financial_period_id', null);
        updateReportFilters('financial_period_id', null);
        trigger('financial_period_id');
        filterSelectionHandler(e);
    }

    const onSubmit = () => {
        getFieldDailiesData();
    }

    // get filters data
    useEffect(() => {
        // assign discipline automatically
        if (user?.group_id === TOOL_PUSHER || user?.group_id === DRILLER || user?.group_id === DRILLER_ASSISTANT) {
            updateReportFilters('discipline_id', user?.discipline_id);

            // get Rigs by Discipline Id
            apiClient
                .post('getAllActiveRigsByDisciplineId', {'discipline_id': user?.discipline_id})
                .then(response => {
                    const rigItems = response.data.rigs.map(
                        ({id, name}) => ({id: id, name: name})
                    );
                    setRigs(rigItems);
                });
        }

        //get Disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(
                    response.data.disciplines.map(
                        ({id, name}) => ({id: id, name: name})
                    )
                );
            });

        //get Financial Periods
        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => {
                            return {
                                id: id, name: name + (is_current ? ' (Current)' : '')
                            }
                        }
                    )
                );
            });

        // preselect Status select for better UX
        setValue('status', '1');
        updateReportFilters('status', 1);
    }, [user, updateReportFilters, setValue]);
    
    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    id="status"
                    label="Status"
                    className="col-md-q"
                    // do not delete
                    // className="col-xs-12 col-sm-4 col-md-2 col-md-q"
                    value={filters.status}
                    onChange={filterSelectionHandler}
                    options={[{value: 1, label: "Active"}, {value: 0, label: "Inactive"}]}
                    register={register({required: {value: true, message: "required"}})}
                    errors={errors}
                />
                {
                    (user?.group_id !== TOOL_PUSHER && user?.group_id !== DRILLER && user?.group_id !== DRILLER_ASSISTANT) &&
                    <FormSelect
                        id="discipline_id"
                        label="Discipline"
                        className="col-md-q"
                        // do not delete
                        // className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        value={filters.discipline_id}
                        onChange={disciplineSelectionHandler}
                        options={disciplines}
                        register={register({required: {value: true, message: "required"}})}
                        errors={errors}
                    />
                }
                <FormSelect
                    id="rig_id"
                    label="Rig"
                    className="col-xs-12 col-sm-6 col-md-2 col-lg-2"
                    value={filters.rig_id}
                    onChange={filterSelectionHandler}
                    options={rigs}
                    register={register({required: {value: true, message: "required"}})}
                    errors={errors}
                />
                <FormSelect
                    id="financial_period_id"
                    label="Financial Period"
                    className="col-xs-12 col-sm-6 col-md-4 col-lg-2"
                    value={filters.financial_period_id}
                    onChange={financialPeriodSelectionHandler}
                    options={financialPeriods}
                    register={register({required: {value: false, message: "required"}})}
                    errors={errors}
                />
                <FormInputDate
                    id="start_date"
                    label="Start Date"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.start_date}
                    onChange={dateFieldSelectionHandler}
                    register={register({validate: (value) => validateDateField(value, getValues)})}
                    errors={errors}
                />
                <FormInputDate
                    id="end_date"
                    label="End Date"
                    className="col-md-q"
                    // className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.end_date}
                    onChange={dateFieldSelectionHandler}
                    register={register({validate: (value) => validateDateField(value, getValues)})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-md-q"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default FieldDailiesFilters;
