import React from 'react';
import {get, isNull, isUndefined} from 'lodash';

/**
 * EditSelect
 * options accept array of object with below parameters
 *  ex:
 *   - [{id: "", name: "" },...],
 *   - [{value: "", label:""},...],
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EditSelect = (props) => {

    const {id, label, name, value, options, className, onChange, errors, register, disabled} = props;

    return (
        <div className={className}>
            {label && <label htmlFor={id}>{label}</label>}
            <select
                id={id}
                name={name}
                className="form-control"
                value={(isUndefined(value) || isNull(value)) ? '' : value}
                onChange={onChange}
                ref={register}
                disabled={disabled}
            >
                <option value=''>- Select -</option>
                {options.map((item, i) =>
                    <option
                        key={i}
                        value={isUndefined(item.id) ? item.value : item.id}
                    >
                        {isUndefined(item.name) ? item.label : item.name}
                    </option>
                )}
            </select>
            {errors && <span>{get(errors, id)?.message}</span>}
        </div>
    );
}

export default React.memo(EditSelect);
