import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import {updateStateValue} from "../../../utils/stateHelpers";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {isCurrentYear} from "../../../utils/dateHelpers";

/**
 * Form Filters Component
 * @returns {JSX.Element}
 * @constructor
 */
export default function FinancialPeriodsFormFilters (prop) {
    const {filters, setFilters, setTableData} = prop;
    const {register, handleSubmit, errors} = useForm();
    const [years, setYears] = useState([]);

    useEffect(() => {
        apiClient
            .get('getFinancialPeriodsYears')
            .then(response => {
                setYears(
                    Object.values(response.data).map((id) => ({value: id, label: id + (isCurrentYear(id) ? " (Current)" : "")}))
                );
            })
    }, []);

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .get('financialPeriods', {params: data})
            .then(response => {
                setTableData(response.data.periods);
            })
            .catch()
            .then(function () {
                updateStateValue(setFilters,'isLoading', false);
            });
    }

    return (
        <form
            className="needs-validation"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row mb-4">
                <FormSelect
                    id="year"
                    label="Year"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.year}
                    onChange={(e) => {updateStateValue(setFilters, e.target.id, e.target.value)}}
                    options={years}
                    register={register({required: "required"})}
                    isControlled={true}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}
