import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import { updateStateValue } from "../../../../utils/stateHelpers";
import { isEmpty } from "lodash";
import moment from "moment";

/**
 * Filter component for ShiftReport Report
 * @returns {JSX.Element}
 * @constructor
 */
const ShiftReportFilters = (prop) => {
    const { setFilters, setTableData } = prop;
    const { register, handleSubmit, setValue, errors } = useForm();
    const [payPeriod, setPayPeriod] = useState({});
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [payPeriods, setPayPeriods] = useState([]);
    const [endDate, setEndDate] = useState(0);

    const onSubmit = (data) => {
        updateStateValue(setFilters, "isLoading", true);
        updateStateValue(setFilters, "isSubmitted", true);

        apiClient
            .post("getShiftReportData", data)
            .then((response) => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .then(function () {
                updateStateValue(setFilters, "isLoading", false);
            });
    }

    // get list of financial periods and pay peridos
    useEffect(() => {
        // get current pay period
        apiClient
            .get("getCurrentPayPeriod")
            .then((response) => {
                setPayPeriod(response.data);
                let year = Number(moment(response.data.end_date).format('YYYY'));
                setEndDate(year);
            });

        apiClient
            .get("getFinancialPeriods")
            .then((response) => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => ({
                            id: id, name: name + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });

        apiClient
            .post("getPayPeriodsByYear", {year: endDate})
            .then((response) => {
                setPayPeriods(
                    response.data.map(
                        ({id, start_date, end_date, is_current}) => ({
                            id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });
    }, [setFinancialPeriods, setPayPeriods, endDate]);

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            updateStateValue(setFilters, "pay_period_id", payPeriod.id);
            setValue("pay_period_id", payPeriod.id);
        }, 200);
    }, [payPeriod, setFilters, setValue]);

    return (
        <form className="needs-validation container-fluid" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row s-gy">
                <FormSelect
                    id="pay_period_id"
                    label="Weekly Data Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={payPeriods}
                    register={register}
                    errors={errors}
                    onChange={() => {
                        setValue("financial_period_id", "");
                    }}
                />
                <FormSelect
                    id="financial_period_id"
                    label="Monthly Data Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={financialPeriods}
                    register={register}
                    onChange={() => {
                        setValue("pay_period_id", "");
                    }}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default ShiftReportFilters;
