import React from 'react';
import TableReport from "../../../common/table/table";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from "lodash"
import {tableColumns} from "./tableColumns";

/**
 * Body report component for active jobs report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ReportBody = (prop) => {
    const {tableData} = prop;

    if (isEmpty(tableData)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={tableColumns}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'active_jobs_report'}
                withGlobalSearch={true}
                scrollable={true}
            />
        </div>
    );
};

export default ReportBody;
