import React from 'react';
import {isEmpty} from "lodash"

/**
 * Display Approved/Unapproved alert for Employees Review report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const employeesReviewBodyLabelTable = (prop) => {
    const {reportLabel} = prop;

    if (isEmpty(reportLabel)) {
        return null;
    }

    let alertStyle = 'alert-success';
    let alertLabel = 'Approved By:';

    if (reportLabel.type === 'unapproved') {
        alertStyle = 'alert-dark';
        alertLabel = 'Unapproved By:';
    }

    return (
        <>
            <div className={`alert ${alertStyle}`}>
                <b>{alertLabel}</b> {reportLabel.by} at {reportLabel.at}
            </div>
        </>
    );
}

export default employeesReviewBodyLabelTable;
