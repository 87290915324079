import React from "react";
import Table from "../../../common/table/table";
import MessageEmptyData from "../../../common/messageEmptyData";
import { ShiftReportHoursStyle } from "./shiftReportHoursStyle";
import shiftReportTableColumns from './shiftReportTableColumns';
import {isEmpty} from "lodash";

/**
 * Body component for ShiftReport Report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ShiftReportBody = (prop) => {
    const { filters, tableData } = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData />;
    }

    return (
        <div className="container-fluid">
            {tableData.map((item) => {
                return (
                    <React.Fragment key={item.rig+'_'+ item.shift}>
                        <div className="row">
                            <h4>{item.rig + (isEmpty(item.shift) ? '' : '-') + item.shift}</h4>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <ShiftReportHoursStyle>
                                    <Table
                                        columns={shiftReportTableColumns(item)}
                                        data={item.table_body}
                                        exportOptions={{
                                            pdf: false,
                                            xlsx: true,
                                            csv: false,
                                        }}
                                        getExportFileName={() => "shift_entry_time"}
                                    />
                                </ShiftReportHoursStyle>
                            </div>
                        </div>
                    </React.Fragment>        
                )
            })}
        </div>
    );
}

export default ShiftReportBody;
