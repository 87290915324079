import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormInputDate from "../../forms/form-control/FormInputDate";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../utils/stateHelpers";

/**
 * Filter component for report
 * @returns {JSX.Element}
 * @constructor
 */
const ProcessInvoicesFilters = (prop) => {
    const {register, handleSubmit, errors} = useForm();
    const {reportFilters, setReportFilters, setTableData} = prop;
    const [disciplines, setDisciplines] = useState([]);
    const [clients, setClients] = useState([]);
    const [jobs, setJobs] = useState([]);

    const onSubmit = () => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);
        apiClient
            .post('getInvoicesForProcess', reportFilters)
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
                updateStateValue(setReportFilters, 'isLoading', false);
        });
    };

    // get list of disciplines and clients
    useEffect(() => {
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });
    }, []);

    // load clients
    useEffect(() => {
        if (reportFilters.discipline_id) {
            // active clients
            apiClient
                .post('getActiveClientsByDiscipline', {'discipline_id': reportFilters.discipline_id})
                .then(response => {
                    setClients(
                        response.data.map(
                            ({id, client_name}) => ({id: id, name: client_name})
                        )
                    );
                });
        } else {
            setClients([]);
        }
    }, [reportFilters.discipline_id]);

    // load jobs
    useEffect(() => {
        if (reportFilters.client_id) {
            // active clients
            apiClient
                .post('getActiveJobsByDisciplineAndClient', {
                    'discipline_id': reportFilters.discipline_id,
                    'client_id': reportFilters.client_id
                })
                .then(response => {
                    setJobs(
                        response.data.map(
                            ({id, job_number}) => ({id: id, name: job_number})
                        )
                    );
                });
            updateStateValue(setReportFilters, 'job_id', '');
        } else {
            setJobs([]);
        }
    }, [reportFilters.discipline_id, reportFilters.client_id, setReportFilters]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={reportFilters.discipline_id}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    options={disciplines}
                    register={register}
                    isControlled={true}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={reportFilters.date_from}
                    register={register({required: "required"})}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    errors={errors}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={reportFilters.date_to}
                    register={register({required: "required"})}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    errors={errors}
                />
                <FormSelect
                    id="client_id"
                    label="Client"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={reportFilters.client_id}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    options={clients}
                />
                <FormSelect
                    id="job_id"
                    label="Job"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={reportFilters.job_id}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    options={jobs}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
};

export default ProcessInvoicesFilters;
