import React from 'react';
import {get} from "lodash";

/**
 * Form input for numbers
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormInputNumber = (props) => {
    const {id, label, value, className, onChange, errors, onInput, register, disabled = 'false'} = props;

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <input
                id={id}
                name={id}
                className="form-control"
                type="number"
                readOnly={disabled === 'true' ? true : ''}
                defaultValue={value || ''}
                onChange={onChange}
                onInput={onInput}
                ref={register}
                step="any"
            />
            {errors && <span>{ get(errors, id)?.message }</span>}
        </div>
    );
};

export default React.memo(FormInputNumber);
