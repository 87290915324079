import React from "react";
import axios from "axios";
import {toast} from "react-toastify";

// Set config defaults when creating the instance
const ApiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

// Alter defaults after instance has been created
ApiClient.defaults.headers.common['Accept'] = 'application/json';
ApiClient.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// Add token (by a request interceptor)
ApiClient.interceptors.request.use(function (request) {    
    const token = localStorage.getItem("token");
    if (!!token) {
        request.headers.Authorization = `Bearer ${token}` 
    } 
    return request;
});

// Error handler (by a response interceptor)
ApiClient.interceptors.response.use(function (response) {
    // Do nothing with response data
    return response;
}, function (error) {
    // Do something with response error
    // For now just print in console
    // TODO: Add better error handler
    console.log('[ApiClient] Error handler');
    if (error.response) {
        // The request was made and the server responded with a status code
        console.log(error.response.data);
        console.log(error.response.headers);
        console.log('status code: ' + error.response.status);
        notifyError(error.response.data);
    } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    return Promise.reject(error);
});

/**
 * Display warning message with error response data
 * @param data
 */
const notifyError = (data) => {
    toast.warn(getErrorMessageContent(data), {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};

/**
 * parse and build error message content
 * @param errorData
 * @returns {JSX.Element}
 */
const getErrorMessageContent = (errorData) => {
    let errorList = [];

    //Check if error response include list of errors
    if (errorData.error) {
        errorList = Object.values(errorData.error).flat();
    }

    return (
        <div>
            <span className="font-weight-bold">{errorData.message}</span>
            <ul>
                {errorList.map((item, index) =>
                    <li key={index} >- {item}</li>
                )}
            </ul>
        </div>
    );
}

export default ApiClient;
