import React from "react";

const Footer = props => {
    return (
        <footer className="footer d-print-none">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 footer-copyright">
                        <p className="mb-0 text-right">Copyright {(new Date().getFullYear())} © National EWP All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
