import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import ClientInvoicesFilters from "./clientInvoicesFilters";
import ClientInvoicesBody from "./clientInvoicesBody";
import LoadingIndicator from "../../common/loadingIndicator";
import {useAuthenticate} from "../../../hooks/auth";
import moment from "moment";

/**
 * Client Invoice Component
 * @returns {JSX.Element}
 * @constructor
 */
const ClientInvoices = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [filters, setFilters] = useState({
        date_from: moment().startOf('month').format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        isLoading: false,
        isSubmitted: false,
    });
    const { user } = useAuthenticate();

    return (
        <>
            <Breadcrumb title="Invoices" parent="Client Portal"/>
            <ClientInvoicesFilters
                filters={filters}
                setTableData={setTableData}
                setFilters={setFilters}
            />
            <ClientInvoicesBody
                filters={filters}
                setFilters={setFilters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                user={user}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default ClientInvoices;
