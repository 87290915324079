import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import ClientJobsBody from "./clientJobsBody";
import ClientFormFilter from "../../common/form-filters/ClientFormFilter";
import LoadingIndicator from "../../common/loadingIndicator";
import FileManager from "../../common/file_manager/fileManager";

/**
 * Client Jobs Component
 * @returns {JSX.Element}
 * @constructor
 */
const ClientJobs = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedFilesRow, setSelectedFilesRow] = useState({});
    const [formModalFiles, setFormModalFiles] = useState({isOpen: false});
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Jobs" parent="Client Portal" />
            <ClientFormFilter {...{setFilters, setTableData, onSubmitUrl:"getClientJobs"}}/>
            <ClientJobsBody {...{filters, tableData, setSelectedFilesRow, setFormModalFiles}} />
            <FileManager
                fileableId={selectedFilesRow.id}
                fileableType ="App\Models\Jobs"
                setFileableId={setSelectedFilesRow}
                modalTitle="Job Files"
                modal={formModalFiles}
                setModal={setFormModalFiles}
                allowUpload={false}
                allowShareFiles={false}
            />
            <LoadingIndicator isLoading={filters.isLoading} />
        </>
    );
}

export default ClientJobs;