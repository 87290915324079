export const tableColumns = [
    {
        Header: 'Driller',
        accessor: 'driller_full_name',
        width: 240,
    },
    {
        Header: 'Client',
        accessor: 'client_name',
    },
    {
        Header: 'State',
        accessor: 'state',
        width: 60,
    },
    {
        Header: 'Rig',
        accessor: 'rig',
        width: 90,
    },
    {
        Header: 'Rig Number',
        accessor: 'rig_number',
        width: 60,
    },
    {
        Header: 'Shift',
        accessor: 'shift',
        width: 60,
    },
    {
        Header: 'Footage',
        accessor: 'footage',
        width: 70,
    },
    {
        Header: 'Shift Hours',
        accessor: 'shift_hours',
        width: 60,
    },
    {
        Header: 'Shifts',
        accessor: 'shifts',
        width: 60,
    },
    {
        Header: 'Footage Per Shift Hour',
        accessor: 'footage_per_shift_hour',
        width: 120,
    },
];
