import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useForm} from "react-hook-form";
import FormInputText from "../../../forms/form-control/FormInputText";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";

/**
 * Modal for edit record
 * @returns {JSX.Element}
 * @constructor
 */
const EditFieldDailyHolesModal = (props) => {
    const {modal, setModal, title, selectedRow} = props;
    const {register, handleSubmit, errors} = useForm();
    const toggle = () => setModal(!modal);

    const onSubmit = (data) => {
        console.log(selectedRow);
        console.log(data);
        apiClient
            .post('updateFieldDailyHoleByFieldDailyId', {
                'field_daily_id': selectedRow.field_daily_id,
                'new_hole_name': data.hole,
                'old_hole_name': selectedRow.name
            })
            .then(response => {
                console.log(response);
                // setTableData(
                //     replaceObjFromArray(
                //         tableData, "direct_entry_id", response.data.id, response.data.record
                //     )
                // );
                toast.success(response.data.message);
                toggle();
            });
    }

    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-body" centered={true} size={'sm'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputText
                            id="hole"
                            label="Hole"
                            className="col-md-10 mb-3"
                            value={selectedRow.name}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default React.memo(EditFieldDailyHolesModal);
