import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import apiClient from "../../../utils/apiClient";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FormInputText from "../../forms/form-control/FormInputText";
import FormInputNumber from "../../forms/form-control/FormInputNumber";
import FormInputFile from "../../forms/form-control/FormInputFile";
import FormInputTextArea from "../../forms/form-control/FormInputTextArea";
import {updateStateValue} from "../../../utils/stateHelpers";
import {removeObjFromArray, replaceObjFromArray} from "../../../utils/arrayHelpers";

/**
 * Modal form to edit a record
 * @returns {JSX.Element}
 * @constructor
 */
const ConsumableRegisterFormModal = (props) => {
    const {modal, setModal, title, selectedRow, tableData, setTableData, filters, setFilters, rigs, setRigs} = props;
    const {register, handleSubmit, errors} = useForm();
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    const calculateMarkupMath = (e) => {
        e.preventDefault();

        let price = (document.getElementById('price') !== null)
            ?
                document.getElementById('price').value
            :
                0;

        let charged = (document.getElementById('charged') !== null)
            ?
                document.getElementById('charged').value
            :
                0;

        let markup = (document.getElementById('markup') !== null)
            ?
                document.getElementById('markup').value
            :
                0;

        let newMarkupPrice = (charged * price * markup / 100);
        let newTotal = (charged * price) + newMarkupPrice;

        if (document.getElementById('disabled_markup_price') !== null) {
            document.getElementById('disabled_markup_price').value = Number.parseFloat(newMarkupPrice.toString()).toFixed(2);
        }

        if (document.getElementById('markup_price') !== null) {
            document.getElementById('markup_price').value = Number.parseFloat(newMarkupPrice.toString()).toFixed(2);
        }

        if (document.getElementById('total') !== null) {
            document.getElementById('total').value = Number.parseFloat(newTotal.toString()).toFixed(2);
        }

        if (document.getElementById('disabled_total') !== null) {
            document.getElementById('disabled_total').value = Number.parseFloat(newTotal.toString()).toFixed(2);
        }
    }

    // update count in rig array state variable
    const updateCountRigDropdown = (response, type ) => {
        // remove row from table
        setTableData(
            removeObjFromArray (
                tableData, "id", response.data.id,
            )
        );

        // get selected rig object
        let rigObj = rigs.find( obj => Number(obj.value) === Number(filters.rig_id) );

        // update rig object label
        rigObj.label = rigObj.rig_name + " - "+ type +"(" + ( Number(rigObj.count) - 1 ).toString() + ")";

        // update list of rigs
        setRigs(
            replaceObjFromArray(
                rigs, "value", filters.rig_id, rigObj
            )
        );
    }

    const onSubmit = (data) => {
        const formData = new FormData();
        for (const key in data) {
            switch (key) {
                case 'consumable_file':
                    for (const file of data[key]) {
                        formData.append('consumable_file[]', file, file.name);
                    }
                    break;
                default:
                    formData.append(key, data[key]);
            }
        }

        updateStateValue(setFilters, 'isLoading', true);

        apiClient
            .post('editConsumable', formData)
            .then(response => {
                // update select row
                let newRow = {...selectedRow};
                newRow.charged = response.data.record.charged;
                newRow.price = response.data.record.price;
                newRow.markup = response.data.record.markup;
                newRow.description = response.data.record.description;
                newRow.total = response.data.record.total;
                newRow.markup_price = response.data.record.markup_price;
                newRow.units = response.data.record.units;

                // update table row
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, newRow
                    )
                );

                if ( filters.consumable_type === "allocated" && newRow.price === "0" ) {
                    updateCountRigDropdown(response, "Allocated");
                }

                if ( filters.consumable_type === "unallocated" && newRow.price > 0 ) {
                    updateCountRigDropdown(response, "Unallocated");
                }

                // display message and close the modal
                toast.success(response.data.message);
                toggle();
            }).catch(function () {
            //In case of error do nothing!!
            //let apiClient to handle the error
            })
            .then(function () {
                updateStateValue(setFilters, 'isLoading', false);
            });
    }

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} size={'lg'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <div className="form-row mb-2">
                        <FormInputText
                            id="id"
                            type="hidden"
                            value={selectedRow.id}
                            register={register}
                        />
                        <FormInputText
                            id="cost_code"
                            label="Cost Code Description"
                            type="text"
                            className="col-md-8"
                            value={selectedRow.code_description}
                            disabled="true"
                        />
                        <FormInputNumber
                            id="units"
                            label="Units"
                            type="number"
                            className="col-md-4"
                            value={selectedRow.units}
                            register={register({required: "required"})}
                        />
                    </div>
                    <div className="form-row mb-2">
                        <FormInputNumber
                            id="charged"
                            label="Charged"
                            type="number"
                            className="col-md-4"
                            value={selectedRow.charged}
                            register={register({
                                    required: "required",
                                    min: {value: 0, message: "min value is 0"},
                                    max: {value: 1000, message: "max value is 1000"}
                                }
                            )}
                            errors={errors}
                            onChange={(e) => calculateMarkupMath(e)}
                        />
                        <FormInputNumber
                            id="price"
                            label="Cost"
                            type="text"
                            className="col-md-4"
                            value={Number(selectedRow.price).toFixed(2)}
                            register={register({required: "required"})}
                            errors={errors}
                            onChange={(e) => calculateMarkupMath(e)}
                        />
                        <FormInputNumber
                            id="markup"
                            label="Markup %"
                            type="text"
                            className="col-md-4"
                            value={selectedRow.markup}
                            register={register({required: "required"})}
                            errors={errors}
                            onChange={(e) => calculateMarkupMath(e)}
                        />
                        <FormInputText
                            id="markup_price"
                            type="hidden"
                            value={
                                (typeof selectedRow.markup_price !== 'undefined')
                                    ?
                                        selectedRow.markup_price.replace(',','')
                                    :
                                        0
                            }
                            register={register}
                            isControlled={true}
                        />
                        <FormInputText
                            id="total"
                            type="hidden"
                            value={
                                (typeof selectedRow.total !== 'undefined')
                                    ?
                                        selectedRow.total.replace(',','')
                                    :
                                        0
                            }
                            register={register}
                            isControlled={true}
                        />
                    </div>
                    <div className="form-row mb-2">
                        <FormInputNumber
                            id="disabled_markup_price"
                            label="Markup Total"
                            type="text"
                            className="col-md-4"
                            value={
                                (typeof selectedRow.markup_price !== 'undefined')
                                    ?
                                        selectedRow.markup_price.replace(',','')
                                    :
                                        0
                            }
                            disabled="true"
                            isControlled={true}
                        />
                        <FormInputNumber
                            id="disabled_total"
                            label="Total"
                            type="text"
                            className="col-md-4"
                            value={
                                (typeof selectedRow.total !== 'undefined')
                                    ?
                                        selectedRow.total.replace(',','')
                                    :
                                        0
                            }
                            errors={errors}
                            disabled='true'
                        />
                        <FormInputText
                            id="hole_name"
                            label="Hole"
                            type="text"
                            className="col-md-4"
                            value={selectedRow.hole_name}
                            disabled='true'
                        />
                    </div>
                    <div className="form-row">
                        <FormInputTextArea
                            id="description"
                            label="Notes"
                            type="text"
                            className="col-md-12"
                            register={register({required: "required"})}
                            value={selectedRow.description}
                        />
                    </div>
                    <div className="form-row">
                        <FormInputFile
                            id="consumable_file"
                            label="Consumable Files"
                            className="col-md-12"
                            accept="image/png,image/jpg,image/jpeg,.pdf"
                            register={register}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default React.memo(ConsumableRegisterFormModal);
