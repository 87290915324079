import {FaPencilAlt, FaPlus, FaTrash} from "react-icons/fa";
import React from "react";

const FinancialPeriodsTableColumns = (setSelectedRow, setFormModal, setDeleteModal) => {
    return [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Start Date',
            accessor: 'start_date',
        },
        {
            Header: 'End Date',
            accessor: 'end_date',
        },
        {
            Header: 'Number Of Weeks',
            accessor: 'number_of_weeks',
        },
        {
            Header: 'Year',
            accessor: 'year',
        },
        {
            id: 'actions',
            Header: () => {
                return (
                    <FaPlus
                        color='#22af47'
                        size={20}
                        title="Create"
                        onClick={() => {
                            setSelectedRow({});
                            setFormModal({isOpen: true, action: 'create'});
                        }}
                    />
                )
            },
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaPencilAlt
                            className="actions"
                            title="Edit"
                            onClick={() => {
                                setSelectedRow(row);
                                setFormModal({isOpen: true, action: 'edit'});
                            }}
                        />
                        {' '}
                        <FaTrash
                            className="actions"
                            title="Delete"
                            onClick={() => {
                                const msg = <p>Are you sure you want to delete <b>{row.name}</b>?</p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                )
            }
        }
    ];
}

export default FinancialPeriodsTableColumns;



