import React from 'react';
import TableReport from "../../../common/table/table";
import FieldDailyHolesTable from "./FieldDailyHolesTable.js";
import MessageEmptyData from "../../../common/messageEmptyData";

/**
 * Body report component for Field Daily Holes
 * * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const FieldDailyHolesBody = (prop) => {
    const {filters, tableData, setSelectedRow, setEditModal} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={FieldDailyHolesTable(setSelectedRow, setEditModal)}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'field_daily_holes'}
                withGlobalSearch={true}
                scrollable={true}
            />
        </div>
    );
}

export default FieldDailyHolesBody;
