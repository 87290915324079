import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import ShopTimeEntryReportBody from "./shopTimeEntryReportBody";
import PayPeriodFormFilter from "../../../common/form-filters/PayPeriodFormFilter";

/**
 * ShopTimeEntryReport Component
 * @returns {JSX.Element}
 * @constructor
 */
export default function ShopTimeEntryReport () {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Shop Entry" parent="Reports"/>
            <PayPeriodFormFilter {...{setFilters, setTableData, OnSubmitUrl:"getShopEntryReportData"}}/>
            <ShopTimeEntryReportBody {...{filters, tableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}