import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import apiClient from "../../../../utils/apiClient";
import TableReport from "../../../common/table/table";
import styled from "styled-components";
import LoadingIndicator from "../../../common/loadingIndicator";
import { useAuthenticate } from '../../../../hooks/auth';

/**
 * Revenue by rig job cost tracking
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const RevenueJobCostTracking = (props) => {
    const date_from = props.match.params.date_from;
    const date_to = props.match.params.date_to;
    const job_id = props.match.params.job_id;
    const [tableFilter, setTableFilter] = useState([]);
    const [tableCostHeader, settableCostHeader] = useState([]);
    const [tableInvoiceChargesHeader, setTableInvoiceChargesHeader] = useState([]);
    const [tableInvoiceChargesData, setTableInvoiceChargesData] = useState([]);
    const [tableCostData, settableCostData] = useState([]);
    const [tableConsumableHeader, settableConsumableHeader] = useState([]);
    const [tableConsumableData, settableConsumableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dailiesTotal, setDailiesTotal] = useState([]);
    const [consumablesTotal, setConsumablesTotal] = useState([]);
    const [invoiceChargesTotal, setInvoiceChargesTotal] = useState([]);
    const [grandTotal, setGrandTotal] = useState([]);
    const { user } = useAuthenticate();
    const isToolPusherUser = user?.group_id === 11;

    // get report detail data
    useEffect(() => {
        //get job cost tracking dailies
        apiClient
            .post('getJobCostTrackingDailies',
                {
                    job_id: job_id,
                    date_from: date_from,
                    date_to: date_to
                })
            .then(response => {
                setTableFilter(response.data.tableFilter);
                settableCostHeader(response.data.tableHeader);
                settableCostData(response.data.tableRows);
                setDailiesTotal(response.data.tableTotal);
            })
            .then(function () {
                setIsLoading(false);
            });

        //get job cost tracking invoice charges
        apiClient
            .post('getJobCostTrackingInvoiceCharges',
                {
                    job_id: job_id,
                    date_from: date_from,
                    date_to: date_to
                })
            .then(response => {
                setTableInvoiceChargesHeader(response.data.tableHeader);
                setTableInvoiceChargesData(response.data.tableRows);
                setInvoiceChargesTotal(response.data.tableTotal);
            })
            .then(function () {
                setIsLoading(false);
            });

        //get job consumable tracking
        apiClient
            .post('getJobCostTrackingConsumables',
                {
                    job_id: job_id,
                    date_from: date_from,
                    date_to: date_to
                })
            .then(response => {
                settableConsumableHeader(response.data.tableHeader);
                settableConsumableData(response.data.tableRows);
                setConsumablesTotal(response.data.tableTotal);
            });

            let totalRaw = Number(consumablesTotal) + Number(dailiesTotal) + Number(invoiceChargesTotal);
            setGrandTotal(Number(totalRaw).toFixed(2));
    }, [job_id, date_from, date_to, consumablesTotal, dailiesTotal, invoiceChargesTotal]);

    return (
        <>
            <Breadcrumb title="Job Cost Tracking" parent="Reports"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-2">
                        <span className="font-weight-bold"> Report Date : </span>
                        {tableFilter.date}
                    </div>
                    <div className="col-sm-3">
                        <span className="font-weight-bold"> Rig: </span>
                        {tableFilter.rig_name}
                    </div>
                    <div className="col-sm-2">
                        <span className="font-weight-bold"> Job: </span>
                        {tableFilter.job_number}
                    </div>
                    <div className="col-sm-5">
                        <span className="font-weight-bold"> Period : </span>
                        {tableFilter.financial_period}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TableStyleCosts isToolPusherUser={isToolPusherUser}>
                            <TableReport
                                columns={tableCostHeader}
                                data={tableCostData}
                                exportOptions={{pdf: false, xlsx: true, csv: false}}
                                getExportFileName={() => 'job_cost_tracking_dailies'}
                                withGlobalSearch={false}
                                scrollable={true}
                            />
                        </TableStyleCosts>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h5>Invoice Charges</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TableReport
                            columns={tableInvoiceChargesHeader}
                            data={tableInvoiceChargesData}
                            exportOptions={{pdf: false, xlsx: true, csv: false}}
                            getExportFileName={() => 'job_cost_tracking_invoice_charges'}
                            withGlobalSearch={false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h5>Consumables</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TableReport
                            columns={tableConsumableHeader}
                            data={tableConsumableData}
                            exportOptions={{pdf: false, xlsx: true, csv: false}}
                            getExportFileName={() => 'job_cost_tracking_consumables'}
                            withGlobalSearch={false}
                        />
                    </div>
                </div>
                {
                    !isToolPusherUser && (
                        <TotalTableStyle>
                            <table>
                                <thead>
                                <tr>
                                    <th>Dailies</th>
                                    <th>Consumables</th>
                                    <th>Invoice Charges</th>
                                    <th>Grand Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{dailiesTotal}</td>
                                    <td>{consumablesTotal}</td>
                                    <td>{invoiceChargesTotal}</td>
                                    <td>{grandTotal}</td>
                                </tr>
                                </tbody>
                            </table>
                        </TotalTableStyle>
                    )
                }
            </div>
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default RevenueJobCostTracking;

const TableStyleCosts = styled.div`
    overflow-x: auto;

    div.table {
        .header {
            .tr {
                .th {
                    text-align: center;
                }
            }
        }

        .body {
            text-align: center;

            .tr {
                :first-child {
                    .td {
                        font-weight: ${(props) => props.isToolPusherUser ? 'normal' : 'bold'};
                    }
                }

                :nth-last-child(2) {
                    font-weight: ${(props) => props.isToolPusherUser ? 'normal' : 'bold'};
                }

                :last-child {
                    .td {
                        font-weight: ${(props) => props.isToolPusherUser ? 'normal' : 'bold'};
                    }
                }
            }
        }
    }
`;

const TotalTableStyle = styled.div`
    overflow-x: auto;
    table {
        width: 100%;
        border: 1px lightgray solid;
        theader {
            th {
                text-align: center;
            }
        }

        tbody {
            border: 1px lightgray solid;
            text-align: left;
            td {
                font-weight: bold;
            }
            .tr {
                :first-child {
                    .td {
                        font-weight: bold;
                    }
                }
                :nth-last-child(2) {
                    font-weight: bold;
                }
            }
        }
    }
`;
