import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import {useFieldArray} from "react-hook-form";
import * as Shared from "../../../../common/shared";
// import ImageUploader from 'react-images-upload';

const Fuel = (props) => {
    const {errors, register, control, collectedHoles, fieldDaily, action} = props;
    const {fields, append, remove} = useFieldArray({control, name: "fuel"});
    const [selectedFuelsHole, setSelectedFuelsHole] = useState('');

    useEffect(() => {
        if (action === 'edit') {
            append(fieldDaily.field_fuels);
        }
    }, [append, action, fieldDaily]);

    // disabled do not delete
    // converts base64 string into file blob
    // const ConvertBase64ImageForImageUploader = (index) => {
    //     const base64Image = (typeof fieldDaily.field_fuels[index] !== 'undefined')
    //         ?
    //             fieldDaily.field_fuels[index].image
    //         :
    //             '';
    //
    //     if (base64Image) {
    //         // Split into two parts
    //         const parts = base64Image.split(';base64,');
    //         // Hold the content type
    //         const imageType = parts[0].split(':')[1];
    //         // Decode Base64 string
    //         const decodedData = window.atob(parts[1]);
    //         // Create UNIT8ARRAY of size same as row data length
    //         const uInt8Array = new Uint8Array(decodedData.length);
    //         // Insert all character code into uInt8Array
    //         for (let i = 0; i < decodedData.length; ++i) {
    //             uInt8Array[i] = decodedData.charCodeAt(i);
    //         }
    //         // Return BLOB image after conversion
    //         const resultBlob = new Blob([uInt8Array], { type: imageType });
    //         const blobUrl = URL.createObjectURL(resultBlob);
    //         let resultArray = [];
    //         resultArray.push(blobUrl);
    //         return resultArray;
    //     }
    // }

    return (
        <AccordionItem className="card-header bg-primary" title="Fuel">
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Gallons</th>
                                        <th>Supplied By</th>
                                        <th>Hole</th>
                                        {/*<th>File</th>*/}
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={
                                                    () => append(
                                                    {
                                                        type: "",
                                                        gallons: "",
                                                        supplied_by: "",
                                                        hole_name: "",
                                                        // image: "",
                                                        // image_original_name: ""
                                                    })
                                                }
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <SelectInTable
                                                id={`fuel[${index}].type`}
                                                name={`fuel[${index}].type`}
                                                value={(action === 'edit') ? item.type : ''}
                                                options={[
                                                    {value: "diesel_red", label: "Diesel Red"},
                                                    {value: "diesel_clear", label: "Diesel Clear"},
                                                    {value: "gasoline", label: "Gasoline"}
                                                ]}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`fuel[${index}].gallons`}
                                                type="number"
                                                value={(action === 'edit') ? item.gallons : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            <SelectInTable
                                                id={`fuel[${index}].supplied_by`}
                                                name={`fuel[${index}].supplied_by`}
                                                value={(action === 'edit') ? item.supplied_by : ''}
                                                options={[
                                                    {value: "Client", label: "Client"},
                                                    {value: "NEWP", label: "NEWP"}
                                                ]}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`fuel[${index}].hole_name`}
                                                    name={`fuel[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`fuel[${index}].hole_name`}
                                                    name={`fuel[${index}].hole_name`}
                                                    value={
                                                        selectedFuelsHole
                                                            ?
                                                            selectedFuelsHole
                                                            :
                                                            (collectedHoles.length === 1)
                                                                ?
                                                                collectedHoles[0].value
                                                                :
                                                                item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedFuelsHole)
                                                    }
                                                    options={collectedHoles}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {/*<td>*/}
                                            {/*    <ImageUploader*/}
                                            {/*        withIcon={false}*/}
                                            {/*        withPreview={true}*/}
                                            {/*        name={`fuel[${index}].file_picker`}*/}
                                            {/*        id={`fuel[${index}].file_picker`}*/}
                                            {/*        buttonText='Choose image'*/}
                                            {/*        label={*/}
                                            {/*            (action === 'create')*/}
                                            {/*                ?*/}
                                            {/*                "Max 2Mb, accepted: jpeg,png,jpg,pdf " +*/}
                                            {/*                "Upload correct image at the first try"*/}
                                            {/*                :*/}
                                            {/*                "Max 2Mb, accepted: jpeg,png,jpg,pdf " +*/}
                                            {/*                "Delete row and enter new row and upload image at the first try"*/}
                                            {/*        }*/}
                                            {/*        // TODO:*/}
                                            {/*        // when pictures removed they are removed on the UI but not from state*/}
                                            {/*        // this is still needs to be fixed*/}
                                            {/*        onChange={*/}
                                            {/*            (picture) =>*/}
                                            {/*                Shared.UploadBase64EncodedImageFile(*/}
                                            {/*                    picture,*/}
                                            {/*                    `fuel[${index}].image`,*/}
                                            {/*                    `fuel[${index}].image_original_name`,*/}
                                            {/*                    `fuel[${index}].type`,*/}
                                            {/*                    `fuel[${index}].gallons`,*/}
                                            {/*                    `fuel[${index}].supplied_by`,*/}
                                            {/*                    `fuel[${index}].hole_name`,*/}
                                            {/*                    setValue*/}
                                            {/*                )*/}
                                            {/*        }*/}
                                            {/*        defaultImages={*/}
                                            {/*            (action === 'edit')*/}
                                            {/*                ?*/}
                                            {/*                    ConvertBase64ImageForImageUploader(index)*/}
                                            {/*                :*/}
                                            {/*                    ''*/}
                                            {/*        }*/}
                                            {/*        accept="image/png, image/jpeg, image/jpg, application/pdf"*/}
                                            {/*        imgExtension={['.jpeg', '.png', '.jpg', '.pdf']}*/}
                                            {/*        maxFileSize={2097152}*/}
                                            {/*        fileSizeError=" file size is too big"*/}
                                            {/*        fileTypeError=" is not supported file extension"*/}
                                            {/*        className="text-center"*/}
                                            {/*        singleImage={true}*/}
                                            {/*    />*/}
                                            {/*</td>*/}
                                            {/*Intentionally hidden */}
                                            {/*<InputInTable*/}
                                            {/*    id={`fuel[${index}].image`}*/}
                                            {/*    value={item.image}*/}
                                            {/*    register={register()}*/}
                                            {/*    type="hidden"*/}
                                            {/*/>*/}
                                            {/*/!*Intentionally hidden *!/*/}
                                            {/*<InputInTable*/}
                                            {/*    id={`fuel[${index}].image_original_name`}*/}
                                            {/*    value={item.image_original_name}*/}
                                            {/*    register={register()}*/}
                                            {/*    type="hidden"*/}
                                            {/*/>*/}
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    title="Remove"
                                                    size={20}
                                                    onClick={() => remove(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default Fuel;
