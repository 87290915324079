import React, {useEffect, useState} from 'react';
import FormSelect from "../../../forms/form-control/FormSelect";
import apiClient from "../../../../utils/apiClient";
import {useForm} from "react-hook-form";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";

/**
 * Filter component for ToolPusher Toolbox Report
 * @returns {JSX.Element}
 * @constructor
 */
const ToolPusherToolboxYTDFilters = (prop) => {
    const {
        setReportFilters, setTableDataDiscipline1, setTableDataDiscipline2, setTableDataDiscipline3,
        setTableDataDiscipline4, setStartDate, setEndDate, setPeriodId
    } = prop;
    const {register, handleSubmit, setValue, errors, watch} = useForm();
    const [currentPeriod, setCurrentPeriod] = useState({});
    const [ytdPeriods, setYtdPeriods] = useState([]);
    const ytdPeriodId = watch('ytd_id');

    useEffect(() => {
        setPeriodId({
            id: ytdPeriodId,
            type: 'financial_period'
        })
    }, [setPeriodId, ytdPeriodId])

    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient
            .post('getToolPusherToolboxYTD', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableDataDiscipline1([]);
                    setTableDataDiscipline2([]);
                    setTableDataDiscipline3([]);
                    setTableDataDiscipline4([]);
                    setStartDate([]);
                    setEndDate([]);
                    return;
                }
                setTableDataDiscipline1(response.data.core_surface);
                setTableDataDiscipline2(response.data.core_underground);
                setTableDataDiscipline3(response.data.rcx);
                setTableDataDiscipline4(response.data.rotary);
                setStartDate(response.data.start_date);
                setEndDate(response.data.end_date);
            })
            .catch(function () {
                setTableDataDiscipline1([]);
                setTableDataDiscipline2([]);
                setTableDataDiscipline3([]);
                setTableDataDiscipline4([]);
                setStartDate([]);
                setEndDate([]);
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    }

    // get list of financial periods
    useEffect(() => {
        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setYtdPeriods(
                    response.data.map(
                        ({id, name, is_current}) => {
                            if (is_current) {
                                setCurrentPeriod({id: id, name: name + ' (Current)'})
                            }
                            return {
                                id: id, name: name + (is_current ? ' (Current)' : '')
                            }
                        }
                    )
                );
            });

    }, [setYtdPeriods]);


    useEffect(() => {
        // check if financial period was selected
        setTimeout(() => {
            setValue('ytd_id', currentPeriod.id);
        }, 200);
    }, [currentPeriod, setValue]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="ytd_id"
                    label="YTD"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={ytdPeriods}
                    register={register}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default ToolPusherToolboxYTDFilters;
