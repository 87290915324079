import {FaPencilAlt} from "react-icons/fa";
import React from "react";

const FieldDailyHolesTable = (setSelectedRow, setEditModal) => {
    return [
        {
            Header: 'Date',
            accessor: 'date',
        },
        {
            Header: 'Hole',
            accessor: 'name',
        },
        {
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;

                return (
                    <>
                        <FaPencilAlt
                            color='#A51E37'
                            title="Edit Hole"
                            onClick={() => {
                                setSelectedRow(row);
                                setEditModal(true);
                            }}
                        />
                    </>
                )
            }
        }
    ];
}

export default FieldDailyHolesTable;

