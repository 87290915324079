import React, {Fragment, useState, useEffect} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import {Controller, useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import {Button} from "reactstrap";
import {toast} from "react-toastify";
import Creatable from 'react-select/creatable';
import FormInputFile from "../../../forms/form-control/FormInputFile";
import FileManager from "./editJobFilesTable";
import FormSelect from "../../../forms/form-control/FormSelect";

const EditJob = () => {
    const [jobId, setJobId] = useState('');
    const [clientsInfo, setClientsInfo] = useState('');
    const {register, handleSubmit, errors, control} = useForm();
    const [endUses, setEndUses] = useState('');
    const [managers, setManagers] = useState([]);
    const [jobMethods, setJobMethods] = useState('');
    const [billingPeriods, setBillingPeriods] = useState('');
    const [workTypes, setWorkTypes] = useState('');
    const [states, setStates] = useState([]);
    const [jobEditData, setJobEditData] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedBillingPeriod, setSelectedBillingPeriod] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedRig, setSelectedRig] = useState('');
    const [rigs, setRigs] = useState('');
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedTypeOfWork, setSelectedTypeOfWork] = useState('');
    const [selectedJobMethod, setSelectedJobMethod] = useState('');
    const [selectedBonded, setSelectedBonded] = useState('');
    const [selectedPrevailingWage, setSelectedPrevailingWage] = useState('');
    const [selectedEndUse, setSelectedEndUse] = useState('');
    const [selectedBillingState, setSelectedBillingState] = useState('');
    const [locations, setLocations] = useState([]);
    const url = new URL(window.location.href);
    const breadcrumbsTitle = (url.searchParams.has('mode')) ? 'View Job' : 'Edit Job';
    // get id from url
    const link = new URL(window.location.href);
    const [isUpdated, setIsUpdated] = useState(false);

    // get job by id
    useEffect(() => {
        // const searchParams = url.searchParams;
        if (link.searchParams.has('id')) {
            const id = link.searchParams.get('id');

            apiClient
                .post('getJobById', {'id' : id})
                .then(response => {
                    if (response.status === 200) {
                        setJobEditData(response.data.jobs);
                        setJobId(response.data.jobs.id);

                        // get rigs for disciplien_id
                        if (response.data.jobs.discipline_id > 0) {
                            apiClient
                                .post(
                                    'getAllActiveRigsByDisciplineId',
                                    {'discipline_id' : response.data.jobs.discipline_id}
                                )
                                .then(response => {
                                    const rigItems = response.data.rigs.map((rig) =>
                                        <option key={rig.id} value={rig.id}>{rig.name}</option>
                                    );

                                    setRigs(rigItems);
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        }
                    } else {
                        setTimeout(() => {
                            toast.error("System Error, please contact System Administrator.");
                        }, 200);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // to set selected value of dropdown
    const setSelectedValue = (value, setter) => {
        setter(value);
    }

    useEffect(() => {
        // Billing Periods
        apiClient
            .get('billingPeriods')
            .then(response => {
                const optionItems = response.data.periods.map((period) =>
                    <option key={period.id} value={period.id}>{period.period_name}</option>
                );

                setBillingPeriods(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // Clients
        apiClient
            .get('getActiveClientsList')
            .then(response => {
                const clientItems = response.data.activeClientsList.map((client) =>
                    <option key={client.id} value={client.id}>
                        {client.client_name + '(' + client.erp_id + ')'}
                    </option>
                );

                setClientsInfo(clientItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // States
        apiClient
            .get('getUSAStates')
            .then(response => {
                const stateItems = response.data.states.map(
                    ({id, abbreviation}) => ({value: id, label: abbreviation})
                );

                setStates(stateItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // end uses
        apiClient
            .get('getJobEndUses')
            .then(response => {
                const optionItems = response.data.jobEndUses.map((endUses) =>
                    <option key={endUses.id} value={endUses.id}>{endUses.name}</option>
                );

                setEndUses(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // Managers in DB  it is operations managers but we are deactivating this group
        apiClient
            .post('getUsersByGroupId', {'id' : 2})
            .then(response => {
                const optionItems = response.data.map(
                    ({id, first_name, last_name}) => ({value: id, label: first_name + ' ' + last_name})
                );

                setManagers(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // job methods
        apiClient
            .get('getJobMethods')
            .then(response => {
                const optionItems = response.data.jobMethods.map((jobMethod) =>
                    <option key={jobMethod.id} value={jobMethod.id}>{jobMethod.name}</option>
                );

                setJobMethods(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // work types
        apiClient
            .get('getJobWorkTypes')
            .then(response => {
                const optionItems = response.data.jobWorkTypes.map((workTypes) =>
                    <option key={workTypes.id} value={workTypes.id}>{workTypes.name}</option>
                );

                setWorkTypes(optionItems);
            })
            .catch((error) => {
                console.error(error);
            });

        // locations
        apiClient
            .get('getLocations')
            .then(response => {
                const locationItems = response.data.locations.map(
                    ({id, name}) => ({value: id, label: name})
                );
                setLocations(locationItems);
            });
    }, []);

    // cancel edit
    const toggleCancel = () => {
        window.close();
    }

    const onSave = (data) => {
        data['id'] = jobId;

        const formData = new FormData();
        for (const key in data) {
            switch (key) {
                case 'job_file':
                    for (const file of data[key]) {
                        formData.append('job_file[]', file, file.name);
                    }
                    break;
                case 'locations':
                    for (const obj of data[key]) {
                        formData.append('locations[]', JSON.stringify(obj));
                    }
                    break;
                default:
                    formData.append(key, data[key]);
            }
        }

        apiClient
            .post('editJob/' + jobId, formData)
            .then(response => {
                toast.success("Job has been saved");
                setIsUpdated(true);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <Fragment>
            <Breadcrumb title={breadcrumbsTitle} parent="Commercial" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <form
                            className="needs-validation"
                            noValidate=""
                            id="edit_job_form"
                            onSubmit={handleSubmit(onSave)}
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-row">
                                        <input
                                            id="discipline_id"
                                            name="discipline_id"
                                            type="hidden"
                                            defaultValue={jobEditData ? jobEditData.discipline_id : ''}
                                            ref={register({ required: true })}
                                        />
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="job_number">Job #</label>
                                            <input
                                                className="form-control"
                                                name="job_number"
                                                type="text"
                                                defaultValue={jobEditData ? jobEditData.job_number : ''}
                                                ref={register({ required: true })}
                                                readOnly
                                            />
                                            <span>{errors.job_number && 'Job number is required'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="contract_date">Contract Date</label>
                                            <input
                                                className="form-control"
                                                name="contract_date"
                                                id="contract_date"
                                                type="date"
                                                defaultValue={jobEditData ? jobEditData.contract_date : ''}
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.contract_date && 'Contract Date is required'}</span>
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="client_id">Client</label>
                                            <select
                                                className="form-control"
                                                name="client_id"
                                                id="client_id"
                                                value={selectedClient ? selectedClient : jobEditData.client_id}
                                                onChange={e => setSelectedValue(e.target.value, setSelectedClient)}
                                                ref={register({ required: true })}
                                            >
                                                <option value="" />
                                                {clientsInfo}
                                            </select>
                                            <span>{errors.client_id && 'Please select Client'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="job_start_date">Start Date</label>
                                            <input
                                                className="form-control"
                                                name="job_start_date"
                                                id="job_start_date"
                                                type="date"
                                                defaultValue={jobEditData ? jobEditData.job_start_date : ''}
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.start_date && 'Job Start Date is required'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="projected_close_date">Close Date</label>
                                            <input
                                                className="form-control"
                                                name="projected_close_date"
                                                id="projected_close_date"
                                                type="date"
                                                defaultValue={jobEditData ? jobEditData.projected_close_date : ''}
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.projected_close_date && 'Projected Close Date is required'}</span>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="job_name">Job Name</label>
                                            <input
                                                className="form-control"
                                                id="job_name"
                                                name="job_name"
                                                type="text"
                                                defaultValue={jobEditData ? jobEditData.job_name : ''}
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.job_name && 'Please provide a valid Job Name.'}</span>
                                            <div className="invalid-feedback">Please provide a valid Job Name.</div>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="billing_period_id">Payment terms</label>
                                            <select
                                                className="form-control"
                                                name="billing_period_id"
                                                id="billing_period_id"
                                                value={selectedBillingPeriod ? selectedBillingPeriod : jobEditData.billing_period_id}
                                                onChange={e => setSelectedValue(e.target.value, setSelectedBillingPeriod)}
                                                ref={register({ required: true })}
                                            >
                                                {billingPeriods}
                                            </select>
                                            <span>{errors.billing_period_id && 'Please Select Payment Term'}</span>
                                        </div>
                                        <div className="col-md-1 mb-3">
                                            <label htmlFor="retainage">Retainage %</label>
                                            <input
                                                className="form-control"
                                                name="retainage"
                                                id="retainage"
                                                type="number"
                                                defaultValue={jobEditData ? jobEditData.retainage : ''}
                                                ref={register({ required: false })}
                                            />
                                            <span>{errors.retainage && 'Please enter Retainage %'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="job_city">Job City</label>
                                            <input
                                                className="form-control"
                                                id="job_city"
                                                name="job_city"
                                                type="text"
                                                defaultValue={jobEditData ? jobEditData.job_city : ''}
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.job_city && 'Please enter Job City.'}</span>
                                        </div>
                                        <FormSelect
                                            id="state_id"
                                            label="State"
                                            className="col-md-1 mb-3"
                                            value={selectedState ? selectedState : jobEditData.state_id}
                                            options={states}
                                            register={register({required: false})}
                                            onChange={(e) => setSelectedValue(e.target.value, setSelectedState)}
                                            isControlled={true}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="service_agreement">Service Agreement</label>
                                            <input
                                                className="form-control"
                                                id="service_agreement"
                                                name="service_agreement"
                                                type="text"
                                                defaultValue={jobEditData ? jobEditData.service_agreement : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-1 mb-3">
                                            <label htmlFor="bid_number">BID #</label>
                                            <input
                                                className="form-control"
                                                id="bid_number"
                                                name="bid_number"
                                                type="number"
                                                defaultValue={jobEditData ? jobEditData.bid_number : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-1 mb-3">
                                            <label htmlFor="rig_id">Rig</label>
                                            <select
                                                className="form-control"
                                                name="rig_id"
                                                id="rig_id"
                                                value={selectedRig ? selectedRig : jobEditData.rig_id}
                                                onChange={e => setSelectedValue(e.target.value, setSelectedRig)}
                                                ref={register({ required: false })}
                                            >
                                                {rigs}
                                            </select>
                                        </div>
                                        <FormSelect
                                            id="operations_manager_id"
                                            label="Manager"
                                            className="col-md-3 mb-3"
                                            value={selectedManager ? selectedManager : jobEditData.operations_manager_id}
                                            options={managers}
                                            register={register({required: false})}
                                            onChange={(e) => setSelectedValue(e.target.value, setSelectedManager)}
                                            isControlled={true}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-5 mb-3">
                                            <label htmlFor="scope_of_work">Scope Of Work</label>
                                            <input
                                                className="form-control"
                                                id="scope_of_work"
                                                name="scope_of_work"
                                                type="text"
                                                defaultValue={jobEditData ? jobEditData.scope_of_work : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="type_of_work_id">Type Of Work</label>
                                            <select
                                                className="form-control"
                                                name="type_of_work_id"
                                                id="type_of_work_id"
                                                value={selectedTypeOfWork ? selectedTypeOfWork : jobEditData.type_of_work_id}
                                                onChange={e => setSelectedValue(e.target.value, setSelectedTypeOfWork)}
                                                ref={register({ required: true })}
                                            >
                                                {workTypes}
                                            </select>
                                            <span>{errors.type_of_work_id && 'Please Select Type Of Work.'}</span>
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="job_method_id">Job Method</label>
                                            <select
                                                className="form-control"
                                                name="job_method_id"
                                                id="job_method_id"
                                                value={selectedJobMethod ? selectedJobMethod : jobEditData.job_method_id}
                                                onChange={e => setSelectedValue(e.target.value, setSelectedJobMethod)}
                                                ref={register({ required: true })}
                                            >
                                                {jobMethods}
                                            </select>
                                            <span>{errors.job_method_id && 'Please Select Job Method.'}</span>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-1 mb-3">
                                            <label htmlFor="bonded">Bonded</label>
                                            <select
                                                className="form-control"
                                                name="bonded"
                                                id="bonded"
                                                value={selectedBonded ? selectedBonded : jobEditData.bonded}
                                                onChange={e => setSelectedValue(e.target.value, setSelectedBonded)}
                                                ref={register({ required: true })}
                                            >
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                            <span>{errors.bonded && 'Please Select Bonded'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="prevailing_wage">Prevailing Wage</label>
                                            <select
                                                className="form-control"
                                                name="prevailing_wage"
                                                id="prevailing_wage"
                                                value={selectedPrevailingWage ? selectedPrevailingWage : jobEditData.prevailing_wage}
                                                onChange={e => setSelectedValue(e.target.value, setSelectedPrevailingWage)}
                                                ref={register({ required: true })}
                                            >
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                            <span>{errors.prevailing_wage && 'Please Select Prevailing Wage'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="end_use_id">End Use</label>
                                            <select
                                                className="form-control"
                                                name="end_use_id"
                                                id="end_use_id"
                                                value={selectedEndUse ? selectedEndUse : jobEditData.end_use_id}
                                                onChange={e => setSelectedValue(e.target.value, setSelectedEndUse)}
                                                ref={register({ required: true })}
                                            >
                                                {endUses}
                                            </select>
                                            <span>{errors.end_use_id && 'Please Select End Use'}</span>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="deposit">Deposit</label>
                                            <input
                                                className="form-control"
                                                id="deposit"
                                                name="deposit"
                                                type="text"
                                                defaultValue={jobEditData ? jobEditData.deposit : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-7 mb-3">
                                            <label htmlFor="notes">Notes</label>
                                            <textarea
                                                className="form-control"
                                                type="text"
                                                name="notes"
                                                id="notes"
                                                rows="3"
                                                cols="33"
                                                defaultValue={jobEditData ? jobEditData.notes : ''}
                                                ref={register({required: false})}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="fuel_markup_percent">Fuel Markup %</label>
                                            <input
                                                className="form-control"
                                                name="fuel_markup_percent"
                                                id="fuel_markup_percent"
                                                defaultValue={jobEditData ? jobEditData.fuel_markup_percent : ''}
                                                type="number"
                                                ref={register({required: true})}
                                            />
                                            <span>{errors.fuel_markup_percent && 'required'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h4>Billing Details</h4>
                                    <div className="form-row">
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="ap_contact_name">AP Contact Name</label>
                                            <input
                                                className="form-control"
                                                id="ap_contact_name"
                                                name="ap_contact_name"
                                                defaultValue={jobEditData ? jobEditData.ap_contact_name : ''}
                                                type="text" ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="ap_email">Email</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    name="ap_email"
                                                    type="email"
                                                    defaultValue={jobEditData ? jobEditData.ap_email : ''}
                                                    id="ap_email"
                                                    aria-describedby="inputGroupPrepend"
                                                    ref={register({ required: false })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="ap_contact_number">Contact #</label>
                                            <input
                                                className="form-control"
                                                id="ap_contact_number"
                                                name="ap_contact_number"
                                                type="number"
                                                defaultValue={jobEditData ? jobEditData.ap_contact_number : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-5 mb-3">
                                            <label htmlFor="billing_address">Address</label>
                                            <input
                                                className="form-control"
                                                id="billing_address"
                                                name="billing_address"
                                                type="text"
                                                defaultValue={jobEditData ? jobEditData.billing_address : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="billing_city">City</label>
                                            <input
                                                className="form-control"
                                                id="billing_city"
                                                name="billing_city"
                                                type="text"
                                                defaultValue={jobEditData ? jobEditData.billing_city : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <FormSelect
                                            id="billing_state"
                                            label="State"
                                            className="col-md-1 mb-3"
                                            value={selectedBillingState ? selectedBillingState : jobEditData.billing_state}
                                            options={states}
                                            register={register({required: false})}
                                            onChange={(e) => setSelectedValue(e.target.value, setSelectedBillingState)}
                                            isControlled={true}
                                        />
                                        <div className="col-md-1 mb-3">
                                            <label htmlFor="billing_zip">Zip</label>
                                            <input
                                                className="form-control"
                                                id="billing_zip"
                                                name="billing_zip"
                                                type="number"
                                                defaultValue={jobEditData ? jobEditData.billing_zip : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h4>Productivity Expectations Section</h4>
                                    <div className="form-row">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="expected_shift_average">Expected Shift Average</label>
                                            <input
                                                className="form-control"
                                                id="expected_shift_average"
                                                name="expected_shift_average"
                                                type="text"
                                                defaultValue={jobEditData ? jobEditData.expected_shift_average : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="expected_ft_per_shift">Expected FT./Shift</label>
                                            <input
                                                className="form-control"
                                                id="expected_ft_per_shift"
                                                name="expected_ft_per_shift"
                                                type="text"
                                                defaultValue={jobEditData ? jobEditData.expected_ft_per_shift : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="expected_billed_percentage">Expected Billed %</label>
                                            <input
                                                className="form-control"
                                                id="expected_billed_percentage"
                                                name="expected_billed_percentage"
                                                type="number"
                                                defaultValue={jobEditData ? jobEditData.expected_billed_percentage : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="expected_drilling_percentage">Expected Drilling %</label>
                                            <input
                                                className="form-control"
                                                id="expected_drilling_percentage"
                                                name="expected_drilling_percentage"
                                                type="number"
                                                defaultValue={jobEditData ? jobEditData.expected_drilling_percentage : ''}
                                                ref={register({ required: false })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h4>Locations</h4>
                                    <div className="form-row">
                                        <div className="col-md-8">
                                            {jobEditData &&
                                                <Controller
                                                    as={Creatable}
                                                    options={locations}
                                                    name="locations"
                                                    defaultValue={jobEditData.locations.map(
                                                        ({id, name}) => ({value: id, label: name})
                                                    )}
                                                    isMulti
                                                    control={control}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h4>Files</h4>
                                    <div className="form-row mb-3">
                                        <div className="col-md-8">
                                            <FormInputFile
                                                id="job_file"
                                                className="col-md-12"
                                                accept="image/png,image/jpg,image/jpeg,.pdf"
                                                register={register}
                                            />
                                        </div>
                                    </div>
                                    <FileManager
                                        fileableId={link.searchParams.get('id')}
                                        fileableType ="App\Models\Jobs"
                                        isUpdated={isUpdated}
                                        setIsUpdated={setIsUpdated}
                                    />
                                </div>
                            </div>
                            { (url.searchParams.has('mode')) ?
                                <></>
                                :
                                <div className='mt-3'>
                                    <Button color="primary" type="submit">Save</Button>
                                    <Button className="btn btn-secondary cancel-button" onClick={toggleCancel}>Cancel</Button>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default EditJob;