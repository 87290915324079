import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import ToolPusherToolboxYTDFilters from "./toolPusherToolboxYTDFilters";
import ToolPusherToolboxYTDBody from "./toolPusherToolboxYTDBody";
import LoadingIndicator from "../../../common/loadingIndicator";

/**
 * ToolPusher Toolbox Report
 * @returns {JSX.Element}
 * @constructor
 */
const ToolPusherToolboxYTD = () => {
    const [tableDataDiscipline1, setTableDataDiscipline1] = useState([]);
    const [tableDataDiscipline2, setTableDataDiscipline2] = useState([]);
    const [tableDataDiscipline3, setTableDataDiscipline3] = useState([]);
    const [tableDataDiscipline4, setTableDataDiscipline4] = useState([]);
    const [reportStartDate, setStartDate] = useState([]);
    const [reportEndDate, setEndDate] = useState([]);
    const [periodId, setPeriodId] = useState({
        id: '',
        type: 'pay_period'
    });
    const [reportFilters, setReportFilters] = useState({
        financial_period_id: '',
        pay_period_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    const reportFilterProps = {
        setReportFilters, setTableDataDiscipline1, setTableDataDiscipline2, setTableDataDiscipline3,
        setTableDataDiscipline4, setStartDate, setEndDate, setPeriodId
    }

    const reportBodyProps = {
        reportFilters, tableDataDiscipline1, tableDataDiscipline2, tableDataDiscipline3,
        tableDataDiscipline4, reportStartDate, reportEndDate, periodId
    }

    return (
        <>
            <Breadcrumb title="Toolpusher Toolbox YTD" parent="Reports"/>
            <ToolPusherToolboxYTDFilters {...reportFilterProps} />
            <ToolPusherToolboxYTDBody {...reportBodyProps} />
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default ToolPusherToolboxYTD;
