import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import {useFieldArray} from "react-hook-form";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import apiClient from "../../../../../utils/apiClient";

const FootageReamed = (props) => {
    const {
        errors, register, control, collectedHoles, reamedDescriptions, setValue, fieldDaily, action, selectedJobId
    } = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "reaming"
    });

    const [totalReamed, setTotalReamed] = useState(0);
    const [selectedReamingHole, setSelectedReamingHole] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!fields.length) {
            setTotalReamed(0);
        }

        if (reamedDescriptions.length) {
            setIsLoading(false);
        }
    }, [fields.length, reamedDescriptions]);

    setTimeout(() => {
        if (!reamedDescriptions.length) {
            setIsLoading(false);
        }
    }, 2000);
    
    useEffect(() => {
        if (action === 'edit') {
            append(fieldDaily.field_reaming);

            setTimeout(() => {
                fieldDaily.field_reaming.forEach((item, index) => {
                    // populate name select
                    setValue(
                        `reaming[${index}].cost_code`,
                        {value: item.cost_code, label: item.description}
                    );
                });
            }, 500);

            setTimeout(() => {
                if (fieldDaily.field_reaming.length > 0) {
                    Shared.calculateTotals('total_reamed', setTotalReamed);
                }
            }, 200);
        }
    }, [action, fieldDaily, setTotalReamed, append, setValue]);


    const selectCode = (index, value) => {
        let jobId = (action === 'edit') ? fieldDaily.job_id : selectedJobId;
        if (value && jobId) {
            apiClient
                .post('getJobChargesPriceByCodeAndJobId', {'job_id': jobId, 'code': value})
                .then(response => {
                    const realPrice = parseFloat(response.data[0]).toFixed(2);
                    setValue(`reaming[${index}].price`, realPrice);
                });
        }
    }

    const calculateTotalReamedInRow = (e, fromId, toId, totalId, priceId, billableId) => {
        e.preventDefault();
        let fromFootage = (document.getElementById(fromId).value) ? document.getElementById(fromId).value : 0;
        let toFootage = (document.getElementById(toId).value) ? document.getElementById(toId).value : 0;
        let price = (document.getElementById(priceId).value) ? document.getElementById(priceId).value : 0;
        let totalRaw = Number(toFootage) - Number(fromFootage);
        let total = Number(totalRaw).toFixed(2);

        if (toFootage > 0) {
            setValue(totalId, total);
            setValue(billableId, (price > 0) ? total : Number(0).toFixed(2));
            Shared.calculateTotals('total_reamed', setTotalReamed);
        }
    }

    const removeFootageReamedRow = (index) => {
        remove(index);
        // recalculate total length when removing one Bottom Hole row.
        setTimeout(() => {
            Shared.calculateTotals('total_reamed', setTotalReamed);
        }, 500);
    }

    return (
            <AccordionItem className="card-header bg-primary" title="Footage Reamed">
                {isLoading ? null : (
                    <div className="card">
                        { totalReamed > 0 && fields.length > 0 &&
                            <div className="form-row" id="reamed_totals">
                                <div className="col-md-3 mb-3 total_block">
                                    <p className="form-control background_total_block_color">
                                        Total Reamed : {totalReamed}
                                    </p>
                                </div>
                            </div>
                        }
                        <div className="form-row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Total</th>
                                                <th>Billable</th>
                                                <th>Hole</th>
                                                <th/>
                                                <th className="action-cell">
                                                    <FaPlus
                                                        color='#22af47'
                                                        size={20}
                                                        title="Add New"
                                                        onClick={() => append({})}
                                                    />
                                                    <FaEraser
                                                        color='#A51E37'
                                                        className="marginLeft"
                                                        size={20}
                                                        title="Remove All"
                                                        onClick={() => remove()}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fields.map((item, index) => (
                                                <tr key={item.id}>
                                                    <SearchableSelectInTable
                                                        id={`reaming[${index}].cost_code`}
                                                        options={reamedDescriptions}
                                                        rules={{ required: "required" }}
                                                        value={(action === 'edit') ? item.cost_code?.value : ''}
                                                        onChange={(value) => selectCode(index, value)}
                                                        control={control}
                                                        errors={errors}
                                                        customStyles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                width: 500,
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                width: 500,
                                                                position: 'sticky',
                                                            })
                                                        }}
                                                    />
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`reaming[${index}].reaming_from`}
                                                        name={`reaming[${index}].reaming_from`}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 15)}
                                                        onKeyUp={(e) => calculateTotalReamedInRow(
                                                            e,
                                                            `reaming[${index}].reaming_from`,
                                                            `reaming[${index}].reaming_to`,
                                                            `reaming[${index}].reaming_total`,
                                                            `reaming[${index}].price`,
                                                            `reaming[${index}].billable`
                                                        )}
                                                        value={(action === 'edit') ? item.reaming_from : ''}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                    <InputInTable
                                                        className="hours-column-width"
                                                        id={`reaming[${index}].reaming_to`}
                                                        name={`reaming[${index}].reaming_to`}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                                        onKeyUp={
                                                            (e) => calculateTotalReamedInRow(
                                                                e,
                                                                `reaming[${index}].reaming_from`,
                                                                `reaming[${index}].reaming_to`,
                                                                `reaming[${index}].reaming_total`,
                                                                `reaming[${index}].price`,
                                                                `reaming[${index}].billable`
                                                            )
                                                        }
                                                        value={(action === 'edit') ? item.reaming_to : ''}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                    <InputInTable
                                                        className="total_reamed hours-column-width"
                                                        id={`reaming[${index}].reaming_total`}
                                                        name={`reaming[${index}].reaming_total`}
                                                        value={(action === 'edit') ? item.reaming_total : ''}
                                                        register={register({required: false})}
                                                        disabled={'true'}
                                                    />
                                                    <InputInTable
                                                        className="short-select-width"
                                                        id={`reaming[${index}].billable`}
                                                        name={`reaming[${index}].billable`}
                                                        value={(action === 'edit') ? item.billable : ''}
                                                        onInput={(e) => Shared.limitInputDaily(e.target, 9)}
                                                        register={register({required: true})}
                                                        errors={errors}
                                                    />
                                                    {action === 'create' &&
                                                        <SelectInTable
                                                            id={`reaming[${index}].hole_name`}
                                                            name={`reaming[${index}].hole_name`}
                                                            options={collectedHoles}
                                                            value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                            register={register({required: "required"})}
                                                            errors={errors}
                                                        />
                                                    }
                                                    {action === 'edit' &&
                                                        <SelectInTable
                                                            id={`reaming[${index}].hole_name`}
                                                            name={`reaming[${index}].hole_name`}
                                                            value={
                                                                selectedReamingHole
                                                                    ?
                                                                    selectedReamingHole
                                                                    :
                                                                    (collectedHoles.length === 1)
                                                                        ?
                                                                        collectedHoles[0].value
                                                                        :
                                                                        item.hole_name
                                                            }
                                                            onChange={(e) => Shared.setSelectedValue(e.target.value, setSelectedReamingHole)}
                                                            options={collectedHoles}
                                                            register={register({required: "required"})}
                                                            errors={errors}
                                                        />
                                                    }
                                                    {/*Intentionally hidden */}
                                                    <InputInTable
                                                        id={`reaming[${index}].price`}
                                                        register={register({required: false})}
                                                        errors={errors}
                                                        type="hidden"
                                                    />
                                                    <td>
                                                        <FaTrash
                                                            color='#A51E37'
                                                            size={20}
                                                            title="Remove"
                                                            onClick={() => removeFootageReamedRow(index)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </AccordionItem>
    );
}

export default FootageReamed;
